import storeService from "../../services/store";

const state = {
  storeList: [],
  isLoading: false,
};
const getters = {};
const actions = {
  // action CRU Store
  async getStoreList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await storeService.getAll(params);
      commit("SET_LIST_STORE", response.data);
      commit("SET_LOADING", false);
      return response;
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getStoreDetail({ commit }, id) {
    try {
      commit("SET_LOADING", true);
      const response = await storeService.getItem(id);
      commit("SET_LOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING", false);
      return {};
    }
  },

  async createStore({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await storeService.create(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Thêm cửa hàng thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async updateStore({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await storeService.update(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
};
const mutations = {
  SET_LIST_STORE(state, payload) {
    state.storeList = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

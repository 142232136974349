<template>
  <div>
    <b-modal size="lg" id="modal-import-warehouse" centered>
      <template #modal-header>
        <h3 class="mx-auto" style="color: #ff8084">
          Thiết lập giá sản phẩm
        </h3>
      </template>
      <div class="d-flex" style="width: 100%">
        <div class="mr-3 d-flex align-items-center" style="width: 100%">
          <p class="mb-0 mr-4">
            Tên bảng giá <span class="validate-star"> *</span>
          </p>
          <input
            v-model="dataSubmit.name"
            placeholder="Tên bảng giá"
            class="form-control rbt-custom-input"
            style="max-width: 100%"
            id="validationCustom1"
            type="text"
          />
        </div>
      </div>
      <div class="d-flex mt-3 ">
        <div class="mr-3 d-flex align-items-center">
          <p class="mb-0 mr-4">Sản phẩm<span class="validate-star"> *</span></p>

          <multiselect
            class="rbt-custom-input"
            v-model="dataImport.product"
            placeholder="Sản phẩm"
            :show-labels="false"
            :custom-label="searchingSelect"
            :options="productsActive"
          >
            <span slot="noResult">Không tìm thấy kết quả!!!</span>
            <template slot="singleLabel" slot-scope="props"
              ><span class="option__desc"
                ><span class="option__title">{{
                  props.option.name
                }}</span></span
              ></template
            >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <p class="option__title">
                  {{ props.option.name }}
                </p>
              </div>
            </template>
          </multiselect>
        </div>
        <div class="mr-3 d-flex align-items-center">
          <p class="mb-0 mr-4">
            Giảm ( % )
          </p>
          <input
            v-model="dataImport.discount"
            placeholder="Nhập % giảm"
            class="form-control rbt-custom-input"
            id="validationCustom1"
            type="number"
          />
        </div>
        <div class="d-flex align-items-center mr-3">
          <div
            class="d-flex"
            style="border-radius: 50%; background-color: #28a745"
            @click="addProduct()"
          >
            <feather
              class="rbt-icon-plus"
              style="cursor: pointer"
              type="plus"
              stroke="#f5fbf6"
              size="28px"
              fill="#f5fbf6"
            ></feather>
          </div>
        </div>
      </div>
      <div class="mt-3 d-flex">
        <div class="d-flex align-items-center">
          <p class="mb-0 mr-4">
            Thời gian<span class="validate-star"> *</span>
          </p>
          <date-picker
            style="height: 43px "
            class="rbt-custom-date rbt-date-price"
            v-model="date"
            value-type="format"
            range
            format="YYYY-MM-DD"
            placeholder="Chọn ngày để xem"
            @change="handleDate"
          ></date-picker>
        </div>
      </div>
      <div class="mt-3 d-flex">
        <div class="mr-3">
          <div class="form-group form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
              v-model="dataSubmit.is_active"
            />
            <label class="form-check-label" for="exampleCheck1">
              Áp dụng
            </label>
          </div>
        </div>
      </div>

      <div>
        <div class="table-responsive datatable-vue text-center mt-4">
          <b-modal
            id="model-remove"
            title="Xác nhận"
            @ok="removeProductInTab()"
          >
            <p class="my-4">Are you sure!</p>
          </b-modal>
          <b-table
            show-empty
            striped
            hover
            head-variant="light"
            bordered
            stacked="md"
            :items="itemsImport"
            :fields="tablefields"
          >
            <template #empty>
              <span class="text-danger">
                Chưa có sản phẩm nào
              </span>
            </template>
            <template v-slot:cell(name)="{ item }">
              <span>
                {{ item.product_name }}
              </span>
            </template>
            <template v-slot:cell(discount_price)="{ item }">
              <span>
                {{ item.discount_price | formatCurrency }} (-{{
                  item.discount
                }}%)
              </span>
            </template>
            <template v-slot:cell(price)="{ item }">
              <span>
                {{ item.price | formatCurrency }}
              </span>
            </template>
            <template v-slot:cell(actions)="{ item }">
              <span
                style="font-size: 20px; color: #FA399F;"
                @click="removeItemInTable(item.sno)"
              >
                <feather
                  style="cursor: pointer"
                  type="trash"
                  stroke="#F72E9F"
                  size="18px"
                  fill="#F72E9F"
                ></feather>
              </span>
            </template>
          </b-table>
        </div>
      </div>
      <template #modal-footer="{cancel}">
        <div style="width: 100%" class="d-flex justify-content-end">
          <div>
            <b-button class="mr-3" size="sm" variant="danger" @click="cancel()">
              Đóng
            </b-button>
            <b-button
              class="btn-popup m-0"
              @click="submit()"
              :disabled="!isComplete"
            >
              <span
                v-if="!dataSubmit.id"
                style="font-size: 24px; font-weight: 100"
              >
                +
              </span>
              <span v-if="!dataSubmit.id">
                Tạo giá
              </span>
              <span v-else>
                Cập nhật
              </span>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
const initalDataImport = {
  product: null,
  price: null,
  discount_price: null,
  discount: null,
};

const initalDataSubmit = {
  products: [],
  name: "",
  is_active: false,
  start_date: null,
  end_date: null,
};

export default {
  props: {
    priceInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  name: "price-add",
  data() {
    return {
      dataImport: { ...initalDataImport },
      dataSubmit: { ...initalDataSubmit },
      date: null,
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "product_name", label: "Sản phẩm" },
        { key: "price", label: "Giá gốc" },
        { key: "discount_price", label: "Giá khuyến mãi" },
        { key: "actions", label: "Xóa" },
      ],
    };
  },
  created() {
    this.onListProduct();
    this.getCategoryList();
  },
  methods: {
    ...mapActions("product", ["onListProduct"]),
    ...mapActions("category", ["getCategoryList"]),
    ...mapActions(["setMessage"]),
    handleDate(value) {
      this.dataSubmit.start_date = value[0];
      this.dataSubmit.end_date = value[1];
    },
    removeItemInTable(index) {
      this.dataSubmit?.products.splice(index - 1, 1);
    },
    submit() {
      if (
        this.dataSubmit.products.length > 0 &&
        this.dataSubmit.name &&
        this.dataSubmit.start_date &&
        this.dataSubmit.end_date
      ) {
        const {
          start_date,
          end_date,
          products,
          sno,
          ...data
        } = this.dataSubmit;
        // get list id product
        let productsId = [];

        productsId = products?.map((item) => {
          const { discount } = item;
          return {
            product: item.product,
            discount: parseInt(discount),
          };
        });

        // update
        if (this.dataSubmit.id) {
          this.$emit("onUpdate", {
            products: productsId,
            start_date,
            end_date,
            ...data,
          });
        }

        // create
        else {
          this.$emit("onSubmit", {
            products: productsId,
            start_date,
            end_date,
            store: this.storeID,
            ...data,
          });
        }
        this.$bvModal.hide("modal-import-warehouse");
        this.dataSubmit = { ...initalDataSubmit };
      } else {
        this.setMessage({
          type: "error",
          content:
            "Thêm sản phẩm hoặc danh mục với thời gian muốn thiết lập giá",
          isShow: true,
        });
      }
    },
    addProduct() {
      const { product, discount } = this.dataImport;
      if (discount < 1 || discount > 100) {
        this.setMessage({
          type: "error",
          content: "Giảm giá không được vượt quá khung cho phép 1-100",
          isShow: true,
        });
        return;
      }
      if (product && discount > 0) {
        this.dataSubmit?.products?.push({
          price: product?.store_price,
          discount_price: product?.store_price - product?.store_price * (discount / 100),
          discount,
          product_name: product.name,
          product: product.id,
        });
        this.dataImport = { ...initalDataImport };
      } else {
        this.setMessage({
          type: "error",
          content: "Sản phẩm và giảm giá không được để trống !",
          isShow: true,
        });
      }
    },
    searchingSelect({ name }) {
      return name;
    },
  },
  computed: {
    ...mapState("product", ["isListProduct"]),
    ...mapState("category", ["categoryList"]),
    ...mapState("profile", ["userProfile"]),
    filteredProducts() {
      return this.isListProduct.filter((item) => {
        return item.store_price != undefined;
      });
    },
    productsActive() {
      const idProductInTable = this.dataSubmit?.products?.map((item) => {
        return item.product;
      });
      return this.filteredProducts?.filter((item) => {
        return !idProductInTable?.includes(item.id);
      });
    },
    itemsImport() {
      return this.dataSubmit?.products?.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
    isComplete() {
      return this.dataSubmit.name;
    },
    storeID() {
      return this.userProfile?.store?.id;
    },
  },
  watch: {
    priceInfo() {
      if (this.priceInfo.id) {
        const { start_date, end_date, ...data } = this.priceInfo;
        this.dataSubmit = {
          start_date,
          end_date,
          ...data,
        };
        this.date = [start_date, end_date];
      } else {
        this.dataSubmit = { ...initalDataSubmit };
        this.date = null;
      }
      this.dataImport = { ...initalDataImport };
    },
  },
};
</script>

<style>
.rbt-date-price .mx-input {
  height: 43px !important;
}
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
</style>

<style scoped>
.rbt-box-input-192 {
  width: 192px;
}
.rbt-custom-input {
  max-width: 192px;
  white-space: nowrap;
  height: 43px !important;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}

/* css switch */
.rbt-switch-price .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.rbt-switch-price .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.rbt-switch-price .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.rbt-switch-price .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.rbt-switch-price input:checked + .slider {
  background-color: #008a00;
}

.rbt-switch-price input:focus + .slider {
  box-shadow: 0 0 1px #008a00;
}

.rbt-switch-price input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.rbt-switch-price .slider.round {
  border-radius: 34px;
}

.rbt-switch-price .slider.round:before {
  border-radius: 50%;
}
</style>

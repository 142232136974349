<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <b-modal id="modal-3" title="Xác nhận" @ok="onHandleSendNotifyFood()">
      <p class="my-4">
        Gửi thông báo về các món ăn nấu trong ngày
        {{ new Date().toISOString().slice(0, 10) | dateFormat }}
      </p>
    </b-modal>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Quản lý website" title="Bếp nhà Ruộng" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>{{ totalRecord }} kết quả</h5>
            </div>
            <div class="card-body">
              <div class="row mb-4">
                <div
                  class="col-sm-7 d-flex justify-content-center align-items-center"
                >
                  <div class="col-sm-5">
                    <date-picker
                      style="width: 100%"
                      v-model="cooking_date"
                      value-type="format"
                      format="DD-MM-YYYY"
                      placeholder="Chọn ngày để xem"
                      @change="onSearch"
                    ></date-picker>
                  </div>
                  <div class="col-sm-7 d-flex">
                    <b-form-group
                      class="datatable-select"
                      style="margin-bottom: 0 !important"
                    >
                      <b-form-input
                        type="text"
                        v-model="search"
                        placeholder="Tìm kiếm"
                        @change="onSearch()"
                      ></b-form-input>
                    </b-form-group>
                    <div class="ml-3">
                      <b-button
                        class="btn-popup mb-0"
                        variant="primary"
                        @click="onSearch()"
                        >Tìm</b-button
                      >
                    </div>
                  </div>
                </div>
                <div class="col-sm-5 d-flex justify-content-end">
                  <b-button class="btn btn-success mr-2" v-b-modal.modal-3
                    >Gửi thông báo</b-button
                  >
                  <b-button class="btn btn-info" @click="openModalImport()">
                    <span style="font-size: 24px; font-weight: 100">
                      +
                    </span>
                    Nấu món ăn</b-button
                  >
                </div>
              </div>
              <!-- {{ warehouseImportList }} -->
              <div class="table-responsive datatable-vue product-list">
                <b-modal id="modal-2" title="Xác nhận" @ok="onDelete(isId)">
                  <p class="my-4">
                    Xóa <b>{{ isName }}</b>
                  </p>
                </b-modal>
                <b-table
                  v-if="!isLoading && foodList.length > 0"
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="foodActive"
                  :fields="tablefields"
                >
                  <template v-slot:cell(food)="{ item }">
                    <span>
                      {{ item.food.name }}
                    </span>
                  </template>
                  <template v-slot:cell(status)="{ item }">
                    <b-badge :variant="colorStatus[item.status]">
                      {{ FOOD_STATUS[item.status] }}
                    </b-badge>
                  </template>
                  <template v-slot:cell(material_warehouse)="{ item }">
                    <span>
                      {{ item.material_warehouse.name }}
                    </span>
                  </template>
                  <template v-slot:cell(kitchen_warehouse)="{ item }">
                    <span>
                      {{ item.kitchen_warehouse.name }}
                    </span>
                  </template>
                  <template v-slot:cell(cooking_date)="{ item }">
                    <span v-if="item.cooking_date">
                      {{
                        item.cooking_date
                          .split("-")
                          .reverse()
                          .join("-")
                      }}
                    </span>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="onEdit(item)"
                      ></feather>
                    </span>
                    <span style="font-size: 20px; color: #FA399F;">
                      <feather
                        v-b-modal.modal-2
                        style="cursor: pointer"
                        type="trash"
                        stroke="#F72E9F"
                        size="18px"
                        fill="#F72E9F"
                        @click="setItem(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="!isLoading && foodList.length === 0"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  total-rows="5"
                  per-page="10"
                  aria-controls="my-table"
                  class="mt-4"
                ></b-pagination>
              </b-col>
              <FoodModal
                :foodInfo="foodInfo"
                @onCreate="onCreate"
                @onUpdate="onUpdate"
              />
              <!-- @onApproved="onApproved" -->
              <!-- @onCancel="onCancel" -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import FoodModal from "../../components/modals/FoodModal.vue";
import { FOOD_STATUSES } from "../../config/food/index";

export default {
  components: {
    FoodModal,
  },
  data() {
    return {
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "kitchen_warehouse", label: "Bếp" },
        { key: "material_warehouse", label: "Kho nguyên liệu" },
        { key: "food", label: "Món ăn" },
        { key: "quantity", label: "Số lượng" },
        { key: "note", label: "Mô tả" },
        { key: "status", label: "Trạng thái" },
        { key: "cooking_date", label: "Ngày nấu" },
        { key: "actions", label: "Hoạt động" },
      ],
      search: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      isId: "",
      isName: "",
      priceInfo: {},
      foodInfo: {},
      cooking_date: "",
    };
  },
  components: {
    Loader,
  },
  created() {
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = { page: pageNumber, page_size: this.pageSize };
    this.onFoodList(params);
  },
  computed: {
    ...mapState("warehouse", [
      "warehouseList",
      "totalRecord",
      "warehouseImportList",
    ]),
    ...mapState("food", ["foodList", "totalRecord", "isLoading"]),
    colorStatus() {
      return FOOD_STATUSES.APPEND_COLOR;
    },
    FOOD_STATUS() {
      return FOOD_STATUSES.STATUS;
    },
    foodActive() {
      return this.foodList?.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
  },
  methods: {
    ...mapActions("warehouse", [
      "onPriceList",
      "deleteWarehouse",
      "onUpdateWarehouseImport",
      "onApprovedWarehouseImport",
      "onDeleteWarehouseImport",
      "onWarehouseImportCancel",
    ]),
    ...mapActions("product", [
      "onPriceList",
      "onUpdatePrice",
      "onCreatePrice",
      "removePrice",
    ]),
    ...mapActions("food", [
      "onFoodList",
      "onUpdateFood",
      "deleteFood",
      "onCreateFood",
      "sendNotifyFood",
    ]),
    onCreate(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onCreateFood({ data: payload, params });
    },
    onHandleSendNotifyFood() {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.sendNotifyFood({ params });
    },
    onUpdate(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onUpdateFood({ data: payload, params });
    },
    onDelete(id) {
      const params = {
        page: this.pageNumber,
        page_size: this.pageSize,
        cooking_date: this.cooking_date
          ?.split("-")
          .reverse()
          .join("-"),
        search: this.search,
      };
      this.deleteFood({ id: id, params });
    },
    onApproved(payload) {
      this.onApprovedWarehouseImport(payload);
    },
    openModalImport() {
      this.foodInfo = {};
      this.$bvModal.show("modal-import-warehouse");
    },
    setItem(item) {
      this.isId = item.id;
      this.isName = item.food.name;
    },
    onEdit(item) {
      this.foodInfo = { ...item };
      this.$bvModal.show("modal-import-warehouse");
    },
    onSearch() {
      const params = {
        cooking_date: this.cooking_date
          ?.split("-")
          .reverse()
          .join("-"),
        search: this.search,
        page: this.pageNumber,
        page_size: this.pageSize,
      };
      this.onFoodList(params);
    },
    handlePageChange(value) {
      this.pageNumber = value;
      const params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.search) {
        const data = {
          cooking_date: this.cooking_date
            ?.split("-")
            .reverse()
            .join("-"),
          search: this.search,
          ...params,
        };
        this.onFoodList(data);
      } else {
        this.onFoodList(params);
        this.$router.push({
          path: "/product/price-list",
          query: { page: this.pageNumber, page_size: this.pageSize },
        });
      }
    },
  },
};
</script>

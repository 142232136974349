<template>
  <div>
    <b-modal
      id="cancel-order-modal"
      title="Xác nhận"
      @ok="cancelOrderSupplier()"
    >
      <p class="my-4" v-if="dataSubmit">
        Bạn có muốn hủy đơn hàng <strong>{{ dataSubmit.name }}</strong>
      </p>
    </b-modal>
    <b-modal id="modal-approve" title="Xác nhận" @ok="approveOrderSupplier()">
      <p class="my-4" v-if="dataSubmit">
        Xác nhận duyệt đơn hàng <strong>{{ dataSubmit.name }}</strong> và gửi
        cho nhà cung cấp!!!
      </p>
    </b-modal>
    <b-modal size="xl" id="order-supplier-modal" centered>
      <template #modal-header>
        <h3 class="mx-auto" style="color: #ff8084">ĐƠN HÀNG NHÀ CUNG CẤP</h3>
      </template>
      <div>
        <b-form-group
          label-cols-md="2"
          label-cols-lg="2"
          content-cols-sm
          content-cols-md="10"
        >
          <template v-slot:label>
            Tên đơn hàng<span class="validate-star"> *</span></template
          >
          <input
            :disabled="!!dataSubmit.id"
            type="text"
            class="form-control rbt-custom-input"
            style="max-width: 100% !important"
            v-model="dataSubmit.name"
            placeholder="Nhập tên đơn hàng"
          />
        </b-form-group>
        <b-form-group
          label-cols-md="2"
          label-cols-lg="2"
          content-cols-sm
          content-cols-md="10"
        >
          <template v-slot:label
            >Nhà cung cấp<span class="validate-star"> *</span></template
          >
          <multiselect
            v-if="!dataSubmit.id"
            class="rbt-custom-input"
            style="max-width: 100% !important"
            placeholder="Chọn nhà cung cấp"
            :options="supplierList"
            v-model="dataSubmit.supplier"
            :show-labels="false"
            :custom-label="searchingSelect"
          >
            <span slot="noResult">Không tìm thấy kết quả!!!</span>
            <template slot="singleLabel" slot-scope="props"
              ><span class="option__desc"
                ><span class="option__title">{{
                  props.option.name
                }}</span></span
              ></template
            >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <p class="option__title">
                  {{ props.option.name }}
                </p>
              </div>
            </template>
          </multiselect>
          <input
            readonly
            v-else
            v-model="dataSubmit.supplier_name"
            class="form-control rbt-custom-input"
            style="max-width: 100% !important"
            type="text"
          />
        </b-form-group>
        <b-form-group
          label-cols-md="2"
          label-cols-lg="2"
          content-cols-sm
          content-cols-md="10"
          v-if="!dataSubmit.id"
        >
          <template v-slot:label
            >Lấy từ báo giá<span class="validate-star"> *</span></template
          >
          <multiselect
            class="rbt-custom-input"
            style="max-width: 100% !important"
            placeholder="Chọn báo giá"
            :disabled="!dataSubmit.supplier"
            :show-labels="false"
            v-model="dataSubmit.quotation"
            @input="changeQuotation"
            :options="quotationsList"
            :custom-label="searchingSelect"
          >
            <span slot="noResult">Không tìm thấy kết quả!!!</span>
            <template slot="singleLabel" slot-scope="props"
              ><span class="option__desc"
                ><span class="option__title">{{
                  props.option.name
                }}</span></span
              ></template
            >
            <template slot="option" slot-scope="props">
              <div class="option__desc d-block">
                <p class="option__title font-weight-bold">
                  {{ props.option.name }}
                </p>
              </div>
            </template>
          </multiselect>
        </b-form-group>
        <b-form-group
          label-cols-md="2"
          label-cols-lg="2"
          content-cols-sm
          content-cols-md="10"
        >
          <template v-slot:label> Thời gian nhận hàng</template>
          <b-form-datepicker
            label-today
            class="form-control rbt-custom-date"
            label-help
            id="datepicker-placeholder"
            placeholder="Chọn thời gian"
            style="font-size: 14px"
            locale="vi"
            v-model="dataSubmit.recieve_time"
          ></b-form-datepicker>
        </b-form-group>
        <hr />
      </div>
      <div>
        <div class="table-responsive datatable-vue text-center mt-4">
          <b-table
            show-empty
            striped
            hover
            head-variant="light"
            bordered
            :items="productInTable"
            :fields="tablefields"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template #empty>
              <span class="text-danger"> Chưa có sản phẩm nào </span>
            </template>
            <template v-slot:cell(name)="{ item }">
              <span> {{ item.name }} </span>
            </template>
            <template v-slot:cell(quotation_price)="{ item }">
              <span v-if="item.quoted_quantity">
                {{ item.quotation_price | formatCurrency }}
              </span>
              <span v-else>_</span>
            </template>
            <template v-slot:cell(price_recommend)="{ item }">
              <span v-if="item.quoted_quantity">
                {{ item.price_recommend | formatCurrency }}
              </span>
              <span v-else>_</span>
            </template>
            <template v-slot:cell(totalPrice)="{ item }">
              <span>
                {{ totalPriceTable(item.sno) | formatCurrency }}
              </span>
            </template>
            <template v-slot:cell(quoted_quantity)="{ item }">
              <IncreaseAndDecrease
                v-if="!dataSubmit.id"
                :isDiscount="true"
                :isNumber="Number(item.quoted_quantity)"
                :product="item"
                @onChangeData="onChangeQuotedQuantity"
              />
              <span v-else>
                {{ item.quoted_quantity }}
              </span>
            </template>
          </b-table>
          <div>
            <b-pagination
              v-if="productInTable.length > 0"
              class="mt-2 d-flex justify-content-end"
              v-model="currentPage"
              :total-rows="productInTable.length"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
          <!-- <div>
            <textarea
              :disabled="dataSubmit.id"
              v-model="dataSubmit.delivery_method"
              rows="2"
              @change="changeNote"
              placeholder="Phương thức giao hàng"
              class="form-control mt-2"
            ></textarea>
          </div> -->
          <div>
            <textarea
              :disabled="dataSubmit.id"
              v-model="dataSubmit.note"
              rows="2"
              @change="changeNote"
              placeholder="Ghi chú đơn hàng"
              class="form-control mt-2"
            ></textarea>
          </div>
          <div class="mt-3 d-flex">
            <span>Tổng tiền: </span>
            <span class="ml-2" style="color: red">
              {{ allPrice | formatCurrency }}
            </span>
          </div>
        </div>
      </div>
      <template #modal-footer="{ cancel }">
        <div style="width: 100%" class="d-flex justify-content-between">
          <div>
            <h2>
              <span
                v-if="dataSubmit.status === ORDER_SUPPLIER_TEXT.CANCEL"
                class="badge badge-danger"
                >Đã huỷ</span
              >
            </h2>
            <h2>
              <span
                v-if="dataSubmit.status === ORDER_SUPPLIER_TEXT.REJECTED"
                class="badge badge-danger"
                >NCC Hủy</span
              >
            </h2>
            <h2>
              <span
                v-if="dataSubmit.status === ORDER_SUPPLIER_TEXT.APPROVED_2"
                class="badge badge-primary"
                >Đã được NCC duyệt</span
              >
            </h2>
            <h2>
              <span
                v-if="dataSubmit.status === ORDER_SUPPLIER_TEXT.APPROVED"
                class="badge badge-primary"
                >Đã duyệt</span
              >
            </h2>
            <h2>
              <span
                v-if="dataSubmit.status === ORDER_SUPPLIER_TEXT.CREATE"
                class="badge badge-success"
                >Chờ duyệt</span
              >
            </h2>
          </div>
          <div>
            <b-button class="mr-3" size="sm" variant="danger" @click="cancel()">
              Đóng
            </b-button>
            <b-button
              v-if="!dataSubmit.id"
              :disabled="!isComplete"
              class="btn-popup m-0"
              @click="createOrderSupplier()"
            >
              <span style="font-size: 24px; font-weight: 100"> + </span>
              <span v-if="!dataSubmit.id"> Tạo đơn hàng</span>
            </b-button>
            <!-- <b-button
              v-if="dataSubmit.status === ORDER_SUPPLIER_TEXT.CREATE"
              :disabled="!isComplete"
              class="btn-popup mr-3 my-0"
              @click="updateOrderSupplier()"
            >
              Cập nhật</b-button
            > -->
            <b-button
              v-if="dataSubmit.status === ORDER_SUPPLIER_TEXT.CREATE"
              class="btn-popup m-0"
              :disabled="checkDisable"
              @click="openModalApprove()"
            >
              Duyệt và gửi nhà cung cấp</b-button
            >
          </div>
        </div>
      </template>
    </b-modal>
    <OrderSupplierModal :orderInfo="orderInfo" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { PAYMENT_STATUS } from "../../config/payment/index";
import {
  WAREHOUSE_STATUS,
  ORDER_SUPPLIER_STATUS,
} from "../../config/warehouse/index";
import IncreaseAndDecrease from "../../components/UI/increaseAndDecrease.vue";
import OrderSupplierModal from "../../components/modals/order/OrderSupplierModal.vue";

const initialDataSubmit = {
  product: [],
  name: "",
  note: null,
  recieve_time: new Date().toISOString().slice(0, 10),
  total: 0,
  pre_payment: 0,
  remain_payment: 0,
  status: null,
  quotation: null,
  supplier: null,
  payment_status: null,
  delivery_method: "",
  discount: 0,
};

export default {
  components: {
    IncreaseAndDecrease,
    OrderSupplierModal,
  },
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  name: "WarehouseImport",
  data() {
    return {
      currentPage: 1,
      perPage: process.env.VUE_APP_PAGE_SIZE,
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "name", label: "Sản phẩm" },
        { key: "product_unit", label: "Đơn vị nhập" },
        { key: "quotation_price", label: "Giá nhập" },
        { key: "price_recommend", label: "Giá khuyến nghị" },
        { key: "quoted_quantity", label: "Số lượng nhập" },
        { key: "totalPrice", label: "Thành tiền" },
      ],
      dataSubmit: { ...initialDataSubmit },
      productInTable: [],
      checkDisable: false,
      WAREHOUSE_STATUS_TEXT: WAREHOUSE_STATUS.TEXT,
      ORDER_SUPPLIER_TEXT: ORDER_SUPPLIER_STATUS.TEXT,
    };
  },
  methods: {
    ...mapActions("supplier", ["onQuotationsList", "getQuotationDetail"]),
    ...mapActions(["setMessage"]),
    createOrderSupplier() {
      const { name, supplier, quotation } = this.dataSubmit;
      if ((supplier, quotation, name, this.checkedProductList.length > 0)) {
        this.handleType({
          type: "add",
        });
        this.$bvModal.hide("order-supplier-modal");
      } else if (!supplier) {
        this.setMessage({
          type: "error",
          content: "Vui lòng chọn nhà cung cấp",
          isShow: true,
        });
        return;
      } else if (!quotation) {
        this.setMessage({
          type: "error",
          content: "Vui lòng chọn báo giá",
          isShow: true,
        });
        return;
      } else if (this.checkedProductList.length == 0) {
        this.setMessage({
          type: "error",
          content: "Báo giá không có sản phẩm, hãy chọn báo giá khác",
          isShow: true,
        });
        return;
      }
    },
    handleType({ type }) {
      const {
        supplier,
        quotation,
        product,
        recieve_time,
        status,
        total,
        payment_status,
        created_by,
        ...data
      } = this.dataSubmit;

      const productActive = this.checkedProductList.map((item) => {
        return {
          unit_price: item.quotation_price || 0,
          quantity: item.quoted_quantity || 0,
          product: item.product,
          price_recommend: item.price_recommend || 0,
        };
      });

      const supplierOrderData = {
        ...data,
        supplier: supplier.id,
        quotation: quotation?.id,
        recieve_time: recieve_time,
        products: productActive,
        payment_status: PAYMENT_STATUS.PAID,
        total: this.allPrice,
        ...(created_by && created_by.id),
      };

      switch (type) {
        case "add":
          this.$emit("onCreate", {
            status: this.ORDER_SUPPLIER_TEXT.CREATE,
            ...supplierOrderData,
          });
          break;

        case "update":
          this.$emit("onUpdate", {
            status: this.ORDER_SUPPLIER_TEXT.CREATE,
            ...supplierOrderData,
          });
          break;

        case "approve":
          this.$emit("onUpdate", {
            status: this.ORDER_SUPPLIER_TEXT.APPROVED,
            ...supplierOrderData,
          });
          break;

        case "cancel":
          this.$emit("onCancel", {
            status: this.ORDER_SUPPLIER_TEXT.CANCEL,
            ...supplierOrderData,
          });
          break;
      }

      this.checkDisable = false;
    },
    totalPriceTable(index) {
      const { quotation_price, quoted_quantity } =
        this.checkedProductList[index - 1] || {};
      const priceTemp = quotation_price || 0;
      const quantityTemp = quoted_quantity || 0;
      return quantityTemp === 0 ? 0 : Math.trunc(quantityTemp * priceTemp);
    },
    onChangeQuotedQuantity({ quantity, product }) {
      this.productInTable.forEach((item) => {
        if (item.product === product.product) {
          const { quotationPrice, recommendedPrice } = this.getPriceFromRanges(
            product,
            quantity
          );
          item.quoted_quantity = quantity;
          item.quotation_price = quotationPrice;
          item.price_recommend = recommendedPrice;
        }
      });
      this.checkDisable = true;
    },
    // Get the price of the product based on the quantity.
    getPriceFromRanges(product, quantity) {
      let quotationPrice = 0;
      let recommendedPrice = 0;
      const quotationRange = product.promotion_according_to_quantity;
      if (quotationRange && quotationRange.length > 0) {
        const priceTemp = quotationRange.find((item) => {
          return (
            quantity <= item.highest_weight && quantity >= item.lowest_weight
          );
        });

        quotationPrice =
          priceTemp?.unit_price ?? quotationRange.at(-1).unit_price;
        recommendedPrice =
          priceTemp?.recommended_price ??
          quotationRange.at(-1).recommended_price;
      }

      return { quotationPrice, recommendedPrice };
    },
    openModalApprove() {
      this.$bvModal.show("modal-approve");
    },
    cancelOrderSupplier() {
      this.handleType({
        type: "cancel",
      });
      this.$bvModal.hide("order-supplier-modal");
    },
    searchingSelect({ name }) {
      return name;
    },
    updateOrderSupplier() {
      this.handleType({
        type: "update",
      });
      this.$bvModal.hide("order-supplier-modal");
    },
    approveOrderSupplier() {
      this.handleType({
        type: "approve",
      });
      this.$bvModal.hide("order-supplier-modal");
    },
    changeNote() {
      this.checkDisable = true;
    },
    async changeQuotation(quotation) {
      if (quotation && !this.dataSubmit.id) {
        const quotationSelect = await this.getQuotationDetail(quotation.id);
        const groupedData = {};
        quotationSelect.products.forEach((item) => {
          const key = item.product;
          if (!groupedData[key]) {
            groupedData[key] = {
              product: key,
              name: item.name,
              product_unit: item.unit,
              promotion_according_to_quantity: [],
            };
          }

          groupedData[key].promotion_according_to_quantity.push({
            lowest_weight: item.lowest_weight,
            highest_weight: item.highest_weight,
            unit_price: item.unit_price,
            recommended_price: item.recommended_price,
          });
        });

        const result = Object.values(groupedData);

        this.productInTable = result.map((item, index) => {
          return {
            sno: index + 1,
            check: "true",
            quotation_price: item.price,
            quoted_quantity: item.quantity,
            price_recommend: item.price_recommend,
            ...item,
          };
        });
      }
    },
  },
  computed: {
    ...mapState("supplier", ["supplierList", "quotationsList"]),
    allPrice() {
      let price = 0;
      this.checkedProductList.forEach((item) => {
        price += this.totalPriceTable(item.sno);
      });
      return price;
    },
    checkedProductList() {
      return this.productInTable;
    },
    isComplete() {
      return this.dataSubmit.name;
    },
  },
  watch: {
    "dataSubmit.supplier"() {
      if (this.dataSubmit.supplier.id && !this.dataSubmit.id) {
        this.dataSubmit.quotation = null;
        this.productInTable = [];
        this.onQuotationsList({
          supplier: this.dataSubmit.supplier.id,
          status: this.WAREHOUSE_STATUS_TEXT.APPROVED,
        });
      } else if (!this.dataSubmit.supplier.id && !this.dataSubmit.id) {
        this.dataSubmit.quotation = null;
      }
    },
    orderInfo() {
      this.dataSubmit = { ...this.orderInfo };
      this.checkDisable = false;
      if (this.dataSubmit.id) {
        // set product in table
        this.productInTable = this.dataSubmit.products.map((item, index) => {
          return {
            sno: index + 1,
            check: "true",
            quotation_price: item.unit_price,
            quoted_quantity: item.quantity,
            price_recommend: item.price_recommend,
            product_unit: item.import_unit,
            product_name: item.name,
            product_id: item.id,
            ...item,
          };
        });
      } else {
        this.dataSubmit = { ...initialDataSubmit };
        this.productInTable = [];
      }
    },
  },
};
</script>

<style>
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
</style>

<style scoped>
.rbt-custom-input {
  max-width: 300px;
  white-space: nowrap;
  height: 43px !important;
}
.rbt-custom-date {
  max-width: 300px;
  white-space: nowrap;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
input {
  height: 100% !important;
}
</style>

export const EXPORT_STATUS = {
  NUMERIC: {
    NEW: "Mới tạo",
    APPROVED: "Đã duyệt",
    REJECTED: "Hủy bỏ",
  },
  TEXT: {
    CREATE: "NEW",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
  },
  APPEND_COLOR: {
    CREATE: "warning",
    APPROVED: "primary",
    REJECTED: "danger",
  },
};

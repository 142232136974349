<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Quản lý website" title="Sản phẩm mới" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <h5>{{ totalRecord }} kết quả</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <b-form-group class="datatable-select col-md-2">
                  <b-form-input
                    type="text"
                    v-model="search"
                    placeholder="Tìm kiếm"
                    @change="onSearch()"
                  ></b-form-input>
                </b-form-group>
                <b-form-select
                  v-model="selectCategory"
                  @change="onSearch()"
                  class="col-md-2 mr-2"
                >
                  <option value="">Danh mục</option>
                  <option
                    v-for="category in categoryList"
                    :key="category.index"
                    :value="category.id"
                    >{{ category.name }}</option
                  >
                </b-form-select>
                <b-form-select
                  v-model="is_new"
                  @change="onSearch()"
                  class="col-md-2 mr-2"
                >
                  <option value="">Trạng thái</option>
                  <option value="true">Sản phẩm mới</option>
                  <option value="false">Sản phẩm thường</option>
                </b-form-select>
                <div class="col-md-1 mr-2">
                  <b-button
                    class="btn-popup"
                    variant="primary"
                    @click="onSearch()"
                    >Tìm</b-button
                  >
                </div>
              </div>
              <div class="table-responsive datatable-vue product-list">
                <b-table
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="isListProduct"
                  :fields="tablefields"
                >
                  <template #cell(image)="{item}">
                    <img
                      v-if="item.image"
                      height="60px"
                      :src="item.image"
                      width="60px"
                    />
                    <img
                      v-if="!item.image"
                      height="60px"
                      src="../../assets/images/image_default.png"
                      width="60px"
                    />
                  </template>
                  <template v-slot:cell(brand)="{ item }">
                    <span v-if="item.brand">
                      {{ item.brand.name }}
                    </span>
                  </template>
                  <template v-slot:cell(category)="{ item }">
                    <span v-if="item.category">
                      {{ item.category.name }}
                    </span>
                  </template>
                  <template v-slot:cell(name)="{ item }">
                    <span
                      v-if="
                        Number(item.product_inventory_quantity) <
                          Number(item.min_inventory)
                      "
                      v-b-tooltip.hover
                      title="Số lượng đã đạt đến mức tồn kho tối thiểu"
                      class="text-warning"
                    >
                      {{ item.name }}
                    </span>
                    <span v-else>{{ item.name }}</span>
                  </template>
                  <template v-slot:cell(expiry)="{ item }">
                    <span v-if="item.expiry">
                      {{ item.expiry  | dateFormat }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </template>
                  <template v-slot:cell(price)="{ value }">
                    <span>
                      {{ value | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(active)="{ item }">
                    <input
                      type="checkbox"
                      id="flexCheckChecked2"
                      @change="onChangeNew($event, item)"
                      v-model="item.is_new"
                    /><span> Sản phẩm mới</span>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="!isLoading && isListProduct.length === 0"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRecord > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecord"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import XLSX from "xlsx";

const keyExcelToJson = {
  "Nhóm hàng": "category",
  "Mã hàng": "code",
  "Tên hàng": "name",
  "Thương hiệu": "brand",
  "Giá bán": "price",
  ĐVT: "unit",
  "Tồn lớn nhất": "preservation",
};

export default {
  data() {
    return {
      tablefields: [
        { key: "image", label: "Ảnh" },
        { key: "name", label: "Tên sản phẩm" },
        {
          key: "price", label: "Giá bán", formatter: (_, key, item) => {
            if (item?.price_detail?.store_price) {
              const store = item?.price_detail?.store_price.find(store => store.store_id === this.storeID)
              if (!store) {
                return 0
              }
              const { price, sale_price } = store
              return price > sale_price ? sale_price : price
            }
            return 0
          }
        },
        {
          key: "product_inventory_quantity", label: "Tồn kho", formatter: (_, key, item) => {
            const warehouseItem = item.inventories.find(item => item.warehouse === this.idWarehouse)
            return warehouseItem?.quantity ?? '-'
          }
        },
        { key: "unit", label: "Đơn vị bán" },
        { key: "expiry", label: "Hạn sử dụng" },
        { key: "category", label: "Danh mục" },
        { key: "brand", label: "Thương hiệu" },
        { key: "active", label: "Trạng thái" },
      ],
      search: "",
      pageNumber: 1,
      selectCategory: "",
      is_new: "",
      pageSize: process.env.VUE_APP_PAGE_SIZE,
    };
  },
  components: {
    Loader,
  },
  created() {
    this.pageNumber = this.$route.query.page || 1;
    this.selectCategory = this.$route.query.category || "";
    this.search = this.$route.query.search || "";
    this.is_new = this.$route.query.is_new || "";

    let params = {
      page: this.pageNumber,
      page_size: this.pageSize,
    };
    if (this.$route?.query?.category) {
      params = {
        category: this.selectCategory,
        ...params,
      };
    }
    if (this.$route?.query?.search) {
      params = {
        search: this.search,
        ...params,
      };
    }
    if (this.$route?.query?.is_new) {
      params = {
        is_new: this.is_new,
        ...params,
      };
    }
    this.onListProduct(params);
    this.getCategoryList();
    this.getStoreList()
  },
  computed: {
    ...mapState("category", ["categoryList"]),
    ...mapState("product", ["isListProduct", "totalRecord", "isLoading"]),
    ...mapState("profile", ["userProfile"]),
    ...mapState("store", ["storeList"]),
    storeID() {
      return this.userProfile?.store?.id;
    },
    idWarehouse() {
      const result = this.storeList.find((item) => item.id === this.storeID);
      return result?.warehouse;
    },
  },
  watch: {},
  methods: {
    ...mapActions("product", ["onListProduct", "updateStatusProduct"]),
    ...mapActions("category", ["getCategoryList"]),
    ...mapActions("store", ["getStoreList"]),
    onSearch() {
      let params = {
        page: 1,
        page_size: this.pageSize,
      };
      if (this.search) {
        params = {
          search: this.search.trim(),
          ...params,
        };
      }
      if (this.selectCategory) {
        params = {
          category: this.selectCategory,
          ...params,
        };
      }
      if (this.is_new) {
        params = {
          is_new: this.is_new,
          ...params,
        };
      }
      this.onListProduct(params);
      this.pageNumber = 1;

      const path = `/product/new-product?page=1&search=${this.search}
                    &category=${this.selectCategory}&is_new=${this.is_new}`;

      if (this.$router.currentRoute.fullPath !== path) {
        this.$router.push({
          path: "/product/new-product",
          query: {
            page: this.pageNumber,
            search: this.search,
            category: this.selectCategory,
            is_new: this.is_new,
          },
        });
      }
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.search) {
        params = {
          search: this.search.trim(),
          ...params,
        };
      }
      if (this.selectCategory) {
        params = {
          category: this.selectCategory,
          ...params,
        };
      }
      if (this.is_new) {
        params = {
          is_new: this.is_new,
          ...params,
        };
      }
      this.onListProduct(params);
      this.$router.push({
        path: "/product/new-product",
        query: {
          page: this.pageNumber,
          search: this.search,
          category: this.selectCategory,
          is_new: this.is_new,
        },
      });
    },
    onChangeNew(event, product) {
      const value = event.target.checked;
      const { id, price } = product;
      const dataSubmit = {
        is_new: value,
        product: id,
        price: (() => {
          if (product?.price_detail?.store_price) {
            const _store = product?.price_detail?.store_price.find(s => s.store_id === this.storeID)
            return _store.price;
          }
          return 0
        })(),
        store: this.storeID,
      };
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.search) {
        params = {
          search: this.search.trim(),
          ...params,
        };
      }
      if (this.selectCategory) {
        params = {
          category: this.selectCategory,
          ...params,
        };
      }
      if (this.is_new) {
        params = {
          is_new: this.is_new,
          ...params,
        };
      }
      this.updateStatusProduct({ data: dataSubmit, params });
    },
  },
};
</script>

<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Kiểm kho" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5 v-if="this.warehouseInventoryList.length > 0">
                {{ totalRecord }} kết quả
              </h5>
            </div>
            <div class="card-body">
              <div class="row mb-4">
                <div
                  class="col-sm-8 d-flex justify-content-center align-items-center"
                >
                  <div class="col-sm-4">
                    <b-form-select @change="onSearchAll" v-model="warehouse">
                      <option value="">Chọn kho hàng</option>
                      <option
                        v-for="warehouse in warehouseList"
                        :key="warehouse.index"
                        :value="warehouse.id"
                        >{{ warehouse.name }}</option
                      >
                    </b-form-select>
                  </div>
                  <div class="col-sm-8 d-flex">
                    <b-form-group
                      class="datatable-select"
                      style="margin-bottom: 0 !important"
                    >
                      <b-form-input
                        type="text"
                        v-model="search"
                        placeholder="Tìm kiếm"
                        @change="onSearchAll"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-select
                      class="ml-3 col-sm-4"
                      @change="onSearchAll"
                      v-model="status"
                    >
                      <option value="">Trạng thái</option>
                      <option
                        v-for="status in STATUS_DEFAULT"
                        :key="status.index"
                        :value="status.id"
                        >{{ status.name }}</option
                      >
                    </b-form-select>
                    <div class="ml-3">
                      <b-button
                        class="btn-popup mb-0"
                        variant="primary"
                        @click="onSearchAll"
                        >Tìm</b-button
                      >
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 d-flex justify-content-end">
                  <b-button class="btn-popup m-0" @click="openModalCreate()">
                    <span style="font-size: 24px; font-weight: 100">
                      +
                    </span>
                    Tạo phiếu kiểm kho</b-button
                  >
                </div>
              </div>
              <div class="table-responsive datatable-vue product-list">
                <b-modal id="modal-2" title="Xác nhận" @ok="onDelete()">
                  <p class="my-4">
                    Xóa <b>{{ isName }}</b>
                  </p>
                </b-modal>
                <b-table
                  v-if="!isLoading && warehouseInventoryList.length > 0"
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="inventoriesWithSno"
                  :fields="tablefields"
                >
                  <template v-slot:cell(warehouse)="{ item }">
                    <span v-if="item.warehouse">
                      {{ item.warehouse.name }}
                    </span>
                  </template>
                  <template v-slot:cell(inventory_officer)="{ item }">
                    <span v-if="item.inventory_officer">
                      {{ item.inventory_officer.name }}
                    </span>
                  </template>
                  <template v-slot:cell(created_at)="{ item }">
                    <span v-if="item.created_at">
                      {{ item.created_at | showDate }}
                    </span>
                  </template>
                  <template v-slot:cell(status)="{ item }">
                    <b-badge :variant="colorStatus[item.status]">
                      {{ WAREHOUSE_STATUS[item.status] }}
                    </b-badge>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="openModalDetail(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="!isLoading && warehouseInventoryList.length === 0"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="warehouseInventoryList.length > 0 && totalRecord > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecord"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
              <WarehouseInventory
                :inventoryInfo="inventoryDetail"
                @onAddCheckWarehouse="onAddCheckWarehouse"
                @onUpdateCheckWareHouse="onUpdateCheckWareHouse"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import WarehouseInventory from "../../components/modals/WarehouseInventory.vue";
import { OBJECT_STATUS } from "../../config/objects-status/index";
import { WAREHOUSE_STATUS } from "../../config/warehouse/index";

export default {
  components: {
    WarehouseInventory,
  },
  data() {
    return {
      tablefields: [
        { key: "code", label: "Mã đơn" },
        { key: "name", label: "Tên kiểm kê" },
        { key: "inventory_officer", label: "Nhân viên kiểm kê" },
        { key: "warehouse", label: "Kho" },
        { key: "created_at", label: "Thời gian tạo" },
        { key: "status", label: "Trạng thái" },
        { key: "actions", label: "Hành động" },
      ],
      search: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      isId: "",
      isName: "",
      warehouse: "",
      status: "",
      inventoryDetail: {},
    };
  },
  components: {
    Loader,
  },
  created() {
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = { page: pageNumber, page_size: this.pageSize };
    this.getCheckListWarehouse(params);
  },
  computed: {
    ...mapState("warehouse", [
      "totalRecord",
      "isLoading",
      "warehouseInventoryList",
      "warehouseList",
    ]),
    colorStatus() {
      return WAREHOUSE_STATUS.APPEND_COLOR;
    },
    WAREHOUSE_STATUS() {
      return WAREHOUSE_STATUS.NUMERIC;
    },
    STATUS_DEFAULT() {
      return OBJECT_STATUS.STATUS_DEFAULT;
    },
    inventoriesWithSno() {
      return this.warehouseInventoryList?.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
  },
  watch: {},
  methods: {
    ...mapActions("warehouse", [
      "getCheckListWarehouse",
      "addCheckWarehouse",
      "updateCheckWarehouse",
      "warehouseCheckDetail",
      "getInventoryList",
    ]),
    onUpdateCheckWareHouse(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.updateCheckWarehouse({ data: payload, params });
      (this.warehouse = ""), (this.search = ""), (this.status = "");
    },
    async openModalDetail(inventory) {
      const { id } = inventory;
      this.inventoryDetail = await this.warehouseCheckDetail(id);
      this.$bvModal.show("modal-inventory-warehouse");
    },
    onDelete() {},
    onAddCheckWarehouse(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.addCheckWarehouse({ data: payload, params });
      (this.warehouse = ""), (this.search = ""), (this.status = "");
    },
    openModalCreate() {
      this.inventoryDetail = {};
      this.$bvModal.show("modal-inventory-warehouse");
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.search) {
        params = {
          query: this.search,
          ...params,
        };
      }
      if (this.warehouse) {
        params = {
          warehouse_id: this.warehouse,
          ...params,
        };
      }
      if (this.search) {
        params = {
          query: this.search.trim(),
          ...params,
        };
      }
      if (this.status || this.status == 0) {
        params = {
          status: this.status,
          ...params,
        };
      }
      this.getCheckListWarehouse(params);
      this.$router.push({
        path: "/warehouse/check-inventory",
        query: { page: this.pageNumber },
      });
    },
    onSearchAll() {
      let params = {
        page: 1,
        page_size: this.pageSize,
      };
      if (this.warehouse) {
        params = {
          warehouse_id: this.warehouse,
          ...params,
        };
      }
      if (this.search) {
        params = {
          query: this.search.trim(),
          ...params,
        };
      }
      if (this.status || this.status == 0) {
        params = {
          status: this.status,
          ...params,
        };
      }
      this.getCheckListWarehouse(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/warehouse/check-inventory",
          query: { page: this.pageNumber },
        });
      }
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loader',{attrs:{"is-visible":_vm.isLoading}}),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"page-header"},[_c('Breadcrumbs',{attrs:{"main":"Thiết lập chung","title":"Thiết lập chiết khấu khách hàng"}})],1)]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"}),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"datatable-select col-md-4"},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Tìm kiếm"},on:{"change":function($event){return _vm.onSearch()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('b-form-select',{staticClass:"col-sm-2",model:{value:(_vm.store),callback:function ($$v) {_vm.store=$$v},expression:"store"}},[_c('option',{attrs:{"value":""}},[_vm._v("Lọc theo cửa hàng")]),_vm._l((_vm.storeList),function(store){return _c('option',{key:store.index,domProps:{"value":store.id}},[_vm._v(" "+_vm._s(store.name)+" ")])})],2),_c('div',{staticClass:"col-md-6"},[_c('b-button',{staticClass:"btn-popup",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.onSearch()}}},[_vm._v("Tìm")])],1)],1),_c('div',{staticClass:"table-responsive datatable-vue product-list"},[(_vm.personDiscountList.length > 0)?_c('b-table',{ref:"selectableTable",staticClass:"text-center",attrs:{"select-mode":_vm.selectMode,"striped":"","hover":"","head-variant":"light","bordered":"","stacked":"md","items":_vm.personDiscountList,"fields":_vm.tablefields,"selectable":true},on:{"row-selected":_vm.rowSelected},scopedSlots:_vm._u([{key:"cell(user)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"cell(phone_number)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.phone_number)+" ")])]}},{key:"cell(discount_for_new_customer)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.discount_for_new_customer)+"% ")])]}},{key:"cell(discount_auto)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.discount_auto)+"% ")])]}},{key:"cell(personal_discount)",fn:function(ref){
                  var value = ref.value;
                  var item = ref.item;
                  var key = ref.field.key;
return [(_vm.edit != item.id)?_c('span',[_vm._v(_vm._s(value)),(value)?_c('span',[_vm._v("%")]):_vm._e()]):[_c('b-form-input',{staticStyle:{"width":"120px"},attrs:{"type":"number","autofocus":""},on:{"blur":function($event){return _vm.saveRow(item)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleEnter(item)}},model:{value:(item[key]),callback:function ($$v) {_vm.$set(item, key, $$v)},expression:"item[key]"}})]]}}],null,false,615514736)}):_vm._e(),(_vm.personDiscountList.length === 0)?_c('div',{staticClass:"text-center"},[_vm._v(" Không có dữ liệu ")]):_vm._e()],1),_c('b-col',{staticClass:"my-1 p-0 pagination-justify",attrs:{"md":"12"}},[(_vm.totalRecord > 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRecord,"per-page":_vm.pageSize,"aria-controls":"my-table"},on:{"change":_vm.handlePageChange},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}}):_vm._e()],1)],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="page-wrapper">
      <div>
        <b-navbar fixed="top" type="dark" style="background-color: #42b983">
          <b-nav-text class="justify-content-end header-nav-shipper"
            ><b>ĐƠN HÀNG</b></b-nav-text
          >
        </b-navbar>
      </div>
      <b-tabs class="rbt-tabs-box-shipper" fill>
        <b-tab
          title="Đơn mới"
          @click="onTabNew()"
          :active="$route.query.id == SHIPPER_STATUS.CONFIRMED"
        >
          <OrderComponent
            :orderShipperList="orderShipperList"
            @onReceiptOrder="onReceiptOrder"
        /></b-tab>
        <b-tab
          title="Đơn nhận"
          @click="onTabPicked()"
          :active="$route.query.id == SHIPPER_STATUS.PICKING"
          ><OrderComponent
            :orderShipperList="orderShipperList"
            @onTookTheGood="onTookTheGood"
            @onCancelOrder="onCancelOrder"
        /></b-tab>
        <b-tab
          title="Đơn giao"
          @click="onTabDelivering()"
          :active="$route.query.id == SHIPPER_STATUS.DELIVERING"
          ><OrderComponent :orderShipperList="orderShipperList"
        /></b-tab>
        <b-tab
          title="Đã giao"
          @click="onTabSuccess()"
          :active="$route.query.id == SHIPPER_STATUS.SUCCESS"
          ><OrderComponent :orderShipperList="orderShipperList"
        /></b-tab>
      </b-tabs>
    </div>
    <VueBottomNavigation
      :options="options"
      v-model="selected"
      foregroundColor="#42b983"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import VueBottomNavigation from "bottom-navigation-vue";
import OrderComponent from "../../../src/components/shipper/order.vue";
import Loader from "../../components/loading/loader.vue";
import LocalStorageService from "../../utils/index";
import { ORDER_STATUS } from "../../config/orders/index";

export default {
  components: { VueBottomNavigation, OrderComponent, Loader },

  data() {
    return {
      selected: 1,
      options: [
        {
          id: 4,
          icon: "fas fa-bell",
          title: "Thông báo",
          path: { name: "notify-shipper" },
        },
        {
          id: 1,
          icon: "fas fa-home",
          title: "Đơn hàng",
          path: { name: "shipper-order" },
          badge: "",
        },
        {
          id: 5,
          icon: "fas fa-user",
          title: "Tài khoản",
          path: { name: "shipper-info" },
        },
        {
          id: 6,
          icon: "fas fa-chart-bar",
          title: "Thống kê",
          path: { name: "shipper-statistic" },
        },
      ],
      idWarehouse: "",
    };
  },
  async created() {
    await this.getUserProfile();
    const idStatus = this.$route.query.id || this.SHIPPER_STATUS.CONFIRMED;
    let params = {
      order_status: idStatus,
    };
    await this.getOrderShipperList(params);
    this.options[1].badge = this.totalRecord;
  },
  watch: {
    isMessage() {
      if (this.isMessage.isShow) {
        this.$toasted.show(`${this.isMessage.content}`, {
          type: this.isMessage.type,
        });
        setTimeout(() => {
          this.setMessage({
            type: "",
            isShow: false,
            content: "",
          });
        }, 4000);
      }
    },
    errorList() {
      if (this?.errorList?.status === 400) {
        const dataError = this.errorList?.data?.message
          ? this?.errorList?.data?.message
          : this?.errorList?.data;
        switch (Object.keys(dataError)[0]) {
          case "old_password":
            this.$toasted.show(dataError[Object.keys(dataError)], {
              duration: 4000,
              type: "error",
            });
            break;
          case "non_field_errors":
            this.$toasted.show(dataError[Object.keys(dataError)], {
              duration: 4000,
              type: "error",
            });
            break;
          case typeof dataError === "string":
            this.$toasted.show(dataError, {
              duration: 4000,
              type: "error",
            });
            break;
          default:
            for (const [key, value] of Object.entries(dataError)) {
              this.$toasted.show(`${value[0]}`, {
                duration: 4000,
                type: "error",
              });
            }
        }
      }
      if (this?.errorList?.status === 403) {
        this.$toasted.show(this?.errorList?.data?.detail, {
          duration: 4000,
          type: "info",
        });
      }
      if (this?.errorList?.status === 500) {
        this.$toasted.show(this?.errorList?.data?.message, {
          duration: 4000,
          type: "error",
        });
      }
    },
    totalRecord() {
      this.options[1].badge = this.totalRecord;
    },
  },
  computed: {
    ...mapState("order", ["orderShipperList", "isLoading", "totalRecord"]),
    ...mapState("profile", ["userProfile"]),
    ...mapState(["isMessage", "errorList"]),
    ...mapState("warehouse", ["warehouseList"]),
    SHIPPER_STATUS() {
      return ORDER_STATUS.SHIPPER_STATUS;
    },
  },
  methods: {
    ...mapActions("order", [
      "getOrderShipperList",
      "updateOrderShipper",
      "cancelOrderShipper",
    ]),
    ...mapActions("profile", ["getUserProfile"]),
    ...mapActions(["setMessage"]),
    onTabPicked() {
      let params = {
        order_status: this.SHIPPER_STATUS.PICKING,
      };
      this.getOrderShipperList(params);
      if (this.$route.query.id !== this.SHIPPER_STATUS.PICKING) {
        this.$router.push({
          name: "shipper-order",
          query: {
            id: this.SHIPPER_STATUS.PICKING,
          },
        });
      }
    },
    onTabNew() {
      let params = {
        order_status: this.SHIPPER_STATUS.CONFIRMED,
      };
      this.getOrderShipperList(params);
      if (this.$route.query.id !== this.SHIPPER_STATUS.CONFIRMED) {
        this.$router.push({
          name: "shipper-order",
          query: {
            id: this.SHIPPER_STATUS.CONFIRMED,
          },
        });
      }
    },
    onTabDelivering() {
      let params = {
        order_status: this.SHIPPER_STATUS.DELIVERING,
        shipper: this.userProfile?.id,
      };
      this.getOrderShipperList(params);
      if (this.$route.query.id !== this.SHIPPER_STATUS.DELIVERING) {
        this.$router.push({
          name: "shipper-order",
          query: {
            id: this.SHIPPER_STATUS.DELIVERING,
          },
        });
      }
    },
    onTabSuccess() {
      let params = {
        order_status: this.SHIPPER_STATUS.SUCCESS,
        shipper: this.userProfile?.id,
      };
      this.getOrderShipperList(params);
      if (this.$route.query.id !== this.SHIPPER_STATUS.SUCCESS) {
        this.$router.push({
          name: "shipper-order",
          query: {
            id: this.SHIPPER_STATUS.SUCCESS,
          },
        });
      }
    },
    onReceiptOrder(idOrder) {
      const data = {
        id: idOrder,
        shipper: this.userProfile?.id,
        order_status: this.SHIPPER_STATUS.PICKING,
      };
      const params = {
        order_status: this.SHIPPER_STATUS.CONFIRMED,
      };
      this.updateOrderShipper({ data: data, params });
    },
    onTookTheGood(idOrder) {
      const data = {
        id: idOrder,
        order_status: this.SHIPPER_STATUS.DELIVERING,
      };
      const params = {
        shipper: this.userProfile?.id,
        order_status: this.SHIPPER_STATUS.PICKING,
      };
      this.updateOrderShipper({ data: data, params });
    },
    onDelivering(idOrder) {
      const data = {
        id: idOrder,
        order_status: this.SHIPPER_STATUS.DELIVERING,
      };
      const params = {
        shipper: this.userProfile?.id,
        order_status: this.SHIPPER_STATUS.SUCCESS,
      };
      this.updateOrderShipper({ data: data, params });
    },
    onCancelOrder(idOrder) {
      const data = {
        id: idOrder,
        order_status: this.SHIPPER_STATUS.CANCEL,
      };
      const params = {
        shipper: this.userProfile?.id,
        order_status: this.SHIPPER_STATUS.PICKING,
      };
      this.cancelOrderShipper({ data: data, params });
    },
  },
};
</script>
<style>
.rbt-tabs-box-shipper > div:first-of-type {
  position: fixed;
  z-index: 1030;
  top: 53px;
  background-color: #fff;
  overflow: hidden;
  width: 100%;
  flex-direction: row;
  font-size: 17px;
}
.active-label .svg-inline--fa {
  font-size: 26px !important;
}
.nav {
  flex-wrap: nowrap !important;
}
.nav-tabs {
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: auto;
}
::-webkit-scrollbar {
  display: none;
}
p {
  font-size: 16px !important;
}
.active-label .btn-badge {
  background-color: red !important;
}
.navbar .custom-select {
  width: 50% !important;
}
.navbar-expand {
  justify-content: space-between !important;
}
</style>

<template>
  <div>
    <!-- Container-fluid starts-->
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs
          main="Quản lý khách hàng"
          title="Tạo tài khoản khách hàng"
        />
      </div>
      <div class="row">
        <div class="col">
          <div class="card tab2-card">
            <div class="card-header">
              <h5 v-if="this.$route.query.id">
                Cập nhật khách hàng
              </h5>
              <h5 v-else>
                Thêm khách hàng
              </h5>
            </div>
            <div class="card-body">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <b-form
                  class="needs-validation customer-add"
                  @submit.stop.prevent="onSubmit()"
                  v-if="customerInfo"
                >
                  <div class="row">
                    <div class="col-sm-6">
                      <validation-provider v-if="$route.query.id">
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            Mã khách hàng
                          </template>
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="customerInfo.code"
                            :disabled="!!$route.query.id"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>
                        </b-form-group>
                      </validation-provider>
                      <validation-provider
                        name="Tên đăng nhập"
                        :rules="{
                          required: true,
                          regexUserName: /^[a-z0-9@._-]{3,50}$/,
                        }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Tên đăng nhập
                          </template>
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="customerInfo.username"
                            placeholder="Nhập tên đăng nhập"
                            :state="getValidationState(validationContext)"
                            :disabled="checkDataInfo"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Mật khẩu"
                        :rules="{
                          required: checkDataInfo ? false : true,
                          min: 6,
                          // regexPassword: /[A-Z]/i,
                        }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          v-if="!checkDataInfo"
                          id="example-input-group-2"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-2"
                        >
                          <template v-slot:label>
                            <span>*</span>Mật khẩu
                          </template>
													<b-input-group>
                          <b-form-input
														v-if="showPassword"
                            id="example-input-2"
                            name="example-input-2"
                            placeholder="******"
                            type="text"
                            v-model="customerInfo.password"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-2-live-feedback"
                          ></b-form-input>

													<b-form-input
														v-else="showPassword"
														id="example-input-2"
														name="example-input-2"
														placeholder="******"
														type="password"
														v-model="customerInfo.password"
														:state="getValidationState(validationContext)"
														aria-describedby="input-2-live-feedback"
													></b-form-input>
													
													<b-input-group-append>
														<b-button @click="toggleShow" variant="outline-secondary"><span class="icon is-small is-right">
															<i class="fa" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
														</span></b-button>
													</b-input-group-append>
													
                          <b-form-invalid-feedback id="input-2-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
													</b-input-group>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Email"
                        :rules="{ required: true, email: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-5"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-5"
                        >
                          <template v-slot:label>
                            <span>*</span>Email
                          </template>
                          <b-form-input
                            id="example-input-5"
                            name="example-input-5"
                            v-model="customerInfo.email"
                            placeholder="Nhập email"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-5-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-5-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Tên khách hàng"
                        :rules="{
                          required: true,
                        }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-3"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-3"
                        >
                          <template v-slot:label>
                            <span>*</span>Tên khách hàng
                          </template>
                          <b-form-input
                            v-model="customerInfo.name"
                            placeholder="Nhập tên khách hàng"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider name="Số tài khoản" :rules="{}">
                        <b-form-group
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                        >
                          <template v-slot:label>
                            Số tài khoản
                          </template>
                          <b-form-input
                            v-model="customerInfo.account_number"
                            placeholder="Nhập số tài khoản"
                          ></b-form-input>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        :rules="{ validPaymentTerm: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-6"
                          label="Kì hạn thanh toán"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="payment-term-input"
                        >
                          <b-form-input
                            id="payment-term-input"
                            name="payment-term-input"
                            v-model="customerInfo.payment_term"
                            placeholder="Kì hạn thanh toán"
                            type="number"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-6-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-6-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider v-slot="validationContext">
                        <b-form-group
                          id="example-input-group-6"
                          label="Nợ tối đa"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="debt-amount-input"
                        >
                          <b-form-input
                            id="debt-amount-input"
                            name="debt-amount-input"
                            v-model="customerInfo.max_debt_amount"
                            placeholder="Nợ tối đa"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-6-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-6-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        v-if="$route.query.id"
                        name="Chiết khấu"
                      >
                        <b-form-group
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                        >
                          <template v-slot:label>
                            Chiết khấu(%)
                          </template>
                          <span v-if="customerInfo && customerInfo.get_discount"
                            >{{ customerInfo.get_discount }}%</span
                          >
                        </b-form-group>
                      </validation-provider>

                      <validation-provider v-if="$route.query.id" name="Số dư">
                        <b-form-group
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                        >
                          <template v-slot:label>
                            Số dư
                          </template>
                          <div class="pt-2">
                            {{ customerInfo.balance | formatCurrency }}
                          </div>
                        </b-form-group>
                      </validation-provider>
                      <div>
                        <div class="text-center">
                          <img
                            v-if="customerInfo.avatar_url"
                            :src="customerInfo.avatar_url"
                            class="img-fluid image_zoom_1 blur-up lazyloaded"
                          />
                          <img
                            v-else
                            src="../../assets/images/image_default.png"
                            class="img-fluid image_zoom_1 blur-up lazyloaded test"
                          />
                          <a
                            style="position: absolute; bottom: -6px; left: 0;right: 0; cursor: pointer;"
                            class="text-dark"
                            @click="onChangeAvatar()"
                            ><i data-feather="camera"
                              ><feather type="camera"></feather></i
                          ></a>
                        </div>
                        <input
                          @change="onFileChanged"
                          ref="uploader"
                          class="form-control d-none"
                          id="validationCustom0"
                          type="file"
                          accept="image/*"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <validation-provider
                        name="Số điện thoại"
                        :rules="{
                          regexPhone: /^[0-9]+$/,
                          min: 10,
                          max: 11,
                        }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-6"
                          label="Số điện thoại"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-6"
                        >
                          <b-form-input
                            id="example-input-6"
                            name="example-input-6"
                            v-model="customerInfo.phone_number"
                            placeholder="Nhập số điện thoại"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-6-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-6-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Địa chỉ"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-3"
                          label="Địa chỉ"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-3"
                        >
                          <b-form-input
                            id="example-input-3"
                            name="example-input-3"
                            v-model="customerInfo.address"
                            placeholder="Nhập địa chỉ"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Thành phố"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-4"
                          label="Thành phố"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-4"
                        >
                          <multiselect
                            placeholder="Chọn thành phố"
                            v-model="customerInfo.city"
                            :custom-label="searchingSelect"
                            class="rbt-custom-input"
                            @input="onChangeCity"
                            :show-labels="false"
                            :options="cityList"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>

                          <b-form-invalid-feedback id="input-4-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Quận huyện"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-5"
                          label="Quận huyện"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-5"
                        >
                          <multiselect
                            placeholder="Chọn quận huyện"
                            v-model="customerInfo.district"
                            :show-labels="false"
                            class="rbt-custom-input"
                            :custom-label="searchingSelect"
                            :options="districtList"
                            :disabled="!customerInfo.city"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>
                          <b-form-invalid-feedback id="input-5-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Ngày sinh"
                        v-slot="validationContext"
                        :rules="{}"
                      >
                        <b-form-group
                          label="Ngày sinh"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                        >
                          <b-form-datepicker
                            placeholder="Chọn ngày"
                            locale="vi"
                            label-help=""
                            v-model="customerInfo.birthday"
                            :max="max"
                            :date-format-options="{
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            }"
                            :state="getValidationState(validationContext)"
                          ></b-form-datepicker>

                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Ngày tạo"
                        v-slot="validationContext"
                        :rules="{}"
                      >
                        <b-form-group
                          label="Ngày tạo"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                        >
                          <b-form-datepicker
                            placeholder="Ngày tạo"
                            locale="vi"
                            label-help=""
                            v-model="customerInfo.date_joined"
                            :max="max"
                            :date-format-options="{
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            }"
                            :state="getValidationState(validationContext)"
                          ></b-form-datepicker>

                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <b-form-group class="d-flex">
                        <template v-slot:label>
                          Giới tính
                        </template>
                        <b-form-radio-group v-model="customerInfo.gender">
                          <b-form-radio value="true">Nam</b-form-radio>
                          <b-form-radio value="false">Nữ</b-form-radio>
                        </b-form-radio-group>
                      </b-form-group>

                      <validation-provider
                        name="Ghi chú"
                        :rules="{}"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label="Ghi chú"
                        >
                          <b-form-textarea
                            id="textarea-rows"
                            rows="7"
                            v-model="customerInfo.note"
                            placeholder="Ghi chú"
                            :state="getValidationState(validationContext)"
                          ></b-form-textarea>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="pull-right">
                    <button
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="handleBackList()"
                    >
                      Trở lại
                    </button>
                    <button
                      v-if="!$route.query.id"
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="cancelUpdate()"
                    >
                      Xóa
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      :disabled="invalid"
                    >
                      Lưu
                    </button>
                  </div>
                  <b-tabs content-class="mt-3" v-if="$route.query.id">
                    <b-tab title="Lịch sử mua hàng" data-feather="user" active>
                      <div
                        class="table-responsive datatable-vue text-center mt-4"
                      >
                        <b-table
                          show-empty
                          striped
                          hover
                          head-variant="light"
                          bordered
                          stacked="md"
                          :items="orderList"
                          :per-page="perPage"
                          :current-page="currentPage"
                          :fields="tableFieldsOrder"
                        >
                          <template #empty>
                            <span class="text-danger">
                              Chưa có đơn hàng nào
                            </span>
                          </template>
                          <template v-slot:cell(total_invoice)="{ item }">
                            <span>
                              {{ item.total_invoice | formatCurrency }}
                            </span>
                          </template>
                          <template v-slot:cell(total_payment)="{ item }">
                            <span>
                              {{ item.total_payment | formatCurrency }}
                            </span>
                          </template>
                          <template v-slot:cell(additional_fee)="{ item }">
                            <span>
                              {{ item.additional_fee | formatCurrency }}
                            </span>
                          </template>
                          <template v-slot:cell(order_status)="{ item }">
                            <span>
                              {{ statusOrder[item.order_status] }}
                            </span>
                          </template>
                          <template v-slot:cell(order_date)="{ item }">
                            <span>
                              {{ item.order_date | showDate }}
                            </span>
                          </template>
                          <template v-slot:cell(type)="{ item }">
                            <span v-if="item.type && item.type == 'online'">
                              <div class="text-info">
                                {{ item.type }}
                              </div>
                            </span>
                            <span v-if="item.type && item.type == 'offline'">
                              {{ item.type }}
                            </span>
                          </template>
                        </b-table>
                        <div>
                          <b-pagination
                            v-if="orderList.length > 0"
                            class="mt-2 d-flex justify-content-end"
                            v-model="currentPage"
                            :total-rows="orderList.length"
                            :per-page="perPage"
                            aria-controls="my-table"
                          ></b-pagination>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Lịch sử giao dịch" data-feather="user">
                      <b-form-radio-group
                        id="radio-group-2"
                        v-model="type_balance"
                        name="radio-sub-component"
                        @change="handleChange"
                      >
                        <b-form-radio value="">Tất cả</b-form-radio>
                        <b-form-radio :value="transactionType.INCOME"
                          >Phiếu thu</b-form-radio
                        >
                        <b-form-radio :value="transactionType.PURCHASE"
                          >Phiếu chi</b-form-radio
                        >
                      </b-form-radio-group>
                      <div
                        class="table-responsive datatable-vue text-center mt-4"
                      >
                        <b-table
                          show-empty
                          striped
                          hover
                          head-variant="light"
                          bordered
                          stacked="md"
                          :items="transactionList"
                          :per-page="perPage"
                          :current-page="currentPage"
                          :fields="tableFieldsTransaction"
                        >
                          <template #empty>
                            <span class="text-danger">
                              Chưa có giao dịch nào
                            </span>
                          </template>
                          <template v-slot:cell(created_by)="{ item }">
                            <span v-if="item.created_by">
                              {{ item.created_by.name }}
                            </span>
                          </template>
                          <template v-slot:cell(type)="{ item }">
                            <span v-if="dataConvert[item.type]">
                              {{ dataConvert[item.type] }}
                            </span>
                          </template>
                          <template v-slot:cell(value)="{ item }">
                            <span
                              v-if="item.type == transactionType.INCOME"
                              class="text-success"
                            >
                              {{ item.value | formatCurrency }}
                            </span>
                            <span
                              v-if="item.type == transactionType.PURCHASE"
                              class="text-danger"
                            >
                              {{ item.value | formatCurrency }}
                            </span>
                            <span v-if="!item.type">
                              {{ item.value | formatCurrency }}
                            </span>
                          </template>
                          <template v-slot:cell(created_at)="{ item }">
                            <span>
                              {{ item.created_at | showDate }}
                            </span>
                          </template>
                          <template v-slot:cell(status)="{ item }">
                            <b-badge :variant="colorStatus[item.status]">
                              {{ statusTransactionReceipt[item.status] }}
                            </b-badge>
                          </template>
                        </b-table>
                        <div>
                          <b-pagination
                            v-if="transactionList.length > 0"
                            class="mt-2 d-flex justify-content-end"
                            v-model="currentPage"
                            :total-rows="transactionList.length"
                            :per-page="perPage"
                            aria-controls="my-table"
                          ></b-pagination>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loader from "../../components/loading/loader.vue";
import { TRANSACTION_STATUS } from "../../config/transactions/index";
import TypeTransactionReceipt from "../../mocks/typeTransactionReceipt.json";
import { addCommaPrice } from "../../config/common";
import { ORDER_STATUS } from "../../config/orders";

const initialData = {
  username: "",
  password: "",
  name: "",
  phone_number: "",
  email: "",
  address: "",
  district: "",
  city: "",
  gender: true,
  account_number: "",
  last_transaction: null,
  status: "moi_tao",
  last_transfers: null,
  discount: null,
  birthday: new Date().toISOString().slice(0, 10),
  date_joined: new Date().toISOString().slice(0, 10),
  payment_term: 0,
  max_debt_amount: "",
  note: "",
  avatar_url: "",
};

export default {
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const customerInfo = this.$route.query.id ? this.customerInfo : initialData;
    const data = TypeTransactionReceipt;
    return {
      tableFieldsOrder: [
        { key: "code", label: "Mã đơn hàng" },
        { key: "name", label: "Tên đơn hàng" },
        { key: "order_date", label: "Thời gian đặt" },
        { key: "total_invoice", label: "Tổng tiền hàng" },
        { key: "additional_fee", label: "Phụ phí" },
        { key: "total_payment", label: "Tổng tiền thanh toán" },
        { key: "order_status", label: "Trạng thái đơn hàng" },
        { key: "type", label: "Đơn hàng" },
      ],
      tableFieldsTransaction: [
        { key: "created_at", label: "Thời gian giao dịch" },
        { key: "created_by", label: "Nhân viên" },
        { key: "type", label: "Loại giao dịch" },
        { key: "status", label: "Trạng thái" },
        { key: "value", label: "Số tiền" },
      ],
      dataConvert: data,
      max: today,
      customerInfo,
      currentPage: 1,
      perPage: process.env.VUE_APP_PAGE_SIZE,
      type_balance: "",
      isChangeImage: false,
			showPassword: false,
    };
  },
  components: {
    Loader,
  },
  async created() {
    this.isChangeImage = false;
    if (this.$route.query.id) {
      this.customerInfo = await this.getCustomerDetail(this.$route.query.id);
      await this.getOrderList({ customer: this.$route.query.id });
      await this.getTransactionList({
        customer: this.$route.query.id,
      });
      this.customerInfo.max_debt_amount = addCommaPrice(
        this.customerInfo.max_debt_amount
      );
    } else {
      this.customerInfo = {
        ...initialData,
      };
    }
    this.getCityList();
    const { city } = this.customerInfo;
    const idCity = city?.id;
    if (idCity) {
      this.getDistrictList({ id: idCity });
    }
  },
  computed: {
    ...mapState("customer", ["isLoading"]),
    ...mapState("city", ["cityList", "districtList"]),
    ...mapState("order", ["orderList", "transactionList"]),
    ...mapState("profile", ["userProfile"]),
    checkDataInfo() {
      if (this.$route.query.id) return true;
      else return false;
    },
    statusTransactionReceipt() {
      return TRANSACTION_STATUS.RECEIPT_TEXT;
    },
    transactionType() {
      return TRANSACTION_STATUS.TRANSACTION_TYPE;
    },
    colorStatus() {
      return TRANSACTION_STATUS.APPEND_COLOR_TEXT;
    },
    storeID() {
      return this.userProfile?.store?.id;
    },
    statusOrder() {
      return ORDER_STATUS.TEXT;
    },
    colorStatus() {
      return ORDER_STATUS.APPEND_COLOR_TEXT;
    },
  },
  watch: {
    "customerInfo.max_debt_amount"() {
      const debtAmount = this.customerInfo.max_debt_amount;
      if (typeof debtAmount == "string") {
        const result = addCommaPrice(debtAmount);
        this.$nextTick(() => (this.customerInfo.max_debt_amount = result));
      }
    },
  },
  methods: {
    ...mapActions("customer", [
      "createCustomer",
      "updateCustomer",
      "getCustomerDetail",
    ]),
    ...mapActions("city", ["getCityList", "getDistrictList"]),
    ...mapActions("order", ["getOrderList", "getTransactionList"]),
    onSubmit() {
      const {
        avatar_url,
        city,
        payment_term,
        max_debt_amount,
        district,
        groups,
        ...data
      } = this.customerInfo;
      const dataSubmit = {
        city: city?.id,
        district: district?.id,
        groups: groups?.id,
        payment_term: Number(payment_term),
        ...(max_debt_amount && {
          max_debt_amount: Number(max_debt_amount.replace(/,/g, "")),
        }),
        ...(avatar_url && this.isChangeImage && { avatar_url: avatar_url }),
        store: this.storeID,
        ...data,
      };

      if (this.customerInfo.id) {
        const customerData = {
          id: this.customerInfo.id,
          data: dataSubmit,
        };
        this.updateCustomer(customerData);
      } else {
        this.createCustomer(dataSubmit);
      }
    },
    cancelUpdate() {
      this.customerInfo = {
        ...initialData,
      };
    },
    onChangeCity() {
      const { city } = this.customerInfo;
      this.getDistrictList({ id: city?.id });
      this.customerInfo.district = "";
    },
    searchingSelect({ name }) {
      return name;
    },
    handleBackList() {
      this.$router.push({ name: "customer-list" });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated
        ? (valid = valid === true ? null : valid)
        : null;
    },
    handleChange() {
      this.getTransactionList({
        customer: this.$route.query.id,
        type: this.type_balance,
      });
    },
    onChangeAvatar() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        this.customerInfo.avatar_url = reader.result;
      };
      reader.readAsDataURL(file);
      this.isChangeImage = true;
    },
		toggleShow() {
			this.showPassword = !this.showPassword;
		}
  },
};
</script>
<style scoped></style>

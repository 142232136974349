<template>
  <div>
    <!-- Container-fluid starts-->
    <div
      v-if="userProfile && userProfile.role != roleList.supplier"
      class="container-fluid"
    >
      <div class="page-header">
        <Breadcrumbs main="" title="Dashboard" />
      </div>
      <Widget></Widget>
      <div class="row">
        <!-- <MarketChart></MarketChart> -->
        <!-- <OrderTable :order="orderList"></OrderTable> -->
      </div>
      <!-- <SecondWidget></SecondWidget> -->
      <div class="row">
        <!-- <BuySellChart></BuySellChart> -->
        <!-- <GoodsReturnTable :data="orderList"></GoodsReturnTable> -->
        <SalesStatus></SalesStatus>
        <OrderTable></OrderTable>
        <EmployeeStatus :employee="employee"></EmployeeStatus>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

import Widget from "../components/dashboard/widget.vue";
import MarketChart from "../components/dashboard/market-chart.vue";
import SecondWidget from "../components/dashboard/secondWidget/secondWidget.vue";
import OrderTable from "../components/dashboard/order-table.vue";
import BuySellChart from "../components/dashboard/buy-sell-chart.vue";
import GoodsReturnTable from "../components/dashboard/goods-returns-table.vue";
import EmployeeStatus from "../components/dashboard/employee-status-table.vue";
import SalesStatus from "../components/dashboard/sales-status/sales-status.vue";
import LocalStorageService from "../utils/index";
import { Roles } from "../types/roles";
export default {
  components: {
    Widget,
    MarketChart,
    SecondWidget,
    OrderTable,
    BuySellChart,
    GoodsReturnTable,
    EmployeeStatus,
    SalesStatus,
  },

  data() {
    return {};
  },
  async created() {
    if (!this.userProfile) {
      await this.getUserProfile();
    }
    // this.$store.dispatch("order/getOrderList");
    const params = { order: "is_online" };
    if (this.userProfile.role !== this.roleList.supplier) {
      this.getEmployeeList(params);
    }
  },
  computed: {
    ...mapGetters({
      employee: "employee/getEmployeeList",
    }),
    ...mapState("profile", ["userProfile"]),
    roleList() {
      return Roles;
    },
  },
  methods: {
    ...mapActions("employee", ["getEmployeeList"]),
    ...mapActions("profile", ["getUserProfile"]),
  },
  mounted() {
    const role = LocalStorageService.getLocalUserRole();
    if (role === Roles.shipper) {
      this.$router.push("/shipper-order");
      return;
    }
    if (role === Roles.supplier) {
      this.$router.push("/provider/list-quote");
      return;
    }
  },
};
</script>

export const TRANSACTION_STATUS = {
  ADMIN: {
    0: "Chờ duyệt",
    1: "Đã kiểm tra",
    2: "Hoàn thành",
    3: "Huỷ",
  },
  SUPPLIER: {
    0: "Chờ duyệt",
    1: "RBT đã kiểm tra",
    2: "Hoàn thành",
  },
  RECEIPT: {
    CONFIRM: "CONFIRM",
    NEW_CREATED: "NEW",
  },
  TRANSACTION_TYPE: {
    INCOME: "INCOME",
    PURCHASE: "PURCHASE",
  },
  RECEIPT_TEXT: {
    CONFIRM: "Đã nhận",
    NEW: "Mới tạo",
  },
  APPEND_COLOR_TEXT: {
    confirm: "success",
    new_created: "warning",
  },
  APPEND_COLOR: {
    0: "warning",
    1: "success",
    2: "primary",
    3: "danger",
  },
};

export const reverseDate = (value) => {
  if(value){
    return value
    .split("-")
    .reverse()
    .join("-");
  }
};

export const addCommaPrice = (value) => {
  let result = typeof value === "string" ? value.trim() : value;
  if (result[0] !== "-") {
    result = value
      .toString()
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return value < 0 ? `-${result}` : result;
};

export const addCommaPriceNoConnect = (value) => {
  let result = typeof value === "string" ? value.trim() : value;
  result = value
      .toString()
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return value < 0 ? `-${result}` : result;
};

export const addDotPrice = (value) => {
  let result = typeof value === "string" ? value.trim() : value;
  if (result[0] !== "-") {
    result = value
      .toString()
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return value < 0 ? `-${result}` : result;
};

<template>
  <div>
    <!-- Container-fluid starts-->
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Danh mục nhà cung cấp" />
      </div>
      <div class="row">
        <div class="col">
          <div class="card tab2-card">
            <div class="card-header">
              <h5 v-if="this.$route.query.id">
                Cập nhật nhà cung cấp
              </h5>
              <h5 v-else>
                Thêm nhà cung cấp
              </h5>
            </div>
            <div class="card-body">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <b-form
                  class="needs-validation supplier-add"
                  @submit.stop.prevent="onSubmit()"
                  v-if="supplierInfo"
                >
                  <div class="form-group row">
                    <label for="validationCustom0" class="col-xl-2 col-md-3"
                      >Ảnh</label
                    >

                    <div class="col-xl-8 col-md-7 px-0">
                      <div class="text-center">
                        <img
                          v-if="supplierInfo.avatar_url"
                          :src="supplierInfo.avatar_url"
                          width="75"
                          height="75"
                          fluid
                          rounded
                        />
                        <img
                          v-else
                          src="../../assets/images/dashboard/avatar.png"
                          width="75"
                          height="75"
                          fluid
                          rounded
                        />
                        <a
                          style="position: absolute; bottom: -6px; left: 0;right: 0; cursor: pointer;"
                          class="text-dark"
                          @click="onChangeAvatar()"
                          ><i data-feather="camera"
                            ><feather type="camera"></feather></i
                        ></a>
                      </div>
                      <input
                        ref="uploader"
                        @change="onFileChanged"
                        class="form-control d-none"
                        id="validationCustom0"
                        type="file"
                        accept="image/*"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6">
                      <validation-provider v-if="$route.query.id">
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            Mã nhà cung cấp
                          </template>
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="supplierInfo.code"
                            :disabled="!!$route.query.id"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Tên đăng nhập"
                        :rules="{
                          required: true,
                          regexUserName: /^[a-z0-9@._-]{3,50}$/,
                        }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Tên đăng nhập
                          </template>
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="supplierInfo.username"
                            placeholder="Nhập tên đăng nhập"
                            :state="getValidationState(validationContext)"
                            :disabled="checkDataInfo"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Mật khẩu"
                        :rules="{
                          required: checkDataInfo ? false : true,
                          min: 8,
                          regexPassword: /[A-Z]/i,
                        }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          v-if="!checkDataInfo"
                          id="example-input-group-2"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-2"
                        >
                          <template v-slot:label>
                            <span>*</span>Mật khẩu
                          </template>
                          <b-form-input
                            id="example-input-2"
                            name="example-input-2"
                            placeholder="******"
                            type="password"
                            v-model="supplierInfo.password"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-2-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-2-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Tên nhà cung cấp"
                        :rules="{ required: true, max: 255 }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-6"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="supplier-name-input"
                        >
                          <template v-slot:label>
                            <span>*</span>Tên nhà cung cấp
                          </template>
                          <b-form-input
                            id="supplier-name-input"
                            name="supplier-name-input"
                            v-model="supplierInfo.name"
                            placeholder="Nhập tên nhà cung cấp"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-6-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-6-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Ngày chốt công nợ"
                        v-slot="validationContext"
                        :rules="{}"
                      >
                        <b-form-group
                          label="Ngày chốt công nợ"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                        >
                          <b-form-datepicker
                            placeholder="Ngày chốt công nợ"
                            locale="vi"
                            label-help=""
                            v-model="supplierInfo.debt_closing"
                            :max="max"
                            :date-format-options="{
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            }"
                            :state="getValidationState(validationContext)"
                          ></b-form-datepicker>

                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        :rules="{}"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-6"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="tax-code-input"
                        >
                          <template v-slot:label>
                            Mã số thuế
                          </template>
                          <b-form-input
                            id="tax-code-input"
                            name="tax-code-input"
                            v-model="supplierInfo.tax_code"
                            placeholder="Nhập mã số thuế"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-6-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-6-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        :rules="{ max: 255 }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-6"
                          label="Tên công ty"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="company-name-input"
                        >
                          <b-form-input
                            id="company-name-input"
                            name="company-name-input"
                            v-model="supplierInfo.company"
                            placeholder="Nhập tên công ty"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-6-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-6-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        :rules="{ validPaymentTerm: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-6"
                          label="Kì hạn thanh toán"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="payment-term-input"
                        >
                          <b-form-input
                            id="payment-term-input"
                            name="payment-term-input"
                            v-model="supplierInfo.payment_term"
                            placeholder="Kì hạn thanh toán"
                            type="number"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-6-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-6-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        :rules="{}"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-6"
                          label="Định mức công nợ"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-6"
                        >
                          <b-form-input
                            id="example-input-6"
                            name="example-input-6"
                            v-model="supplierInfo.debt_amount"
                            placeholder="Định mức công nợ"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-6-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-6-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        :rules="{ max: 255 }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-6"
                          label="Tín nhiệm"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-6"
                        >
                          <b-form-rating
                            id="rating-inline"
                            inline
                            variant="warning"
                            :state="getValidationState(validationContext)"
                            v-model="supplierInfo.trust"
                          ></b-form-rating>

                          <b-form-invalid-feedback id="input-6-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        v-if="$route.query.id"
                        name="Số dư"
                        :rules="{}"
                      >
                        <b-form-group
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                        >
                          <template v-slot:label>
                            Số dư
                          </template>
                          <div class="pt-2">
                            {{ surplus | formatCurrency }}
                          </div>
                        </b-form-group>
                      </validation-provider>
                    </div>
                    <div class="col-sm-6 mt-2">
                      <validation-provider
                        :rules="{ max: 255 }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-6"
                          label="Địa chỉ"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="address-input"
                        >
                          <b-form-input
                            id="address-input"
                            name="address-input"
                            v-model="supplierInfo.address"
                            placeholder="Nhập địa chỉ"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-6-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-6-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Thành phố"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-4"
                          label="Thành phố"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-4"
                        >
                          <multiselect
                            placeholder="Chọn thành phố"
                            v-model="supplierInfo.city"
                            :custom-label="searchingSelect"
                            class="rbt-custom-input"
                            @input="onChangeCity"
                            :show-labels="false"
                            :options="cityList"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>

                          <b-form-invalid-feedback id="input-4-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Quận huyện"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-5"
                          label="Quận huyện"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-5"
                        >
                          <multiselect
                            placeholder="Chọn quận huyện"
                            v-model="supplierInfo.district"
                            :show-labels="false"
                            class="rbt-custom-input"
                            :custom-label="searchingSelect"
                            :options="districtList"
                            :disabled="!supplierInfo.city"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>

                          <b-form-invalid-feedback id="input-5-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Ngày bắt đầu hợp đồng"
                        v-slot="validationContext"
                        :rules="{}"
                      >
                        <b-form-group
                          label="Ngày bắt đầu hợp đồng"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                        >
                          <b-form-datepicker
                            placeholder="Ngày bắt đầu hợp đồng"
                            locale="vi"
                            label-help=""
                            v-model="supplierInfo.contract_start_date"
                            :date-format-options="{
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            }"
                            :state="getValidationState(validationContext)"
                          ></b-form-datepicker>

                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Email"
                        :rules="{ required: true, email: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-5"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-5"
                        >
                          <template v-slot:label>
                            <span>*</span>Email
                          </template>
                          <b-form-input
                            id="example-input-5"
                            name="example-input-5"
                            v-model="supplierInfo.email"
                            placeholder="Nhập email"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-5-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-5-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Số điện thoại"
                        :rules="{ regexPhone: /^[0-9]+$/, min: 10, max: 11 }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-6"
                          label="Số điện thoại"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="phone-number-input"
                        >
                          <b-form-input
                            id="phone-number-input"
                            name="phone-number-input"
                            v-model="supplierInfo.phone_number"
                            placeholder="Nhập số điện thoại"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-6-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-6-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Ghi chú"
                        :rules="{}"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label="Ghi chú"
                        >
                          <b-form-textarea
                            id="textarea-rows"
                            rows="7"
                            v-model="supplierInfo.note"
                            placeholder="Ghi chú"
                            :state="getValidationState(validationContext)"
                          ></b-form-textarea>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="pull-right">
                    <button
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="handleBackList()"
                    >
                      Trở lại
                    </button>
                    <button
                      v-if="!$route.query.id"
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="cancelUpdate()"
                    >
                      Xóa
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      :disabled="invalid"
                    >
                      Lưu
                    </button>
                  </div>
                  <b-tabs content-class="mt-3" v-if="$route.query.id">
                    <b-tab title="Lịch sử đơn hàng" data-feather="user" active>
                      <div class="table-responsive datatable-vue mt-4">
                        <b-table
                          show-empty
                          striped
                          hover
                          head-variant="light"
                          bordered
                          :items="ordersSupplier"
                          :per-page="perPage"
                          :current-page="currentPage"
                          :fields="tableFieldsOrder"
                        >
                          <template #empty>
                            <span class="text-danger">
                              Chưa có đơn hàng nào
                            </span>
                          </template>
                          <template v-slot:cell(total)="{ item }">
                            <span>
                              {{ item.total | formatCurrency }}
                            </span>
                          </template>
                          <template v-slot:cell(payment_status)="{ item }">
                            <span v-if="item.payment_status">
                              {{ item.payment_status.name }}
                            </span>
                          </template>
                          <template v-slot:cell(recieve_time)="{ item }">
                            <span>
                              {{ item.recieve_time | dateFormat }}
                            </span>
                          </template>
                          <template v-slot:cell(status)="{ item }">
                            <b-badge :variant="orderColor[item.status]">
                              {{ ORDER_STATUS_CREATE_SUPPLIER[item.status] }}
                            </b-badge>
                          </template>
                        </b-table>
                        <div>
                          <b-pagination
                            v-if="ordersSupplier.length > 0"
                            class="mt-2 d-flex justify-content-end"
                            v-model="currentPage"
                            :total-rows="ordersSupplier.length"
                            :per-page="perPage"
                            aria-controls="my-table"
                          ></b-pagination>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Lịch sử giao dịch" data-feather="user">
                      <b-form-radio-group
                        id="radio-group-2"
                        v-model="type_balance"
                        name="radio-sub-component"
                        @change="handleChange"
                      >
                        <b-form-radio value="">Tất cả</b-form-radio>
                        <b-form-radio value="cash_balance"
                          >Tài khoản có</b-form-radio
                        >
                        <b-form-radio value="debt_balance"
                          >Tài khoản nợ</b-form-radio
                        >
                      </b-form-radio-group>
                      <div class="table-responsive datatable-vue mt-4">
                        <b-table
                          show-empty
                          striped
                          hover
                          head-variant="light"
                          bordered
                          :items="transactionList"
                          :per-page="perPage"
                          :current-page="currentPage"
                          :fields="tableFieldsTransaction"
                        >
                          <template #empty>
                            <span class="text-danger">
                              Chưa có giao dịch nào
                            </span>
                          </template>
                          <template v-slot:cell(created_by)="{ item }">
                            <span>
                              {{ item.created_by }}
                            </span>
                          </template>
                          <template v-slot:cell(type)="{ item }">
                            <span v-if="dataConvert[item.type]">
                              {{ dataConvert[item.type] }}
                            </span>
                          </template>
                          <template v-slot:cell(money_transcation)="{ item }">
                            <span
                              v-if="item.type_balance == 'cash_balance'"
                              class="text-success"
                            >
                              {{ item.money_transcation | formatCurrency }}
                            </span>
                            <span
                              v-if="item.type_balance == 'debt_balance'"
                              class="text-danger"
                            >
                              {{ item.money_transcation | formatCurrency }}
                            </span>
                            <span v-if="!item.type_balance">
                              {{ item.money_transcation | formatCurrency }}
                            </span>
                          </template>
                          <template
                            v-slot:cell(balance_after_transaction)="{ item }"
                          >
                            <span
                              v-if="item.type_balance == 'cash_balance'"
                              class="text-success"
                            >
                              {{
                                item.balance_after_transaction | formatCurrency
                              }}
                            </span>
                            <span
                              v-if="item.type_balance == 'debt_balance'"
                              class="text-danger"
                            >
                              {{
                                Math.abs(item.balance_after_transaction)
                                  | formatCurrency
                              }}
                            </span>
                            <span v-if="!item.type_balance">
                              {{
                                Math.abs(item.balance_after_transaction)
                                  | formatCurrency
                              }}
                            </span>
                          </template>
                          <template v-slot:cell(transaction_time)="{ item }">
                            <span>
                              {{ item.transaction_time | showDate }}
                            </span>
                          </template>
                          <template v-slot:cell(status)="{ item }">
                            <b-badge :variant="colorStatus[item.status]">
                              {{ statusTransactionReceipt[item.status] }}
                            </b-badge>
                          </template>
                        </b-table>
                        <div>
                          <b-pagination
                            v-if="transactionList.length > 0"
                            class="mt-2 d-flex justify-content-end"
                            v-model="currentPage"
                            :total-rows="transactionList.length"
                            :per-page="perPage"
                            aria-controls="my-table"
                          ></b-pagination>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loader from "../../components/loading/loader.vue";
import { extend } from "vee-validate";
import { regex } from "vee-validate/dist/rules";
import { ORDER_STATUS } from "../../config/orders/index";
import { TRANSACTION_STATUS } from "../../config/transactions/index";
import TypeTransactionReceipt from "../../mocks/typeTransactionReceipt.json";
import { addCommaPrice } from "../../config/common";

extend("regexUserName", {
  ...regex,
  message:
    "Tên đăng nhập chỉ chứa các chữ cái, số, ký tự và độ dài lớn hơn 3 nhỏ hơn 17 ký tự",
});
extend("regexPassword", {
  ...regex,
  message: "Mật khẩu chỉ toàn số, hãy nhập mật khẩu mạnh hơn nhé",
});
extend("regexPhone", {
  ...regex,
  message: "Số điện thoại không hợp lệ",
});

extend("validPaymentTerm", (value) => {
  if (value >= 0) return true;
  return "Kì hạn thanh toán phải lớn hơn hoặc bằng 0";
});

extend("validDebtAmount", (value) => {
  if (value >= 0) return true;
  return "Định mức công nợ phải lớn hơn hoặc bằng 0";
});

const initialData = {
  username: "",
  password: "",
  name: "",
  phone_number: null,
  avatar_url: null,
  address: "",
  city: "",
  district: "",
  status: "moi_tao",
  trust: 3,
  contract_start_date: null,
  debt_closing: new Date().toISOString(),
  note: "",
  company: "",
  email: "",
  tax_code: "",
  payment_term: 0,
  debt_amount: "0",
};

export default {
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const supplierInfo = this.$route.query.id ? this.supplierInfo : initialData;
    const data = TypeTransactionReceipt;
    return {
      tableFieldsOrder: [
        { key: "code", label: "Mã đơn hàng" },
        { key: "name", label: "Tên đơn hàng" },
        { key: "total", label: "Tổng tiền" },
        { key: "status", label: "Trạng thái đơn hàng" },
        { key: "recieve_time", label: "Ngày nhận hàng" },
      ],
      tableFieldsTransaction: [
        { key: "transaction_time", label: "Thời gian giao dịch" },
        { key: "created_by", label: "Nhân viên" },
        { key: "type", label: "Loại giao dịch" },
        { key: "status", label: "Trạng thái" },
        { key: "money_transcation", label: "Số tiền" },
        { key: "balance_after_transaction", label: "Số tiền sau giao dịch" },
      ],
      dataConvert: data,
      max: today,
      supplierInfo,
      type_balance: "",
      currentPage: 1,
      perPage: process.env.VUE_APP_PAGE_SIZE,
    };
  },
  components: {
    Loader,
  },
  async created() {
    if (this.$route.query.id) {
      this.supplierInfo = await this.getSupplierDetail(this.$route.query.id);
      // await this.getOrderSupplier({ supplier: this.$route.query.id });
      await this.getTransactionList({
        transaction_person: this.$route.query.id,
      });
      // this.supplierInfo.debt_amount = addCommaPrice(
      //   this.supplierInfo?.debt_amount
      // );
    } else {
      this.supplierInfo = {
        ...initialData,
      };
    }
    this.getCityList();
    const { city } = this.supplierInfo;
    const idCity = city?.id;
    if (idCity) {
      this.getDistrictList({ id: idCity });
    }
  },
  computed: {
    ...mapState("supplier", ["isLoading", "ordersSupplier"]),
    ...mapState("city", ["cityList", "districtList"]),
    ...mapState("order", [, "transactionList"]),
    checkDataInfo() {
      if (this.$route.query.id) return true;
      else return false;
    },
    statusTransactionReceipt() {
      return TRANSACTION_STATUS.RECEIPT_TEXT;
    },
    colorStatus() {
      return TRANSACTION_STATUS.APPEND_COLOR_TEXT;
    },
    ORDER_STATUS_CREATE_SUPPLIER() {
      return ORDER_STATUS.ADMIN;
    },
    orderColor() {
      return TRANSACTION_STATUS.APPEND_COLOR;
    },
    surplus() {
      return this.supplierInfo.balance;
    },
  },
  watch: {
    "supplierInfo.debt_amount"() {
      const debtAmount = this.supplierInfo.debt_amount;
      if (typeof debtAmount == "string") {
        const result = addCommaPrice(debtAmount);
        this.$nextTick(() => (this.supplierInfo.debt_amount = result));
      }
    },
  },
  methods: {
    ...mapActions("supplier", [
      "createSupplier",
      "updateSupplier",
      "getSupplierDetail",
      "getOrderSupplier",
    ]),
    ...mapActions("city", ["getCityList", "getDistrictList"]),
    ...mapActions("order", ["getTransactionList"]),
    onSubmit() {
      const {
        avatar_url,
        debt_closing,

        city,
        district,
        debt_amount,
        ...data
      } = this.supplierInfo;
      const dataSubmit = {
        ...(debt_closing && {
          debt_closing: new Date(debt_closing).toISOString(),
        }),
        city: city?.id,
        district: district?.id,
        ...(debt_amount && {
          debt_amount: Number(debt_amount.replace(/,/g, "")),
        }),
        ...data,
      };
      if (this.supplierInfo.id) {
        const idSupplier = this.supplierInfo.id;
        const supplierData = { id: idSupplier, data: dataSubmit };
        this.updateSupplier({ ...supplierData });
      } else {
        if (this.supplierInfo.avatar_url) {
          const dataWithImg = {
            avatar_url: this.supplierInfo.avatar_url,
            ...dataSubmit,
          };
          this.createSupplier(dataWithImg);
        } else {
          this.createSupplier(dataSubmit);
        }
      }
    },
    cancelUpdate() {
      this.supplierInfo = {
        ...initialData,
      };
    },
    searchingSelect({ name }) {
      return name;
    },
    onFileChanged(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = (file) => {
        this.supplierInfo.avatar_url = reader.result;
        if (this.supplierInfo.id) {
          const idUser = this.supplierInfo.id;
          const {
            avatar_url,
            city,
            debt_amount,
            district,
            ...data
          } = this.supplierInfo;
          const dataSubmit = {
            avatar_url: this.supplierInfo.avatar_url,
            city: city?.id,
            district: district?.id,
            debt_amount: Number(debt_amount.replace(/,/g, "")),
            ...data,
          };
          const supplierData = { id: idUser, data: dataSubmit };
          this.updateSupplier(supplierData);
        }
      };
      reader.readAsDataURL(file);
    },
    onChangeAvatar() {
      this.$refs.uploader.click();
    },
    handleBackList() {
      this.$router.push({ name: "supplier-list" });
    },
    onChangeCity() {
      const { city } = this.supplierInfo;
      this.getDistrictList({ id: city?.id });
      this.supplierInfo.district = "";
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated
        ? (valid = valid === true ? null : valid)
        : null;
    },
    handleChange() {
      this.getTransactionList({
        transaction_person: this.$route.query.id,
        type_balance: this.type_balance,
      });
    },
  },
};
</script>
<style scoped></style>

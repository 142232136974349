var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"size":"lg","id":"quotation-modal-supplier","centered":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h3',{staticClass:"mx-auto",staticStyle:{"color":"#ff8084"}},[_vm._v(" Báo giá ")])]},proxy:true},{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('div',{staticClass:"d-flex justify-content-between",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex justify-content-center text-center"},[_c('b-button',{staticClass:"btn btn-default",attrs:{"variant":_vm.colorStatus[_vm.dataSubmit.status]}},[_vm._v(" "+_vm._s(_vm.QUOTATION_STATUS_SUPPLIER[_vm.dataSubmit.status])+" ")])],1),_c('div',[_c('b-button',{staticClass:"mr-3",attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return cancel()}}},[_vm._v(" Đóng ")])],1)])]}}])},[_c('div',[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"d-flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataSubmit.name),expression:"dataSubmit.name"}],staticClass:"form-control rbt-custom-input mr-3",attrs:{"disabled":"","placeholder":"Tên báo giá","id":"validationCustom1","type":"text"},domProps:{"value":(_vm.dataSubmit.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dataSubmit, "name", $event.target.value)}}})])]),_c('div',{staticClass:"table-responsive datatable-vue standard-list text-center mt-4"},[_c('b-table',{attrs:{"show-empty":"","striped":"","hover":"","head-variant":"light","bordered":"","items":_vm.listProductTable,"fields":_vm.tablefields,"per-page":_vm.perPage,"current-page":_vm.currentPage},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('span',{staticClass:"text-danger"},[_vm._v(" Chưa có sản phẩm nào ")])]},proxy:true},{key:"thead-top",fn:function(){return [_c('b-tr',[_c('b-th',{staticStyle:{"width":"50px"}},[_vm._v("STT")]),_c('b-th',[_vm._v("Sản phẩm")]),_c('b-th',{staticStyle:{"width":"100px"}},[_vm._v("Đơn vị tính")]),_c('b-th',{staticStyle:{"width":"160px"}},[_vm._v("Đơn giá")]),_c('b-th',{staticStyle:{"width":"160px"}},[_vm._v("Giá khuyến nghị")]),_c('b-th',{attrs:{"colspan":"2"}},[_vm._v("Khối lượng tính")])],1)]},proxy:true},{key:"cell(unit_price)",fn:function(ref){
var item = ref.item;
return [(item.unit_price)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.unit_price))+" ")]):_vm._e()]}},{key:"cell(recommended_price)",fn:function(ref){
var item = ref.item;
return [(item.recommended_price)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.recommended_price))+" ")]):_vm._e()]}},{key:"cell(unit)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.unit)+" ")])]}},{key:"cell(lowest_vol)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v("Lớn hơn "+_vm._s(item.volume_from || 0))])]}},{key:"cell(highest_vol)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.volume_to || 100000)+" ")])]}}])}),_c('div',[(_vm.listProductTable.length > 0)?_c('b-pagination',{staticClass:"mt-2 d-flex justify-content-end",attrs:{"per-page":_vm.perPage,"total-rows":_vm.listProductTable.length,"aria-controls":"my-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
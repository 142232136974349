<template>
  <b-modal
    :no-close-on-backdrop="true"
    style="width: 350px !important"
    id="modal-print-order"
    centered
  >
    <template #modal-header>
      <div class="mx-auto">
        <h3 class="mb-0" style="color: #ff8084">
          Ruộng bậc thang
        </h3>
      </div>
    </template>
    <div v-if="orderDetail" id="rbtContentPrint">
      <div class="mx-auto">
        <div class="text-center mx-auto">
          <p>
            D/C: Số 82 - 84 Ngọc Hân Công Chúa - Tp. Bắc Ninh
          </p>
          <p>
            Tài khoản: Techcombank - 19031781986686 - <br />
            Nguyễn Thu Trang
          </p>
          <p>
            website: ruongbacthang.com.vn
          </p>
          <p>
            Điện thoại: 0862968081 - 0988861479
          </p>
        </div>
        <hr />
        <div>
          <p>Ngày bán: {{ orderDetail.order_date | showDate }}</p>
        </div>
        <div class="text-center">
          <h3 style="color: #ff8084">
            Hóa đơn bán hàng
          </h3>
          <h5>
            {{ orderDetail.code }}
          </h5>
        </div>
        <div>
          <b v-if="orderDetail.customer"
            >Khách hàng: {{ orderDetail.customer.name }}</b
          >
          <p v-if="orderDetail.customer">
            Địa chỉ: {{ orderDetail.customer.address }}
          </p>
          <p v-if="orderDetail.customer">
            Điện thoại: {{ orderDetail.customer.phone_number }}
          </p>
          <b v-if="orderDetail.staff">
            Người bán: {{ orderDetail.staff.name }}
          </b>
        </div>
        <div>
          <div class="datatable-vue text-center mt-4">
            <b-table
              show-empty
              striped
              hover
              head-variant="light"
              bordered
              :fields="tablefields"
              :items="itemsTable"
            >
              <template #empty>
                <span class="text-danger">
                  Chưa có sản phẩm nào
                </span>
              </template>
              <template v-slot:cell(price)="{ item }">
                <span> {{ item.price | formatCurrency }} </span>
              </template>
              <template v-slot:cell(discount)="{ item }">
                <span> {{ item.discount | formatCurrency }} </span>
              </template>
              <template v-slot:cell(totalPrice)="{ item }">
                <span v-if="item.customer_come_to_pick_up">
                  {{ (item.discount * item.quantity) | formatCurrency }}
                </span>
                <span v-if="!item.customer_come_to_pick_up">
                  {{ (item.price * item.quantity) | formatCurrency }}
                </span>
              </template>

              <template v-slot:cell(actions)="{ item }">
                <span
                  style="font-size: 20px; color: #FA399F;"
                  @click="removeItemInTable(item.sno)"
                >
                  <feather
                    style="cursor: pointer"
                    type="trash"
                    stroke="#F72E9F"
                    size="18px"
                    fill="#F72E9F"
                  ></feather>
                </span>
              </template>
            </b-table>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-7">
            <p>
              Tổng tiền hàng:
            </p>
          </div>
          <div class="col-5">
            <p>
              {{ orderDetail.total_invoice | formatCurrency }}
            </p>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-7">
            <p>
              Chiết khấu:
            </p>
          </div>
          <div class="col-5">
            <p>
              {{ orderDetail.discount | formatCurrency }}
            </p>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-7">
            <p>
              Phụ phí:
            </p>
          </div>
          <div class="col-5">
            <p>
              {{ orderDetail.additional_fee | formatCurrency }}
            </p>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-7">
            <p>
              Tổng phải thanh toán:
            </p>
          </div>
          <div class="col-5">
            <p>
              {{ totalInvoice(orderDetail) | formatCurrency }}
            </p>
          </div>
        </div>
        <!-- <div class="row text-right">
          <div class="col-7">
            <p>
              Tổng cộng:
            </p>
          </div>
          <div class="col-5">
            <p>
              {{orderDetail.}}
            </p>
          </div>
        </div> -->
        <div class="text-center mt-5">
          <h4 style="color: #ff8084">
            Xin cảm ơn và hẹn gặp lại Quý khách !!!
          </h4>
        </div>
      </div>
    </div>
    <template #modal-footer="{cancel}">
      <div style="width: 100%" class="d-flex justify-content-end">
        <div>
          <b-button
            @click="closeOrder()"
            class="mr-3"
            size="sm"
            variant="danger"
          >
            Đóng
          </b-button>
          <b-button v-print="`#rbtContentPrint`" class="btn-popup m-0">
            <span style="font-size: 24px; font-weight: 100">
              +
            </span>
            <span>
              In hóa đơn
            </span>
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  props: {},
  name: "WarehouseImport",
  data() {
    return {
      tablefields: [
        { key: "name", label: "Tên hàng", sortable: false },
        { key: "price", label: "Đơn giá", sortable: false },
        { key: "quantity", label: "Số lượng", sortable: false },
        { key: "discount", label: "Sau giảm giá", sortable: false },
        { key: "totalPrice", label: "Thành tiền", sortable: false },
      ],
    };
  },
  created() {},
  computed: {
    ...mapState("order", ["orderDetail"]),
    itemsTable() {
      const orders = this.orderDetail.products.map((item) => {
        const { quantity, is_original_price } = item;
        return {
          name: item.product_name,
          price: item.price,
          quantity: quantity,
          discount: item.sale_price,
          is_original_price,
          totalPrice: item.revenue,
        };
      });
      return orders;
    },
  },
  methods: {
    closeOrder() {
      this.$emit("onCloseOrder");
    },
    onPrintElement() {},
    totalInvoice(order) {
      return order.total_invoice - order.discount + order.additional_fee;
    },
  },
};
</script>

<style>
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
</style>

<style scoped>
.rbt-content-print-order {
  font-size: 9px;
}
p {
  margin-bottom: 8px;
}
.rbt-custom-input {
  max-width: 192px;
  white-space: nowrap;
  height: 43px !important;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
input {
  height: 100% !important;
}
</style>

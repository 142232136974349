<template>
  <div>
    <!-- Container-fluid starts-->
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Quản lý vai trò" />
      </div>
      <div class="row">
        <div class="col">
          <div class="card tab2-card">
            <div class="card-header">
              <h5>
                Thêm vai trò
              </h5>
            </div>
            <div class="card-body">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <b-form
                  class="needs-validation user-add"
                  @submit.stop.prevent="onSubmit()"
                  v-if="roleInfo"
                >
                  <div class="row">
                    <div class="col-sm-6 col-md-12">
                      <validation-provider
                        name="Tên vai trò"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="9"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Tên vai trò
                          </template>
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="roleInfo.name"
                            placeholder="Nhập tên vai trò"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="pull-right">
                    <button
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="handleBackList()"
                    >
                      Trở lại
                    </button>
                    <button
                      class="btn btn-danger mr-3"
                      type="button"
                      @click="cancelUpdate()"
                    >
                      Xóa
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      :disabled="invalid"
                    >
                      Lưu
                    </button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loader from "../../components/loading/loader.vue";

const initialData = {
  name: "",
};

export default {
  data() {
    const roleInfo = { ...initialData };
    return {
      roleInfo,
    };
  },
  components: {
    Loader,
  },
  async created() {},
  computed: {
    ...mapState("role", ["isLoading"]),
  },
  watch: {},
  methods: {
    ...mapActions("role", ["createRole"]),
    onSubmit() {
      this.createRole(this.roleInfo);
    },
    cancelUpdate() {
      this.roleInfo = {
        ...initialData,
      };
    },
    handleBackList() {
      this.$router.push({ name: "role-list" });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated
        ? (valid = valid === true ? null : valid)
        : null;
    },
  },
};
</script>
<style scoped></style>

<template>
  <div>
    <b-modal size="lg" id="product-refund-modal" centered>
      <template #modal-header>
        <h3 class="mx-auto" style="color: #ff8084">
          Trả hàng nhà cung cấp
        </h3>
      </template>
      <validation-provider>
        <b-form-group
          label-cols-md="3"
          label-cols-lg="3"
          content-cols-sm
          content-cols-md="9"
        >
          <template v-slot:label>
            Tên phiếu trả hàng<span class="validate-star"> *</span></template
          >
          <b-form-input
            class="form-control rbt-custom-input"
            style="max-width: 100% !important"
            :disabled="dataSubmit.status >= 1"
            v-model="dataSubmit.name"
            placeholder="Nhập tên phiếu trả hàng"
          ></b-form-input>
        </b-form-group>
      </validation-provider>
      <validation-provider>
        <b-form-group
          label-cols-md="3"
          label-cols-lg="3"
          content-cols-sm
          content-cols-md="9"
        >
          <template v-slot:label>
            Nhà cung cấp<span class="validate-star"> *</span></template
          >
          <multiselect
            :disabled="!!dataSubmit.id"
            class="rbt-custom-input"
            style="max-width: 100% !important"
            v-model="dataSubmit.supplier"
            placeholder="Nhà cung cấp"
            @input="onChangeSupplier"
            :show-labels="false"
            :custom-label="searchingSelect"
            :options="supplierList"
          >
            <span slot="noResult">Không tìm thấy kết quả!!!</span>
            <template slot="singleLabel" slot-scope="props"
              ><span class="option__desc"
                ><span class="option__title">{{
                  props.option.name
                }}</span></span
              ></template
            >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <p class="option__title">
                  {{ props.option.name }}
                </p>
              </div>
            </template>
          </multiselect>
        </b-form-group>
      </validation-provider>
      <div
        v-if="
          !dataSubmit.order_supplier &&
            (dataSubmit.status < 2 || !dataSubmit.id)
        "
        class="mt-3 form-row d--flex"
      >
        <div class="col-md-2 mt-3">
          Sản phẩm
        </div>
        <div class="mr-3 mt-2 col-md-3">
          <multiselect
            class="rbt-custom-input"
            v-model="dataImport.product"
            placeholder="Chọn sản phẩm"
            :show-labels="false"
            :custom-label="searchingSelect"
            :options="productsActive"
          >
            <span slot="noOptions">
              Không có sản phẩm
            </span>
            <span slot="noResult">Không tìm thấy kết quả!!!</span>
            <template slot="singleLabel" slot-scope="props"
              ><span class="option__desc"
                ><span class="option__title">{{
                  props.option.name
                }}</span></span
              ></template
            >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <p class="option__title">
                  {{ props.option.name }}
                </p>
              </div>
            </template>
          </multiselect>
          <div class="d-flex align-items-center">
            <b-spinner
              v-if="isLoading"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </div>

        <div class="col-md-3 mt-2 d-flex">
          <input
            v-model="dataImport.quantity"
            placeholder="Số lượng"
            class="form-control rbt-custom-input"
            id="validationCustom1"
            type="number"
          />
        </div>
        <div class="col-md-3 mt-2 d-flex">
          <input
            v-model="dataImport.price"
            placeholder="Giá"
            class="form-control rbt-custom-input"
            id="validationCustom1"
          />
        </div>
        <div class="align-items-center mb-3 ml-3 mt-3">
          <div
            class="d-flex"
            style="border-radius: 50%; background-color: #28a745"
            @click="addProduct()"
          >
            <feather
              class="rbt-icon-plus"
              style="cursor: pointer"
              type="plus"
              stroke="#f5fbf6"
              size="28px"
              fill="#f5fbf6"
            ></feather>
          </div>
        </div>
      </div>
      <div>
        <div class="table-responsive datatable-vue text-center mt-4">
          <b-table
            show-empty
            striped
            hover
            head-variant="light"
            bordered
            :items="itemsImport"
            :fields="computedFields"
            :per-page="perPage"
          >
            <template #empty>
              <span class="text-danger">
                Chưa có sản phẩm nào
              </span>
            </template>
            <template v-slot:cell(name)="{ item }">
              <span> {{ item.product.name }} </span>
            </template>
            <template v-slot:cell(quantity)="{ item }">
              <div
                class="d-flex justify-content-center align-items-center"
                v-if="dataSubmit.status != 2 && dataSubmit.status != 3"
              >
                <IncreaseAndDecrease
                  style="max-width: 150px"
                  :isNumber="Number(item.quantity) || 0"
                  @onChangeData="onChangeQuantity"
                  :isGreaterZero="true"
                  :product="item.product"
                  :isDiscount="true"
                />
              </div>
              <div v-else>
                <span>
                  {{ item.quantity || 0 }}
                </span>
              </div>
            </template>
            <template v-slot:cell(price)="{ item }">
              <span>
                {{ item.price | formatCurrency }}
                <span v-if="item.product.import_unit"
                  >/{{ item.product.import_unit }}</span
                >
              </span>
            </template>
            <template v-slot:cell(totalPrice)="{ item }">
              <span>
                {{
                  Math.trunc(
                    item.quantity * Number(item.price.replace(/,/g, ""))
                  ) | formatCurrency
                }}
              </span>
            </template>
            <template v-slot:cell(actions)="{ item }">
              <span
                style="font-size: 20px; color: #FA399F;"
                @click="removeItemInTable(item.sno)"
              >
                <feather
                  style="cursor: pointer"
                  type="trash"
                  stroke="#F72E9F"
                  size="18px"
                  fill="#F72E9F"
                ></feather>
              </span>
            </template>
          </b-table>
        </div>
        <div>
          <textarea
            v-model="dataSubmit.reason"
            :disabled="dataSubmit.status >= 1"
            rows="2"
            placeholder="Lý do trả hàng"
            @change="changeNote"
            class="form-control"
          ></textarea>
        </div>
        <div class="media-field mt-4">
          <span
            v-if="file || (dataSubmit.reason_media && dataSubmit.status === 0)"
            class="delete-icon"
          >
            <feather
              @click="deleteFile"
              style="cursor: pointer"
              type="x-circle"
              size="24px"
              fill="#ffffff"
            ></feather>
          </span>
          <input
            type="file"
            id="input-field"
            accept="video/*,image/*"
            class="mt-2"
            style="display: none"
            @change="handleFileUpload($event)"
          />
          <div
            v-if="!file && !dataSubmit.reason_media && dataSubmit.status < 1"
            class="set-full-height"
          >
            <label
              class="d-flex justify-content-center align-items-center set-full-height cursor-pointer"
              for="input-field"
              >Gắn ảnh/video đính kèm</label
            >
          </div>
          <div
            v-if="!dataSubmit.reason_media && dataSubmit.status > 0"
            class="set-full-height"
            style="cursor: pointer"
          >
            <label
              class="d-flex justify-content-center align-items-center set-full-height"
              >Không có ảnh/video đính kèm</label
            >
          </div>
          <!-- showcase for detail/edit order return -->
          <video
            width="100%"
            height="100%"
            controls
            :src="dataSubmit.reason_media"
            v-if="
              !file &&
                dataSubmit.id &&
                dataSubmit.reason_media_type &&
                dataSubmit.reason_media_type === 1
            "
          ></video>
          <img
            v-if="!file && dataSubmit.id && dataSubmit.reason_media_type === 0"
            :src="dataSubmit.reason_media"
            width="100%"
            height="100%"
            fluid
            style="object-fit: cover;"
            class="d-flex justify-content-center"
            rounded
            alt="img"
            @click="handlerPreviewImg(dataSubmit.reason_media)"
          />

          <!-- showcase for create order return -->
          <video
            ref="previewVideo"
            width="100%"
            height="100%"
            controls
            style="display: block"
            v-show="file && file.type.includes('video')"
          ></video>
          <img
            v-if="file && file.type.includes('image')"
            :src="dataSubmit.reason_media"
            width="100%"
            height="100%"
            fluid
            style="object-fit: cover;"
            class="d-flex justify-content-center"
            rounded
            alt="img"
            @click="handlerPreviewImg(dataSubmit.reason_media)"
          />
        </div>
        <div class="mt-3">
          <p>
            Tổng tiền:
            <strong v-if="totalPrice">
              {{ totalPrice | formatCurrency }}
            </strong>
            <strong v-else>
              0 đ
            </strong>
          </p>
        </div>
      </div>
      <template #modal-footer="{cancel}">
        <div style="width: 100%" class="d-flex justify-content-between">
          <div>
            <h2>
              <span v-if="dataSubmit.status === 3" class="badge badge-danger"
                >Đã huỷ</span
              >
            </h2>
            <h2>
              <span v-if="dataSubmit.status === 1" class="badge badge-success"
                >Đã duyệt</span
              >
            </h2>
            <h2>
              <span v-if="dataSubmit.status === 2" class="badge badge-primary"
                >NCC đã kiểm tra</span
              >
            </h2>
          </div>
          <div>
            <b-button class="mr-3" size="sm" variant="danger" @click="cancel()">
              Đóng
            </b-button>
            <b-button
              v-if="!dataSubmit.id"
              class="btn-popup m-0"
              :disabled="!isComplete"
              @click="createOrderReturn()"
            >
              <span style="font-size: 24px; font-weight: 100">
                +
              </span>
              <span v-if="!dataSubmit.id"> Tạo đơn trả hàng</span>
            </b-button>
            <b-button
              v-if="dataSubmit.status === 0"
              class="btn-popup mr-3 my-0"
              :disabled="!isComplete"
              @click="updateOrderReturn()"
            >
              Cập nhật</b-button
            >
            <b-button
              v-if="dataSubmit.status === 0"
              class="btn-popup m-0"
              :disabled="checkDisable"
              @click="approveOrderReturn()"
            >
              Duyệt</b-button
            >
          </div>
        </div>
      </template>
    </b-modal>
    <PreviewImage
      v-if="showPreviewPopup"
      :items="listImgPreview"
      :handler-close-popup="handlerClosePopup"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import IncreaseAndDecrease from "../../components/UI/increaseAndDecrease.vue";
import { PAYMENT_STATUS } from "../../config/payment/index";
import { WAREHOUSE_STATUS } from "../../config/warehouse/index";
import { addCommaPrice } from "../../config/common";
import PreviewImage from "../../components/modals/PreviewImage.vue";

const initalDataImport = {
  price: "",
  quantity: null,
  product: null,
  unit: "",
};

const initalDataSubmit = {
  product: [],
  name: "Trả hàng nhà cung cấp",
  total: null,
  reason: "",
  supplier: null,
  status: null,
  reason_media: "",
};

export default {
  props: {
    refundInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    IncreaseAndDecrease,
    PreviewImage,
  },
  name: "WarehouseImport",
  data() {
    return {
      perPage: process.env.VUE_APP_PAGE_SIZE,
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "name", label: "Sản phẩm" },
        { key: "quantity", label: "Số lượng" },
        { key: "price", label: "Đơn giá" },
        { key: "totalPrice", label: "Thành tiền" },
        { key: "actions", label: "Xóa", require: true },
      ],
      productRefund: [],
      dataImport: { ...initalDataImport },
      dataSubmit: { ...initalDataSubmit },
      checkDisable: false,
      file: "",
      showPreviewPopup: false,
      WAREHOUSE_STATUS_TEXT: WAREHOUSE_STATUS.TEXT
    };
  },
  created() {
    this.getUnitList();
    this.getSupplierList();
  },
  methods: {
    ...mapActions("supplier", ["getSupplierList"]),
    ...mapActions("product", ["onListProduct"]),
    ...mapActions("unit", ["getUnitList"]),
    ...mapActions(["setMessage"]),
    removeItemInTable(index) {
      this.dataSubmit.product.splice(index - 1, 1);
      this.checkDisable = true;
    },
    deleteFile() {
      this.file = "";
      this.dataSubmit.reason_media = "";
      this.dataSubmit.reason_media_type = "";
    },
    handleType({ type }) {
      const {
        supplier,
        product,
        total,
        status,
        payment_status,
        reason_media,
        reason_media_type,
        ...dataSubmit
      } = this.dataSubmit;

      const productList = product.map((item) => {
        const { product, price, quantity, unit } = item;
        return {
          product: product.id,
          total: Number(price.replace(/,/g, "")) * Number(quantity),
          price: Number(price.replace(/,/g, "")),
          quantity: Number(quantity),
          unit: unit,
        };
      });

      const orderReturnData = {
        product: productList,
        supplier: supplier.id,
        total: this.totalPrice,
        payment_status: PAYMENT_STATUS.PAID,
        status: this.WAREHOUSE_STATUS_TEXT.CREATE,
        ...dataSubmit,
      };
      if (type === "add") {
        this.$emit("onCreate", {
          ...(reason_media && { reason_media }),
          ...orderReturnData,
        });
      } else if (type === "update") {
        this.$emit("onUpdate", {
          ...(reason_media && this.file && { reason_media }),
          ...orderReturnData,
        });
      }
      this.checkDisable = false;
    },
    handleFileUpload(event) {
      this.file = "";
      this.file = event.target.files[0];
      let reader = new FileReader();

      reader.readAsDataURL(this.file);
      reader.onloadend = () => {
        this.dataSubmit.reason_media = reader.result;
      };
      let blobURL = URL.createObjectURL(this.file);
      this.$refs.previewVideo.src = blobURL;
      this.checkDisable = true;
    },
    handlerPreviewImg(files) {
      if (!files) return;
      this.listImgPreview = [{ path: files }];
      this.showPreviewPopup = true;
    },
    handlerClosePopup() {
      this.showPreviewPopup = false;
    },
    createOrderReturn() {
      if (this.dataSubmit.product.length > 0 && this.dataSubmit.supplier) {
        this.handleType({
          type: "add",
        });
        this.$bvModal.hide("product-refund-modal");
      } else {
        this.setMessage({
          type: "error",
          content: "Vui lòng chọn sản phẩm và nhà cung cấp",
          isShow: true,
        });
      }
    },
    updateOrderReturn() {
      if (this.dataSubmit.product.length > 0 && this.dataSubmit.supplier) {
        this.handleType({
          type: "update",
        });
        this.$bvModal.hide("product-refund-modal");
      } else {
        this.setMessage({
          type: "error",
          content: "Vui lòng chọn sản phẩm và nhà cung cấp",
          isShow: true,
        });
      }
    },
    approveOrderReturn() {
      this.$emit("onApproved", {
        id: this.dataSubmit.id,
        status: this.WAREHOUSE_STATUS_TEXT.PENDING,
      });
      this.$bvModal.hide("product-refund-modal");
    },
    addProduct() {
      const { product, quantity, price } = this.dataImport;
      if (
        product &&
        quantity > 0 &&
        price &&
        Number(price.replace(/,/g, "")) > 0
      ) {
        this.dataSubmit.product.push({
          product: product,
          unit: product.unit,
          quantity,
          price,
        });
        this.dataImport = { ...initalDataImport };
        this.checkDisable = true;
      } else {
        this.setMessage({
          type: "error",
          content: "Sản phẩm, đơn giá và số lượng không được để trống",
          isShow: true,
        });
      }
    },
    searchingSelect({ name }) {
      return name;
    },
    onChangeQuantity({ quantity, product }) {
      this.dataSubmit.product.forEach((item) => {
        if (item.product.id === product.id) {
          item.quantity = quantity;
        }
      });
      this.checkDisable = true;
    },
    onChangeSupplier(supplier) {
      this.dataImport.product = "";
      this.dataSubmit.product = [];
      if (supplier) {
        this.onListProduct({ supplier_id: supplier.id });
      }
    },
    changeNote() {
      this.checkDisable = true;
    },
  },
  computed: {
    ...mapState("supplier", ["supplierList"]),
    ...mapState("product", ["isListProduct", "isLoading"]),
    ...mapState("unit", ["unitList"]),
    totalPrice() {
      let total = 0;
      if (this.dataSubmit.product.length > 0) {
        this.dataSubmit.product.forEach((item) => {
          total += item.quantity * Number(item.price.replace(/,/g, ""));
        });
      }
      return total;
    },
    productsActive() {
      const idProductInTable = this.dataSubmit.product.map((item) => {
        return item.product.id;
      });
      return this.isListProduct.filter((item) => {
        return !idProductInTable.includes(item.id);
      });
    },
    itemsImport() {
      return this.dataSubmit.product.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
    computedFields() {
      if (this.dataSubmit.status === 0 || !this.dataSubmit.status)
        return this.tablefields;
      else return this.tablefields.filter((field) => !field.require);
    },
    isComplete() {
      return this.dataSubmit.name;
    },
  },
  watch: {
    refundInfo() {
      this.checkDisable = false;
      this.file = "";
      this.dataImport = { ...initalDataImport };
      if (this.refundInfo.id) {
        const { product, ...data } = this.refundInfo;
        const productConvertPrice = product.map((item) => {
          const { price, ...data } = item;
          return {
            price: addCommaPrice(price),
            ...data,
          };
        });
        this.dataSubmit = { product: productConvertPrice, ...data };
      } else {
        this.dataSubmit = { ...initalDataSubmit };
        this.dataSubmit.product = [];
      }
    },
    "dataImport.price"() {
      const priceImport = this.dataImport.price;
      if (typeof priceImport == "string") {
        const result = addCommaPrice(priceImport);
        this.$nextTick(() => (this.dataImport.price = result));
      }
    },
    "dataImport.quantity": {
      handler: function(valueAfter, valuebBefore) {
        if (valueAfter) {
          const result = this.unitList.filter(
            (item) => item.name == this.dataImport.product.unit
          );
          if (result && result.length > 0) {
            const { is_real } = result[0];
            if (is_real) {
              this.dataImport.quantity = parseFloat(valueAfter);
            } else {
              this.dataImport.quantity = parseInt(valueAfter);
            }
          } else {
            this.dataImport.quantity = parseInt(valueAfter);
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
</style>

<style scoped>
.rbt-custom-input {
  max-width: 192px;
  white-space: nowrap;
  height: 43px !important;
}
.media-field {
  width: 100%;
  height: 200px;
  border: dashed 2px gray;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}
.delete-icon {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 100;
}
.set-full-height {
  height: 100%;
}
.cursor-pointer {
  cursor: pointer;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
input {
  height: 100% !important;
}
</style>

<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Báo cáo khách hàng" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <date-picker
                  v-model="date"
                  value-type="format"
                  range
                  class="ml-3 mt-1"
                  format="DD-MM-YYYY"
                  placeholder="Chọn ngày để xem"
                  @change="onChangeValue"
                ></date-picker>
                <div class="col-md-3">
                  <multiselect
                    placeholder="Khách hàng"
                    :show-labels="false"
                    v-model="customer"
                    @search-change="searchCustomer"
                    @input="onChangeValue"
                    :internal-search="false"
                    :options="customerList"
                  >
                    <span slot="noResult">Không tìm thấy kết quả!!!</span>
                    <template slot="singleLabel" slot-scope="props"
                      ><span class="option__desc"
                        ><span class="option__title">{{
                          props.option.name
                        }}</span></span
                      ></template
                    >
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <p class="option__title">
                          {{ props.option.name }}
                        </p>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive datatable-vue product-list">
                <b-table
                  v-if="!isLoading && customerReportList.length > 0"
                  class="text-center"
                  show-empty
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="customerReportData"
                  :fields="tablefields"
                  :tbody-tr-class="rowClass"
                >
                  <template v-slot:cell(total_order)="{ item }">
                    <span>
                      {{ item.total_order | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(total_return)="{ item }">
                    <span> {{ item.total_return | formatCurrency }} </span>
                  </template>
                  <template v-slot:cell(net_total)="{ item }">
                    <span>
                      {{ item.net_total | formatCurrency }}
                    </span>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="!isLoading && customerReportList.length === 0"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalCustomerRecord > 0"
                  v-model="pageNumber"
                  :total-rows="totalCustomerRecord"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import { reverseDate } from "../../config/common";

export default {
  data() {
    return {
      tablefields: [
        { key: "code", label: "Mã KH" },
        { key: "name", label: "Khách hàng" },
        { key: "num_order", label: "Số lượng mua" },
        { key: "total_order", label: "Doanh thu" },
        { key: "num_order_return", label: "Số lượng trả" },
        { key: "total_return", label: "Giá trị trả" },
        { key: "net_total", label: "Doanh thu thuần" },
      ],
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      date: "",
      customer: "",
    };
  },
  components: {
    Loader,
  },
  created() {
    const pageNumber = this.$route.query.page || 1;
    const params = { page: pageNumber, page_size: this.pageSize };
    this.getCustomerReportList(params);
    this.getCustomerList();
  },
  computed: {
    ...mapState("reports", [
      "customerReportList",
      "totalCustomerRecord",
      "totalOrderCustomer",
      "numOrdersCustomer",
      "quantityCustomer",
      "numOrdersReturnCustomer",
      "totalReturnOrderCustomer",
      "totalNetOrderCustomer",
      "isLoading",
    ]),
    ...mapState("customer", ["customerList"]),
    customerReportData() {
      const data = {
        code: `SL Khách hàng: ${this.quantityCustomer}`,
        num_order: this.numOrdersCustomer,
        total_order: this.totalOrderCustomer,
        num_order_return: this.numOrdersReturnCustomer,
        total_return: this.totalReturnOrderCustomer,
        net_total: this.totalNetOrderCustomer,
        status: "awesome",
      };
      const newData = [data].concat(this.customerReportList);
      return newData;
    },
  },
  methods: {
    ...mapActions("reports", ["getCustomerReportList"]),
    ...mapActions("customer", ["getCustomerList"]),
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.date[0] && this.date[1]) {
        params = {
          start: reverseDate(this.date[0]),
          end: reverseDate(this.date[1]),
          ...params,
        };
      }
      if (this.customer) {
        params = {
          customer_id: this.customer,
          ...params,
        };
      }
      this.getCustomerReportList(params);
      this.$router.push({
        path: "/reports/customer-report",
        query: { page: this.pageNumber },
      });
    },
    onChangeValue() {
      let params = {
        page: 1,
        page_size: this.pageSize,
      };
      if (this.date[0] && this.date[1]) {
        params = {
          start: reverseDate(this.date[0]),
          end: reverseDate(this.date[1]),
          ...params,
        };
      }
      if (this.customer) {
        params = {
          customer_id: this.customer?.id,
          ...params,
        };
      }
      this.getCustomerReportList(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/reports/customer-report",
          query: { page: this.pageNumber },
        });
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === "awesome") return "table-warning";
    },
    searchCustomer: _.debounce(function(query) {
      this.getCustomerList({ query: query.trim() });
    }, 250),
  },
};
</script>

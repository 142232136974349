<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Danh sách sản phẩm có định mức" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>{{ totalRecord }} kết quả</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <b-form-group class="datatable-select col-md-3">
                  <b-form-input
                    type="text"
                    v-model="search"
                    placeholder="Tìm kiếm"
                    @change="onSearch()"
                  ></b-form-input>
                </b-form-group>
                <b-form-select
                  v-model="selectCategory"
                  @change="onSearch()"
                  class="col-md-2 mr-2"
                >
                  <option value="">Danh mục</option>
                  <option
                    v-for="category in categoryList"
                    :key="category.index"
                    :value="category.id"
                    >{{ category.name }}</option
                  >
                </b-form-select>
                <b-form-select
                  v-model="activeProduct"
                  @change="onSearch()"
                  class="col-md-2"
                >
                  <option value="">Trạng thái</option>
                  <option value="true">Đang kinh doanh</option>
                  <option value="false">Không kinh doanh</option>
                </b-form-select>
                <b-form-select
                  v-model="ordering"
                  @change="onSearch()"
                  class="col-md-2 ml-2"
                >
                  <option value="">Lọc theo</option>
                  <option value="expiry">Sắp hết hạn</option>
                  <option value="product_inventory_quantity"
                    >Sắp hết hàng</option
                  >
                </b-form-select>
                <div class="col-md-1 mr-2">
                  <b-button
                    class="btn-popup"
                    variant="primary"
                    @click="onSearch()"
                    >Tìm</b-button
                  >
                </div>
                <router-link to="/product/product-add">
                  <b-button class="btn-popup">Thêm mới</b-button></router-link
                >
              </div>
              <div class="table-responsive datatable-vue product-list">
                <b-modal id="modal-2" title="Xác nhận" @ok="onDelete(isId)">
                  <p class="my-4">
                    Xóa <b>{{ isName }}</b>
                  </p>
                </b-modal>
                <b-table
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="productWithSno"
                  :fields="tablefields"
                  :filter="filter"
                >
                  <template v-slot:cell(brand)="{ item }">
                    <span v-if="item.brand">
                      {{ item.brand.name }}
                    </span>
                  </template>
                  <template v-slot:cell(name)="{ item }">
                    <span
                      v-if="
                        Number(item.product_inventory_quantity) <
                          Number(item.min_inventory)
                      "
                      class="text-warning"
                    >
                      {{ item.name }}
                    </span>
                    <span v-else>{{ item.name }}</span>
                  </template>
                  <template v-slot:cell(expiry)="{ item }">
                    <span>
                      {{ item.expiry | dateFormat }}
                    </span>
                  </template>
                  <template v-slot:cell(category)="{ item }">
                    <span v-if="item.category">
                      {{ item.category.name }}
                    </span>
                  </template>
                  <template v-slot:cell(price)="{ item }">
                    {{
                      !item.default_price && item.default_price !== 0
                        ? "-"
                        : item.default_price | formatCurrency
                    }}
                  </template>
                  <template v-slot:cell(active)="{ item }">
                    <input
                      type="checkbox"
                      id="flexCheckChecked2"
                      @change="onChangeStatus($event, item)"
                      v-model="item.active"
                    /><span> Kinh doanh</span>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="onEdit(item)"
                      ></feather>
                    </span>
                    <span style="font-size: 20px; color: #FA399F;">
                      <feather
                        v-b-modal.modal-2
                        style="cursor: pointer"
                        type="trash"
                        stroke="#F72E9F"
                        size="18px"
                        fill="#F72E9F"
                        @click="setItem(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="!isLoading && isListProduct.length === 0"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRecord > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecord"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";

export default {
  data() {
    return {
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "name", label: "Tên sản phẩm" },
        { key: "price", label: "Giá bán" },
        { key: "product_inventory_quantity", label: "Tồn kho" },
        { key: "unit", label: "Đơn vị bán" },
        { key: "expiry", label: "Hạn sử dụng" },
        { key: "category", label: "Danh mục" },
        { key: "brand", label: "Thương hiệu" },
        { key: "active", label: "Trạng thái" },
        { key: "actions", label: "Hành động" },
      ],
      filter: null,
      isId: "",
      isName: "",
      search: "",
      pageNumber: 1,
      activeProduct: "",
      ordering: "",
      selectCategory: "",
      pageSize: process.env.VUE_APP_PAGE_SIZE,
    };
  },
  components: {
    Loader,
  },
  created() {
    const pageNumber = this.$route.query.page || 1;
    const params = {
      page: pageNumber,
      page_size: this.pageSize,
      have_quantitations: true,
    };
    this.onListProduct(params);
    this.getCategoryList();
  },
  computed: {
    ...mapState("product", ["isListProduct", "totalRecord", "isLoading"]),
    ...mapState("category", ["categoryList"]),
    productWithSno() {
      return (
        this.isListProduct?.map((product, index) => {
          return {
            sno: index + 1,
            ...product,
          };
        }) || []
      );
    },
  },
  watch: {},
  methods: {
    ...mapActions("product", [
      "onListProduct",
      "onUpdateProduct",
      "removeProduct",
    ]),
    ...mapActions("category", ["getCategoryList"]),
    onDelete(id) {
      const params = {
        page: this.pageNumber,
        page_size: this.pageSize,
        have_quantitations: true,
      };
      this.removeProduct({ id: id, params });
      this.search = "";
    },
    setItem(item) {
      this.isId = item.id;
      this.isName = item.name;
    },
    onEdit(item) {
      this.$router.push({
        name: "product-add",
        query: { id: item.id },
      });
    },
    onSearch() {
      let params = {
        page: 1,
        page_size: this.pageSize,
        have_quantitations: true,
      };
      if (this.search) {
        params = {
          name: this.search.trim(),
          ...params,
        };
      }
      if (this.activeProduct) {
        params = {
          active: this.activeProduct,
          ...params,
        };
      }
      if (this.ordering) {
        params = {
          ordering: this.ordering,
          ...params,
        };
      }
      if (this.selectCategory) {
        params = {
          category_id: this.selectCategory,
          ...params,
        };
      }
      this.onListProduct(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/settings/product-quantitation",
          query: { page: this.pageNumber },
        });
      }
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = {
        page: this.pageNumber,
        page_size: this.pageSize,
        have_quantitations: true,
      };
      if (this.search) {
        params = {
          name: this.search.trim(),
          ...params,
        };
      }
      if (this.activeProduct) {
        params = {
          active: this.activeProduct,
          ...params,
        };
      }
      if (this.ordering) {
        params = {
          ordering: this.ordering,
          ...params,
        };
      }
      if (this.selectCategory) {
        params = {
          category_id: this.selectCategory,
          ...params,
        };
      }
      this.onListProduct(params);
      this.$router.push({
        path: "/settings/product-quantitation",
        query: { page: this.pageNumber },
      });
    },
    onChangeStatus(event, product) {
      const value = event.target.checked;
      const {
        active,
        image,
        price,
        sale_price,
        category,
        brand,
        suppliers,
        list_images,
        quantitations,
        ...data
      } = product;
      const supplierListId = suppliers?.map((item) => {
        return item.id;
      });

      const productsId = quantitations?.map((item) => {
        const { quantity } = item;
        return {
          product: item.product.id,
          quantity: quantity,
        };
      });
      const dataSubmit = {
        active: value,
        price: parseInt(price),
        sale_price: parseInt(sale_price) || 0,
        category: category?.id,
        brand: brand?.id,
        suppliers: supplierListId,
        quantitations: productsId,
        ...data,
      };
      let params = {
        page: this.pageNumber,
        page_size: this.pageSize,
        have_quantitations: true,
      };
      if (this.search) {
        params = {
          name: this.search.trim(),
          ...params,
        };
      }
      if (this.activeProduct) {
        params = {
          active: this.activeProduct,
          ...params,
        };
      }
      if (this.ordering) {
        params = {
          ordering: this.ordering,
          ...params,
        };
      }
      if (this.selectCategory) {
        params = {
          category_id: this.selectCategory,
          ...params,
        };
      }
      if (this.supplier) {
        params = {
          supplier_id: this.supplier,
          ...params,
        };
      }
      this.onUpdateProduct({ data: dataSubmit, params });
    },
  },
};
</script>

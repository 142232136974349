<template>
  <div style="min-height: calc(100vh - 100px)" class="row">
    <loader :is-visible="isLoading"></loader>
    <!-- <div class="container-fluid">
      <div class="page-header py-4">
        <Breadcrumbs title="Đơn hàng" />
      </div>
    </div> -->
    <div
      class="container-fluid container-order"
      :style="
        tabList.length <= 0 ? { width: '100% !important' } : { width: '70%' }
      "
    >
      <!-- <button @click="openModalPrint()">open modal</button> -->
      <div class="row">
        <div class="col-md-12 px-0">
          <div class="card" style="margin-bottom: 0 !important">
            <div class="card-body py-0">
              <!-- select order -->
              <div v-if="tabList.length > 0" class="row">
                <!-- order new -->
                <div
                  class="col-12 my-2"
                  :class="!checkRoles('employee') ? 'col-md-12' : 'col-md-6'"
                >
                  <span class="font-weight-bold">
                    Đơn hàng online mới
                  </span>
                </div>

                <!-- order come to pick -->
                <div
                  v-if="checkRoles('employee')"
                  class="col-12 col-md-6 my-2 pl-0"
                >
                  <span class="font-weight-bold">
                    Đơn hàng khách nhận tại quầy
                  </span>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="row" style="width: 100%">
                  <!-- order new -->
                  <div
                    class="col-12"
                    :class="!checkRoles('employee') ? 'col-md-12' : 'col-md-6'"
                  >
                    <multiselect
                      v-if="tabList.length > 0"
                      v-model="selectOrder"
                      class="rbt-custom-multiselect"
                      placeholder="Chọn đơn hàng"
                      track-by="code"
                      :disabled="!!comeToPickOrder"
                      :custom-label="searchingSelect"
                      :show-labels="false"
                      :options="[]"
                      @select="onChangeSelect"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">
                            {{ props.option.name }} / {{ props.option.code }}
                          </span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <p class="option__title">
                            {{ props.option.name }}
                          </p>
                          <p class="option__small mb-0">
                            {{ props.option.code }}
                          </p>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                  <!-- order come to pick -->
                  <div class="col-12 col-md-6">
                    <multiselect
                      v-if="tabList.length > 0 && checkRoles('employee')"
                      v-model="comeToPickOrder"
                      class="rbt-custom-multiselect"
                      placeholder="Đơn hàng khách nhân tại quầy"
                      track-by="code"
                      :disabled="!!selectOrder"
                      :custom-label="searchingSelect"
                      :show-labels="false"
                      :options="listOrderComeToPick"
                      @select="onChangeSelect"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">
                            {{ props.option.name }} / {{ props.option.code }}
                          </span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <p class="option__title">
                            {{ props.option.name }}
                          </p>
                          <p class="option__small mb-0">
                            {{ props.option.code }}
                          </p>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <div v-if="tabList.length > 0" class="ml-2">
                  <i
                    @click="reloadOrder()"
                    class="d-flex align-items-center"
                    style="cursor: pointer"
                    data-feather="refresh-ccw"
                    ><feather type="refresh-ccw"></feather
                  ></i>
                </div>
              </div>
              <div class="mt-3 d-flex">
                <!-- warehouse -->
                <div class="mr-3 d-flex align-items-center" style="width: 30%">
                  <input
                    v-if="selectWarehouse && checkRoles('employee')"
                    readonly
                    :value="selectWarehouse.name"
                    placeholder="Tên bảng giá"
                    class="form-control rbt-custom-input"
                    style="max-width: 100%"
                    id="validationCustom1"
                    type="text"
                  />
                  <input
                    v-if="!selectWarehouse"
                    readonly
                    value="Chưa có cửa hàng"
                    class="form-control rbt-custom-input"
                    style="max-width: 100%"
                    id="validationCustom1"
                    type="text"
                  />
                </div>
                <multiselect
                  v-model="activePromotions"
                  :disabled="true"
                  class="rbt-custom-multiselect"
                  placeholder="Bảng giá khuyến mãi"
                  track-by="name"
                  label="name"
                  :show-labels="false"
                  :options="activePromotions"
                  :multiple="true"
                >
                  <template slot="singleLabel" slot-scope="props"
                    ><span class="option__desc"
                      ><span class="option__title">{{
                        props.option.name
                      }}</span></span
                    ></template
                  >
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <p class="option__title">
                        {{ props.option.name }}
                      </p>
                    </div>
                  </template>
                </multiselect>
              </div>
              <b-tabs
                v-if="!isUpdateOrder || !$route.query.id"
                v-model="isTabCurrent"
                @changed="onTabChanged"
                card
                class="rbt-card-tab-list"
              >
                <!-- Render Tabs, supply a unique `key` to each tab -->
                <b-tab
                  active
                  class="py-0"
                  v-for="tab in tabList"
                  :key="tab.index"
                >
                  <template #title>
                    <strong>{{ tab.title }}</strong>
                    <feather
                      v-if="currentTab === tab.name"
                      class="ml-2 mb-1"
                      stroke="#F72E9F"
                      size="14px"
                      stroke-width="5"
                      type="x"
                      @click="closeTab(tab.name)"
                    ></feather>
                  </template>
                  <div
                    v-if="!comeToPickOrder && !isUpdateOrder"
                    class="row mt-3"
                  >
                    <div ref="elementProduct" class="col-sm-6">
                      <multiselect
                        v-model="productImport"
                        ref="selectProduct"
                        placeholder="Sản phẩm"
                        class="rbt-custom-input"
                        :show-labels="false"
                        @search-change="search"
                        :internal-search="false"
                        :options="productsBottom"
                      >
                        <span slot="noResult">Không tìm thấy kết quả!!!</span>
                        <template slot="singleLabel" slot-scope="props"
                          ><span class="option__desc"
                            ><span class="option__title">{{
                              props.option.name
                            }}</span></span
                          ></template
                        >
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <p class="option__title">
                              {{ props.option.name }}
                            </p>
                          </div>
                        </template>
                      </multiselect>
                    </div>
                    <div class="col-sm-6">
                      <b-form-group>
                        <b-form-input
                          v-model="quantityImport"
                          ref="quantityFocus"
                          placeholder="Số lượng"
                          class="rbt-custom-input"
                          @keyup.enter="changeQuantityImport"
                          type="number"
                          min="1"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                </b-tab>
                <!-- New Tab Button (Using tabs-end slot) -->
                <template #tabs-end>
                  <b-nav-item
                    class="rbt-end-tab"
                    role="presentation"
                    @click.prevent="newTab"
                    href="#"
                    ><b>+</b></b-nav-item
                  >
                </template>

                <!-- Render this if no tabs -->
                <template #empty>
                  <div class="text-center text-muted">
                    Không có cửa sổ nào<br />
                    Bấm vào dấu <b>+</b> để mở cửa sổ.
                  </div>
                </template>
              </b-tabs>

              <div
                v-if="tabList.length > 0"
                class="table-responsive datatable-vue text-center mt-4"
                :style="
                  isUpdateOrder
                    ? 'height: calc(100vh - 294px) !important; overflow: scroll'
                    : 'height: calc(100vh - 365px); overflow: scroll'
                "
              >
                <b-modal
                  id="model-remove"
                  title="Xác nhận"
                  @ok="removeProductInTab()"
                  @hide="cancelModalRemove()"
                >
                  <p class="my-4">
                    Xoá sản phẩm <b>{{ nameRemove }}</b>
                  </p>
                </b-modal>
                <b-table
                  show-empty
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="itemsWithSno"
                  :fields="computedFields"
                >
                  <template #empty>
                    <span class="text-danger">
                      không có sản phẩm nào
                    </span>
                  </template>
                  <template v-slot:cell(unit)="{ item }">
                    <span>
                      {{ item.product.unit }}
                    </span>
                  </template>
                  <template v-slot:cell(name)="{ item }">
                    <span>
                      {{ item.product.name }}
                      <span
                        v-if="
                          item.product.store_price_sale !=
                            item.product.store_price
                        "
                      >
                        🎁
                      </span>
                    </span>
                  </template>

                  <template v-slot:cell(store_price)="{ item }">
                    <div class="group-store-price">
                      <span
                        :style="
                          item.product.store_price !=
                            item.product.store_price_sale &&
                          !item.is_original_price
                            ? 'text-decoration: line-through; color: #8b8787'
                            : 'text-decoration: none; color: #212529'
                        "
                      >
                        {{ item.product.store_price | formatCurrency
                        }}<b-checkbox
                          v-if="
                            item.product.store_price !=
                              item.product.store_price_sale && !isUpdateOrder
                          "
                          v-model="item.is_original_price"
                          @change="
                            changeStatus($event, item.product.id, item.sno)
                          "
                          >Áp dụng</b-checkbox
                        >
                      </span>
                    </div>
                  </template>
                  <template v-slot:cell(store_price_sale)="{ item }">
                    <span
                      :style="
                        item.is_original_price
                          ? 'text-decoration: line-through; color: #8b8787'
                          : 'text-decoration: none; color: #212529'
                      "
                      v-if="
                        item.product.store_price !=
                          item.product.store_price_sale
                      "
                    >
                      {{ item.product.store_price_sale | formatCurrency }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </template>
                  <template v-slot:cell(totalPrice)="{ item }">
                    <span>
                      {{
                        (item.is_original_price
                          ? item.product.store_price * item.quantity
                          : item.product.store_price_sale * item.quantity
                        ).toFixed(0) | formatCurrency
                      }}
                    </span>
                  </template>

                  <template v-slot:cell(inventories)="{ item }">
                    <div
                      v-for="inventory in item.product.inventories"
                      :key="inventory.index"
                    >
                      <div v-if="inventory.warehouse === idWarehouse">
                        <span>
                          {{ inventory.quantity }}
                        </span>
                      </div>
                    </div>
                  </template>

                  <template v-slot:cell(quantity)="{ item }">
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <div
                        v-if="!comeToPickOrder && !isUpdateOrder"
                        style="width: 170px;"
                      >
                        <IncreaseAndDecrease
                          :isNumber="Number(item.quantity)"
                          :product="item.product"
                          :isFocusInput="isFocusInputQuantity"
                          @onChangeData="onIncreAndDecre"
                        />
                      </div>
                      <div v-else>
                        <span>
                          {{ item.quantity }}
                        </span>
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(note)="{ item }">
                    <div>
                      <textarea
                        v-model="item.note"
                        :disabled="comeToPickOrder"
                        rows="2"
                        @change="changeNote($event, item.product.id, item.sno)"
                        placeholder="Ghi chú"
                        class="form-control"
                      ></textarea>
                    </div>
                  </template>
                  <template
                    v-slot:cell(actions)="{ item }"
                    v-if="!isUpdateOrder"
                  >
                    <span style="font-size: 20px; color: #FA399F;">
                      <feather
                        @click="openModalRemove(item.product)"
                        style="cursor: pointer"
                        type="trash"
                        stroke="#F72E9F"
                        size="18px"
                        fill="#F72E9F"
                      ></feather>
                    </span>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
        <!-- <ListProductBottom
          style="bottom: 0px; width: 100%;"
          v-if="tabList.length > 0"
          :productList="productsBottom"
          @onAddProductInTab="onAddProductInTab"
        /> -->
      </div>
    </div>
    <div
      v-if="tabList.length > 0"
      style="height: calc(100vh - 80px); overflow: scroll"
      class="container-info"
    >
      <OrderPayment
        v-if="!comeToPickOrder"
        :selectOrder="selectOrder"
        :isSelectOrderToUpdate="isSelectOrderToUpdate"
        :customers="customerList"
        :isOrderPayment="isOrderPayment"
        :isCloseTab="isCloseTab"
        :isNewTab="isNewTab"
        :isSelectCustomer="isSelectCustomer"
        :isCustomerMoney="isCustomerMoney"
        :isPay="isPay"
        :isChangePrice="isChangePrice"
        @onSubmitOrder="onSubmitOrder"
        @onConfirmOrder="onConfirmOrder"
        @onCancelOrder="onCancelOrder"
        @onSubmitOrderOnline="onSubmitOrderOnline"
        @onClosePayment="onClosePayment"
        @onPrintBill="onPrintBill"
      />
      <ComeToPickOrder
        v-else
        :selectOrder="selectOrder"
        :isSelectOrderToUpdate="isSelectOrderToUpdate"
        :customers="customerList"
        :isOrderPayment="isOrderPayment"
        :isCloseTab="isCloseTab"
        :isNewTab="isNewTab"
        :isSelectCustomer="isSelectCustomer"
        :isCustomerMoney="isCustomerMoney"
        :isPay="isPay"
        :isChangePrice="isChangePrice"
        @onSubmitOrder="onSubmitOrder"
        @onConfirmOrder="onConfirmOrder"
        @onCancelOrder="onCancelOrder"
        @onClosePayment="onClosePayment"
        @onUpdatePaymentNotShip="onUpdatePaymentNotShip"
      />
    </div>
    <InvoiceModal @onCloseOrder="onCloseOrder" />
  </div>
</template>

<script>
import OrderPayment from "../../components/UI/order-payment.vue";
import ComeToPickOrder from "../../components/UI/ComeToPickOrder.vue";
import IncreaseAndDecrease from "../../components/UI/increaseAndDecrease.vue";
import ListProductBottom from "../../components/UI/listProductBottom.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import Loader from "../../components/loading/loader.vue";
import { vueInstance } from "../../main";
import { constant, debounce, forEach } from "lodash";
import product from "../../services/product";
import InvoiceModal from "../../components/modals/order/InvoiceModal.vue";
import { Roles } from "../../types/roles";
import { ORDER_STATUS } from "../../config/orders/index";

export default {
  components: {
    OrderPayment,
    IncreaseAndDecrease,
    ListProductBottom,
    Loader,
    InvoiceModal,
    ComeToPickOrder,
  },
  data() {
    return {
      selectWarehouse: null,
      selectCategory: null,
      tabs: [1],
      tabCounter: 2,
      isTabCurrent: 0,
      idRemove: null,
      nameRemove: null,
      value: "",
      tablefields: [
        { key: "actions", label: "Xóa", require: true },
        { key: "sno", label: "STT" },
        { key: "name", label: "Sản phẩm" },
        { key: "inventories", label: "Tồn kho" },
        { key: "unit", label: "Đơn vị" },
        { key: "quantity", label: "Số lượng bán" },
        { key: "store_price", label: "Giá gốc" },
        { key: "store_price_sale", label: "Giá sau khuyến mãi" },
        { key: "note", label: "Ghi chú", require: true },
        { key: "totalPrice", label: "Thành tiền" },
      ],
      isOrderPayment: true,
      isNewTab: false,
      isCloseTab: false,
      isTouched: false,
      isMounted: false,
      isSelectCustomer: false,
      isCustomerMoney: false,
      isPay: false,
      isFocusInputQuantity: false,
      pageSize: process.env.VUE_APP_MAX_PAGE_SIZE,
      quantityImport: null,
      productImport: null,
      selectOrder: null,
      newOrderId: null,
      orderConfirmed: null,
      listOrderComeToPick: [],
      comeToPickOrder: null,
      isSelectOrderToUpdate: false,
      isCloseOrder: true,
      isLoadingOrder: false,
    };
  },
  async created() {
    this.detechParamsInRoute();
    this.selectWarehouse = this.userProfile?.store;
    if (!this.isUpdateOrder) {
      this.selectPrice = this.subContentActive?.priceList;
    }
    // this.isTabCurrent =
    //   this.currentTab > 0 ? this.currentTab - 1 : this.currentTab;
    if (this.tabList?.length < 1) {
      this.initialLocalOrder();
    }
    this.getCustomerList();
    this.getCategoryList();
    this.getWarehouseList();
    this.getPromotions();
    document.addEventListener("keydown", this.handleKeyPress);
  },
  destroyed() {
    document.removeEventListener("keydown", this.handleKeyPress);
  },
  computed: {
    ...mapState("order", [
      "currentTab",
      "tabList",
      "tabListContent",
      "orderActive",
      "subContentActive",
      "isLoading",
      "orderDetail",
      "isChangePrice",
      "orderList",
      "isOrderSubmit",
      "isOrderSuccess",
      "isUpdateOrder",
      "isUpdatePaymentNotShipSuccess",
      "isUpdateOrderOnline",
      "isConfirmOrderOnline",
    ]),
    ...mapState("product", ["isListProduct"]),
    ...mapState("customer", ["customerList"]),
    ...mapState("category", ["categoryList"]),
    ...mapState("warehouse", ["warehouseList"]),
    ...mapState("profile", ["userProfile"]),
    ...mapState("promotion", ["promotions"]),
    ...mapState("store", ["storeList"]),
    roleList() {
      return Roles;
    },
    newOrderOnline() {
      return ORDER_STATUS.NUMERIC;
    },
    productWithSalePrice() {
      return this.isListProduct.filter((item) => {
        return item.store_price != undefined;
      });
    },
    itemsWithSno() {
      if (this.isLoading === false) {
        return this.orderActive?.map((item, index) => {
          return {
            sno: index + 1,
            ...item,
          };
        });
      }
    },
    productsBottom() {
      if (this.selectWarehouse && this.orderActive) {
        const idPurchasedProduct = this.orderActive.map((item) => {
          return item.product.id;
        });
        if (this.selectCategory) {
          const data = this.productWithSalePrice.filter((item) => {
            return this.selectCategory.id === item.category.id;
          });
          return data?.filter((item) => {
            return !idPurchasedProduct.includes(item.id);
          });
        } else {
          if (!idPurchasedProduct) return [];
          return this.productWithSalePrice.filter((item) => {
            return !idPurchasedProduct.includes(item.id);
          });
        }
      }
      return [];
    },
    activePromotions() {
      return this.promotions.filter((item) => item.is_active);
    },
    computedFields() {
      if (!this.isUpdateOrder) return this.tablefields;
      else return this.tablefields.filter((field) => !field.require);
    },
    idWarehouse() {
      const currentStoreID = this.userProfile.store.id;
      const result = this.storeList.find((item) => item.id === currentStoreID);
      return result?.warehouse;
    },
  },
  mounted() {
    this.toggleFullscreen();
    this.isMounted = true;
    this.SET_DISABLED_CHANGE_PRICE(true);
    this.onListProduct();
    this.getOrderList({
      status_order: this.newOrderOnline.NEW_ORDER,
      type: this.newOrderOnline.ONLINE,
    });
  },
  methods: {
    ...mapMutations("order", [
      "SET_DISABLED_SUBMIT",
      "SET_DISABLED_CHANGE_PRICE",
    ]),
    ...mapActions("order", [
      "onCurrentTab",
      "onTabList",
      "onTabListContent",
      "onRemoveTabList",
      "addProductInTab",
      "onAddQuantity",
      "onRemoveProductInTab",
      "onOrder",
      "onSetSubContentInTab",
      "handlePriceInTab",
      "getOrderDetail",
      "onUpdateOrderLocal",
      "updateOrderOnline",
      "confirmOrderOnline",
      "onChangeNote",
      "onChangeStatus",
      "cancelOrderOnline",
      "getOrderList",
      "updatePaymentNotShip",
    ]),
    ...mapActions("product", ["onListProduct"]),
    ...mapActions("customer", ["getCustomerList"]),
    ...mapActions("category", ["getCategoryList"]),
    ...mapActions("warehouse", ["getWarehouseList"]),
    ...mapActions("profile", ["getUserProfile"]),
    ...mapActions(["setMessage"]),
    ...mapActions("promotion", ["getPromotions"]),
    reloadOrder() {
      this.checkRoleGetOrder();
    },
    onPrintBill() {
      this.isCloseOrder = false;
      this.$bvModal.show("modal-print-order");
    },
    onChangeSelect(value) {
      const { id } = value;
      this.$router.push({
        name: "order",
        query: { id: id },
      });
    },
    async onUpdatePaymentNotShip(payload) {
      await this.updatePaymentNotShip(payload);
    },
    checkRoles(role) {
      return this.userProfile?.groups[0].name === this.roleList[role];
    },
    async checkRoleGetOrder() {
      if (this.newOrderId) {
        if (this.userProfile?.groups[0].name === this.roleList.cskh) {
          this.getOrderList({
            status_order: this.newOrderId.id,
            warehouse_isnull: "true",
          });
        } else if (
          this.userProfile?.groups[0].name === this.roleList.employee
        ) {
          this.getOrderList({
            status_order: this.newOrderId.id,
            warehouse_isnull: "false",
            store: this.userProfile?.store.id,
          });
          // orderConfirmed
          try {
            this.listOrderComeToPick = await this.getOrderList({
              status_order: this.orderConfirmed.id,
              warehouse_isnull: false,
              store: this.userProfile?.store.id,
              customer_come_to_pick_up: true,
            });
          } catch (error) {
            console.error(error);
            this.listOrderComeToPick = [];
          }
        }
        // this.selectOrder = null;
      }
    },
    changeQuantityImport() {
      if (this.productImport && this.quantityImport > 0) {
        this.onChangeSelectProduct(this.productImport, this.quantityImport);
        this.productImport = null;
        this.quantityImport = null;
      }
    },
    changeNote(value, id, index) {
      this.SET_DISABLED_CHANGE_PRICE(true);
      this.onChangeNote({ value: value.target.value, id, index: index - 1 });
    },
    changeStatus(value, id, index) {
      this.onChangeStatus({ value, id, index: index - 1 });
    },
    detechParamsInRoute() {
      // this.selectWarehouse = !this.isUpdateOrder
      //   ? this.userProfile?.warehouse
      //   : null;
      const id = this.$route.query?.id;
      if (id || this.checkRoles("cskh")) {
        this.onUpdateOrderLocal(true);
        this.getOrderDetail(id);
      } else if (!id && !this.checkRoles("cskh")) {
        this.onUpdateOrderLocal(false);
      }
      if (this.selectWarehouse) {
        this.SET_DISABLED_CHANGE_PRICE(false);
      }
    },
    openSelectProduct() {
      if (this.isMounted) {
        if (!this.isUpdateOrder) {
          this.$refs.selectProduct[this.currentTab - 1].toggle();
        } else {
          this.$refs.selectProduct.toggle();
        }
      }
    },
    onCloseOrder() {
      this.$bvModal.hide("modal-print-order");
      if (this.isCloseOrder) {
        this.closeTab(this.currentTab);
        if (this.$route.path !== "/sales/order") {
          this.$router.push("/sales/order");
        }
        this.selectOrder = null;
        this.comeToPickOrder = null;
      }
    },
    openModalPrint() {
      this.$bvModal.show("modal-print-order");
      this.SET_DISABLED_SUBMIT(false);
    },
    handleKeyPress(e) {
      if (e.keyCode === 112) {
        if (!this.selectOrder && !this.comeToPickOrder && !this.isUpdateOrder) {
          this.newTab();
        }
      }
      if (e.keyCode === 113) {
        if (!this.selectOrder && !this.comeToPickOrder && !this.isUpdateOrder) {
          this.closeTab(this.currentTab);
        }
      }
      if (e.keyCode === 114) {
        this.openSelectProduct();
      }
      if (e.keyCode === 115) {
        this.isSelectCustomer = !this.isSelectCustomer;
      }
      if (e.keyCode === 119) {
        this.isCustomerMoney = !this.isCustomerMoney;
      }
      if (e.keyCode === 120) {
        this.isPay = !this.isPay;
      }
      if (e.keyCode === 122) {
        this.toggleFullscreen();
      }
      // if (e.keyCode === 13) {
      //   this.isFocusInputQuantity = !this.isFocusInputQuantity;
      // }
      if (e.keyCode === 27) {
        this.$store.dispatch("menu/openSidebar");
      }
    },
    handlePrice() {
      let listIdProductDiscount = [];
      let listIdCategoryDiscount = [];
      const priceListLocal =
        this.selectOrder || this.comeToPickOrder
          ? this.selectOrder?.price_lists || this.comeToPickOrder?.price_lists
          : this.subContentActive?.priceList;
      for (let index = 0; index < priceListLocal?.length; index++) {
        const item = priceListLocal[index];
        if (!item.product?.[0]?.category) {
          const listIdProductDiscountTemp = item?.product?.map((item) => {
            return {
              id: item?.product || item?.product_detail?.id,
              default_price: item.default_price,
            };
          });
          listIdProductDiscountTemp?.forEach((item) => {
            if (!listIdProductDiscount.some((check) => check?.id === item.id)) {
              listIdProductDiscount.push(item);
            }
          });
        } else if (item.product?.[0]?.category) {
          const listIdCategoryDiscountTemp = item?.product?.map((item) => {
            return {
              id: item?.category,
              discount: item?.store_price_sale,
            };
          });
          listIdCategoryDiscountTemp?.forEach((item) => {
            if (
              !listIdCategoryDiscount.some((check) => check?.id === item.id)
            ) {
              listIdCategoryDiscount.push(item);
            }
          });
        }
      }
      const listTabs = Object.keys(this.tabListContent);
      const listTabsContent = listTabs.map((item) => {
        return this.tabListContent[item]?.length > 0
          ? this.tabListContent[item].map((product) => {
              return {
                note: product?.note || "",
                quantity: product.quantity,
                product: product.product,
                default_price:
                  listIdProductDiscount.find(
                    (idDiscount) => idDiscount.id === product.product.id
                  )?.default_price ||
                  product.product.default_price -
                    (listIdCategoryDiscount.find((idDiscount) => {
                      return idDiscount.id === product.product.category?.id;
                    })?.store_price_sale /
                      100) *
                      product.product.default_price ||
                  this.handleRangePrice(product.product, product.quantity),
                is_original_price: product?.is_original_price,
              };
            })
          : [];
      });
      let dataHandledPrice = {};
      listTabs.forEach((item, index) => {
        dataHandledPrice[item] = listTabsContent[index];
      });
      this.handlePriceInTab(dataHandledPrice);
    },
    handleRangePrice(product, quantity) {
      let default_price = 0;
      const priceRange = product?.promotion_according_to_quantity;
      if (priceRange && priceRange.length > 0) {
        // let priceTemp = priceRange[0];
        let priceTemp =
          priceRange.find((item) => {
            return quantity <= item.max && quantity > item.min;
          }) || 0;
        if (typeof priceTemp === "object") {
          priceRange.forEach((item) => {
            if (quantity > item.min && quantity <= item.max) {
              if (item.max - quantity < priceTemp.max - quantity) {
                priceTemp = item;
              }
            }
          });
        }
        default_price =
          typeof priceTemp === "object" ? priceTemp.default_price : 0;
      }
      return default_price;
    },
    handleAddProductInTab(product, isAddUpdate, quantity) {
      if (!this.isUpdateOrder) {
        this.addProductInTab({
          product,
          quantity: quantity || 1,
          default_price: product.default_price,
          note: "",
          is_original_price: false,
        });
      } else {
        let payload = [...this.orderActive];
        if (isAddUpdate) {
          payload.push({
            product,
            quantity: quantity || 1,
            default_price: product.default_price,
            note: "",
            is_original_price: false,
          });
        } else {
          payload = product;
        }
        this.addProductInTab(payload);
      }
      this.handlePrice();
      this.isFocusInputQuantity = !this.isFocusInputQuantity;
    },
    onSubmitOrder(order) {
      if (!order.id) {
        this.onOrder(order);
      } else {
        const { id, ...data } = order;
        this.updateOrderOnline({ id, data: { ...data } });
      }
    },
    async onConfirmOrder(payload) {
      const address = this.orderDetail.address?.id
        ? this.orderDetail.address?.id
        : null;
      payload.address = address ? address : null;
      const { id, ...data } = payload;
      await this.confirmOrderOnline({ id, data: data });
      this.$bvModal.show("modal-print-order");
      this.closeTab(this.currentTab);
      this.getUserProfile();
      this.$router.push({
        name: "update-order-online",
        query: { id: id },
      });
    },
    async onCancelOrder(payload) {
      const { id, ...data } = payload;
      await this.confirmOrderOnline({ id: id, data: data });
      this.closeTab(this.currentTab);
      this.getUserProfile();
      this.$router.push({
        name: "update-order-online",
        query: { id: id },
      });
    },
    async onSubmitOrderOnline(payload) {
      const { id, ...data } = payload;
      await this.confirmOrderOnline({ id: id, data: data });
      this.$bvModal.show("modal-print-order");
      this.closeTab(this.currentTab);
      this.getUserProfile();
    },
    onChangeSelectProduct(product, quantity) {
      const isAddUpdate = this.isUpdateOrder;
      this.handleAddProductInTab(product, isAddUpdate, quantity);
      this.isFocusInputQuantity = !this.isFocusInputQuantity;
    },
    searchingSelect({ name }) {
      return name;
    },
    removeProductInTab() {
      this.onRemoveProductInTab(this.idRemove);
      this.SET_DISABLED_CHANGE_PRICE(true);
    },
    cancelModalRemove() {},
    openModalRemove(item) {
      const { id, name } = item;
      this.idRemove = id;
      this.nameRemove = name;
      this.$bvModal.show("model-remove");
    },
    onAddProductInTab(product) {
      const isAddUpdate = this.isUpdateOrder;
      this.handleAddProductInTab(product, isAddUpdate);
      this.SET_DISABLED_CHANGE_PRICE(true);
    },
    initialLocalOrder() {
      // initial when tabList local empty
      this.onTabList({ title: "HĐ 1", name: 1 });
      this.onCurrentTab(1);
      this.onTabListContent({ key: 1, value: [] });
    },
    onIncreAndDecre({ product, quantity }) {
      if (quantity <= 0) {
        this.openModalRemove({ id: product.id, name: product.name });
        return;
      }
      this.onAddQuantity({ product, quantity });
      this.handlePrice();
      this.SET_DISABLED_CHANGE_PRICE(true);
    },
    onClosePayment() {
      this.isOrderPayment = false;
    },
    closeTab(index) {
      this.onRemoveTabList(index);
      this.setCurrentTab();
      this.isCloseTab = !this.isCloseTab;
    },
    newTab() {
      const lastOrderIndex = this.tabList[this.tabList.length - 1]?.name;
      const index = lastOrderIndex ? lastOrderIndex + 1 : 1;
      this.onTabList({
        title: `HĐ${index}`,
        name: index,
      });
      this.onTabListContent({ key: index, value: [] });
      // this.tabs.push(this.tabCounter++);
      this.isNewTab = !this.isNewTab;
    },
    setCurrentTab() {
      const isExactlyCurrentTab = this.tabList[this.isTabCurrent]?.name;
      this.onCurrentTab(isExactlyCurrentTab);
    },
    search: _.debounce(function(query) {
      let params = {
        search: query,
        active: true,
      };
      if (this.selectWarehouse) {
        const idWarehouse = this.selectWarehouse?.id;
        params = {
          search: query,
          page_size: this.pageSize,
        };
      }
      if (this.selectCategory) {
        const { id } = this.selectCategory;
        params = {
          name: query,
          page_size: this.pageSize,
        };
      }
      this.onListProduct(params);
    }, 250),
    searchCategory: _.debounce(function(query) {
      this.getCategoryList({ name: query });
    }, 250),
    onTabChanged() {
      this.isTabCurrent = this.tabList.length - 1;
    },
    toggleFullscreen() {
      this.$store.dispatch("menu/openSidebar");
      if (
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)
      ) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    changeWarehouse() {
      this.SET_DISABLED_CHANGE_PRICE(true);
    },
  },
  watch: {
    isConfirmOrderOnline() {
      if (this.isConfirmOrderOnline) {
        this.checkRoleGetOrder();
      }
    },
    isUpdateOrderOnline() {
      if (this.isUpdateOrderOnline && this.checkRoles("cskh")) {
        this.selectOrder = null;
        this.checkRoleGetOrder();
      }
    },
    isUpdatePaymentNotShipSuccess() {
      if (this.isUpdatePaymentNotShipSuccess) {
        // this.closeTab(this.currentTab);
        this.$bvModal.show("modal-print-order");
        // this.getUserProfile();
        // this.$router.push({
        //   name: "order",
        // });
      }
    },
    comeToPickOrder() {
      const { comeToPickOrder } = this;
      if (comeToPickOrder) {
        if (!this.$route.query.id) {
          this.newTab();
        }
        setTimeout(() => {
          this.$router
            .push({
              name: "order",
              query: { id: comeToPickOrder.id },
            })
            .catch(() => {});
          // this.selectOrder = null;
          this.isSelectOrderToUpdate = true;
        }, 300);
      } else {
        this.$router
          .push({
            name: "order",
          })
          .catch(() => {});
        this.selectWarehouse = this.userProfile?.store;
        this.closeTab(this.currentTab);
        if (this.selectOrder) {
          this.isSelectOrderToUpdate = true;
        } else {
          this.isSelectOrderToUpdate = false;
        }
      }
    },

    selectOrder() {
      const { selectOrder } = this;
      if (selectOrder) {
        if (!this.$route.query.id) {
          this.selectOrder = null;
          this.newTab();
        }
      } else {
        this.$router
          .push({
            name: "order",
          })
          .catch(() => {});
        this.selectWarehouse = this.userProfile?.store;
        this.closeTab(this.currentTab);
        if (this.comeToPickOrder) {
          this.isSelectOrderToUpdate = true;
        } else {
          this.isSelectOrderToUpdate = false;
        }
      }
    },

    isOrderSuccess() {
      if (this.isOrderSuccess) {
        this.checkRoleGetOrder();
        this.selectWarehouse = this.userProfile?.store;
      }
    },
    isOrderSubmit() {
      if (this.isOrderSubmit) {
        this.openModalPrint();
      }
    },

    productImport() {
      if (this.productImport) {
        if (this.selectOrder || this.comeToPickOrder) {
          this.$refs.quantityFocus.focus();
        } else {
          this.$refs.quantityFocus[this.isTabCurrent].focus();
        }
      }
    },
    isUpdateOrder() {},
    "$route.query.id": {
      handler() {
        const routeId = this.$route.query.id;
        this.detechParamsInRoute();
        if (!routeId) {
          this.selectOrder = null;
        }
      },
      deep: true,
    },
    userProfile() {
      if (this.userProfile) {
        this.checkRoleGetOrder();
        if (this.checkRoles("cskh")) {
          this.onUpdateOrderLocal(true);
        }
      }
      this.selectWarehouse = this.userProfile.store;
    },
    orderDetail() {
      if (this.orderDetail) {
        // status_order: this.newOrderId.id
        if (
          !this.orderDetail.customer_come_to_pick_up ||
          this.orderDetail.status_order.id === this.newOrderId.id
        ) {
          this.selectOrder = this.orderDetail;
        } else {
          this.comeToPickOrder = this.orderDetail;
        }
      }
      if (!this.isUpdateOrder) {
        // this.openModalPrint();
      } else {
        const fillOrder = this.orderDetail.products.map((item) => {
          return {
            product: {
              name: item.product_name,
              store_price_sale: item.sale_price,
              store_price: item.price,
              id: item.product,
            },
            quantity: item.quantity,
            note: item?.note,
          };
        });
        this.handleAddProductInTab(fillOrder);
        this.handlePrice();
        this.selectWarehouse = this.userProfile.store;
        if (this.selectWarehouse) {
          this.SET_DISABLED_CHANGE_PRICE(false);
        }
      }
    },
    isTabCurrent() {
      this.selectCategory = null;
      this.setCurrentTab();
    },
  },
};
</script>

<style>
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
.container-order .form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff !important;
}
.container-info::-webkit-scrollbar {
  width: 0px;
  background: transparent !important; /* make scrollbar transparent */
}
.container-order {
  width: 70% !important;
}
.container-info {
  width: 30%;
  background: white;
}
.rbt-card-tab-list .card-header {
  padding: 20px 10px !important;
}
.rbt-card-tab-list .card-header-tabs {
  border-bottom: 1px solid #dee2e6;
}
.rbt-card-tab-list .nav-tabs .nav-link,
.nav-tabs .nav-item.show .nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
}
.rbt-card-tab-list .nav-tabs .rbt-end-tab .nav-link,
.nav-tabs .nav-item.show .rbt-end-tab .nav-link {
  border-bottom: none;
}
</style>

<style scoped>
.rbt-custom-input {
  white-space: nowrap;
  height: 43px !important;
}
.skin-7 .num-in {
  float: left;
  width: 170px;
  border: 1px solid #a4a4a3;
  border-radius: 5px;
}

.skin-7 input.in-num {
  font-family: "HelveticaNeueCyr-Roman";
  font-size: 18px;
  color: #33830e;
  font-weight: bold;
  float: left;
  height: 32px;
  width: 83px;
  border-left: 1px solid #a4a4a3;
  border-right: 1px solid #a4a4a3;
  background-color: #fff;
  text-align: center;
}

.skin-7 .num-in span {
  font-size: 24px;
  text-align: center;
  display: block;
  width: 46px;
  float: left;
  height: 32px;
  background-color: #f5f7fa;
  color: #606266;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.skin-7 .num-in span:hover {
  background-color: #d7d7d8;
}

.skin-7 .num-in input {
  border: none;
  float: left;
  width: 76px;
  line-height: 34px;
  text-align: center;
  font-family: "helveticaneuecyrbold";
}
.group-store-price {
  min-width: 100px;
}
</style>

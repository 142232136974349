<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Danh sách sản phẩm" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <h5>{{ totalRecord }} kết quả</h5>
              <div>
                <b-form-file v-model="fileExcel" plain></b-form-file>
              </div>
            </div>
						<div class="mt-3 px-4">
								<div>
									<p v-if="fileExcel">
										<b-icon-file-text></b-icon-file-text>&nbsp;{{ fileExcel.name }}
									</p>
									<p v-if="processImportProduct.error" style="color: red">
										<b>Import file lỗi:</b>
										<br>
										{{ processImportProduct.error }}
									</p>
								</div>
	              <div v-if="processImportProduct.display">
									<p v-if="processImportProduct.success > 0" style="color: #00a8ff">
										<b>Import thành công. Nhấn phím F5 để thấy dữ liệu mới nhất.</b>
	                </p>
	                <p>
	                  Tải lên thành công:
	                  <span style="color: #00a8ff">{{
	                  	processImportProduct.success
	                  }}</span> sản phẩm
	                </p>
	                <p>
	                  Tải lên không thành công:
	                  <span style="color: red">
	                    {{ processImportProduct.fail }}</span> sản phẩm
	                </p>
	                <p v-if="processImportProduct.line_errors">
	                  Các dòng có lỗi:
	                  <span style="color: #FFB319">
	                    {{ processImportProduct.line_errors }}
	                  </span>
	                </p>
	              </div>
	            </div>
            <div class="card-body">
							<div class="row mb-4">
								<div class="col-sm-6 d-flex align-items-center mb-2">
								<b-form-select v-model="store" :disabled="storeID !== undefined" @change="onSearch()">
										<option
											v-for="store in storeList"
											:key="store.index"
											:value="store.id"
											>{{ store.name }}</option
										>
									</b-form-select>
								</div>
							</div>
              <div class="row">
                <b-form-group class="datatable-select col-md-2">
                  <b-form-input type="text" v-model="search" placeholder="Tìm kiếm" @change="onSearch()"></b-form-input>
                </b-form-group>
                <b-form-select v-model="selectCategory" @change="onSearch()" class="col-md-2 mr-2">
                  <option value="">Danh mục</option>
                  <option v-for="category in categoryList" :key="category.index" :value="category.id">{{ category.name }}
                  </option>
                </b-form-select>
                <b-form-select v-model="supplier" @change="onSearch()" class="col-md-2 mr-2">
                  <option value="">Nhà cung cấp</option>
                  <option v-for="supplier in supplierList" :key="supplier.index" :value="supplier.id">{{ supplier.name }}
                  </option>
                </b-form-select>
								<b-form-select v-show="storeID == undefined" v-model="is_active" @change="onSearch()" class="col-md-2 mr-2">
									<option value="">Trạng thái</option>
									<option value="true">Đang kinh doanh</option>
									<option value="false">Ngừng kinh doanh</option>
								</b-form-select>
                <div class="col-md-0.5 mr-2">
                  <b-button class="btn-popup" variant="primary" @click="onSearch()">Tìm</b-button>
                </div>
								<div class="col-md-2.5">
										<div class="d-flex" style="gap: 1rem;">
											<router-link to="/product/product-add" v-if="!storeID">
											<b-button class="btn-popup">Thêm mới</b-button></router-link>
											<b-button v-b-modal.import-modal class="btn-popup" variant="primary">Nhập file</b-button>
										</div>
								</div>
								<b-modal id="import-modal">
									<p>
										Nhập hàng hoá từ file dữ liệu(Tải về file mẫu: <a :href="`${exampleProductExcel}`">Excel file</a>)
									</p>
									<template #modal-footer>
										<b-button size="sm" variant="success" @click="uploadImport()">
											 Chọn file dữ liệu
										</b-button>
									</template>
								</b-modal>
              </div>
              <div class="table-responsive datatable-vue product-list">
								<b-modal id="modal-delete" title="Xác nhận" @ok="onDelete(isId)">
									<p class="my-4">
										Xóa <b>{{ isName }}</b>
									</p>
								</b-modal>
                <b-table class="text-center" striped hover head-variant="light" bordered stacked="md"
                  :items="isListProduct" :fields="computedFields" :filter="filter">
                  <template #cell(image)="{ item }">
                    <img v-if="item.image" height="60px" :src="item.image" width="60px" />
                    <img v-if="!item.image" height="60px" src="../../assets/images/image_default.png" width="60px" />
                  </template>
									<template v-slot:cell(code)="{ item }">
										<span v-if="item.code">
											{{ item.code }}
										</span>
									</template>
                  <template v-slot:cell(name)="{ item }">
                    <span v-if="Number(item.product_inventory_quantity) <
                      Number(item.min_inventory)
                      " v-b-tooltip.hover title="Số lượng đã đạt đến mức tồn kho tối thiểu" class="text-warning">
                      {{ item.name }}
                    </span>
                    <span v-else>{{ item.name }}</span>
                  </template>
                  <template v-slot:cell(price)="{ value }">
                    <span>
                      {{ value | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(expiry)="{ item }">
                    <span v-if="item.expiry">
                      {{ item.expiry  | dateFormat }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </template>
                  <template v-slot:cell(category)="{ item }">
                    <span v-if="item.category">
                      {{ item.category.name }}
                    </span>
                  </template>
                  <template v-slot:cell(brand)="{ item }">
                    <span v-if="item.brand">
                      {{ item.brand.name }}
                    </span>
                  </template>
                  <template v-slot:cell(active)="{ item }">
                    <div v-for="salePrice in item.price_detail.store_price" :key="salePrice.index">
                      <div style="width: 120px" v-if="salePrice.store_id === store">
                        <input type="checkbox" id="flexCheckChecked2" v-if="!storeID"
                          @change="onChangeStatus($event, item)" v-model="salePrice.active" />
                        <span>{{
                          salePrice.active
														? "Kinh doanh"
														: "Ngừng kinh doanh"
                        }}</span>
                      </div>
                    </div>
                    <div v-if="!checkActiveProduct(item.price_detail.store_price)">
                      <span>Chưa có bảng giá cho cửa hàng này</span>
                    </div>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather style="cursor: pointer" type="edit-2" stroke="#3758FD" stroke-width="1" size="18px"
                        fill="#3758FD" stroke-linejoin="round" @click="onEdit(item)"></feather>
                    </span>
										<span style="font-size: 20px; color: #FA399F;">
											<feather
												v-b-modal.modal-delete
												style="cursor: pointer"
												type="trash"
												stroke="#F72E9F"
												size="18px"
												fill="#F72E9F"
												@click="setItem(item)"
											></feather>
										</span>
                  </template>
                </b-table>
                <div class="text-center" v-if="!isLoading && isListProduct.length === 0">
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination v-if="totalRecord > 0" v-model="pageNumber" :total-rows="totalRecord" :per-page="pageSize"
                  aria-controls="my-table" class="mt-4" @change="handlePageChange"></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import Loader from "../../components/loading/loader.vue";
import XLSX from "xlsx";

const keyExcelToJson = {
  "Nhóm hàng": "category",
  "Mã hàng": "code",
  "Tên hàng": "name",
  "Thương hiệu": "brand",
  "Giá bán": "price",
  ĐVT: "unit",
  "Tồn lớn nhất": "preservation",
};

export default {
  data() {
    return {
      tablefields: [
        { key: "image", label: "Ảnh" },
				{ key: "code", label: "Mã sản phẩm" },
        { key: "name", label: "Tên sản phẩm" },
        {
          key: "price", label: "Giá bán", formatter: (_, key, item) => {
						if (item?.price_detail?.store_price) {
							if (this.store == '') {
								this.store = this.store = this.storeID ? this.storeID : this.storeList[0].id;
							} 
              const store = item?.price_detail?.store_price.find(store => {
								return store.store_id === this.store
							})
              if (store) {
                const { price, sale_price } = store
								return price > sale_price ? sale_price : price
              }
            }
            return 0
          }
        },
        {
          key: "product_inventory_quantity", label: "Tồn kho", formatter: (_, key, item) => {
            const warehouseItem = item.inventories.find(item => item.warehouse === this.idWarehouse)
            return warehouseItem?.quantity ?? '-'
          }
        },
        { key: "unit", label: "Đơn vị bán" },
        { key: "expiry", label: "Hạn sử dụng" },
        { key: "category", label: "Danh mục" },
        { key: "brand", label: "Thương hiệu" },
        { key: "active", label: "Trạng thái" },
        { key: "actions", label: "Hành động", require: true },
      ],
      filter: null,
      isId: "",
      isName: "",
      search: "",
      pageNumber: 1,
      selectCategory: "",
      supplier: "",
			store: "",
			is_active: true,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      fileExcel: null,
      excelData: {
        header: null,
        results: null,
      },
      listProductImport: null,
    };
  },
  components: {
    Loader,
  },
  created() {
    this.pageNumber = this.$route.query.page || 1;
    this.selectCategory = this.$route.query.category || "";
    this.search = this.$route.query.search || "";
    this.supplier = this.$route.query.suppliers || "";
    this.active = this.$route.query.active || "";
		if (this.$route.query.store) {
			this.store = Number(this.$route.query.store);
		} else {
			if (this.storeList.length > 0) {
				this.store = this.storeID ? this.storeID : this.storeList[0].id;
			}
		}
		this.is_active = this.$route.query.is_active || "";

    let params = {
      page: this.pageNumber,
      page_size: this.pageSize,
    };
    if (this.$route?.query?.category) {
      params = {
        category: this.selectCategory,
        ...params,
      };
    }
    if (this.$route?.query?.search) {
      params = {
        search: this.search,
        ...params,
      };
    }
    if (this.$route?.query?.suppliers) {
      params = {
        suppliers: this.supplier,
        ...params,
      };
    }
    if (this.$route?.query?.active) {
      params = {
        active: this.active,
        ...params,
      };
    }
		if (this.$route?.query?.store || this.storeID) {
			params = {
				store: this.store,
				...params,
			};
			if (this.storeID) {
				params = {
					store: this.storeID,
					is_active: true,
					...params,
				};
			}
		}
		if (this.$route?.query?.is_active) {
			params = {
				is_active: this.is_active,
				...params,
			};
		}
    this.onListProduct(params);
    this.getCategoryList();
    this.getSupplierList();
    this.getStoreList()
  },
  computed: {
    ...mapState("category", ["categoryList"]),
    ...mapState("supplier", ["supplierList"]),
    ...mapState("product", [
      "isListProduct",
      "totalRecord",
      "isLoading",
      "processImportProduct",
    ]),
    ...mapState("profile", ["userProfile"]),
    ...mapState("store", ["storeList"]),
		...mapGetters({
			exampleProductExcel: "product/getExampleProductExcel"
		}),
    storeID() {
      return this.userProfile?.store?.id;
    },
    computedFields() {
      if (this.storeID) {
        return this.tablefields.filter((field) => !field.require);
      }
      return this.tablefields;
    },
    idWarehouse() {
      const result = this.storeList.find((item) => item.id === this.storeID);
      return result?.warehouse;
    },
  },
  watch: {
    fileExcel() {
      this.handleClick();
    },
  },
	mounted() {
		// auto select first store
		if (this.storeList.length > 0) {
			this.store = this.storeID ? this.storeID : this.storeList[0].id;
		}
	},
  methods: {
    ...mapActions("product", [
      "onListProduct",
      "updateStatusProduct",
      "removeProduct",
			"importListProduct",
			"onNotExcelExtension",
			"clearImportStatus"
    ]),
    ...mapActions("category", ["getCategoryList"]),
    ...mapActions("supplier", ["getSupplierList"]),
    ...mapActions("store", ["getStoreList"]),
    replaceKeyExcelToData(tab) {
      // const tab = { abc: 1, def: 40, xyz: 50 };
      // const replacements = { abc: "a_b_c", def: "d_e_f" };
      let replacedItems = Object.keys(tab).map((key) => {
        const newKey = keyExcelToJson[key] || key;
        return { [newKey]: tab[key] };
      });
      const newTab = replacedItems.reduce((a, b) => Object.assign({}, a, b));
      return newTab;
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]);
      let C;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        /* find the cell in the first row */
        let hdr = "UNKNOWN " + C; // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },
    beforeUpload(file) {
      var extension = file.name.split(".").pop();
      if (["xls", "xlsx"].indexOf(extension) < 0) {
        this.onNotExcelExtension();
        return false;
      }
			this.clearImportStatus();
      return true;
    },
    upload(rawFile) {
      if (!this.beforeUpload(rawFile)) {
        // this.readerData(rawFile);
        return;
      }
    },
    handleClick() {
      let files = this.fileExcel;
      const rawFile = files;
      if (!rawFile) return;
      this.upload(rawFile);
    },
    setItem(item) {
      this.isId = item.id;
      this.isName = item.name;
    },
    onEdit(item) {
      this.$router.push({
        name: "product-add",
        query: { id: item.id },
      });
    },
    onSearch() {
      let params = {
        page: 1,
        page_size: this.pageSize,
      };
      if (this.search) {
        params = {
          search: this.search.trim(),
          ...params,
        };
      }
      if (this.selectCategory) {
        params = {
          category: this.selectCategory,
          ...params,
        };
      }
      if (this.supplier) {
        params = {
          suppliers: this.supplier,
          ...params,
        };
      }
			if (this.store || this.storeID) {
				params = {
					store: this.store,
					...params,
				};
				if (this.storeID) {
					params = {
						store: this.storeID,
						is_active: true,
						...params,
					};
				}
			}
			if (this.is_active) {
				params = {
					is_active: this.is_active,
					...params,
				};
			}
      this.onListProduct(params);
      this.pageNumber = 1;

      const path = `/product/product-list?page=1&search=${this.search}
                    &category=${this.selectCategory}
                    &suppliers=${this.supplier}&store=${this.store}&is_active=${this.is_active}`;

      if (this.$router.currentRoute.fullPath !== path) {
        this.$router.push({
          path: "/product/product-list",
          query: {
            page: this.pageNumber,
            search: this.search,
            category: this.selectCategory,
            suppliers: this.supplier,
						store: this.store,
						is_active: this.is_active,
          },
        });
      }
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.search) {
        params = {
          search: this.search.trim(),
          ...params,
        };
      }
      if (this.selectCategory) {
        params = {
          category: this.selectCategory,
          ...params,
        };
      }
      if (this.supplier) {
        params = {
          suppliers: this.supplier,
          ...params,
        };
      }
			if (this.store || this.storeID) {
				params = {
					store: this.store,
					...params,
				};
				if (this.storeID) {
					params = {
						store: this.storeID,
						is_active: true,
						...params,
					};
				}
			}
			if (this.is_active) {
				params = {
					is_active: this.is_active,
					...params,
				};
			}
      this.onListProduct(params);
      this.$router.push({
        path: "/product/product-list",
        query: {
          page: this.pageNumber,
          search: this.search,
          category: this.selectCategory,
          suppliers: this.supplier,
					store: this.store,
					is_active: this.is_active,
        },
      });
    },
    onChangeStatus(event, product) {
      const value = event.target.checked;
      const { id, price } = product;
      const dataSubmit = {
        active: value,
        product: id,
        price: (() => {
          if (product?.price_detail?.store_price) {
            const _store = product?.price_detail?.store_price.find(s => s.store_id === this.store)
						if (_store) {
							return _store.price;
						}
          }
          return 0
        })(),
        store: this.store,
      };
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.search) {
        params = {
          search: this.search.trim(),
          ...params,
        };
      }
      if (this.selectCategory) {
        params = {
          category: this.selectCategory,
          ...params,
        };
      }
      if (this.supplier) {
        params = {
          suppliers: this.supplier,
          ...params,
        };
      }
			if (this.store) {
				params = {
					store: this.store,
					...params,
				};
			}
			if (this.is_active) {
				params = {
					is_active: this.is_active,
					...params,
				};
			}
      this.updateStatusProduct({ data: dataSubmit, params });
    },
    checkActiveProduct(storePrice) {
      const foundProduct = storePrice.find(
        (product) => product.store_id === this.store
      );
      return foundProduct;
    },
		uploadImport() {
			if (this.fileExcel) {
				const formData = new FormData();
				formData.append("file", this.fileExcel);
				this.importListProduct(formData);
				this.$bvModal.hide('import-modal')
			}
		},
		onDelete(id) {
			const params = { page: this.pageNumber, page_size: this.pageSize };
			this.removeProduct({ id: id, params });
			this.search = "";
		},
  },
};
</script>

<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Danh sách sản phẩm trong kho" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>{{ totalRecord }} kết quả</h5>
            </div>
            <div class="card-body">
              <div class="row col-md-12">
                <b-form-group class="datatable-select col-md-5">
                  <b-form-input
                    type="text"
                    v-model="search"
                    placeholder="Tìm kiếm"
                    @change="onSearch"
                  ></b-form-input>
                </b-form-group>
                <div class="col-md-5">
                  <b-button
                    class="btn-popup"
                    variant="primary"
                    @click="onSearch"
                    >Tìm</b-button
                  >
                </div>
              </div>
              <div class="table-responsive datatable-vue product-list">
                <b-table
                  v-if="!isLoading && isListProduct.length > 0"
                  class="text-center"
                  show-empty
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="isListProduct"
                  :fields="tablefields"
                >
                  <template #cell(image)="field">
                    <img
                      v-if="field.image"
                      height="60px"
                      :src="item.image"
                      width="60px"
                    />
                    <img
                      v-if="!field.image"
                      height="60px"
                      src="../../assets/images/image_default.png"
                      width="60px"
                    />
                  </template>
                  <template v-slot:cell(product)="{ item }">
                    <!-- <span
                      v-if="
                        Number(item.quantity) <
                          Number(item.product.min_inventory)
                      "
                      v-b-tooltip.hover
                      title="Số lượng đã đạt đến mức tồn kho tối thiểu"
                      class="text-warning"
                    >
                      {{ item.product.name }}
                    </span> -->
                    <span>
                      {{ item.name }}
                    </span>
                  </template>
                  <template v-slot:cell(price)="{ item }">
                    {{ item.price | formatCurrency }}
                  </template>
                  <template v-slot:cell(quantity)="{ item }">
                    <div
                      v-for="inventory in item.inventories"
                      :key="inventory.index"
                    >
                      <div v-if="inventory.warehouse === idWarehouse">
                        <span>
                          {{ inventory.quantity }}
                        </span>
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(unit)="{ item }">
                    <span> {{ item.unit }} </span>
                  </template>
                  <template v-slot:cell(category)="{ item }">
                    <span v-if="item.category">
                      {{ item.category.name }}
                    </span>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="!isLoading && isListProduct.length === 0"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRecord > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecord"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";

export default {
  data() {
    return {
      tablefields: [
        { key: "image", label: "Hình ảnh" },
        { key: "product", label: "Tên sản phẩm" },
        { key: "price", label: "Giá bán" },
        { key: "quantity", label: "Số lượng" },
        { key: "unit", label: "Đơn vị" },
        { key: "category", label: "Danh mục" },
      ],
      search: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
    };
  },
  components: {
    Loader,
  },
  created() {
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = { page: pageNumber, page_size: this.pageSize };
    this.onListProduct(params);
    this.getWarehouseList();
    this.getStoreList();
  },
  computed: {
    ...mapState("product", ["isListProduct", "totalRecord", "isLoading"]),
    ...mapState("warehouse", ["warehouseList"]),
    ...mapState("profile", ["userProfile"]),
    ...mapState("store", ["storeList"]),
    idWarehouse() {
      const currentStoreID = this.userProfile.store.id;
      const result = this.storeList.find((item) => item.id === currentStoreID);
      return result?.warehouse;
    },
  },
  watch: {},
  methods: {
    ...mapActions("product", ["onListProduct"]),
    ...mapActions("store", ["getStoreList"]),
    ...mapActions("warehouse", ["getWarehouseList"]),
    onSearch() {
      let params = {
        page: 1,
        page_size: this.pageSize,
      };
      if (this.search) {
        params = {
          product_name: this.search.trim(),
          ...params,
        };
      }
      this.onListProduct(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/inventory/inventory-list",
          query: { page: this.pageNumber },
        });
      }
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.search) {
        params = { product_name: this.search.trim(), ...params };
      }
      this.$router.push({
        path: "/inventory/inventory-list",
        query: { page: this.pageNumber },
      });
      this.onListProduct(params);
    },
  },
};
</script>

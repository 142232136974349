<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs
          main="Thiết lập chung"
          title="Thiết lập tỉ suất lợi nhuận"
        />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between"></div>
            <div class="card-body">
              <div class="row">
                <b-form-select v-model="selectCategory" class="col-md-3 ml-3">
                  <option value="">Nhóm hàng</option>
                  <option
                    v-for="category in categoryList"
                    :key="category.index"
                    :value="category.id"
                    >{{ category.name }}</option
                  >
                </b-form-select>
                <div class="col-md-1 mr-2">
                  <b-button
                    class="btn-popup"
                    variant="primary"
                    @click="onSearch()"
                    >Lọc</b-button
                  >
                </div>
              </div>
              <div class="table-responsive datatable-vue standard-list">
                <b-table
                  class="text-center"
                  :select-mode="selectMode"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  :items="productTable"
                  :fields="tablefields"
                  ref="selectableTable"
                  :selectable="true"
                  @row-selected="rowSelected"
                >
                  <template
                    v-slot:cell(margin_percent)="{
                      value,
                      item,
                      field: { key },
                    }"
                  >
                    <span v-if="edit != item.id"
                      >{{ value }}<span v-if="value">%</span></span
                    >
                    <template v-else>
                      <b-form-input
                        style="width: 120px"
                        type="number"
                        autofocus
                        v-model="item[key]"
                        @blur="saveRow(item)"
                        @keyup.enter="handleEnter(item)"
                      />
                    </template>
                  </template>
                  <template v-slot:cell(category)="{ item }">
                    <span v-if="item.category">
                      {{ item.category.name }}
                    </span>
                  </template>
                </b-table>
                <div class="text-center mt-4" v-if="productTable.length === 0">
                  Không có sản phẩm thuộc nhóm hàng này
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRecord > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecord"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import { extend } from "vee-validate";

extend("percentGreaterZero", (value) => {
  if (value >= 0 && value <= 100) return true;
  return "Tỷ lệ phải lớn hơn 0 và không vượt quá 100";
});

export default {
  data() {
    return {
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "code", label: "MÃ SẢN PHẨM" },
        { key: "name", label: "TÊN SẢN PHẨM" },
        {
          key: "margin_percent",
          label: "TỶ SUẤT LỢI NHUẬN",
        },
        { key: "category", label: "NHÓM HÀNG" },
      ],
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      selectCategory: "",
      edit: null,
      selectMode: "single",
      handlingEnter: false,
    };
  },
  components: {
    Loader,
  },

  created() {
    this.pageNumber = this.$route.query.page || 1;
    this.selectCategory = this.$route.query.category || "";
    const params = {
      page: this.pageNumber,
      page_size: this.pageSize,
      ...(this.selectCategory && { category_id: this.selectCategory }),
    };
    this.onListProduct(params);
    this.getCategoryList();
  },

  computed: {
    ...mapState("category", ["categoryList"]),
    ...mapState("product", ["isListProduct", "totalRecord"]),
    productTable() {
      return this.isListProduct.map((item, index) => {
        return {
          sno: this.pageNumber * this.pageSize - this.pageSize + index + 1,
          ...item,
        };
      });
    },
  },

  methods: {
    ...mapActions("product", ["onListProduct", "onUpdateProduct"]),
    ...mapActions("category", ["getCategoryList"]),

    onSearch() {
      let params = {
        page: 1,
        page_size: this.pageSize,
        ...(this.selectCategory && { category_id: this.selectCategory }),
      };
      this.onListProduct(params);
      this.pageNumber = 1;
      this.$router.push({
        path: "/settings/default-price",
        query: { page: this.pageNumber, category: this.selectCategory },
      });
    },

    handlePageChange(value) {
      this.pageNumber = value;
      const params = {
        page: this.pageNumber,
        page_size: this.pageSize,
        ...(this.selectCategory && { category_id: this.selectCategory }),
      };
      this.onListProduct(params);
      this.$router.push({
        path: "/settings/default-price",
        query: { page: this.pageNumber, category: this.selectCategory },
      });
    },

    rowSelected(item) {
      if (item[0]) {
        this.edit = this.edit !== item[0].id ? item[0].id : null;
      }
    },

    updateProduct(product) {
      const {
        image,
        category,
        brand,
        suppliers,
        list_images,
        quantitations,
        margin_percent,
        ...data
      } = product;
      const supplierListId = suppliers.map((item) => {
        return item.id;
      });
      const dataSubmit = {
        ...data,
        ...(category && { category: category.id }),
        ...(brand && { brand: brand.id }),
        suppliers: supplierListId,
        // hard code to pass save
        quantitations: [],
        margin_percent: Number(margin_percent),
      };
      const params = {
        page: this.pageNumber,
        page_size: this.pageSize,
        ...(this.selectCategory && { category_id: this.selectCategory }),
      };
      this.onUpdateProduct({ data: dataSubmit, params });
    },

    saveRow(item) {
      if (!this.handlingEnter) {
        this.updateProduct(item);
      }
    },
    handleEnter(item) {
      const { id } = item;
      this.handlingEnter = true;
      this.updateProduct(item);
      const currentIndex = this.isListProduct.findIndex((i) => i.id === id);
      if (currentIndex < this.isListProduct.length - 1) {
        const nextIndex = currentIndex + 1;
        this.$refs.selectableTable.selectRow(nextIndex);
      } else {
        this.edit = null;
      }
    },
  },
};
</script>

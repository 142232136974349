<template>
  <div>
    <loader :is-visible="isLoadingQuotation"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs
          main="Quản lý nhà cung cấp"
          title="Danh sách báo giá NCC"
        />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>{{ totalRecordQuotation }} kết quả</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <b-form-group class="datatable-select col-md-3">
                  <b-form-input
                    type="text"
                    v-model="search"
                    placeholder="Tìm kiếm"
                    @change="onSearch()"
                  ></b-form-input>
                </b-form-group>
                <b-form-select v-model="supplier" class="col-md-3 mr-3">
                  <option value="">Lọc theo tên nhà cung cấp</option>
                  <option
                    v-for="supplier in supplierList"
                    :key="supplier.index"
                    :value="supplier.id"
                    >{{ supplier.name }}</option
                  >
                </b-form-select>
                <b-form-select v-model="status" class="col-md-2">
                  <option value="">Trạng thái</option>
                  <option
                    v-for="(item, i) in statusMock"
                    :key="i"
                    :value="item.status"
                  >
                    {{ QUOTATION_STATUS_SUPPLIER[item.status] }}</option
                  >
                </b-form-select>
                <div class="ml-3">
                  <b-button
                    class="btn-popup mb-0"
                    variant="primary"
                    @click="onSearch()"
                    >Tìm</b-button
                  >
                </div>
              </div>
              <div class="table-responsive datatable-vue standard-list">
                <b-table
                  v-if="!isLoadingQuotation && quotationsList.length > 0"
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  :items="quotationsList"
                  :fields="tablefields"
                >
                  <template #thead-top>
                    <b-tr>
                      <b-th style="width: 150px">Thời gian tạo</b-th>
                      <b-th>Nhà cung cấp</b-th>
                      <b-th>Tên báo giá</b-th>
                      <b-th style="width: 100px">Trạng thái</b-th>
                      <b-th>Hành động</b-th>
                    </b-tr>
                  </template>
                  <template v-slot:cell(supplier)="{ item }">
                    <span v-if="item.supplier">
                      {{ item.supplier.name }}
                    </span>
                  </template>
                  <template v-slot:cell(user)="{ item }">
                    <span v-if="item.user">
                      {{ item.user.name }}
                    </span>
                  </template>
                  <template v-slot:cell(created_at)="{ item }">
                    <div class="number-and-date-table" v-if="item.created_at">
                      {{ item.created_at | showDateOnly }}
                    </div>
                  </template>
                  <template v-slot:cell(end_date)="{ item }">
                    <div class="number-and-date-table" v-if="item.end_date">
                      {{ item.end_date | showDateOnly }}
                    </div>
                  </template>
                  <template v-slot:cell(status)="{ item }">
                    <div class="d-flex justify-content-center text-center">
                      <b-badge
                        style="margin-right: 6px; height: 18px"
                        :variant="colorStatus[item.status]"
                      >
                        {{ QUOTATION_STATUS_SUPPLIER[item.status] }}
                      </b-badge>
                    </div>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="handleQuotation(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div
                  v-if="!isLoadingQuotation && quotationsList.length === 0"
                  class="text-center"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRecordQuotation > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecordQuotation"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
              <QuotationsModal
                :quotationsInfo="quotationsInfo"
                @onUpdateQuotations="onUpdateQuotations"
                @onCreateQuotations="onCreateQuotations"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import QuotationsModal from "../../components/modals/QuotationsModal.vue";
import { QUOTATION_STATUS, StatusMock } from "../../config/quotations/index";

export default {
  components: {
    QuotationsModal,
    Loader,
  },
  data() {
    return {
      tablefields: [
        { key: "created_at", label: "" },
        { key: "supplier_name", label: "" },
        { key: "name", label: "" },
        { key: "status", label: "" },
        { key: "actions", label: "" },
      ],
      supplier: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      quotationsInfo: {},
      search: "",
      status: "",
    };
  },
  async created() {
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = { page: pageNumber, page_size: this.pageSize };
    await this.onQuotationsList(params);
    await this.getSupplierList();
  },
  computed: {
    ...mapState("supplier", [
      "supplierList",
      "quotationsList",
      "isLoadingQuotation",
      "totalRecordQuotation",
    ]),
    APPLY_PRICE() {
      return QUOTATION_STATUS.APPLY_PRICE;
    },
    colorStatus() {
      return QUOTATION_STATUS.APPEND_COLOR;
    },
    QUOTATION_STATUS_SUPPLIER() {
      return QUOTATION_STATUS.SUPPLIER;
    },
    QUOTATION_STATUS_SUPPLIER() {
      return QUOTATION_STATUS.SUPPLIER;
    },
    statusMock() {
      return StatusMock;
    },
  },
  watch: {},
  methods: {
    ...mapActions("supplier", [
      "onQuotationsList",
      "getQuotationDetail",
      "onQuotationsUpdate",
      "onQuotations",
      "getSupplierList",
    ]),
    onCreateQuotations(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onQuotations({ data: payload, params });
    },
    onUpdateQuotations(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onQuotationsUpdate({ data: payload, params });
    },
    async handleQuotation(item) {
      const { id } = item;
      this.quotationsInfo = await this.getQuotationDetail(id);
      this.$bvModal.show("quotation-modal");
    },
    onSearch() {
      let params = {
        page: this.pageNumber,
        page_size: this.pageSize,
      };
      if (this.supplier) {
        params = {
          supplier: this.supplier,
          ...params,
        };
      }
      if (this.search) {
        params = {
          search: this.search.trim(),
          ...params,
        };
      }
      if (this.status || this.status === 0) {
        params = {
          status: this.status,
          ...params,
        };
      }
      this.onQuotationsList(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/supplier-transaction/quotation-list",
          query: { page: this.pageNumber },
        });
      }
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.supplier) {
        params = {
          supplier: this.supplier,
          ...params,
        };
      }
      if (this.search) {
        params = {
          search: this.search.trim(),
          ...params,
        };
      }
      if (this.status || this.status === 0) {
        params = {
          status: this.status,
          ...params,
        };
      }
      this.onQuotationsList(params);
      this.$router.push({
        path: "/supplier-transaction/quotation-list",
        query: { page: this.pageNumber },
      });
    },
  },
};
</script>

export const SET_PRICE_STATUS = {
  STATUS_TEXT: {
    [-1]: "Đang cập nhật trạng thái",
    0: "Không áp dụng",
    1: "Đang áp dụng",
    2: "Chưa áp dụng",
    3: "Hết hạn",
  },
  APPEND_CLASSES: {
    [-1]: {
      badge: "badge-secondary",
      color: "secondary",
    },
    0: {
      badge: "badge-danger",
      color: "danger",
    },
    1: {
      badge: "badge-success",
      color: "success",
    },
    2: {
      badge: "badge-warning",
      color: "warning",
    },
    3: {
      badge: "badge-primary",
      color: "primary",
    },
  },
  UNDEFINED_STATUS: {
    SHORTCUT: "N/A",
    FULLTEXT: "Chưa xác định",
  },
};

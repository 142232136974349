<template>
  <div>
    <b-card
      v-for="productDetail in productListData"
      :key="productDetail.id"
      class="header-card-nav"
    >
      <img
        v-if="productDetail.image"
        class="img-fluid img-90 blur-up lazyloaded pull-left"
        :src="productDetail.image"
        fluid
      />
      <img
        v-else
        class="img-fluid img-90 blur-up lazyloaded pull-left"
        src="../../assets/images/image_default.png"
        fluid
      />
      <div class="ml-4 pull-left">
        <b-card-text>
          {{ productDetail.product_name }} <br />
          <span>{{ productDetail.sale_price | formatCurrency }}</span> <br />
        </b-card-text>
      </div>
      <b-card-text class="pull-right"
        ><br />SL: {{ productDetail.quantity
        }}<span v-if="productDetail.product.unit">
          {{ productDetail.product.unit }}</span
        ></b-card-text
      >
    </b-card>
  </div>
</template>
<script>
export default {
  props: {
    productListData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>
<style scoped>
.header-card-nav:first-child {
  margin-top: 60px;
}
</style>

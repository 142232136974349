import axiosInstance from "../../services/axios";
const url = "pages.json";
const menuUrl = "menus.json";

const state = {
  pages: [],
  menus: [],
};
const getters = {
  getPages(state) {
    return state.pages;
  },
  getMenus(state) {
    return state.menus;
  },
};
const actions = {
  getPages(context) {
    axiosInstance
      .get(url)
      .then((response) => {
        context.commit("getProducts", response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  },
  getMenus(context) {
    axiosInstance
      .get(menuUrl)
      .then((response) => {
        context.commit("getMenus", response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  },
};
const mutations = {
  getProducts(state, payload) {
    state.pages = payload.data;
  },
  getMenus(state, payload) {
    state.menus = payload.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

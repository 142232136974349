var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"page-header"},[_c('Breadcrumbs',{attrs:{"main":"Thiết lập chung","title":"Bảng Giá"}})],1)]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h5',[_vm._v(_vm._s(_vm.totalRecordPrice)+" sản phẩm")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-sm-6 d-flex align-items-center mb-2"},[_c('b-form-select',{attrs:{"disabled":_vm.storeID},model:{value:(_vm.currentStore),callback:function ($$v) {_vm.currentStore=$$v},expression:"currentStore"}},_vm._l((_vm.storeList),function(store){return _c('option',{key:store.index,domProps:{"value":store.id}},[_vm._v(_vm._s(store.name))])}),0)],1)]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-sm-6 d-flex align-items-center mb-2"},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Tìm kiếm sản phẩm"},on:{"input":function($event){return _vm.onSearch()}},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)]),_c('div',{staticClass:"table-responsive datatable-vue standard-list"},[(!_vm.isLoading && _vm.totalRecordPrice > 0)?_c('b-table',{ref:"selectableTable",attrs:{"striped":"","hover":"","head-variant":"light","select-mode":_vm.selectMode,"bordered":"","items":_vm.priceListActive,"fields":_vm.tablefields,"selectable":true},on:{"row-selected":_vm.rowSelected},scopedSlots:_vm._u([{key:"cell(sno)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.sno)+" ")])]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"cell(import_price)",fn:function(ref){
var item = ref.item;
return _vm._l((item.price_detail.supplier_price),function(priceDetail){return _c('ul',{key:priceDetail.index},[_c('li',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(priceDetail.supplier_name)+" : "+_vm._s(_vm._f("formatCurrency")(priceDetail.price))+" ")])])})}},{key:"cell(recommended_price)",fn:function(ref){
var item = ref.item;
return _vm._l((item.price_detail.supplier_price),function(priceDetail){return _c('ul',{key:priceDetail.index},[_c('li',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(priceDetail.supplier_name)+" : "+_vm._s(_vm._f("formatCurrency")(priceDetail.recommended_price))+" ")])])})}},{key:"cell(price)",fn:function(ref){
var item = ref.item;
return _vm._l((item.price_detail.supplier_price),function(priceDetail){return _c('ul',{key:priceDetail.index},[_c('li',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(priceDetail.supplier_name)+" : "+_vm._s(_vm._f("formatCurrency")(priceDetail.price + (priceDetail.price * (item.margin_percent / 100))))+" ")])])})}},{key:"cell(store_price)",fn:function(ref){
                  var item = ref.item;
                  var key = ref.field.key;
return [_vm._l((item.price_detail.store_price),function(salePrice){return _c('div',{key:salePrice.index},[(salePrice.store_id === _vm.currentStore)?_c('div',{staticStyle:{"width":"120px"}},[(_vm.edit != item.id)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(salePrice.price))+" ")]):_vm._e()]):_vm._e()])}),(_vm.edit === item.id)?[_c('b-form-input',{staticStyle:{"width":"120px"},attrs:{"type":"number","autofocus":""},on:{"blur":function($event){return _vm.saveRow(item)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleEnter(item)}},model:{value:(item[key]),callback:function ($$v) {_vm.$set(item, key, $$v)},expression:"item[key]"}})]:_vm._e()]}}],null,false,329964684)}):_vm._e(),(!_vm.isLoading && _vm.totalRecordPrice === 0)?_c('div',{staticClass:"text-center"},[_vm._v(" Không có dữ liệu ")]):_vm._e()],1),_c('b-col',{staticClass:"my-1 p-0 pagination-justify",attrs:{"md":"12"}},[(_vm.totalRecordPrice > 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRecordPrice,"per-page":_vm.pageSize,"aria-controls":"my-table"},on:{"change":_vm.handlePageChange},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}}):_vm._e()],1)],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
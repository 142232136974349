import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Breadcrumbs from "./components/bread-crumbs.vue";
import VueFeather from "vue-feather";
import firebase from "firebase/app";
import PxCard from "./components/pxcard.vue";
import VueApexCharts from "vue-apexcharts";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
//import CKEditor from "@ckeditor/ckeditor5-vue";
import { VueEditor } from "vue2-editor";
import StarRating from "vue-star-rating";
import GlobalCategoryModal from "./components/modals/globalCategoryModal.vue";
import WarehouseImport from "./components/modals/WarehouseImport.vue";
import ImportTransactionDetail from "./components/modals/ImportTransactionDetail.vue";
import WarehouseInventory from "./components/modals/WarehouseInventory.vue";
import OrderSupplierModal from "./components/modals/OrderSupplierModal.vue";
import OrderSupplierModalSupplier from "./components/modals/OrderSupplierModalSupplier.vue";
import ProductRefund from "./components/modals/ProductRefund.vue";
import OrderRefundSupplier from "./components/modals/OrderRefundSupplier.vue";
import CustomerRefundModal from "./components/modals/CustomerRefundModal.vue";
import PriceAdd from "./components/modals/PriceAdd.vue";
import QuotationsModal from "./components/modals/QuotationsModal.vue";
import QuotationsModalSupplier from "./components/modals/QuotationsModalSupplier.vue";
import AddQuotationModal from "./components/modals/AddQuotationModal.vue";
import FoodModal from "./components/modals/FoodModal.vue";
import InvoiceModal from "./components/modals/order/InvoiceModal.vue";
import AddCustomerModal from "./components/modals/AddCustomerModal.vue";
import VueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Toasted from "vue-toasted";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/vi";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import { PushNotifications } from "@capacitor/push-notifications";
import platform from "platform-detect";
import { FCM } from "@capacitor-community/fcm";
import LocalStorageService from "./utils/index";

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate";
import ZoomOnHover from "vue-zoom-on-hover";
Vue.use(ZoomOnHover);
import env from "vee-validate/dist/locale/vi.json";
import * as rules from "vee-validate/dist/rules";
// Multi language add
import { defaultLocale, localeOptions } from "./constants/config";
import VueI18n from "vue-i18n";
// import i18n translation languages
import vi from "./locales/vi.json";
import en from "./locales/en.json";
import es from "./locales/es.json";
import pt from "./locales/pt.json";
import fr from "./locales/fr.json";
import filter from "./filters/filter.js";
import commonTable from "./components/commonTable.vue";
import VueBottomNavigation from "bottom-navigation-vue";
import money from "v-money";
import axios from "axios";
import Rollbar from "rollbar";

// Set the Rollbar instance in the Vue prototype
// before creating the first Vue instance.
// This ensures it is available in the same way for every
// instance in your app.
Vue.prototype.$rollbar = new Rollbar({
  accessToken: "2f4cb7dc92194196a029f5196db7bbd8",
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: "development",
  payload: {
    // Track your events to a specific version of code for better visibility into version health
    code_version: "1.0.0",
    // Add custom data to your events by adding custom key/value pairs like the one below
    custom_data: "foo",
  },
});

Vue.config.errorHandler = (err, bm) => {
  bm.$rollbar.error(err);
  throw err; // rethrow
};

Vue.use(VueBottomNavigation);

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
localize("vi", env);
// config for firebase
import config from "../config.json";

Vue.use(Toasted, {
  duration: 3000,
  position: "top-right",
  type: "success",
});
Vue.component("multiselect", Multiselect);
Vue.component("date-picker", DatePicker);

Vue.component("commonTable", commonTable);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.use(VueI18n);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("VueEditor", VueEditor);
Vue.component("VueDropzone", VueDropzone);
Vue.use(VueDropzone);
Vue.component("GlobalCategoryModal", GlobalCategoryModal);
Vue.component("WarehouseImport", WarehouseImport);
Vue.component("ImportTransactionDetail", ImportTransactionDetail);
Vue.component("WarehouseInventory", WarehouseInventory);
Vue.component("PriceAdd", PriceAdd);
Vue.component("QuotationsModal", QuotationsModal);
Vue.component("QuotationsModalSupplier", QuotationsModalSupplier);
Vue.component("AddQuotationModal", AddQuotationModal);
Vue.component("StarRating", StarRating);
Vue.component("ProductRefund", ProductRefund);
Vue.component("OrderRefundSupplier", OrderRefundSupplier);
Vue.component("CustomerRefundModal", CustomerRefundModal);
Vue.component("FoodModal", FoodModal);
Vue.component("InvoiceModal", InvoiceModal);
Vue.component("AddCustomerModal", AddCustomerModal);
Vue.use(VueEditor);
//Vue.use(CKEditor);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
import "./assets/scss/app.scss";
import "./assets/scss/admin.scss";
Vue.component("Breadcrumbs", Breadcrumbs);
Vue.use(VueFeather);
Vue.use(require("vue-chartist"));
Vue.component(PxCard.name, PxCard);
Vue.component("OrderSupplierModal", OrderSupplierModal);
Vue.component("OrderSupplierModalSupplier", OrderSupplierModalSupplier);
Vue.use(VueLodash, { name: "custom", lodash: lodash });
Vue.use(money);

import Print from "vue-print-nb";
// Global instruction
Vue.use(Print);

//or

// Local instruction
import print from "vue-print-nb";

import wb from "./registerServiceWorker";

Vue.prototype.$workbox = wb;

directives: {
  print;
}

if (!platform.desktop) {
  PushNotifications.requestPermissions()
    .then(() => PushNotifications.register())
    .catch((error) => {
      console.log("Request permission failed ", error);
    });
  PushNotifications.addListener("registration", async (token) => {
    let resultToken = "";
    try {
      resultToken = await FCM.getToken();
    } catch (error) {
      alert(JSON.stringify(error));
      console.log("registration error", JSON.stringify(error));
      return;
    }

    store.dispatch("notify/addRegistrationDevice", {
      registration_id: platform.android ? token.value : resultToken.token,
      cloud_message_type: "FCM",
    });
  });
  PushNotifications.addListener("registrationError", (error) => {
    console.log("get device token error ", error);
  });
  PushNotifications.addListener("pushNotificationReceived", (notiSchema) => {
    Vue.toasted.show(notiSchema.body, {
      duration: 4000,
      type: "info",
    });
    const idStatus = router.currentRoute.query.id;
    const idStatusOrderLocal = LocalStorageService.getLocalStatusOrder();
    if (idStatus == Number(idStatusOrderLocal)) {
      let params = {
        status_order: idStatusOrderLocal,
      };
      store.dispatch("order/getOrderShipperList", params);
    }
  });
  PushNotifications.addListener("pushNotificationActionPerformed");
}

firebase.initializeApp(config.firebase);

if (firebase.messaging.isSupported()) {
  let messaging = firebase.messaging();
  messaging.usePublicVapidKey(
    "BPy_kjJVKiVbpHz0jS9kTLQaLA3eedB4M8tusKLfReX6InaAJ4MyV2DLEzM5fzFCdE_SJfjuUAaTUGiSXEvqXO8"
  );

  // Request Permission of Notifications
  messaging
    .requestPermission()
    .then(() => {
      // Get Token
      messaging.getToken().then((token) => {
        store.dispatch("notify/addRegistrationDevice", {
          registration_id: token,
          cloud_message_type: "FCM",
        });
        LocalStorageService.saveLocalAccessTokenDevice(token);
      });
    })
    .catch((err) => {
      console.log("Unable to get permission to notify.", err);
    });
}

const messages = { vi: vi, en: en, es: es, pt: pt, fr: fr };
const currentLanguage = localStorage.getItem("currentLanguage");
const locale =
  currentLanguage &&
  localeOptions.filter((x) => x.id === currentLanguage).length > 0
    ? currentLanguage
    : defaultLocale;
const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: "vi",
  messages,
  silentTranslationWarn: true,
});
// export const db = firebase.firestore();

Vue.filter("formatCurrency", function(value) {
  if (!value || value === '0') return 0 + "₫";
  if (typeof value === 'string' && !Number(value)) return value;
  value = value.toString();
  return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "₫";
});

Vue.filter("showDate", function(value) {
  if (!value) return "";
  const theDate = new Date(Date.parse(value));
  return theDate.toLocaleString("vi-VN", { timeZone: "Asia/Ho_Chi_Minh" });
});

Vue.filter("showDateOnly", function(value) {
  if (!value) return "";
  const datePart = value.match(/\d+/g),
    year = datePart[0],
    month = datePart[1],
    day = datePart[2];

  return day + "/" + month + "/" + year;
});

Vue.filter("dateFormat", function(value) {
  if (!value) return "";
  const datePart = value.match(/\d+/g),
    year = datePart[0],
    month = datePart[1],
    day = datePart[2];

  return day + "-" + month + "-" + year;
});

var vm = new Vue({
  router,
  store,
  i18n,
  filter,
  Rollbar,
  render: (h) => h(App),
}).$mount("#app");

vm.$mount("#app");

export const vueInstance = vm;

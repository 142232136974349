<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <b-modal ref="modalFilter" title="Bộ lọc" hide-footer>
      <date-picker
        v-model="date"
        value-type="format"
        range
        class="col-12"
        format="DD-MM-YYYY"
        placeholder="Chọn ngày để xem"
      ></date-picker>
      <div class="mt-2">
        <b-button class="btn btn-secondary" @click="cancelFilter()"
          >Huỷ lọc</b-button
        >
        <b-button class="btn btn-info pull-right" @click="onFilterOrder()"
          >Lọc</b-button
        >
      </div>
    </b-modal>
    <div class="page-wrapper">
      <div>
        <b-navbar fixed="top" type="dark" style="background-color: #42b983">
          <b-nav-text class="header-nav-shipper"
            ><b>THỐNG KÊ ĐƠN HÀNG</b></b-nav-text
          >
        </b-navbar>
      </div>
      <div class="card">
        <b-card style="margin-top: 50px">
          <input
            class="form-control-custom pull-left"
            placeholder="Tìm kiếm"
            v-model="search"
          />
          <feather
            style="cursor: pointer;"
            type="search"
            stroke-width="1"
            size="20px"
            @click="searchOrder"
            class="icon mt-2 ml-2"
          ></feather>
          <button class="btn pull-right" @click="openFilter()">
            Bộ lọc
            <feather type="filter" size="14"></feather>
          </button>
        </b-card>
        <b-card>
          Số đơn hàng: <b>{{ totalOrderShipper }}</b>
          <span class="pull-right">
            <div class="custom-card-text">
              Tổng tiền hàng:
              <b class="custom-card-text">{{
                totalInvoiceShipper | formatCurrency
              }}</b>
            </div>
            <span class="custom-card-text"
              >Tổng tiền khách đưa:
              <b class="custom-card-text">
                {{ totalCustomerGave | formatCurrency }}</b
              >
            </span>
          </span>
        </b-card>
        <b-table
          show-empty
          striped
          hover
          head-variant="light"
          bordered
          style="margin-bottom: 40px"
          :items="orderShipperStatisticList"
          :fields="tableFieldsOrder"
        >
          <template #empty>
            <span class="text-danger">
              Chưa có đơn hàng nào
            </span>
          </template>
          <template v-slot:cell(code)="{ item }">
            <span>
              <b>{{ item.code }}</b> <i>{{ item.order_date | showDate }}</i>
            </span>
          </template>
          <template v-slot:cell(total_payment)="{ item }">
            <span>
              {{ item.total_payment | formatCurrency }}
            </span>
          </template>
          <template v-slot:cell(prepay)="{ item }">
            <span v-if="item.prepay == null">
              _
            </span>
            <span v-else> {{ item.prepay | formatCurrency }}</span>
          </template>
        </b-table>
      </div>
    </div>
    <VueBottomNavigation
      :options="options"
      v-model="selected"
      foregroundColor="#42b983"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import VueBottomNavigation from "bottom-navigation-vue";
import Loader from "../../components/loading/loader.vue";
import { reverseDate } from "../../config/common";

export default {
  components: { VueBottomNavigation, Loader },
  created() {
    this.getShipperStatisticOrder();
  },
  computed: {
    ...mapState("order", [
      "orderShipperStatisticList",
      "isLoading",
      "totalOrderShipper",
      "totalInvoiceShipper",
    ]),
    totalCustomerGave() {
      return this.orderShipperStatisticList.reduce(
        (sum, item) => sum + item.prepay,
        0
      );
    },
  },
  methods: {
    ...mapActions("order", ["getShipperStatisticOrder"]),
    openFilter() {
      this.$refs.modalFilter.show();
    },
    onFilterOrder() {
      let params = {};
      if (this.date[0] && this.date[1]) {
        params = {
          start_date: reverseDate(this.date[0]),
          end_date: reverseDate(this.date[1]),
          ...params,
        };
      }
      if (this.search) {
        params = {
          search: this.search.trim(),
          ...params,
        };
      }
      this.getShipperStatisticOrder(params);
      this.$refs.modalFilter.hide();
    },
    cancelFilter() {
      this.date = "";
      this.getShipperStatisticOrder();
      this.$refs.modalFilter.hide();
    },
    searchOrder() {
      let params = {
        search: this.search.trim(),
      };
      if (this.date[0] && this.date[1]) {
        params = {
          start_date: reverseDate(this.date[0]),
          end_date: reverseDate(this.date[1]),
          ...params,
        };
      }
      this.getShipperStatisticOrder(params);
    },
  },
  data() {
    return {
      selected: 6,
      options: [
        {
          id: 4,
          icon: "fas fa-bell",
          title: "Thông báo",
          path: { name: "notify-shipper" },
        },
        {
          id: 1,
          icon: "fas fa-home",
          title: "Đơn hàng",
          path: { name: "shipper-order" },
        },
        {
          id: 5,
          icon: "fas fa-user",
          title: "Tài khoản",
          path: { name: "shipper-info" },
        },
        {
          id: 6,
          icon: "fas fa-chart-bar",
          title: "Thống kê",
          path: { name: "shipper-statistic" },
        },
      ],
      tableFieldsOrder: [
        { key: "code", label: "Thông tin đơn hàng" },
        { key: "total_payment", label: "Tổng tiền" },
        { key: "prepay", label: "Khách trả" },
      ],
      date: "",
      search: "",
    };
  },
};
</script>
<style>
.table .thead-light th {
  vertical-align: middle;
}
.custom-card-text {
  font-size: 13px;
}
.form-control-custom {
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 40%;
}
</style>

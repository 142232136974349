<template>
  <div>
    <b-modal size="lg" id="quotation-modal-supplier" centered>
      <template #modal-header>
        <h3 class="mx-auto" style="color: #ff8084">
          Báo giá
        </h3>
      </template>
      <div>
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex">
            <input
              v-model="dataSubmit.name"
              disabled
              placeholder="Tên báo giá"
              class="form-control rbt-custom-input mr-3"
              id="validationCustom1"
              type="text"
            />
          </div>
        </div>
        <div
          class="table-responsive datatable-vue standard-list text-center mt-4"
        >
          <b-table
            show-empty
            striped
            hover
            head-variant="light"
            bordered
            :items="listProductTable"
            :fields="tablefields"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template #empty>
              <span class="text-danger">
                Chưa có sản phẩm nào
              </span>
            </template>

            <template #thead-top>
              <b-tr>
                <b-th style="width: 50px">STT</b-th>
                <b-th>Sản phẩm</b-th>
                <b-th style="width: 100px">Đơn vị tính</b-th>
                <b-th style="width: 160px">Đơn giá</b-th>
                <b-th style="width: 160px">Giá khuyến nghị</b-th>
                <b-th colspan="2">Khối lượng tính</b-th>
              </b-tr>
            </template>

            <template v-slot:cell(unit_price)="{ item }">
              <div v-if="item.unit_price">
                {{ item.unit_price | formatCurrency }}
              </div>
            </template>
            <template v-slot:cell(recommended_price)="{ item }">
              <div v-if="item.recommended_price">
                {{ item.recommended_price | formatCurrency }}
              </div>
            </template>
            <template v-slot:cell(unit)="{ item }">
              <div>
                {{ item.unit }}
              </div>
            </template>
            <template v-slot:cell(lowest_vol)="{ item }">
              <div>Lớn hơn {{ item.volume_from || 0 }}</div>
            </template>
            <template v-slot:cell(highest_vol)="{ item }">
              <div>
                {{ item.volume_to || 100000 }}
              </div>
            </template>
          </b-table>
          <div>
            <b-pagination
              v-if="listProductTable.length > 0"
              class="mt-2 d-flex justify-content-end"
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="listProductTable.length"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
      <template #modal-footer="{cancel}">
        <div style="width: 100%" class="d-flex justify-content-between">
          <div class="d-flex justify-content-center text-center">
            <b-button class="btn btn-default" :variant="colorStatus[dataSubmit.status]">
              {{ QUOTATION_STATUS_SUPPLIER[dataSubmit.status] }}
            </b-button>
          </div>
          <div>
            <b-button class="mr-3" size="sm" variant="danger" @click="cancel()">
              Đóng
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { QUOTATION_STATUS } from "../../config/quotations/index";

const initialDataSubmit = {
  products: [],
  name: "",
  note: "",
};

export default {
  props: {
    quotationsInfo: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      tablefields: [
        { key: "sno", label: "" },
        { key: "name", label: "" },
        { key: "unit", label: "" },
        { key: "unit_price", label: "" },
        { key: "recommended_price", label: "" },
        { key: "lowest_vol", label: "Từ" },
        { key: "highest_vol", label: "Đến" },
      ],
      productQuotes: [],
      dataSubmit: { ...initialDataSubmit },
    };
  },
  components: {},
  computed: {
    listProductTable() {
      return this.productQuotes.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
    colorStatus() {
      return QUOTATION_STATUS.APPEND_COLOR;
    },
    QUOTATION_STATUS_SUPPLIER() {
      return QUOTATION_STATUS.SUPPLIER;
    },
  },
  watch: {
    quotationsInfo() {
      if (this.quotationsInfo.id) {
        this.dataSubmit = { ...this.quotationsInfo };
        const products = this.quotationsInfo.products.map((item) => {
          return {
            product: item.product,
            name: item.name,
            unit: item.unit,
            recommended_price: item.recommended_price,
            unit_price: item.unit_price,
            volume_from: item.lowest_weight,
            volume_to: item.highest_weight,
          };
        });
        this.productQuotes = [...products];
      } else {
        this.dataSubmit = { ...this.initialDataSubmit };
        this.productQuotes = [];
      }
    },
  },
  methods: {
    ...mapActions("supplier", ["onQuotationsUpdateSupplier"]),
    ...mapActions(["setMessage"]),
    submitQuotation() {
      if (this.productQuotes.length > 0 && this.dataSubmit.name) {
        const { sno, user, ...data } = this.dataSubmit;
        const productQuotations = this.productQuotes.map((item) => {
          return {
            unit_price:
              typeof item.unit_price === "string"
                ? Number(item.unit_price.replaceAll(",", ""))
                : item.unit_price,
            recommended_price:
              typeof item.recommended_price === "string"
                ? Number(item.recommended_price.replaceAll(",", ""))
                : item.recommended_price,
            product: item.product,
            lowest_weight: item.volume_from,
            highest_weight: item.volume_to,
          };
        });
        this.onQuotationsUpdateSupplier({
          data: {
            ...data,
            products: [...productQuotations],
            status: 1,
          },
        });
        this.dataSubmit = { ...initialDataSubmit };
        this.$bvModal.hide("quotation-modal-supplier");
      } else {
        this.setMessage({
          type: "error",
          content: "Tên báo giá, nhà cung cấp, sản phẩm không được để trống",
          isShow: true,
        });
      }
    },
  },
};
</script>

<style>
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
</style>

<style scoped>
.rbt-custom-input {
  max-width: 192px;
  white-space: nowrap;
  height: 43px !important;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
input {
  height: 100% !important;
}
</style>

import personDiscountService from "../../services/person-discount";

const state = {
  personDiscountList: [],
  totalRecord: 0,
  isLoading: false,
};
const getters = {};
const actions = {
  async getPersonDiscountList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await personDiscountService.getAll(params);
      const { results, count } = response.data.data;
      commit("SET_LIST_DISCOUNT", results);
      commit("SET_TOTAL_RECORD", count);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getPersonDiscountDetail({ commit }, id) {
    try {
      commit("SET_LOADING", true);
      const response = await personDiscountService.getItem(id);
      commit("SET_LOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING", false);
      return {};
    }
  },

  async createPersonDiscount({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await personDiscountService.create(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cài đặt chiết khấu thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async updatePersonDiscount({ commit }, payload) {
    try {
      const response = await personDiscountService.update(payload);
    } catch (error) {
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async deletePersonDiscount({ commit, dispatch }, payload) {
    try {
      const { id, params } = payload;
      await commit("SET_LOADING", true);
      const response = await personDiscountService.delete(id);
      await dispatch("getPersonDiscountList", params);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Xoá thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
};
const mutations = {
  SET_LIST_DISCOUNT(state, payload) {
    state.personDiscountList = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_TOTAL_RECORD(state, payload) {
    state.totalRecord = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

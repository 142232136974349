<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Phiếu trả hàng nhà cung cấp" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>{{ totalRefunds }} kết quả</h5>
            </div>
            <div class="card-body">
              <div class="row mb-4">
                <div
                  class="col-sm-8 d-flex justify-content-center align-items-center"
                >
                  <b-form-select v-model="supplier" class="col-sm-3">
                    <option value="">Lọc theo tên NCC</option>
                    <option
                      v-for="supplier in supplierList"
                      :key="supplier.index"
                      :value="supplier.id"
                      >{{ supplier.name }}</option
                    >
                  </b-form-select>
                  <date-picker
                    v-model="date"
                    value-type="format"
                    range
                    format="DD-MM-YYYY"
                    placeholder="Lọc theo ngày"
                    class="d-flex pl-0 flex-wrap ncc-date-picker mr-2 ml-3"
                  ></date-picker>
                  <div class="col-sm-6 d-flex">
                    <b-form-group
                      class="datatable-select"
                      style="margin-bottom: 0 !important"
                    >
                      <b-form-input
                        type="text"
                        v-model="query"
                        placeholder="Lọc theo tên phiếu trả"
                      ></b-form-input>
                    </b-form-group>
                    <div class="ml-3">
                      <b-button
                        class="btn-popup mb-0"
                        variant="primary"
                        @click="onSearch()"
                        >Tìm</b-button
                      >
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 d-flex justify-content-end">
                  <b-button class="btn-popup m-0" @click="openModalImport()">
                    <span style="font-size: 24px; font-weight: 100">
                      +
                    </span>
                    Tạo đơn trả hàng NCC</b-button
                  >
                </div>
              </div>
              <div class="table-responsive datatable-vue standard-list">
                <b-modal
                  id="modal-2"
                  title="Xác nhận"
                  @ok="deleteProductRefund(isId)"
                >
                  <p class="my-4">
                    Xóa <b>{{ isName }}</b>
                  </p>
                </b-modal>
                <b-table
                  v-if="!isLoading"
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  :items="refundList"
                  :fields="tablefields"
                >
                  <template v-slot:cell(supplier)="{ item }">
                    <span>
                      {{ item.supplier.name }}
                    </span>
                  </template>
                  <template v-slot:cell(status)="{ item }">
                    <div class="text-center">
                      <b-badge :variant="colorStatus[item.status]">
                        {{ ORDER_RETURN_STATUS_ADMIN[item.status] }}
                      </b-badge>
                    </div>
                  </template>
                  <template v-slot:cell(created_at)="{ item }">
                    <div class="number-and-date-table" v-if="item.created_at">
                      {{ item.created_at | dateFormat }}
                    </div>
                  </template>
                  <template v-slot:cell(created_by)="{ item }">
                    <span v-if="item.created_by">
                      {{ item.created_by.name }}
                    </span>
                  </template>
                  <template v-slot:cell(total)="{ item }">
                    <span v-if="item.total">
                      {{ item.total | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="handleOrderReturn(item)"
                      ></feather>
                    </span>
                    <span style="font-size: 20px; color: #FA399F;">
                      <feather
                        v-if="item.status === 0"
                        v-b-modal.modal-2
                        style="cursor: pointer"
                        type="trash"
                        stroke="#F72E9F"
                        size="18px"
                        fill="#F72E9F"
                        @click="setItem(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="!isLoading && refundList.length < 0"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRefunds > 0"
                  v-model="pageNumber"
                  :total-rows="totalRefunds"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
              <ProductRefund
                :refundInfo="refundInfo"
                @onCreate="onCreate"
                @onUpdate="onUpdate"
                @onApproved="onApproved"
                @onCancel="onCancel"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import ProductRefund from "../../components/modals/ProductRefund.vue";
import { ORDER_RETURN_STATUS } from "../../config/orders-return/index";
import { reverseDate } from "../../config/common";

export default {
  components: {
    ProductRefund,
    Loader,
  },
  data() {
    return {
      tablefields: [
        { key: "code", label: "Mã phiếu trả" },
        { key: "name", label: "Tên phiếu trả" },
        { key: "supplier", label: "Nhà cung cấp" },
        { key: "created_by", label: "Người tạo" },
        { key: "reason", label: "Lý do" },
        { key: "status", label: "Trạng thái" },
        { key: "created_at", label: "Ngày tạo" },
        { key: "actions", label: "Hành động" },
      ],
      query: "",
      date: "",
      supplier: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      refundInfo: {},
      isId: "",
      isName: "",
    };
  },
  async created() {
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = { page: pageNumber, page_size: this.pageSize };
    await this.getSupplierList();
    await this.getAllRefundList(params);
  },
  computed: {
    ...mapState("supplier", [
      "refundList",
      "totalRefunds",
      "isLoading",
      "supplierList",
    ]),
    colorStatus() {
      return ORDER_RETURN_STATUS.APPEND_COLOR.ADMIN;
    },
    ORDER_RETURN_STATUS_ADMIN() {
      return ORDER_RETURN_STATUS.ADMIN;
    },
  },
  watch: {},
  methods: {
    ...mapActions("supplier", [
      "getAllRefundList",
      "onCreateRefund",
      "onCancelRefund",
      "onUpdateRefund",
      "onApprovedRefund",
      "getRefundDetail",
      "deleteRefund",
      "getSupplierList",
    ]),
    ...mapActions("product", ["onListProduct"]),
    onCancel(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onCancelRefund({ data: payload, params });
      this.handleClearData();
    },
    onApproved(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onApprovedRefund({ data: payload, params });
      this.handleClearData();
    },
    onUpdate(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onUpdateRefund({ data: payload, params });
      this.handleClearData();
    },
    onCreate(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onCreateRefund({ data: payload, params });
      this.handleClearData();
    },
    handleClearData() {
      this.date = "";
      this.query = "";
      this.supplier = "";
    },
    openModalImport() {
      this.refundInfo = {};
      this.$bvModal.show("product-refund-modal");
    },
    async handleOrderReturn(item) {
      const { id, supplier } = item;
      await this.onListProduct({ supplier_id: supplier.id });
      this.refundInfo = await this.getRefundDetail(id);
      this.$bvModal.show("product-refund-modal");
    },
    onSearch() {
      let params = {
        page: 1,
        page_size: this.pageSize,
      };
      if (this.date[0] && this.date[1]) {
        params = {
          start: reverseDate(this.date[0]),
          end: reverseDate(this.date[1]),
          ...params,
        };
      }
      if (this.query) {
        params = { name: this.query, ...params };
      }
      if (this.supplier) {
        params = {
          supplier_id: this.supplier,
          ...params,
        };
      }
      this.getAllRefundList(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/supplier-transaction/return-list",
          query: { page: this.pageNumber },
        });
      }
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.date[0] && this.date[1]) {
        params = {
          start: reverseDate(this.date[0]),
          end: reverseDate(this.date[1]),
          ...params,
        };
      }
      if (this.query) {
        params = { name: this.query, ...params };
      }
      if (this.supplier) {
        params = {
          supplier_id: this.supplier,
          ...params,
        };
      }
      this.getAllRefundList(params);
      this.$router.push({
        path: "/supplier-transaction/return-list",
        query: { page: this.pageNumber },
      });
    },
    setItem(item) {
      this.isId = item.id;
      this.isName = item.name;
    },
    deleteProductRefund(id) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.deleteRefund({ data: id, params });
      this.handleClearData();
    },
  },
};
</script>

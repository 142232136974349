<template>
  <div>
    <b-modal
      size="xl"
      :no-close-on-backdrop="true"
      id="modal-inventory-warehouse"
      centered
    >
      <template #modal-header>
        <h3 class="mx-auto" style="color: #ff8084">
          Kiểm kho
        </h3>
      </template>
      <div class="row mt-4">
        <div class="col-lg-6">
          <validation-provider
            name="Tên kiểm kê"
            :rules="{
              required: true,
            }"
            v-slot="validationContext"
          >
            <b-form-group
              id="exampleInputGroup3"
              label-cols-md="2"
              label-cols-lg="3"
              content-cols-sm
              content-cols-lg="8"
              label-for="exampleInput3"
            >
              <template v-slot:label>
                Tên kiểm kê <span class="validate-star">*</span>
              </template>
              <b-form-input
                v-model="dataSubmit.name"
                placeholder="Tên kiểm kê"
                class="form-control rbt-custom-input"
                :disabled="!!inventoryInfo.status || inventoryInfo.status == 0"
                :state="getValidationState(validationContext)"
                type="text"
              ></b-form-input>
              <b-form-invalid-feedback>{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>
        <div class="col-lg-6">
          <validation-provider
            name="Nhân viên kiểm kê"
            :rules="{
              required: true,
            }"
          >
            <b-form-group
              id="exampleInputGroup3"
              label-cols-sm="2"
              label-cols-lg="3"
              content-cols-sm
              content-cols-lg="8"
              label-for="exampleInput3"
            >
              <template v-slot:label>
                Nhân viên kiểm kê
                <span class="validate-star"> *</span>
              </template>
              <multiselect
                class="rbt-custom-input"
                placeholder="Nhân viên kiểm kê"
                v-model="dataSubmit.inventory_officer"
                :disabled="!!inventoryInfo.status || inventoryInfo.status == 0"
                :show-labels="false"
                :custom-label="searchingSelect"
                :options="employeeList"
              >
                <span slot="noResult">Không tìm thấy kết quả!!!</span>
                <template slot="singleLabel" slot-scope="props"
                  ><span class="option__desc"
                    ><span class="option__title">{{
                      props.option.name
                    }}</span></span
                  ></template
                >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <p class="option__title">
                      {{ props.option.name }}
                    </p>
                  </div>
                </template>
              </multiselect>
            </b-form-group>
          </validation-provider>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-lg-6">
          <validation-provider
            name="Kho kiểm kê"
            :rules="{
              required: true,
            }"
            v-slot="validationContext"
          >
            <b-form-group
              id="exampleInputGroup3"
              label-cols-md="2"
              label-cols-lg="3"
              content-cols-sm
              content-cols-lg="8"
              label-for="exampleInput3"
            >
              <template v-slot:label>
                Kho kiểm kê <span class="validate-star">*</span>
              </template>
              <multiselect
                class="rbt-custom-input"
                placeholder="Chọn kho"
                v-model="dataSubmit.warehouse"
                :disabled="!!inventoryInfo.status || inventoryInfo.status == 0"
                :show-labels="false"
                :custom-label="searchingSelect"
                @input="onChangeWarehouse"
                :options="warehouseList"
              >
                <span slot="noResult">Không tìm thấy kết quả!!!</span>
                <template slot="singleLabel" slot-scope="props"
                  ><span class="option__desc"
                    ><span class="option__title">{{
                      props.option.name
                    }}</span></span
                  ></template
                >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <p class="option__title">
                      {{ props.option.name }}
                    </p>
                  </div>
                </template>
              </multiselect>
              <b-form-invalid-feedback>{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>
        <div class="col-lg-6" v-if="!inventoryInfo.code">
          <validation-provider :rules="{}">
            <b-form-group
              id="exampleInputGroup3"
              label-cols-md="2"
              label-cols-lg="3"
              content-cols-sm
              content-cols-lg="8"
              label-for="exampleInput3"
            >
              <template v-slot:label>
                Sắp xếp theo
              </template>
              <b-select
                v-model="ordering"
                class="rbt-custom-input"
                @change="onChangeFilter"
              >
                <option value="">Tất cả</option>
                <option value="product_inventory_quantity">Sắp hết hàng</option>
                <option value="expiry">Sắp hết hạn</option>
              </b-select>
            </b-form-group>
          </validation-provider>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-lg-6" v-if="!inventoryInfo.code">
          <validation-provider
            name="Danh mục"
            :rules="{
              required: true,
            }"
          >
            <b-form-group
              id="exampleInputGroup3"
              label-cols-sm="2"
              label-cols-lg="3"
              content-cols-sm
              content-cols-lg="8"
              label-for="exampleInput3"
            >
              <template v-slot:label>
                Danh mục
              </template>
              <multiselect
                class="rbt-custom-input"
                placeholder="Chọn danh mục"
                :show-labels="false"
                v-model="dataSubmit.category"
                :custom-label="searchingSelect"
                @input="onChangeCategory"
                :options="categoryList"
              >
                <span slot="noOptions">
                  Không có sản phẩm
                </span>
                <span slot="noResult">Không tìm thấy kết quả!!!</span>
                <template slot="singleLabel" slot-scope="props"
                  ><span class="option__desc"
                    ><span class="option__title">{{
                      props.option.name
                    }}</span></span
                  ></template
                >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <p class="option__title">
                      {{ props.option.name }}
                    </p>
                  </div>
                </template>
              </multiselect>
            </b-form-group>
          </validation-provider>
        </div>
        <div class="col-lg-6" v-if="!inventoryInfo.code">
          <validation-provider
            name="Sản phẩm"
            :rules="{
              required: true,
            }"
          >
            <b-form-group
              id="exampleInputGroup3"
              label-cols-sm="2"
              label-cols-lg="3"
              content-cols-sm
              content-cols-lg="8"
              label-for="exampleInput3"
            >
              <template v-slot:label>
                Chọn sản phẩm<span class="validate-star"> *</span>
              </template>
              <multiselect
                class="rbt-custom-input"
                placeholder="Chọn sản phẩm"
                :show-labels="false"
                @search-change="search"
                @input="onAddProductInTab"
                :options="productsActive"
                :internal-search="false"
              >
                <span slot="noOptions">
                  Nhập để tìm kiếm
                </span>
                <span slot="noResult">Không tìm thấy kết quả!!!</span>
                <template slot="singleLabel" slot-scope="props"
                  ><span class="option__desc"
                    ><span class="option__title">{{
                      props.option.name
                    }}</span></span
                  ></template
                >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <p class="option__title">
                      {{ props.option.name }}
                    </p>
                  </div>
                </template>
              </multiselect>
            </b-form-group>
          </validation-provider>
        </div>
      </div>
      <div class="text-center">
        <b-spinner
          v-if="isLoading"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </div>
      <div>
        <div class="table-responsive datatable-vue text-center mt-4">
          <b-modal
            id="model-remove"
            title="Xác nhận"
            @ok="removeProductInTab()"
          >
            <p class="my-4">Xác nhận</p>
          </b-modal>
          <b-modal
            id="model-remove-check"
            title="Xác nhận"
            :no-close-on-backdrop="true"
            @ok="cancelCheckWarehouse()"
          >
            <p class="my-4" v-if="this.dataSubmit.warehouse">
              Bạn có muốn hủy kiểm kê kho
              <b>{{ this.dataSubmit.warehouse.name }}</b>
            </p>
          </b-modal>

          <!-- create -->
          <b-table
            v-if="!inventoryInfo.code"
            show-empty
            striped
            hover
            head-variant="light"
            bordered
            stacked="md"
            :items="itemsImport"
            :fields="tablefields"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template #empty>
              <span class="text-danger">
                Chưa có sản phẩm nào
              </span>
            </template>
            <template v-slot:cell(quantity)="{ item }">
              <span>
                {{ item.quantity }}
              </span>
            </template>
            <template v-slot:cell(actions)="{ item }">
              <span
                style="font-size: 20px; color: #FA399F;"
                @click="removeItemInTable(item.sno)"
              >
                <feather
                  style="cursor: pointer"
                  type="trash"
                  stroke="#F72E9F"
                  size="18px"
                  fill="#F72E9F"
                ></feather>
              </span>
            </template>
          </b-table>

          <!-- detail -->
          <b-table
            v-if="inventoryInfo.code"
            class="table"
            show-empty
            striped
            hover
            head-variant="light"
            bordered
            stacked="md"
            :items="defaultActive"
            :fields="tableDetail"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template #empty>
              <span class="text-danger">
                Chưa có sản phẩm nào
              </span>
            </template>
            <template v-slot:cell(quantity_before_checking)="{ item }">
              <span>
                {{ item.quantity_before_checking }}
              </span>
            </template>
            <template v-slot:cell(name)="{ item }">
              <span>
                {{ item.product.name }}
              </span>
            </template>
            <template v-slot:cell(unit)="{ item }">
              <span>
                {{ item.product.unit }}
              </span>
            </template>
            <template v-slot:cell(difference)="{ item }">
              <span>
                {{ parseFloat(item.different_quantity) }}
              </span>
            </template>
            <template v-slot:cell(actual_quantity)="{ item }">
              <div
                v-if="dataSubmit.status < 1"
                class="d-flex justify-content-center align-items-center"
              >
                <IncreaseAndDecrease
                  style="max-width: 150px"
                  :isNumber="Number(item.actual_quantity) || 0"
                  @onChangeData="onIncreAndDecre"
                  :product="item.product"
                  :isDiscount="true"
                />
              </div>
              <div v-else>
                <span>
                  {{ item.actual_quantity || 0 }}
                </span>
              </div>
            </template>
          </b-table>
          <div>
            <b-pagination
              v-if="itemsImport.length > 0"
              class="mt-2 d-flex justify-content-end"
              v-model="currentPage"
              :total-rows="itemsImport.length"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
          <div>
            <b-pagination
              v-if="defaultActive && defaultActive.length > 0"
              class="mt-2 d-flex justify-content-end"
              v-model="currentPage"
              :total-rows="defaultActive.length"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
        <div>
          <textarea
            v-model="dataSubmit.notes"
            rows="2"
            :disabled="inventoryInfo.status == 3 || inventoryInfo.status == 2"
            placeholder="Ghi chú kiểm kho"
            class="form-control"
          ></textarea>
        </div>
      </div>
      <div v-if="!inventoryInfo.code" class="mt-3">
        <ListProductBottom
          :productList="productsActive"
          @onAddProductInTab="onAddProductInTab"
        />
      </div>
      <template #modal-footer="{cancel}">
        <div style="width: 100%" class="d-flex justify-content-between">
          <div>
            <b-button
              v-if="inventoryInfo.code && inventoryInfo.status <= 1"
              class="m-0"
              @click="openModelCancelCheck()"
            >
              <span>
                Hủy kiểm kê
              </span>
            </b-button>
          </div>
          <div>
            <b-button
              class="mr-3"
              size="sm"
              variant="danger"
              @click="
                cancel();
                listImport = [];
              "
            >
              Đóng
            </b-button>
            <b-button
              v-if="!inventoryInfo.code"
              class="btn-popup m-0"
              @click="submit()"
            >
              <span style="font-size: 24px; font-weight: 100">
                +
              </span>
              Tạo phiếu kiểm kho</b-button
            >
            <b-button
              v-if="
                inventoryInfo.code &&
                  inventoryInfo.status >= 0 &&
                  inventoryInfo.status <= 1
              "
              class="btn-popup m-0"
              @click="updateCheckWarehouse()"
            >
              <span v-if="inventoryInfo.status === 0">
                Kiểm kê
              </span>
              <span v-if="inventoryInfo.status === 1">
                Duyệt
              </span>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ListProductBottom from "../../components/UI/listProductBottom.vue";
import IncreaseAndDecrease from "../../components/UI/increaseAndDecrease.vue";
import { WAREHOUSE_STATUS } from "../../config/warehouse/index";
import { debounce } from "lodash";

const initalDataSubmit = {
  name: "",
  inventory_officer: null,
  notes: "",
  warehouse: null,
};

export default {
  props: {
    inventoryInfo: {
      type: Object,
      default: {},
    },
  },
  components: {
    ListProductBottom,
    IncreaseAndDecrease,
  },
  name: "WarehouseImport",
  data() {
    return {
      warehouseSelected: null,
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "name", label: "Sản phẩm" },
        { key: "unit", label: "Đơn vị nhập" },
        { key: "min_inventory", label: "Tồn kho tối thiểu" },
        { key: "product_inventory_quantity", label: "Tồn kho" },
        { key: "actions", label: "Xóa" },
      ],
      tableDetail: [
        { key: "sno", label: "STT" },
        { key: "name", label: "Sản phẩm" },
        { key: "unit", label: "Đơn vị nhập" },
        { key: "quantity_before_checking", label: "Tồn kho" },
        { key: "actual_quantity", label: "Số thực tế" },
        { key: "difference", label: "Chênh lệch" },
      ],
      listImport: [],
      ordering: "",
      level: 0,
      dataSubmit: {
        ...initalDataSubmit,
      },
      maxPageSize: process.env.VUE_APP_MAX_PAGE_SIZE,
      currentPage: 1,
      perPage: process.env.VUE_APP_PAGE_SIZE,
      WAREHOUSE_STATUS_TEXT: WAREHOUSE_STATUS.TEXT
    };
  },
  created() {
    this.onListProduct();
    this.getEmployeeList();
    this.getCategoryList();
    this.getWarehouseList();
  },
  methods: {
    ...mapActions("employee", ["getEmployeeList"]),
    ...mapActions("product", ["onListProduct"]),
    ...mapActions("category", ["getCategoryList"]),
    ...mapActions("warehouse", ["getWarehouseList"]),
    openModelCancelCheck() {
      this.$bvModal.show("model-remove-check");
    },
    onIncreAndDecre({ quantity, product }) {
      this.dataSubmit?.products_checked.forEach((item) => {
        if (item.product.id === product.id) {
          item.actual_quantity = quantity;
          item.different_quantity =
            item?.quantity_before_checking - item.actual_quantity;
        }
      });
    },
    submit() {
      const { name, inventory_officer, notes, warehouse } = this.dataSubmit;
      if (
        name &&
        inventory_officer &&
        warehouse &&
        this.listImport.length > 0
      ) {
        const products_checked = this.listImport.map((item) => {
          return {
            product: item.id,
          };
        });
        this.$emit("onAddCheckWarehouse", {
          warehouse: warehouse.id,
          name,
          status: this.WAREHOUSE_STATUS_TEXT.CREATE,
          inventory_officer: inventory_officer.id,
          notes,
          products_checked,
        });
        this.$bvModal.hide("modal-inventory-warehouse");
        this.dataSubmit = { ...initalDataSubmit };
        this.listImport = [];
      } else {
        alert("Cần phải điền đủ các trường cần thiết");
      }
    },
    cancelCheckWarehouse() {
      const {
        id,
        notes,
        products_checked,
        inventory_officer,
        status,
        ...data
      } = this.dataSubmit;
      const converToUpdate = products_checked.map((item) => {
        return {
          product: item.product.id,
          actual_quantity: item.actual_quantity || 0,
        };
      });
      this.$emit("onUpdateCheckWareHouse", {
        id,
        products_checked: converToUpdate,
        status: this.WAREHOUSE_STATUS_TEXT.CANCEL,
        inventory_officer: inventory_officer?.id,
        notes: notes || "",
        ...data,
      });
      this.$bvModal.hide("modal-inventory-warehouse");
    },
    updateCheckWarehouse() {
      const {
        id,
        products_checked,
        inventory_officer,
        status,
        notes,
        ...data
      } = this.dataSubmit;
      let dataSubmit = {};
      if (this.dataSubmit.status === 0) {
        const converToUpdate = products_checked.map((item) => {
          return {
            product: item.product.id,
            actual_quantity: item.actual_quantity,
            different_quantity: item.different_quantity,
          };
        });
        dataSubmit = {
          id,
          products_checked: converToUpdate,
          status: this.WAREHOUSE_STATUS_TEXT.PENDING,
          inventory_officer: inventory_officer.id,
          notes: notes || "",
          ...data,
        };
      } else if (this.dataSubmit.status === 1) {
        dataSubmit = {
          id,
          status: this.WAREHOUSE_STATUS_TEXT.APPROVED,
          inventory_officer: inventory_officer.id,
          notes: notes || "",
          ...data,
        };
      }
      this.$emit("onUpdateCheckWareHouse", dataSubmit);
      this.$bvModal.hide("modal-inventory-warehouse");
      data = {};
    },

    onAddProductInTab(product) {
      this.listImport.push(product);
    },
    removeItemInTable(index) {
      this.listImport.splice(index - 1, 1);
    },
    searchingSelect({ name }) {
      return name;
    },
    onChangeWarehouse(warehouse) {
      this.listImport = [];
      this.dataSubmit.category = "";
      if (warehouse) {
        const { id } = warehouse;
        let params = {
          warehouse_id: id,
          active: true,
          page_size: this.maxPageSize,
        };
        if (this.ordering) {
          params = {
            ordering: this.ordering,
            ...params,
          };
        }
        this.onListProduct(params);
      } else {
        this.onListProduct();
      }
    },
    async onChangeCategory(category) {
      this.listImport = [];
      if (category) {
        const { id } = category;
        let params = {
          category_id: id,
          active: true,
          page_size: this.maxPageSize,
        };
        if (this.ordering) {
          params = {
            ordering: this.ordering,
            ...params,
          };
        }
        await this.onListProduct(params);
        this.isListProduct.map((item) => this.listImport.push(item));
      }
    },
    onChangeFilter() {
      this.listImport = [];
      let params = {
        ordering: this.ordering,
        active: true,
      };
      if (this.dataSubmit?.warehouse) {
        const { id } = this.dataSubmit?.warehouse;
        params = {
          warehouse_id: id,
          ...params,
        };
      }
      if (this.dataSubmit.category) {
        const { id } = this.dataSubmit?.category;
        params = {
          category_id: id,
          ...params,
        };
      }
      this.onListProduct(params);
      this.isListProduct.map((item) => this.listImport.push(item));
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated
        ? (valid = valid === true ? null : valid)
        : null;
    },
    search: _.debounce(function(query) {
      this.onListProduct({ name: query });
    }, 250),
  },
  computed: {
    ...mapState("category", ["categoryList"]),
    ...mapState("employee", ["employeeList"]),
    ...mapState("product", ["isListProduct", "isLoading"]),
    ...mapState("warehouse", ["warehouseList"]),
    defaultActive() {
      return this.inventoryInfo?.products_checked?.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
    productsActive() {
      const idProductInTable = this.listImport.map((item) => {
        return item.id;
      });
      return this.isListProduct?.filter((item) => {
        return !idProductInTable?.includes(item.id);
      });
    },
    itemsImport() {
      return this.listImport?.map((item, index) => ({
        sno: index + 1,
        ...item,
      }));
    },
  },
  watch: {
    inventoryInfo() {
      this.dataSubmit = this.inventoryInfo?.code
        ? { ...this.inventoryInfo }
        : { ...initalDataSubmit };
      if (this.dataSubmit.status == 0) {
        this.dataSubmit?.products_checked.forEach((item) => {
          item.different_quantity = item.quantity_before_checking;
        });
      }
    },
  },
};
</script>

<style>
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
</style>

<style scoped>
.rbt-custom-input {
  max-width: 192px;
  white-space: nowrap;
  height: 43px !important;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
input {
  height: 100% !important;
}
</style>

import axiosInstance from "./axios";
import { apiRoutesEnum } from "../enums/routes";

class StoreService {
  getAll(params) {
    return axiosInstance.get(`${apiRoutesEnum.store}`, { params: params });
  }

  getItem(id) {
    return axiosInstance.get(`${apiRoutesEnum.store}/${id}`);
  }

  create(payload) {
    return axiosInstance.post(`${apiRoutesEnum.store}`, payload);
  }

  update(payload) {
    const id = payload.id;
    const data = payload.data;
    return axiosInstance.put(`${apiRoutesEnum.store}/${id}`, data);
  }
}

export default new StoreService();

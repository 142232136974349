import axiosInstance from "../../services/axios";

const url = "invoice.json";

const state = {
  invoice: [],
};
const getters = {
  getInvoice(state) {
    return state.invoice;
  },
};
const actions = {
  getInvoice(context) {
    axiosInstance
      .get(url)
      .then((response) => {
        context.commit("getInvoice", response.data);
      })
      .catch((error) => {});
  },
};
const mutations = {
  getInvoice(state, payload) {
    state.invoice = payload.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

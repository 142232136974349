import supplierService from "../../services/supplier";

const state = {
  supplierList: [],
  totalRecord: 0,
  isLoading: false,
  isLoadingQuotation: false,
  ordersSupplier: [],
  totalRecordOrder: 0,
  quotationsList: [],
  totalRecordQuotation: 0,
  refundList: [],
  totalRefunds: 0,
  products: [],
  providerOrder: [],
  totalProviderOrder: 0,
};
const getters = {};
const actions = {
  async deleteRefund({ commit, dispatch }, payload) {
    try {
      const { data, params } = payload;
      const response = await supplierService.deleteRefund(data);
      await dispatch("getAllRefundList", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Xoá đơn trả hàng thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async getAllRefundList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await supplierService.getAllRefundList(params);
      const { results, count } = response.data.data;
      commit("SET_LIST_REFUND", results);
      commit("SET_TOTAL_RECORD_REFUND", count);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getRefundDetail({ commit }, id) {
    try {
      commit("SET_LOADING", true);
      const response = await supplierService.getRefundDetail(id);
      commit("SET_LOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING", false);
      return {};
    }
  },

  async onUpdateRefund({ commit, dispatch }, payload) {
    try {
      const { data, params } = payload;
      const response = await supplierService.onUpdateRefund(data);
      await dispatch("getAllRefundList", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật đơn trả hàng thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit(
        "SET_MESSAGE",
        {
          type: "error",
          content: "Lỗi cập nhật",
          isShow: true,
        },
        { root: true }
      );
      commit("SET_LOADING", false);
    }
  },

  async onCancelRefund({ commit, dispatch }, payload) {
    try {
      const { data, params } = payload;
      const response = await supplierService.onUpdateRefund(data);
      await dispatch("getAllRefundList", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Huỷ đơn trả hàng thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      commit("SET_UPDATE", false, { root: true });
      commit("SET_LOADING", false);
    }
  },

  async onApprovedRefund({ commit, dispatch }, payload) {
    try {
      const { data, params } = payload;
      const response = await supplierService.onUpdateRefund(data);
      await dispatch("getAllRefundList", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Duyệt đơn trả hàng thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      commit("SET_UPDATE", false, { root: true });
      commit("SET_LOADING", false);
    }
  },

  async onCreateRefund({ commit, dispatch }, payload) {
    try {
      const { data, params } = payload;
      const response = await supplierService.onCreateRefund(data);
      await dispatch("getAllRefundList", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Tạo đơn trả hàng thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async onCreateOrderSupplier({ commit, dispatch }, payload) {
    try {
      const { data, params } = payload;
      const response = await supplierService.onCreateOrderSupplier(data);
      await dispatch("getOrderSupplier", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Tạo đơn hàng thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async orderSupplierDetail({ commit }, id) {
    try {
      commit("SET_LOADING", true);
      const response = await supplierService.orderSupplierDetail(id);
      commit("SET_LOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING", false);
      return {};
    }
  },

  async orderProviderDetail({ commit }, id) {
    try {
      commit("SET_LOADING", true);
      const response = await supplierService.orderProviderDetail(id);
      commit("SET_LOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING", false);
      return {};
    }
  },

  async onUpdateOrderSupplier({ commit, dispatch }, payload) {
    try {
      const { data, params } = payload;
      const response = await supplierService.onUpdateOrderSupplier(data);
      await dispatch("getOrderSupplier", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật đơn hàng thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async onApprovedOrderSupplier({ dispatch, commit }, payload) {
    try {
      const { data, params } = payload;
      const response = await supplierService.onUpdateOrderSupplier(data);
      await dispatch("getOrderSupplier", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Duyệt đơn hàng thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async onApprovedOrderInSupplier({ dispatch, commit }, payload) {
    try {
      const { data, params } = payload;
      const response = await supplierService.onUpdateOrderInSupplier(data);
      await dispatch("getProviderOrder", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Duyệt đơn hàng thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async onCancelOrderSupplier({ dispatch, commit }, payload) {
    try {
      const { data, params } = payload;
      const response = await supplierService.onUpdateOrderSupplier(data);
      await dispatch("getOrderSupplier", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Huỷ đơn hàng thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async onCancelOrderInSupplier({ dispatch, commit }, payload) {
    try {
      const { data, params } = payload;
      const response = await supplierService.onUpdateOrderInSupplier(data);
      await dispatch("getProviderOrder", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Huỷ đơn hàng thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async onDeleteOrder({ commit, dispatch }, payload) {
    try {
      const { data, params } = payload;
      const response = await supplierService.deleteOrder(data);
      await dispatch("getOrderSupplier", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Xoá đơn hàng thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR_LIST", error.response.data.message, { root: true });
    }
  },

  async onDeleteQuotation({ commit, dispatch }, payload) {
    try {
      const { data, params } = payload;
      const response = await supplierService.deleteQuotations(data);
      await dispatch("onQuotationsList", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Xoá báo giá thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      commit("SET_LOADING_QUOTATION", false);
      commit("SET_ERROR_LIST", error.response.data.message, { root: true });
    }
  },

  async onDeleteQuotationSupplier({ commit, dispatch }, payload) {
    try {
      const { data, params } = payload;
      const response = await supplierService.deleteQuotationsSupplier(data);
      await dispatch("onQuotationsSupplierList", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Xoá báo giá thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      commit("SET_LOADING_QUOTATION", false);
      // commit("SET_ERROR_LIST", error.response.data.message, { root: true });
    }
  },

  async onQuotationsUpdate({ commit, dispatch }, payload) {
    try {
      const { data, params } = payload;
      const response = await supplierService.quotationsUpdate(data);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật báo giá thành công",
          isShow: true,
        },
        { root: true }
      );
      await dispatch("onQuotationsList", params);
    } catch (error) {
      commit("SET_UPDATE", false, { root: true });
      commit("SET_LOADING_QUOTATION", false);
    }
  },

  async onQuotationsUpdateSupplier({ commit, dispatch }, payload) {
    try {
      const { data, params } = payload;
      const response = await supplierService.quotationsUpdateSupplier(data);
      if (data.status === 1) {
        await commit(
          "SET_MESSAGE",
          {
            type: "success",
            content: "Đã gửi báo giá thành công tới quản lý Ruộng bậc thang",
            isShow: true,
          },
          { root: true }
        );
      } else {
        await commit(
          "SET_MESSAGE",
          {
            type: "success",
            content: "Cập nhật báo giá thành công",
            isShow: true,
          },
          { root: true }
        );
      }
      await dispatch("onQuotationsSupplierList", params);
    } catch (error) {
      commit("SET_LOADING_QUOTATION", false);
    }
  },

  async onQuotationsList({ commit }, params) {
    try {
      commit("SET_LOADING_QUOTATION", true);
      const response = await supplierService.getQuotationList(params);
      const { results, count } = response.data.data;
      commit("SET_LIST_QUOTATION", results);
      commit("SET_TOTAL_RECORD_QUOTATION", count);
      commit("SET_LOADING_QUOTATION", false);
    } catch (error) {
      commit("SET_LOADING_QUOTATION", false);
    }
  },

  async onQuotationsSupplierList({ commit }, params) {
    try {
      commit("SET_LOADING_QUOTATION", true);
      const response = await supplierService.getQuotationSupplierList(params);
      const { results, count } = response.data.data;
      commit("SET_LIST_QUOTATION", results);
      commit("SET_TOTAL_RECORD_QUOTATION", count);
      commit("SET_LOADING_QUOTATION", false);
    } catch (error) {
      commit("SET_LOADING_QUOTATION", false);
    }
  },

  async getQuotationDetail({ commit }, id) {
    try {
      commit("SET_LOADING_QUOTATION", true);
      const response = await supplierService.getQuotationDetail(id);
      commit("SET_LOADING_QUOTATION", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING_QUOTATION", false);
      return {};
    }
  },

  async getQuotationGroupByProduct({ commit }, id) {
    try {
      commit("SET_LOADING_QUOTATION", true);
      const response = await supplierService.getQuotationGroupByProduct(id);
      commit("SET_LOADING_QUOTATION", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING_QUOTATION", false);
      return {};
    }
  },

  async getQuotationDetailSupplier({ commit }, id) {
    try {
      commit("SET_LOADING_QUOTATION", true);
      const response = await supplierService.getQuotationDetailSupplier(id);
      commit("SET_LOADING_QUOTATION", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING_QUOTATION", false);
      return {};
    }
  },

  async getOrderSupplier({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await supplierService.getAllOrderSupplier(params);
      const { results, count } = response.data.data;
      commit("SET_LIST_ORDER", results);
      commit("SET_TOTAL_RECORD_ORDER", count);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async onQuotationsSupplier({ commit, dispatch }, payload) {
    try {
      const { data, params } = payload;
      const response = await supplierService.quotationsSubmit(data);
      await dispatch("onQuotationsSupplierList", params);
      if (data.status === 1) {
        await commit(
          "SET_MESSAGE",
          {
            type: "success",
            content: "Đã gửi báo giá thành công tới quản lý Ruộng bậc thang",
            isShow: true,
          },
          { root: true }
        );
      } else {
        await commit(
          "SET_MESSAGE",
          {
            type: "success",
            content: "Tạo báo giá thành công",
            isShow: true,
          },
          { root: true }
        );
      }
      return response;
    } catch (error) {
      commit("SET_LOADING_QUOTATION", false);
      commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async getSupplierList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await supplierService.getAll(params);
      const { results, count } = response.data.data;
      commit("SET_LIST_SUPPLIER", results);
      commit("SET_TOTAL_RECORD", count);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getSupplierDetail({ commit }, id) {
    try {
      commit("SET_LOADING", true);
      const response = await supplierService.getItem(id);
      commit("SET_LOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING", false);
      return {};
    }
  },

  async createSupplier({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await supplierService.create(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Thêm nhà cung cấp thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async updateSupplier({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await supplierService.update(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async deleteSupplier({ commit, dispatch }, payload) {
    try {
      const { id, params } = payload;
      await commit("SET_LOADING", true);
      const response = await supplierService.delete(id);
      await dispatch("getSupplierList", params);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Xoá thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async getProductWithSupplier({ commit }, params) {
    try {
      const response = await supplierService.getProducts(params);
      const { results } = response.data.data;
      commit("SET_LIST_PRODUCT", results);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getProviderOrder({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await supplierService.getAllOrderProvider(params);
      const { results, count } = response.data.data;
      commit("SET_LIST_PROVIDER_ORDER", results);
      commit("SET_TOTAL_RECORD_PROVIDER_ORDER", count);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },
};
const mutations = {
  SET_LIST_SUPPLIER(state, payload) {
    state.supplierList = payload;
  },
  SET_LIST_PRODUCT(state, payload) {
    state.products = payload;
  },
  SET_TOTAL_RECORD(state, payload) {
    state.totalRecord = payload;
  },
  SET_LIST_ORDER(state, payload) {
    state.ordersSupplier = payload;
  },
  SET_LIST_PROVIDER_ORDER(state, payload) {
    state.providerOrder = payload;
  },
  SET_TOTAL_RECORD_ORDER(state, payload) {
    state.totalRecordOrder = payload;
  },
  SET_LIST_QUOTATION(state, payload) {
    state.quotationsList = payload;
  },
  SET_TOTAL_RECORD_QUOTATION(state, payload) {
    state.totalRecordQuotation = payload;
  },
  SET_LIST_REFUND(state, payload) {
    state.refundList = payload;
  },
  SET_TOTAL_RECORD_REFUND(state, payload) {
    state.totalRefunds = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_LOADING_QUOTATION(state, payload) {
    state.isLoadingQuotation = payload;
  },
  SET_TOTAL_RECORD_PROVIDER_ORDER(state, payload) {
    state.totalProviderOrder = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export const ORDER_RETURN_STATUS = {
  ADMIN: {
    0: "Chờ duyệt",
    1: "Đã duyệt",
    2: "NCC đã kiểm tra",
    3: "Đã hủy",
  },
  SUPPLIER: {
    0: "Chờ duyệt",
    1: "RBT Đã duyệt",
    2: "Đã kiểm tra",
    3: "Đã hủy",
  },
  APPEND_COLOR: {
    SUPPLIER: {
      0: "warning",
      1: "primary",
      2: "success",
      3: "danger",
    },
    ADMIN: {
      0: "warning",
      1: "success",
      2: "primary",
      3: "danger",
    },
  },
};

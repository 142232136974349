<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Thiết lập chung" title="Thiết lập cửa hàng" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header"></div>
            <div class="card-body">
              <div class="row">
                <b-form-group class="datatable-select col-md-6">
                  <b-form-input
                    type="text"
                    v-model="search"
                    placeholder="Tìm kiếm"
                    @change="onSearch()"
                  ></b-form-input>
                </b-form-group>
                <div class="col-md-6">
                  <b-button
                    class="btn-popup"
                    variant="primary"
                    @click="onSearch()"
                    >Tìm</b-button
                  >
                  <router-link to="/thiet-lap/thiet-lap-cua-hang/them-cua-hang">
                    <b-button class="btn-popup pull-right"
                      >Thêm mới</b-button
                    ></router-link
                  >
                </div>
              </div>
              <div class="table-responsive datatable-vue product-list">
                <b-table
                  v-if="!isLoading && storeList.length > 0"
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="storeList"
                  :fields="tablefields"
                >
                  <template v-slot:cell(name)="{ item }">
                    <span v-if="item.name">
                      {{ item.name }}
                    </span>
                  </template>
                  <template v-slot:cell(address)="{ item }">
                    <span>
                      {{ item.address }}
                      <span v-if="item.district">
                        - {{ item.district.name }}</span
                      >
                      <span v-if="item.city"> - {{ item.city.name }}</span>
                    </span>
                  </template>
                  <template v-slot:cell(phone_number)="{ item }">
                    <div v-if="item.phone_number">
                      <span> {{ item.phone_number }} </span>
                    </div>
                  </template>
                  <template v-slot:cell(manager)="{ item }">
                    <div v-if="item.manager">
                      <span> {{ item.manager.name }} </span>
                    </div>
                  </template>
                  <template v-slot:cell(status)="{ item }">
                    <b-badge v-if="item.status" pill color="primary"
                      >Đang hoạt động</b-badge
                    >
                    <b-badge v-else pill variant="danger"
                      >Không hoạt động</b-badge
                    >
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="onEdit(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="!isLoading && storeList.length === 0"
                >
                  Không có dữ liệu
                </div>
              </div>
              <!-- <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRecord > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecord"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";

export default {
  data() {
    return {
      tablefields: [
        { key: "name", label: "Tên cửa hàng" },
        { key: "address", label: "Địa chỉ" },
        { key: "phone_number", label: "Hotline" },
        { key: "status", label: "Trạng thái" },
        { key: "actions", label: "Hành động" },
      ],
      search: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
    };
  },
  components: {
    Loader,
  },
  created() {
    this.getStoreList();
  },
  computed: {
    ...mapState("store", ["storeList", "isLoading"]),
  },
  methods: {
    ...mapActions("store", ["getStoreList"]),
    onEdit(item) {
      this.$router.push({
        name: "create-store",
        query: { id: item.id },
      });
    },
    onSearch() {
      const params = {
        search: this.search.trim(),
      };
      this.getStoreList(params);
    },
  },
};
</script>

import axiosInstance from "../../services/axios";

const state = {
  products: [],
};
const getters = {
  getProducts: (state) => {
    return state.products;
  },
};
const actions = {
  getProducts: (context) => {
    const url = "digitalProduct.json";
    axiosInstance
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        context.commit("getProducts", response.data);
      })
      .catch((error) => {});
  },
};
const mutations = {
  getProducts: (state, payload) => {
    state.products = payload.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

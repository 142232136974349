<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Trả hàng từ RBT" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>{{ totalRefunds }} kết quả</h5>
            </div>
            <div class="card-body">
              <div class="row mb-4">
                <div class="col-sm-12 d-flex align-items-center flex-wrap">
                  <date-picker
                    v-model="date"
                    value-type="format"
                    range
                    format="DD-MM-YYYY"
                    placeholder="Chọn ngày để xem"
                    class="mb-2 mr-2"
                    @change="onSearch"
                  ></date-picker>
                  <div class="col-sm-9 pl-0 mb-2 d-flex align-items-center">
                    <b-form-group
                      class="datatable-select col-sm-6 pl-0 pr-0 mb-0"
                    >
                      <b-form-input
                        type="text"
                        v-model="query"
                        placeholder="Tìm kiếm theo tên phiếu"
                        @change="onSearch()"
                      ></b-form-input>
                    </b-form-group>
                    <div class="ml-3">
                      <b-button
                        class="btn-popup mb-0"
                        variant="primary"
                        @click="onSearch()"
                        >Tìm</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-responsive datatable-vue standard-list">
                <b-table
                  v-if="!isLoading"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  :items="warehouseImportActive"
                  :fields="tablefields"
                >
                  <template v-slot:cell(created_by)="{ item }">
                    <div v-if="item.created_by">
                      {{ item.created_by.name }}
                    </div>
                  </template>
                  <template v-slot:cell(status)="{ item }">
                    <div class="d-flex justify-content-center text-center">
                      <b-badge :variant="colorStatus[item.status]">
                        {{ ORDER_RETURN_STATUS_SUPPLIER[item.status] }}
                      </b-badge>
                    </div>
                  </template>
                  <template v-slot:cell(created_at)="{ item }">
                    <div class="number-and-date-table" v-if="item.created_at">
                      {{ item.created_at | showDate }}
                    </div>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="onEdit(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div
                  class="text-center mt-4"
                  v-if="!isLoading && refundList.length <= 0"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRefunds > 0"
                  v-model="pageNumber"
                  :total-rows="totalRefunds"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
              <OrderRefundSupplier
                :refundInfo="refundInfo"
                @onUpdate="onUpdate"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import OrderRefundSupplier from "../../components/modals/OrderRefundSupplier.vue";
import { ORDER_RETURN_STATUS } from "../../config/orders-return/index";
import { reverseDate } from "../../config/common";
import { Roles } from "../../types/roles";

export default {
  components: {
    OrderRefundSupplier,
    Loader,
  },
  data() {
    return {
      tablefields: [
        { key: "code", label: "Mã phiếu trả" },
        { key: "name", label: "Tên phiếu trả" },
        { key: "created_by", label: "Người tạo" },
        { key: "reason", label: "Lý do" },
        { key: "created_at", label: "Thời gian tạo" },
        { key: "status", label: "Trạng thái" },
        { key: "actions", label: "Hành động" },
      ],
      query: "",
      date: "",
      supplier: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      isId: "",
      isName: "",
      refundInfo: {},
    };
  },
  async created() {
    if (!this.userProfile) {
      await this.getUserProfile();
    }
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = {
      page: pageNumber,
      page_size: this.pageSize,
      supplier_id: this.userProfile.id,
    };
    if (this.userProfile.groups[0].name !== this.roleList.supplier) {
      delete params.supplier_id;
      await this.getAllRefundList(params);
    } else {
      await this.getAllRefundList(params);
    }
  },
  computed: {
    ...mapState("supplier", ["refundList", "totalRefunds", "isLoading"]),
    ...mapState("profile", ["userProfile"]),
    colorStatus() {
      return ORDER_RETURN_STATUS.APPEND_COLOR.SUPPLIER;
    },
    ORDER_RETURN_STATUS_SUPPLIER() {
      return ORDER_RETURN_STATUS.SUPPLIER;
    },
    warehouseImportActive() {
      return this.refundList?.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
    roleList() {
      return Roles;
    },
  },
  watch: {},
  methods: {
    ...mapActions("supplier", [
      "getAllRefundList",
      "onUpdateRefund",
      "getRefundDetail",
    ]),
    ...mapActions("product", ["onListProduct"]),
    ...mapActions("profile", ["getUserProfile"]),
    onUpdate(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onUpdateRefund({ data: payload, params });
      this.date = "";
      (this.query = ""), (this.supplier = "");
    },
    async onEdit(item) {
      const { id, supplier } = item;
      await this.onListProduct({ supplier_id: supplier?.id });
      this.refundInfo = await this.getRefundDetail(id);
      this.$bvModal.show("detail-order-refund");
    },
    onSearch() {
      let params = {
        page: 1,
        page_size: this.pageSize,
        supplier_id: this.userProfile.id,
      };
      if (this.date[0] && this.date[1]) {
        params = {
          start: reverseDate(this.date[0]),
          end: reverseDate(this.date[1]),
          ...params,
        };
      }
      if (this.query) {
        params = { name: this.query, ...params };
      }
      if (this.supplier) {
        params = {
          supplier_id: this.supplier,
          ...params,
        };
      }
      this.getAllRefundList(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/provider/order-refund",
          query: { page: this.pageNumber },
        });
      }
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = {
        page: this.pageNumber,
        page_size: this.pageSize,
        supplier_id: this.userProfile.id,
      };
      if (this.date[0] && this.date[1]) {
        params = {
          start: reverseDate(this.date[0]),
          end: reverseDate(this.date[1]),
          ...params,
        };
      }
      if (this.query) {
        params = { name: this.query, ...params };
      }
      if (this.supplier) {
        params = {
          supplier_id: this.supplier,
          ...params,
        };
      }
      this.getAllRefundList(params);
      this.$router.push({
        path: "/provider/order-refund",
        query: { page: this.pageNumber, page_size: this.pageSize },
      });
    },
  },
};
</script>

<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <form class="form-horizontal auth-form" @submit.stop.prevent="login()">
      <validation-provider
        name="Tên đăng nhập"
        :rules="{ required: true }"
        v-slot="validationContext"
      >
        <b-form-group>
          <b-form-input
            id="name-input"
            name="name-input"
            v-model="username"
            placeholder="Nhập tên đăng nhập"
            :state="getValidationState(validationContext)"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">{{
            validationContext.errors[0]
          }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider
        name="Mật khẩu"
        :rules="{ required: true }"
        v-slot="validationContext"
      >
        <b-form-group>
					<b-input-group>
          <b-form-input
						v-if="showPassword"
            id="password-input"
            name="password-input"
            v-model="password"
            placeholder="Mật khẩu"
            type="text"
            :state="getValidationState(validationContext)"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
					<b-form-input
						v-else="showPassword"
						id="password-input"
						name="password-input"
						v-model="password"
						placeholder="Mật khẩu"
						type="password"
						:state="getValidationState(validationContext)"
						aria-describedby="input-1-live-feedback"
					></b-form-input>
					<b-input-group-append>
						<b-button @click="toggleShow" variant="outline-secondary"><span class="icon is-small is-right">
							<i class="fa" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
						</span></b-button>
					</b-input-group-append>
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            validationContext.errors[0]
          }}</b-form-invalid-feedback>
					</b-input-group>
        </b-form-group>
      </validation-provider>
      <!-- <div class="form-terms">
      <div class="custom-control custom-checkbox mr-sm-2">
        <a href="#" class="btn btn-default forgot-pass">lost your password</a>
      </div>
    </div> -->
      <div class="form-button">
        <button
          class="btn btn-primary"
          type="submit"
          :disabled="!isComplete || invalid"
        >
          Đăng nhập
        </button>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      type: "password",
      username: "",
      password: "",
      submitted: false,
			showPassword: false,
    };
  },
  created() {
    // reset login status for JWT
    this.$store.dispatch("authentication/logout");
  },
  computed: {
    ...mapState("authentication", ["errorList"]),
    isComplete() {
      return this.username && this.password;
    },
  },
  watch: {
    errorList() {
      if (this.errorList) {
        this.$toasted.show(this.errorList[Object.keys(this.errorList)[0]], {
          duration: 4000,
          type: "error",
        });
      }
    },
  },
  methods: {
    ...mapActions("authentication", ["signIn"]),
    login() {
      let data = {
        username: this.username,
        password: this.password,
      };
      this.signIn(data);
    },
    onSubmit() {
      this.submitted = true;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated
        ? (valid = valid === true ? null : valid)
        : null;
    },
		toggleShow() {
			this.showPassword = !this.showPassword;
		}
  },
};
</script>

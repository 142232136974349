<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Tạo mới thiết lập giá bán" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header"></div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="d-flex flex-wrap">
                    <input
                      v-model="dataSubmit.name"
                      placeholder="Nhập tên bảng giá"
                      class="form-control mb-2"
                      id="validationCustom1"
                      type="text"
                    />
                  </div>
                  <div class="d-flex flex-wrap">
                    <div
                      class="create-quotation col-sm-12 col-md-6 left-date mb-2"
                    >
                      <date-picker
                        v-model="start_date"
                        value-type="format"
                        format="DD-MM-YYYY"
                        placeholder="Ngày bắt đầu"
                      ></date-picker>
                    </div>
                    <div
                      class="create-quotation col-sm-12 col-md-6 right-date mb-2"
                    >
                      <date-picker
                        v-model="end_date"
                        value-type="format"
                        format="DD-MM-YYYY"
                        placeholder="Ngày kết thúc"
                      ></date-picker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-sm-8 d-flex align-items-center mb-2">
                  <b-form-group
                    class="datatable-select col-md-9 pl-0 pr-2"
                    style="margin-bottom: 0 !important"
                  >
                    <b-form-input
                      class="form-control"
                      type="text"
                      v-model="search"
                      placeholder="Tìm kiếm bằng mã hoặc tên sản phẩm"
                      @change="applyFilterOnTable()"
                    ></b-form-input>
                  </b-form-group>
                  <div class="ml-3">
                    <b-button
                      class="btn-popup mb-0"
                      variant="primary"
                      @click="applyFilterOnTable()"
                      >Lọc</b-button
                    >
                  </div>
                </div>

                <div
                  class="table-responsive datatable-vue standard-list text-center mt-4 ml-3 mr-3"
                >
                  <b-table
                    v-if="!isLoading && filteredTable.length > 0"
                    show-empty
                    striped
                    hover
                    head-variant="light"
                    bordered
                    :items="filteredTable"
                    :fields="tablefields"
                    :per-page="perPage"
                    :current-page="pageNumber"
                  >
                    <template #empty>
                      <span class="text-danger">
                        Không có sản phẩm nào
                      </span>
                    </template>

                    <template v-slot:cell(sno)="{ item }">
                      <div class="number-and-date-table">
                        {{ item.sno }}
                      </div>
                    </template>
                    <template v-slot:cell(name)="{ item }">
                      <div v-if="item.name">
                        {{ item.name }}
                      </div>
                    </template>
                    <template v-slot:cell(import_price)="{ item }">
                      <div class="number-and-date-table">
                        {{
                          item.price.import_price === UNDEFINED_STATUS.SHORTCUT
                            ? UNDEFINED_STATUS.FULLTEXT
                            : item.price.import_price | formatCurrency
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(recommended_price)="{ item }">
                      <div class="number-and-date-table">
                        {{
                          item.price.recommended_price ===
                          UNDEFINED_STATUS.SHORTCUT
                            ? UNDEFINED_STATUS.FULLTEXT
                            : item.price.recommended_price | formatCurrency
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(default_price)="{ item }">
                      <div class="number-and-date-table">
                        {{
                          item.price.default_price === UNDEFINED_STATUS.SHORTCUT
                            ? UNDEFINED_STATUS.FULLTEXT
                            : item.price.default_price | formatCurrency
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(sell_price)="{ item }">
                      <input
                        v-model="item.sell_price"
                        placeholder="-"
                        class="form-control"
                        type="text"
                        @keyup="onFormatPriceRecommended($event, item)"
                      />
                    </template>
                  </b-table>
                  <b-col md="12" class="my-1 p-0 pagination-justify">
                    <b-pagination
                      v-if="filteredTable.length > 0"
                      v-model="pageNumber"
                      :total-rows="filteredTable.length"
                      :per-page="pageSize"
                      aria-controls="my-table"
                      class="mt-4"
                    ></b-pagination>
                  </b-col>
                </div>
              </div>
              <div>
                <div style="width: 100%" class="d-flex justify-content-center">
                  <div>
                    <b-button
                      class="mr-3"
                      size="sm"
                      variant="danger"
                      @click="directListPrice()"
                    >
                      Quay lại
                    </b-button>
                  </div>
                  <div>
                    <b-button
                      :disabled="
                        !listProductActive.length ||
                          !dataSubmit.name ||
                          !start_date ||
                          !end_date
                      "
                      size="sm"
                      class="m-0"
                      @click="submitCreateSellPrice()"
                    >
                      Thiết lập</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import {
  addCommaPrice,
  addCommaPriceNoConnect,
  addDotPrice,
  reverseDate,
} from "../../config/common";
import { SET_PRICE_STATUS } from "../../config/set-price/index";
import { convertVietnameseLetterDiacritic } from "../../utils/convertVietnameseLetterDiacritic";

const initialDataSubmit = {
  product: [],
  name: "",
};

const initialDataTable = {
  product: {},
  unit: "",
  unitPrice: "",
  recommendedPrice: "",
  lowestVol: "0",
  highestVol: "100000",
};

export default {
  props: {},
  components: {},
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "code", label: "Mã sản phẩm" },
        { key: "name", label: "Tên sản phẩm" },
        { key: "unit", label: "Đơn vị tính" },
        { key: "import_price", label: "Giá nhập" },
        { key: "recommended_price", label: "Giá khuyến nghị NCC" },
        { key: "default_price", label: "Giá mặc định" },
        { key: "sell_price", label: "Giá bán" },
      ],
      productQuotes: [],
      filteredTable: [],
      downloadLoading: false,
      dataSubmit: { ...initialDataSubmit },
      dataTable: { ...initialDataTable },
      checkDisable: false,
      oldQuotation: "",
      start_date: "",
      end_date: "",
      search: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
    };
  },
  components: {
    Loader,
  },
  async mounted() {
    if (!this.userProfile) {
      await this.getUserProfile();
    }
    this.onProductSellPrice();
  },
  computed: {
    ...mapState("product", ["isListAllProducts", "isLoading"]),
    ...mapState("profile", ["userProfile"]),
    listProductActive() {
      return this.isListAllProducts.map((item, index) => {
        return {
          sno: index + 1,
          sell_price:
            item.price.price || item.price.price === 0
              ? addCommaPrice(item.price.price)
              : null,
          ...item,
        };
      });
    },
    UNDEFINED_STATUS() {
      return SET_PRICE_STATUS.UNDEFINED_STATUS;
    },
  },
  watch: {
    isListAllProducts() {
      this.applyFilterOnTable();
    },
  },
  methods: {
    ...mapActions("product", ["onProductSellPrice", "onCreatePrice"]),
    ...mapActions(["setMessage"]),
    ...mapActions("profile", ["getUserProfile"]),
    directListPrice() {
      this.$router.push("/settings/list-price");
    },
    onFormatPrice(event) {
      if (event.target.value) {
        this.dataTable.unitPrice = addDotPrice(this.dataTable.unitPrice);
      }
    },
    applyFilterOnTable() {
      this.pageNumber = 1;
      this.filteredTable = this.listProductActive.filter((item) => {
        return (
          convertVietnameseLetterDiacritic(item.code.toLowerCase()).includes(
            convertVietnameseLetterDiacritic(this.search.toLowerCase())
          ) ||
          convertVietnameseLetterDiacritic(item.name.toLowerCase()).includes(
            convertVietnameseLetterDiacritic(this.search.toLowerCase())
          )
        );
      });
    },
    onFormatPriceRecommended(event, item) {
      this.isListAllProducts.forEach((product) => {
        if (item.id === product.id) {
          item.sell_price = addCommaPriceNoConnect(event.target.value);
        }
      });
    },
    async submitCreateSellPrice() {
      if (
        this.listProductActive.length > 0 &&
        this.dataSubmit.name &&
        this.start_date &&
        this.end_date
      ) {
        const productLists = this.listProductActive.map((item) => {
          return {
            product: item.id,
            price: item.sell_price
              ? Number(item.sell_price.replaceAll(",", ""))
              : null,
          };
        });
        const sendData = {
          data: {
            ...this.dataSubmit,
            start_date: reverseDate(this.start_date),
            end_date: reverseDate(this.end_date),
            product: productLists.filter(
              (value) => Object.keys(value).length !== 0
            ),
          },
        };
        // convert date to miliseconds then compare
        const dateStart = new Date(reverseDate(this.start_date));
        const millisecondsStart = dateStart.getTime();
        const dateEnd = new Date(reverseDate(this.end_date));
        const millisecondsEnd = dateEnd.getTime();
        if (millisecondsStart > millisecondsEnd) {
          this.setMessage({
            type: "error",
            content: "Ngày bắt đầu không được lớn hơn ngày kết thúc",
            isShow: true,
          });
          return;
        }
        await this.onCreatePrice(sendData);
        this.directListPrice();
      } else {
        this.setMessage({
          type: "error",
          content:
            "Tên bảng giá, ngày bắt đầu, ngày kết thúc, sản phẩm không được để trống",
          isShow: true,
        });
      }
    },
    onSearch() {
      let params = {
        page: this.pageNumber,
        page_size: this.pageSize,
      };
      if (this.search) {
        params = { search: this.search, ...params };
      }
      this.onProductSellPrice(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/settings/create-set-sell-price",
          query: { page: this.pageNumber },
        });
      }
    },
  },
};
</script>

<style>
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
.create-quotation .mx-datepicker {
  width: 100% !important;
}

.create-quotation .mx-datepicker input::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
</style>

<style scoped>
.rbt-custom-input {
  max-width: 192px;
  white-space: nowrap;
  height: 43px !important;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
input {
  height: unset;
}
</style>

<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <form class="form-horizontal auth-form" @submit.stop.prevent="onRegister()">
      <validation-provider
        name="Tên đăng nhập"
        :rules="{ required: true, regexUserName: /^[a-z0-9@._-]{3,50}$/ }"
        v-slot="validationContext"
      >
        <b-form-group>
          <b-form-input
            id="username-input"
            name="username-input"
            v-model="dataSubmit.username"
            placeholder="Tên đăng nhập"
            :state="getValidationState(validationContext)"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">{{
            validationContext.errors[0]
          }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider
        name="Email"
        :rules="{ required: true, email: true }"
        v-slot="validationContext"
      >
        <b-form-group>
          <b-form-input
            id="password"
            name="password"
            v-model="dataSubmit.email"
            placeholder="Email"
            :state="getValidationState(validationContext)"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">{{
            validationContext.errors[0]
          }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <ValidationProvider
        :rules="{ required: true, min: 6 }"
        name="Mật khẩu mới"
        vid="password"
      >
        <b-form-group slot-scope="{ valid, errors }">
					<b-input-group>
          <b-form-input
						v-if="showPassword"
            type="text"
            v-model="dataSubmit.password"
            :state="errors[0] ? false : valid ? true : null"
            validateOnInput
            placeholder="********"
          ></b-form-input>

					<b-form-input
						v-else="showPassword"
						type="password"
						v-model="dataSubmit.password"
						:state="errors[0] ? false : valid ? true : null"
						validateOnInput
						placeholder="********"
					></b-form-input>

					<b-input-group-append>
						<b-button @click="toggleShow" variant="outline-secondary"><span class="icon is-small is-right">
							<i class="fa" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
						</span></b-button>
					</b-input-group-append>
					
          <b-form-invalid-feedback>
            {{ errors[0] }}
          </b-form-invalid-feedback>
					</b-input-group>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
        rules="required|confirmed:password"
        name="Mật khẩu nhập lại"
      >
        <b-form-group
          slot-scope="{ valid, errors }"
          label-for="example-input-3"
        >
					<b-input-group>
          <b-form-input
						v-if="showPassword"
            type="text"
            v-model="dataSubmit.rePassword"
            placeholder="********"
            :state="errors[0] ? false : valid ? true : null"
          >
          </b-form-input>

					<b-form-input
						v-else="showPassword"
						type="password"
						v-model="dataSubmit.rePassword"
						placeholder="********"
						:state="errors[0] ? false : valid ? true : null"
					>
					</b-form-input>

					<b-input-group-append>
						<b-button @click="toggleShow" variant="outline-secondary"><span class="icon is-small is-right">
							<i class="fa" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
						</span></b-button>
					</b-input-group-append>

          <b-form-invalid-feedback>
            {{ errors[0] }}
          </b-form-invalid-feedback>
					</b-input-group>
        </b-form-group>
      </ValidationProvider>
      <div class="form-button">
        <button class="btn btn-primary" type="submit" :disabled="invalid">
          Đăng kí
        </button>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      dataSubmit: {
        email: null,
        password: null,
        username: null,
        rePassword: null,
        type: "SHIPPER",
      },
			showPassword: false,
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  created() {},
  computed: {
    ...mapState(["isMessage", "errorList"]),
  },
  watch: {
    errorList() {
      if (this?.errorList?.status === 400) {
        const dataError = this.errorList?.data?.message
          ? this?.errorList?.data?.message
          : this?.errorList?.data;
        switch (Object.keys(dataError)[0]) {
          case "non_field_errors":
            this.$toasted.show(dataError[Object.keys(dataError)], {
              duration: 4000,
              type: "error",
            });
            break;
          case typeof dataError === "string":
            this.$toasted.show(dataError, {
              duration: 4000,
              type: "error",
            });
            break;
          default:
            for (const [key, value] of Object.entries(dataError)) {
              this.$toasted.show(`${value}`, {
                duration: 4000,
                type: "error",
              });
            }
        }
      }
    },
    isMessage() {
      if (this.isMessage.isShow) {
        this.$toasted.show(`${this.isMessage.content}`, {
          type: this.isMessage.type,
        });
        setTimeout(() => {
          this.setMessage({
            type: "",
            isShow: false,
            content: "",
          });
        }, 4000);
      }
    },
  },
  methods: {
    ...mapActions("authentication", ["signUp"]),
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated
        ? (valid = valid === true ? null : valid)
        : null;
    },
    onRegister() {
      this.signUp(this.dataSubmit);
    },
		toggleShow() {
			this.showPassword = !this.showPassword;
		}
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loader',{attrs:{"is-visible":_vm.isLoading}}),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"page-header"},[_c('Breadcrumbs',{attrs:{"main":"Quản lý hàng hóa","title":"Thiết lập giảm giá"}})],1)]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h5',[_vm._v(_vm._s(_vm.totalRecord)+" kết quả")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-sm-8 d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"col-sm-12 d-flex"},[_c('b-form-group',{staticClass:"datatable-select",staticStyle:{"margin-bottom":"0 !important"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Tìm kiếm"},on:{"change":function($event){return _vm.onSearch()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"ml-3"},[_c('b-button',{staticClass:"btn-popup mb-0",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.onSearch()}}},[_vm._v("Tìm")])],1)],1)]),_c('div',{staticClass:"col-sm-4 d-flex justify-content-end"},[_c('b-button',{staticClass:"btn-popup m-0",staticStyle:{"font-size":"14px"},on:{"click":function($event){return _vm.openModalImport()}}},[_c('span',{staticStyle:{"font-size":"20px","font-weight":"100"}},[_vm._v(" + ")]),_vm._v(" Tạo giá sản phẩm")])],1)]),_c('div',{staticClass:"table-responsive datatable-vue product-list"},[(!_vm.isLoading && _vm.promotions.length > 0)?_c('b-table',{staticClass:"text-center",attrs:{"striped":"","hover":"","head-variant":"light","bordered":"","stacked":"md","items":_vm.priceActive,"fields":_vm.tablefields},scopedSlots:_vm._u([{key:"cell(start_date)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.start_date))+" ")])]}},{key:"cell(end_date)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.end_date))+" ")])]}},{key:"cell(is_active)",fn:function(ref){
var item = ref.item;
return [(!item.is_active)?_c('b-badge',{attrs:{"variant":"warning"}},[_vm._v(" Chưa áp dụng ")]):_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(" Đang áp dụng ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"20px","color":"blue"}},[_c('feather',{staticStyle:{"cursor":"pointer"},attrs:{"type":"edit-2","stroke":"#3758FD","stroke-width":"1","size":"18px","fill":"#3758FD","stroke-linejoin":"round"},on:{"click":function($event){return _vm.onEdit(item)}}})],1)]}}],null,false,155705276)}):_vm._e(),(!_vm.isLoading && _vm.promotions.length === 0)?_c('div',{staticClass:"text-center"},[_vm._v(" Không có dữ liệu ")]):_vm._e()],1),_c('b-col',{staticClass:"my-1 p-0 pagination-justify",attrs:{"md":"12"}},[(_vm.totalRecord > 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRecord,"per-page":_vm.pageSize,"aria-controls":"my-table"},on:{"change":_vm.handlePageChange},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}}):_vm._e()],1),_c('PriceAdd',{attrs:{"priceInfo":_vm.priceInfo},on:{"onSubmit":_vm.onSubmitImport,"onUpdate":_vm.onUpdateImport}})],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
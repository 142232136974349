<template>
  <div>
    <b-modal size="lg" id="detail-order-refund" centered>
      <template #modal-header>
        <h3 class="mx-auto" style="color: #ff8084">
          Trả hàng nhà cung cấp
        </h3>
      </template>
      <validation-provider>
        <b-form-group
          label-cols-md="3"
          label-cols-lg="3"
          content-cols-sm
          content-cols-md="9"
        >
          <template v-slot:label>
            Tên phiếu trả hàng<span class="validate-star"> *</span></template
          >
          <b-form-input
            :disabled="dataSubmit.status >= 1"
            v-model="dataSubmit.name"
            placeholder="Nhập tên phiếu trả hàng"
          ></b-form-input>
        </b-form-group>
      </validation-provider>
      <div>
        <div
          class="table-responsive datatable-vue standard-list text-center mt-4"
        >
          <b-modal
            id="model-remove"
            title="Xác nhận"
            @ok="removeProductInTab()"
          >
            <p class="my-4">Are you sure!</p>
          </b-modal>
          <b-table
            show-empty
            striped
            hover
            head-variant="light"
            bordered
            :items="itemsImport"
            :fields="computedFields"
            :per-page="perPage"
          >
            <template #empty>
              <span class="text-danger">
                Chưa có sản phẩm nào
              </span>
            </template>
            <template v-slot:cell(name)="{ item }">
              <span> {{ item.product.name }} </span>
            </template>
            <template v-slot:cell(quantity)="{ item }">
              <div>
                <span>
                  {{ item.quantity || 0 }}
                </span>
              </div>
            </template>
            <template v-slot:cell(price)="{ item }">
              <span>
                {{ item.price | formatCurrency }}
                <span v-if="item.product.import_unit"
                  >/{{ item.product.import_unit }}</span
                >
              </span>
            </template>
            <template v-slot:cell(totalPrice)="{ item }">
              <span>
                {{
                  Math.trunc(
                    item.quantity * Number(item.price.replace(/,/g, ""))
                  ) | formatCurrency
                }}
              </span>
            </template>
          </b-table>
        </div>
        <div>
          <textarea
            v-model="dataSubmit.reason"
            disabled
            rows="2"
            placeholder="Lý do trả hàng"
            class="form-control"
          ></textarea>
        </div>
        <div class="media-field mt-4">
          <div
            v-if="!dataSubmit.reason_media"
            class="set-full-height"
            style="cursor: pointer"
          >
            <label
              class="d-flex justify-content-center align-items-center set-full-height"
              >Không có ảnh/video đính kèm</label
            >
          </div>
          <video
            id="videoUpload"
            width="100%"
            height="100%"
            controls
            :src="dataSubmit.reason_media"
            style="display: block"
            v-show="
              dataSubmit.reason_media && dataSubmit.reason_media_type === 1
            "
          ></video>
          <img
            :src="dataSubmit.reason_media"
            width="100%"
            height="100%"
            fluid
            style="object-fit: cover;"
            class="d-flex justify-content-center"
            rounded
            alt="img"
            v-if="dataSubmit.reason_media && dataSubmit.reason_media_type === 0"
            @click="handlerPreviewImg(dataSubmit.reason_media)"
          />
        </div>
        <div class="mt-3">
          <p>
            Tổng tiền:
            <b v-if="totalPrice">
              {{ totalPrice | formatCurrency }}
            </b>
            <b v-else>
              0 đ
            </b>
          </p>
        </div>
      </div>
      <template #modal-footer="{cancel}">
        <div
          style="width: 100%"
          class="d-flex justify-content-between align-items-center flex-wrap"
        >
          <div>
            <h2>
              <span v-if="dataSubmit.status === 3" class="badge badge-danger"
                >Đã huỷ</span
              >
            </h2>
            <h2>
              <span v-if="dataSubmit.status === 1" class="badge badge-primary"
                >Đã duyệt</span
              >
            </h2>
            <h2>
              <span v-if="dataSubmit.status === 2" class="badge badge-success"
                >Đã kiểm tra</span
              >
            </h2>
          </div>
          <div>
            <b-button class="mr-3" size="sm" variant="danger" @click="cancel()">
              Đóng
            </b-button>
            <b-button
              v-if="dataSubmit.status === 1"
              class="btn-popup mr-3 my-0"
              @click="onCheckSubmit"
            >
              Xác nhận kiểm tra</b-button
            >
          </div>
        </div>
      </template>
    </b-modal>
    <PreviewImage
      v-if="showPreviewPopup"
      :items="listImgPreview"
      :handler-close-popup="handlerClosePopup"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { addCommaPrice } from "../../config/common";
import { WAREHOUSE_STATUS } from "../../config/warehouse/index";
import PreviewImage from "../../components/modals/PreviewImage.vue";

const initalDataImport = {
  price: "",
  quantity: null,
  product: null,
  unit: "",
};

const initalDataSubmit = {
  product: [],
  name: "Trả hàng nhà cung cấp",
  total: null,
  reason: "",
  supplier: null,
  status: null,
  payment_status: 8, //default payment status is PAID
};

export default {
  props: {
    refundInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    PreviewImage,
  },
  name: "WarehouseImport",
  data() {
    return {
      perPage: process.env.VUE_APP_PAGE_SIZE,
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "name", label: "Sản phẩm" },
        { key: "quantity", label: "Số lượng" },
        { key: "price", label: "Đơn giá" },
        { key: "totalPrice", label: "Thành tiền" },
      ],
      productRefund: [],
      dataImport: { ...initalDataImport },
      dataSubmit: { ...initalDataSubmit },
      checkDisable: false,
      showPreviewPopup: false,
      listImgPreview: [],
    };
  },
  created() {
    this.getUnitList();
  },
  methods: {
    ...mapActions("product", ["onListProduct"]),
    ...mapActions("unit", ["getUnitList"]),
    ...mapActions(["setMessage"]),
    handlerPreviewImg(files) {
      if (!files) return;
      this.listImgPreview = [{ path: files }];
      this.showPreviewPopup = true;
    },
    handlerClosePopup() {
      this.showPreviewPopup = false;
    },
    onCheckSubmit() {
      this.$emit("onUpdate", {
        id: this.dataSubmit.id,
        status: WAREHOUSE_STATUS.TEXT.APPROVED,
      });
      this.$bvModal.hide("detail-order-refund");
    },
  },
  computed: {
    ...mapState("product", ["isListProduct", "isLoading"]),
    ...mapState("unit", ["unitList"]),
    totalPrice() {
      let total = 0;
      if (this.dataSubmit?.product?.length > 0) {
        this.dataSubmit?.product?.forEach((item) => {
          total += item.quantity * Number(item.price.replace(/,/g, ""));
        });
      }
      return total;
    },
    itemsImport() {
      return this.dataSubmit.product?.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
    computedFields() {
      if (this.dataSubmit.status != 2 && this.dataSubmit.status != 3)
        return this.tablefields;
      else return this.tablefields.filter((field) => !field.require);
    },
  },
  watch: {
    refundInfo() {
      this.checkDisable = false;
      this.dataImport = { ...initalDataImport };
      if (this.refundInfo.id) {
        const { product, ...data } = this.refundInfo;
        const productConvertPrice = product?.map((item) => {
          const { price, ...info } = item;
          return {
            price: addCommaPrice(price),
            ...info,
          };
        });
        this.dataSubmit = { product: productConvertPrice, ...data };
      } else {
        this.dataSubmit = { ...initalDataSubmit };
        this.dataSubmit.product = [];
      }
    },
  },
};
</script>

<style>
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
</style>

<style scoped>
.rbt-custom-input {
  max-width: 192px;
  white-space: nowrap;
  height: 43px !important;
}
.media-field {
  width: 100%;
  height: 200px;
  border: dashed 2px gray;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}
.set-full-height {
  height: 100%;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
input {
  height: 100% !important;
}
</style>

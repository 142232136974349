<template>
  <div>
    <b-modal id="model-cancel-import" title="Xác nhận" @ok="cancelImport()">
      <p class="my-4" v-if="dataSubmit">
        Bạn có muốn hủy đơn hàng <strong>{{ dataSubmit.name }}</strong>
      </p>
    </b-modal>
    <b-modal size="xl" id="detail-order-supplier" centered>
      <template #modal-header>
        <h3 class="mx-auto" style="color: #ff8084">
          CHI TIẾT ĐƠN HÀNG
        </h3>
      </template>
      <div>
        <div class="row">
          <div class="col-12 col-md-6 d-flex align-items-center">
            <span class="mr-3"
              ><span class="validate-star">* </span>Tên đơn hàng</span
            >
            <input
              v-model="dataSubmit.name"
              :disabled="!!dataSubmit.id"
              class="form-control rbt-custom-input"
              placeholder="Nhập tên đơn hàng"
              type="text"
            />
          </div>
        </div>
        <div class="row mt-3">
          <div
            class="col-12 col-md-6 d-flex align-items-center mt-3"
            v-if="dataSubmit.quotation"
          >
            <span class="mr-3"
              ><span class="validate-star">* </span>Lấy từ báo giá</span
            >
            <input
              disabled
              v-model="dataSubmit.quotation.name"
              class="form-control rbt-custom-input"
              type="text"
            />
            <div class="d-flex align-items-center">
              <b-spinner
                v-if="isLoadingQuotation"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </div>
          </div>
          <div class="col-12 col-md-6 d-flex align-items-center mt-3">
            <span class="mr-3">Thời gian giao hàng</span>
            <b-form-datepicker
              label-today
              class="form-control rbt-custom-date"
              label-help
              disabled
              id="datepicker-placeholder"
              placeholder="Chọn thời gian"
              style="font-size: 14px"
              locale="vi"
              v-model="dataSubmit.recieve_time"
            ></b-form-datepicker>
          </div>
        </div>
        <hr />
      </div>
      <div>
        <div
          class="table-responsive datatable-vue standard-list text-center mt-4"
        >
          <b-table
            show-empty
            striped
            hover
            head-variant="light"
            bordered
            :items="productInTable"
            :fields="dataSubmit.id ? tablefieldUpdate : tablefields"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template #empty>
              <span class="text-danger">
                Chưa có sản phẩm nào
              </span>
            </template>
            <template v-slot:cell(name)="{ item }">
              <span> {{ item.name }} </span>
            </template>
            <template v-slot:cell(price)="{ item }">
              <span>
                {{ item.price | formatCurrency }}
                <span v-if="item.import_unit">/{{ item.import_unit }}</span>
              </span>
            </template>
            <template v-slot:cell(price_recommend)="{ item }">
              <span>
                {{ item.price_recommend | formatCurrency }}
              </span>
            </template>
            <template v-slot:cell(totalPrice)="{ item }">
              <span>
                {{ totalPriceTable(item.sno) | formatCurrency }}
              </span>
            </template>
            <template v-slot:cell(quoted_quantity)="{ item }">
              <span>
                {{ item.quoted_quantity }}
              </span>
            </template>
            <template v-slot:cell(actions)="{ item }">
              <div style="cursor: pointer;">
                <b-form-checkbox
                  :disabled="!!dataSubmit.id"
                  v-model="item.check"
                  value="true"
                  unchecked-value="false"
                >
                </b-form-checkbox>
              </div>
            </template>
          </b-table>
        </div>
        <div>
          <b-pagination
            v-if="productInTable.length > 0"
            class="mt-2 d-flex justify-content-end"
            v-model="currentPage"
            :total-rows="productInTable.length"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
        <!-- <div>
          <textarea
            disabled
            v-model="dataSubmit.delivery_method"
            rows="2"
            placeholder="Phương thức giao hàng"
            class="form-control mt-2"
          ></textarea>
        </div> -->
        <div>
          <textarea
            disabled
            v-model="dataSubmit.note"
            rows="2"
            @change="changeNote"
            placeholder="Ghi chú đơn hàng"
            class="form-control mt-2"
          ></textarea>
        </div>
        <div class="mt-3 d-flex">
          <span>Tổng tiền: </span>
          <span class="ml-2" style="color: red">
            {{ allPrice | formatCurrency }}
          </span>
        </div>
      </div>
      <template #modal-footer="{cancel}">
        <div
          style="width: 100%"
          class="d-flex justify-content-between align-items-center flex-wrap"
        >
          <div>
            <h2>
              <span
                v-if="dataSubmit.status === WAREHOUSE_STATUS_TEXT.REJECTED"
                class="badge badge-danger mb-2"
                >Đã huỷ</span
              >
            </h2>
            <h2>
              <span
                v-if="dataSubmit.status === WAREHOUSE_STATUS_TEXT.APPROVED"
                class="badge badge-primary mb-2"
                >RBT đã duyệt</span
              >
            </h2>
          </div>
          <div>
            <b-button
              v-if="dataSubmit.status === WAREHOUSE_STATUS_TEXT.APPROVED"
              type="button"
              class="btn btn-danger mr-3 mb-2"
              @click="openModelCancel()"
            >
              <span>
                Hủy đơn hàng
              </span>
            </b-button>
            <b-button
              class="mr-3 mb-2"
              size="sm"
              variant="danger"
              @click="cancel()"
            >
              Đóng
            </b-button>
            <b-button
              v-if="!dataSubmit.id"
              :disabled="!isComplete"
              class="btn-popup mb-2"
              @click="submit()"
            >
              <span style="font-size: 24px; font-weight: 100">
                +
              </span>
              <span v-if="!dataSubmit.id"> Tạo đơn hàng</span>
            </b-button>
            <b-button
              v-if="dataSubmit.status === WAREHOUSE_STATUS_TEXT.APPROVED"
              class="btn-popup mb-2"
              :disabled="checkDisable"
              @click="approved()"
            >
              Xác nhận kiểm tra</b-button
            >
          </div>
        </div>
      </template>
    </b-modal>
    <OrderSupplierModal :orderInfo="orderInfo" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { ORDER_SUPPLIER_STATUS } from "../../config/warehouse/index";
import OrderSupplierModal from "../../components/modals/order/OrderSupplierModal.vue";

const initialDataSubmit = {
  product: [],
  name: "",
  note: null,
  recieve_time: new Date().toISOString().slice(0, 10),
  total: 0,
  pre_payment: 0,
  remain_payment: 0,
  status: null,
  quotation: null,
  supplier: null,
  payment_status: null,
};

export default {
  components: {
    OrderSupplierModal,
  },
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  name: "WarehouseImport",
  data() {
    return {
      currentPage: 1,
      perPage: process.env.VUE_APP_PAGE_SIZE,
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "actions", label: "Chọn" },
        { key: "name", label: "Sản phẩm" },
        { key: "import_unit", label: "Đơn vị nhập" },
        { key: "price", label: "Giá nhập" },
        { key: "price_recommend", label: "Giá khuyến nghị" },
        { key: "quoted_quantity", label: "Số lượng nhập" },
        { key: "totalPrice", label: "Thành tiền" },
      ],
      tablefieldUpdate: [
        { key: "sno", label: "STT" },
        { key: "name", label: "Sản phẩm" },
        { key: "import_unit", label: "Đơn vị nhập" },
        { key: "price", label: "Giá nhập" },
        { key: "price_recommend", label: "Giá khuyến nghị" },
        { key: "quoted_quantity", label: "Số lượng nhập" },
        { key: "totalPrice", label: "Thành tiền" },
      ],
      dataSubmit: { ...initialDataSubmit },
      productInTable: [],
      checkDisable: false,
      WAREHOUSE_STATUS_TEXT: ORDER_SUPPLIER_STATUS.TEXT,
    };
  },
  created() {},
  methods: {
    ...mapActions("product", ["onListProduct"]),
    ...mapActions("supplier", ["onQuotationsList", "getQuotationDetail"]),
    ...mapActions(["setMessage"]),
    submit() {
      const { supplier, quotation } = this.dataSubmit;
      if (!supplier) {
        this.setMessage({
          type: "error",
          content: "Vui lòng chọn nhà cung cấp",
          isShow: true,
        });
        return;
      } else if (!quotation) {
        this.setMessage({
          type: "error",
          content: "Vui lòng chọn báo giá",
          isShow: true,
        });
        return;
      } else if (this.productActive.length == 0) {
        this.setMessage({
          type: "error",
          content: "Báo giá không có sản phẩm, hãy chọn báo giá khác",
          isShow: true,
        });
        return;
      }
    },
    onEmitSubmit({ type }) {
      const {
        supplier,
        quotation,
        product,
        recieve_time,
        status,
        total,
        payment_status,
        created_by,
        ...data
      } = this.dataSubmit;
      if (type === "update") {
        const productActive = this.productActive.map((item) => {
          return {
            price: item.price,
            quantity: item.quantity,
            product: item.id,
            quoted_quantity: item.quoted_quantity,
          };
        });
        const dataUpdate = {
          supplier: supplier.id,
          quotation: quotation.id,
          recieve_time: new Date(recieve_time).toISOString().slice(0, 10),
          product: productActive,
          status: this.WAREHOUSE_STATUS_TEXT.PENDING,
          payment_status: 8, // default payment_status is PAID
          total: this.allPrice,
          created_by: created_by.id,
          ...data,
        };
        this.$emit("onUpdate", dataUpdate);
        this.checkDisable = false;
      }
    },
    totalPriceTable(index) {
      const { price, quantity, quoted_quantity } =
        this.productInTable[index - 1] || {};
      const priceTemp = price || 0;
      const quantityTemp = this.dataSubmit.id
        ? quantity || 0
        : quoted_quantity || 0;
      return quantityTemp === 0 ? "0" : Math.trunc(quantityTemp * priceTemp);
    },
    openModelCancel() {
      this.$bvModal.show("model-cancel-import");
    },
    cancelImport() {
      const dataUpdate = {
        id: this.dataSubmit.id,
        status: this.WAREHOUSE_STATUS_TEXT.REJECTED,
      };
      this.$emit("onCancel", dataUpdate);
      this.$bvModal.hide("detail-order-supplier");
    },
    searchingSelect({ name }) {
      return name;
    },
    update() {
      const {
        supplier,
        quotation,
        product,
        recieve_time,
        status,
        total,
        payment_status,
        ...data
      } = this.dataSubmit;
      if (
        (supplier,
        quotation,
        this.dataSubmit.name,
        this.productActive.length > 0)
      ) {
        this.onEmitSubmit({
          type: "update",
        });
        this.$bvModal.hide("detail-order-supplier");
      } else {
        this.setMessage({
          type: "error",
          content: "Nhập đủ các trường cần thiết",
          isShow: true,
        });
      }
    },
    approved() {
      const dataUpdate = {
        status: this.WAREHOUSE_STATUS_TEXT.APPROVED_2,
        id: this.dataSubmit.id,
      };
      this.$emit("onApproved", dataUpdate);
      this.$bvModal.hide("detail-order-supplier");
    },
    changeNote() {
      this.checkDisable = true;
    },
  },
  computed: {
    ...mapState("product", ["isListProduct"]),
    ...mapState("warehouse", ["warehouseList"]),
    ...mapState("supplier", [
      "supplierList",
      "quotationsList",
      "totalRecordQuotation",
      "isLoadingQuotation",
    ]),
    allPrice() {
      let price = 0;
      this.productActive.forEach((item) => {
        price += this.totalPriceTable(item.sno);
      });
      return price;
    },
    productActive() {
      const results = this.productInTable.filter((item) => {
        return item.check === "true";
      });
      return results;
    },
    isComplete() {
      return this.dataSubmit.name;
    },
  },
  watch: {
    "dataSubmit.supplier"() {
      if (this.dataSubmit.supplier.id && !this.dataSubmit.id) {
        this.dataSubmit.quotation = null;
        this.productInTable = [];
        this.onQuotationsList({ supplier: this.dataSubmit.supplier.id });
      } else if (!this.dataSubmit.supplier.id && !this.dataSubmit.id) {
        this.dataSubmit.quotation = null;
      }
    },
    orderInfo() {
      this.dataSubmit = { ...this.orderInfo };
      this.checkDisable = false;
      if (this.dataSubmit.id) {
        // set product in table
        this.productInTable = this.dataSubmit.products.map((item, index) => {
          return {
            sno: index + 1,
            check: "true",
            price: item.unit_price,
            quoted_quantity: item.quantity,
            price_recommend: item.price_recommend,
            product_unit: item.import_unit,
            product_name: item.name,
            product_id: item.id,
            ...item,
          };
        });
      } else {
        this.dataSubmit = { ...initialDataSubmit };
        this.productInTable = [];
      }
    },
  },
};
</script>

<style>
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
</style>

<style scoped>
.rbt-custom-input {
  max-width: 192px;
  white-space: nowrap;
  height: 43px !important;
}
.rbt-custom-date {
  max-width: 192px;
  white-space: nowrap;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
input {
  height: 100% !important;
}
</style>

<template>
  <div>
    <loader :is-visible="isLoadingImport"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Phiếu nhập hàng nhà cung cấp" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>{{ totalImportlist }} kết quả</h5>
            </div>
            <div class="card-body">
              <div class="row mb-4">
                <div
                  class="col-sm-8 d-flex justify-content-center align-items-center"
                >
                  <b-form-select
                    v-model="supplier"                    
                    class="col-sm-3"
                  >
                    <option value="">Lọc theo tên NCC</option>
                    <option
                      v-for="supplier in supplierList"
                      :key="supplier.index"
                      :value="supplier.id"
                      >{{ supplier.name }}</option
                    >
                  </b-form-select>
                  <date-picker
                    v-model="date"
                    value-type="format"
                    range
                    format="DD-MM-YYYY"
                    placeholder="Lọc theo ngày"
                    class="pl-0 flex-wrap ncc-date-picker mr-2 ml-3"                    
                  ></date-picker>
                  <div class="col-sm-6 d-flex">
                    <b-form-group
                      class="datatable-select"
                      style="margin-bottom: 0 !important"
                    >
                      <b-form-input
                        type="text"
                        v-model="search"
                        placeholder="Lọc theo tên đơn hàng"                        
                      ></b-form-input>
                    </b-form-group>
                    <div class="ml-3">
                      <b-button
                        class="btn-popup mb-0"
                        variant="primary"
                        @click="onSearch()"
                        >Tìm</b-button
                      >
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 d-flex justify-content-end">
                  <b-button class="btn-popup m-0" @click="openModalImport()">
                    <span style="font-size: 24px; font-weight: 100">
                      +
                    </span>
                    Tạo phiếu nhập hàng NCC</b-button
                  >
                </div>
              </div>
              <div class="table-responsive datatable-vue standard-list">
                <b-table
                  v-if="!isLoadingImport && importList.length > 0"
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  :items="importList"
                  :fields="tablefields"
                >
                  <template #empty>
                    <span>
                      Không có dữ liệu
                    </span>
                  </template>
                  <template v-slot:cell(status)="{ item }">
                    <div class="text-center">
                      <b-badge :variant="colorStatus[item.status]">
                        {{ TRANSACTION_STATUS_ADMIN[item.status] }}
                      </b-badge>
                    </div>
                  </template>
                  <template v-slot:cell(supplier)="{ item }">
                    <span v-if="item.supplier">
                      {{ item.supplier.name }}
                    </span>
                  </template>
                  <template v-slot:cell(staff)="{ item }">
                    <span v-if="item.staff">
                      {{ item.staff.name }}
                    </span>
                  </template>
                  <template v-slot:cell(created_at)="{ item }">
                    <div class="number-and-date-table" v-if="item.created_at">
                      {{ item.created_at | dateFormat }}
                    </div>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="handleSupplierReceipt(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div
                  v-if="!isLoadingImport && importList.length === 0"
                  class="text-center"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalImportlist > 0"
                  v-model="pageNumber"
                  :total-rows="totalImportlist"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
              <SupplierReceiptModal
                :warehouseImportInfo="warehouseImportInfo"
                @onCreate="onSubmitImport"
                @onUpdate="handleUpdateImport"
                @onApproved="onApproved"
                @onCancelWarehouseImport="onCancelWarehouseImport"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import SupplierReceiptModal from "../../components/modals/SupplierReceiptModal.vue";
import { TRANSACTION_STATUS } from "../../config/transactions/index";
import { reverseDate } from "../../config/common";

export default {
  components: {
    SupplierReceiptModal,
    Loader,
  },
  data() {
    return {
      tablefields: [
        { key: "code", label: "Mã đơn hàng" },
        { key: "name", label: "Tên đơn hàng" },
        { key: "created_at", label: "Ngày tạo" },
        { key: "staff", label: "Người tạo" },
        { key: "supplier", label: "Nhà cung cấp" },
        { key: "status", label: "Trạng thái" },
        { key: "actions", label: "Hành động" },
      ],
      search: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      isName: "",
      warehouseImportInfo: {},
      date: "",
      supplier: "",
    };
  },
  created() {
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = { page: pageNumber, page_size: this.pageSize };
    this.getImportList(params);    
    this.getSupplierList();
  },
  computed: {
    ...mapState("warehouse", [
      "isLoadingImport",
      "importList",
      "totalImportlist",
    ]),
    ...mapState("supplier", ["supplierList"]),
    colorStatus() {
      return TRANSACTION_STATUS.APPEND_COLOR;
    },
    TRANSACTION_STATUS_ADMIN() {
      return TRANSACTION_STATUS.ADMIN;
    },
  },
  methods: {
    ...mapActions("warehouse", [
      "getImportList",
      "onImport",
      "onUpdateImport",
      "onApprovedImport",
      "onWarehouseImportCancel",
      "importDetail",
    ]),
    ...mapActions("supplier", ["getSupplierList"]),
    onCancelWarehouseImport(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onWarehouseImportCancel({ data: payload, params });
      this.handleClearData();
    },
    onApproved(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onApprovedImport({ data: payload, params });
      this.handleClearData();
    },
    handleUpdateImport(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onUpdateImport({ data: payload, params });
      this.handleClearData();
    },
    onSubmitImport(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onImport({ data: payload, params });
      this.handleClearData();
    },
    openModalImport() {
      this.warehouseImportInfo = {};
      this.$bvModal.show("supplier-receipt-modal");
    },
    handleClearData() {
      this.search = "";
      this.date = "";
      this.supplier = "";
    },
    async handleSupplierReceipt(item) {
      const { id } = item;
      this.warehouseImportInfo = await this.importDetail(id);
      this.$bvModal.show("supplier-receipt-modal");
    },
    onSearch() {
      let params = {
        page: 1,
        page_size: this.pageSize,
      };
      if (this.date[0] && this.date[1]) {
        params = {
          date_start: reverseDate(this.date[0]),
          date_end: reverseDate(this.date[1]),
          ...params,
        };
      }
      if (this.supplier) {
        params = {
          supplier: this.supplier,
          ...params,
        };
      }
      if (this.search) {
        params = { search: this.search, ...params };
      }
      this.getImportList(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/supplier-transaction/import-list",
          query: { page: this.pageNumber },
        });
      }
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.search) {
        params = {
          search: this.search.trim(),
          ...params,
        };
      }
      if (this.supplier) {
        params = {
          supplier: this.supplier,
          ...params,
        };
      }
      if (this.date[0] && this.date[1]) {
        params = {
          date_start: reverseDate(this.date[0]),
          date_end: reverseDate(this.date[1]),
          ...params,
        };
      }
      this.getImportList(params);
      this.$router.push({
        path: "/supplier-transaction/import-list",
        query: { page: this.pageNumber },
      });
    },
  },
};
</script>

export const FOOD_STATUSES = {
  STATUS: {
    0: "Mới tạo",
    1: "Đã duyệt",
    2: "Hủy bỏ",
  },
  APPEND_COLOR: {
    0: "warning",
    1: "success",
    2: "danger",
  },
};

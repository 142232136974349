<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Danh sách phiếu thu/chi" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>{{ totalRecord }} kết quả</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-9 d-flex">
                  <date-picker
                    class="col-md-4 mt-1"
                    v-model="date"
                    value-type="format"
                    range
                    format="DD-MM-YYYY"
                    placeholder="Chọn ngày để xem"
                    @change="onSearch"
                  ></date-picker>
                  <div class="col-md-7 d-flex">
                    <multiselect
                      placeholder="Người giao dịch"
                      @input="onSearch"
                      v-model="user"
                      :show-labels="false"
                      @search-change="searchUser"
                      :internal-search="false"
                      :options="userList"
                      class="rbt-custom-input mr-3"
                    >
                      <span slot="noResult">Không tìm thấy kết quả!!!</span>
                      <template slot="singleLabel" slot-scope="props"
                        ><span class="option__desc"
                          ><span class="option__title">{{
                            props.option.name
                          }}</span></span
                        ></template
                      >
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <p class="option__title">
                            {{ props.option.name }}
                          </p>
                        </div>
                      </template>
                    </multiselect>

                    <b-form-select
                      v-model="type"
                      @change="onSearch()"
                      class="col-md-4"
                    >
                      <option value="income_ballot">Phiếu thu</option>
                      <option value="spending_ballot">Phiếu chi</option>
                    </b-form-select>

                    <b-form-select
                      v-model="method"
                      @change="onSearch()"
                      class="col-md-4 ml-3"
                    >
                      <option value="">Hình thức</option>
                      <option value="cash">Tiền mặt</option>
                      <option value="transfer">Chuyển khoản</option>
                    </b-form-select>

                    <div class="ml-3">
                      <b-button
                        class="btn-popup mb-0"
                        variant="primary"
                        @click="onSearch()"
                        >Tìm</b-button
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <router-link to="/settings/create-transaction-receipt">
                    <b-button class="btn-popup pull-right"
                      >Thêm mới</b-button
                    ></router-link
                  >
                </div>
              </div>
              <div class="col-md-5 mb-3">
                <b>Tổng số tiền:</b>
                {{ totalMoneyTransaction | formatCurrency }}
              </div>
              <div class="table-responsive datatable-vue product-list">
                <b-modal id="modal-2" title="Xác nhận" @ok="onDelete(isId)">
                  <p class="my-4">
                    Xóa <b>{{ isName }}</b>
                  </p>
                </b-modal>
                <b-table
                  v-if="transactionReceiptList.length > 0"
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="transactionReceiptList"
                  :fields="tablefields"
                >
                  <template v-slot:cell(transaction_time)="{ item }">
                    <span>
                      {{ item.transaction_time | showDate }}
                    </span>
                  </template>
                  <template v-slot:cell(type)="{ item }">
                    <span v-if="dataType[item.type]">
                      {{ dataType[item.type] }}
                    </span>
                  </template>
                  <template v-slot:cell(method)="{ item }">
                    <span v-if="dataMethod[item.method]">
                      {{ dataMethod[item.method] }}
                    </span>
                  </template>
                  <template v-slot:cell(transaction_person)="{ item }">
                    <span v-if="item.transaction_person">
                      {{ item.transaction_person.name }}
                    </span>
                  </template>
                  <template v-slot:cell(employee)="{ item }">
                    <span v-if="item.employee">
                      {{ item.employee.name }}
                    </span>
                  </template>
                  <template v-slot:cell(money_transcation)="{ item }">
                    <span>
                      {{ item.money_transcation | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(status)="{ item }">
                    <b-badge :variant="colorStatus[item.status]">
                      {{ statusTransactionReceipt[item.status] }}
                    </b-badge>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="onEdit(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="transactionReceiptList.length === 0"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRecord > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecord"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import { TRANSACTION_STATUS } from "../../config/transactions/index";
import TypeTransactionReceipt from "../../mocks/typeTransactionReceipt.json";
import MethodTransactionReceipt from "../../mocks/methodTransactionReceipt.json";
import { reverseDate } from "../../config/common";
import { debounce } from "lodash";

export default {
  data() {
    const dataType = TypeTransactionReceipt;
    const dataMethod = MethodTransactionReceipt;
    return {
      tablefields: [
        { key: "code", label: "Mã phiếu" },
        { key: "transaction_person", label: "Người giao dịch" },
        { key: "employee", label: "Nhân viên thực hiện" },
        { key: "money_transcation", label: "Số tiền" },
        { key: "type", label: "Loại" },
        { key: "method", label: "Hình thức" },
        { key: "status", label: "Trạng thái" },
        { key: "transaction_time", label: "Ngày giao dịch" },
        { key: "actions", label: "Hành động" },
      ],
      isId: "",
      isName: "",
      search: "",
      pageNumber: 1,
      user: "",
      date: "",
      type: "income_ballot",
      method: "",
      dataType: dataType,
      dataMethod: dataMethod,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
    };
  },
  components: {
    Loader,
  },
  created() {
    const pageNumber = this.$route.query.page || 1;
    const dateNow = new Date().toISOString().slice(0, 10);
    const params = {
      page: pageNumber,
      page_size: this.pageSize,
      type: this.type,
      transaction_time_from: dateNow,
      transaction_time_to: dateNow,
    };
    const reverseDateInit = reverseDate(dateNow);
    this.date = [reverseDateInit, reverseDateInit];
    this.getTransactionReceiptList(params);
    this.getUserList();
  },
  computed: {
    ...mapState("transactionReceipt", [
      "transactionReceiptList",
      "totalRecord",
      "isLoading",
      "totalMoneyTransaction",
    ]),
    ...mapState("user", ["userList"]),
    statusTransactionReceipt() {
      return TRANSACTION_STATUS.RECEIPT_TEXT;
    },
    colorStatus() {
      return TRANSACTION_STATUS.APPEND_COLOR_TEXT;
    },
  },
  watch: {},
  methods: {
    ...mapActions("transactionReceipt", [
      "getTransactionReceiptList",
      "deleteTransactionReceipt",
    ]),
    ...mapActions("user", ["getUserList"]),
    onDelete(id) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.deleteTransactionReceipt({ id: id, params });
      this.search = "";
    },
    setItem(item) {
      this.isId = item.id;
      this.isName = item.code;
    },
    onEdit(item) {
      this.$router.push({
        name: "create-transaction-receipt",
        query: { id: item.id },
      });
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.user) {
        const { id } = this.user;
        params = {
          transaction_person: id,
          ...params,
        };
      }
      if (this.date[0] && this.date[1]) {
        params = {
          transaction_time_from: reverseDate(this.date[0]),
          transaction_time_to: reverseDate(this.date[1]),
          ...params,
        };
      }
      if (this.type) {
        params = {
          type: this.type,
          ...params,
        };
      }
      if (this.method) {
        params = {
          method: this.method,
          ...params,
        };
      }
      this.getTransactionReceiptList(params);
      this.$router.push({
        path: "/settings/transaction-receipt",
        query: { page: this.pageNumber },
      });
    },
    onSearch(user) {
      let params = {
        page: 1,
        page_size: this.pageSize,
      };
      if (user) {
        const { id } = user;
        params = {
          transaction_person: id,
          ...params,
        };
      }
      if (this.date[0] && this.date[1]) {
        params = {
          transaction_time_from: reverseDate(this.date[0]),
          transaction_time_to: reverseDate(this.date[1]),
          ...params,
        };
      }
      if (this.type) {
        params = {
          type: this.type,
          ...params,
        };
      }
      if (this.method) {
        params = {
          method: this.method,
          ...params,
        };
      }
      this.getTransactionReceiptList(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/settings/transaction-receipt",
          query: { page: this.pageNumber },
        });
      }
    },
    searchUser: _.debounce(function(query) {
      this.getUserList({ query: query.trim() });
    }, 250),
  },
};
</script>

import { apiRoutesEnum } from "../enums/routes";
import axiosInstance from "./axios";

class PromotionServices {
  async getPromotions(params) {
    return axiosInstance.get(apiRoutesEnum.promotion, {
      params: params,
    });
  }
  async getDetailPromotion(id) {
    return axiosInstance.get(`${apiRoutesEnum.promotion}/${id}`);
  }
  async createPromotion(payload) {
    return axiosInstance.post(apiRoutesEnum.promotion, payload);
  }
  async updatePromotion(payload) {
    const { id, ...data } = payload;
    return axiosInstance.patch(`${apiRoutesEnum.promotion}/${id}`, {
      ...data,
    });
  }
}

export default new PromotionServices();

import notifyService from "../../services/notify";
import platform from "platform-detect";

const state = {
  notifyList: [],
  totalRecord: 0,
  isLoading: false,
};
const getters = {};
const actions = {
  async getNotifyList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await notifyService.getAll(params);
      const { results, count } = response.data.data;
      commit("SET_LIST_NOTIFY", results);
      commit("SET_TOTAL_RECORD", count);
      commit("SET_LOADING", false);
      return response;
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getNotifyDetail({ commit }, id) {
    try {
      commit("SET_LOADING", true);
      const response = await notifyService.getItem(id);
      commit("SET_LOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING", false);
      return {};
    }
  },

  async readNotify({ commit, dispatch }, id) {
    try {
      const response = await notifyService.readNotify(id);
      await dispatch("getNotifyList");
    } catch (error) {
      commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async readAllNotify({ commit, dispatch }) {
    try {
      const response = await notifyService.readAllNotify();
      await dispatch("getNotifyDetail");
    } catch (error) {
      commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async addRegistrationDevice({ commit }, payload) {
    try {
      const response = await notifyService.addRegistrationDevice(payload);
    } catch (error) {
      commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
};
const mutations = {
  SET_LIST_NOTIFY(state, payload) {
    state.notifyList = payload;
  },
  SET_TOTAL_RECORD(state, payload) {
    state.totalRecord = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div>
    <b-modal
      size="xl"
      :no-close-on-backdrop="true"
      id="detail-import-transaction"
      centered
    >
      <template #modal-header>
        <h3 class="mx-auto" style="color: #ff8084">
          Chi tiết phiếu nhập hàng
        </h3>
      </template>
      <validation-provider
        name="Tên phiếu nhập"
        :rules="{ required: true }"
        v-slot="validationContext"
      >
        <b-form-group
          label-cols-md="2"
          label-cols-lg="2"
          content-cols-sm
          content-cols-md="10"
        >
          <template v-slot:label>
            Tên phiếu nhập<span class="validate-star"> *</span></template
          >
          <b-form-input
            disabled
            v-model="dataSubmit.name"
            placeholder="Nhập tên phiếu nhập"
            :state="getValidationState(validationContext)"
          ></b-form-input>
        </b-form-group>
      </validation-provider>
      <validation-provider
        name="Đơn hàng nhà cung cấp"
        :rules="{ required: false }"
        v-slot="validationContext"
      >
        <b-form-group
          label-cols-md="2"
          label-cols-lg="2"
          content-cols-sm
          content-cols-md="10"
        >
          <template v-slot:label> Đơn hàng nhà cung cấp</template>
          <multiselect
            disabled
            v-model="dataSubmit.order_supplier"
            class="rbt-custom-input"
            style="max-width: 100% !important"
            placeholder="Đơn hàng nhà cung cấp"
            :show-labels="false"
            :internal-search="false"
            :options="ordersSupplier"
          >
            <span slot="noOptions">
              Không có đơn hàng nào!
            </span>
            <span slot="noResult">Không tìm thấy kết quả!!!</span>
            <template slot="singleLabel" slot-scope="props"
              ><span class="option__desc"
                ><span class="option__title">{{
                  props.option.name
                }}</span></span
              ></template
            >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <p class="option__title">
                  {{ props.option.name }}
                </p>
              </div>
            </template>
          </multiselect>
          <b-form-invalid-feedback>{{
            validationContext.errors[0]
          }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <div>
        <div class="table-responsive datatable-vue text-center mt-4">
          <b-table
            show-empty
            striped
            hover
            head-variant="light"
            bordered
            :items="itemsImport"
            :fields="computedFields"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template #empty>
              <span class="text-danger">
                Chưa có sản phẩm nào
              </span>
            </template>
            <template v-slot:cell(name)="{ item }">
              <span v-if="item.product.name">{{ item.product.name }} </span>
            </template>
            <template v-slot:cell(unit)="{ item }">
              <span v-if="item.product.import_unit"
                >{{ item.product.import_unit }}
              </span>
            </template>
            <template v-slot:cell(quantity)="{ item }">
              <span>
                {{ item.quantity }}
              </span>
            </template>
          </b-table>
          <div>
            <b-pagination
              v-if="itemsImport.length > 0"
              class="mt-2 d-flex justify-content-end"
              v-model="currentPage"
              :total-rows="itemsImport.length"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
      <template #modal-footer="{cancel}">
        <div style="width: 100%" class="d-flex justify-content-end">
          <div>
            <b-button
              class="mr-3"
              size="sm"
              variant="danger"
              @click="
                cancel();
                checkDisable = false;
                dataSubmit.products_in_goods_receipt.splice(
                  0,
                  dataSubmit.products_in_goods_receipt.length
                );
              "
            >
              Đóng
            </b-button>
            <b-button
              v-if="dataSubmit.status === 1"
              :disabled="checkDisable"
              class="btn-popup m-0"
              @click="approveImportTransaction()"
            >
              Duyệt</b-button
            >
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { WAREHOUSE_STATUS } from "../../config/warehouse/index";
import IncreaseAndDecrease from "../../components/UI/increaseAndDecrease.vue";
import { extend } from "vee-validate";

extend("greaterThanZero", (value) => {
  if (value > 0) return true;
  return "Số lượng phải lớn hơn 0";
});

const initalDataImport = {
  product: null,
  quantity: null,
  price: null,
};

const initalDataSubmit = {
  products_in_goods_receipt: [],
  name: "",
  note: "",
  order_supplier: null,
  supplier_id: null,
};

export default {
  props: {
    warehouseImportInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    IncreaseAndDecrease,
  },
  name: "WarehouseImport",
  data() {
    return {
      currentPage: 1,
      perPage: process.env.VUE_APP_PAGE_SIZE,
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "name", label: "Tên hàng" },
        { key: "unit", label: "Đơn vị tính" },
        { key: "quantity", label: "Số lượng" },
      ],
      dataImport: { ...initalDataImport },
      dataSubmit: { ...initalDataSubmit },
      checkDisable: false,
      orderSupplierImport: null,
    };
  },
  async created() {
    this.onListProduct();
    this.getUnitList();
  },
  methods: {
    ...mapActions("product", ["onListProduct"]),
    ...mapActions("unit", ["getUnitList"]),
    ...mapActions("supplier", ["getOrderSupplier"]),
    approveImportTransaction() {
      const { products_in_goods_receipt, order_supplier } = this.dataSubmit;
      const productsId = products_in_goods_receipt?.map((item) => {
        const { price, quantity } = item;
        return {
          product: item.product.id,
          price,
          quantity: parseFloat(quantity),
        };
      });
      this.$emit("onApproved", {
        products_in_goods_receipt: productsId,
        id: this.dataSubmit.id,
        order_supplier: order_supplier?.id,
        status: WAREHOUSE_STATUS.TEXT.APPROVED,
      });
      this.$bvModal.hide("detail-import-transaction");
    },
    getValidationState({ validated, valid = null }) {
      valid = valid ? null : valid;
      return validated ? valid : null;
    },
  },
  computed: {
    ...mapState("product", ["isListProduct"]),
    ...mapState("unit", ["unitList"]),
    ...mapState("supplier", ["ordersSupplier"]),
    ...mapState("supplier", ["supplierList"]),
    itemsImport() {
      return this.dataSubmit?.products_in_goods_receipt?.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
    computedFields() {
      if (
        this.dataSubmit.status != 2 &&
        this.dataSubmit.status != 3 &&
        !this.dataSubmit.order_supplier
      )
        return this.tablefields;
      else return this.tablefields.filter((field) => !field.require);
    },
  },
  watch: {
    warehouseImportInfo() {
      if (this.warehouseImportInfo.id) {
        this.dataSubmit = this.warehouseImportInfo;
      } else {
        this.dataSubmit = { ...initalDataSubmit };
      }
    },
  },
};
</script>

<style>
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
.validate-star {
  color: red;
}
</style>

<style scoped>
.rbt-custom-input {
  max-width: 192px;
  white-space: nowrap;
  height: 43px !important;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
input {
  height: 100% !important;
}
</style>

<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Danh sách đơn hàng" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>{{ totalProviderOrder }} kết quả</h5>
            </div>
            <div class="card-body">
              <div class="row mb-4">
                <div class="col-sm-12 d-flex align-items-center flex-wrap">
                  <b-form-group class="datatable-select col-sm-4 pl-0 mb-2">
                    <b-form-input
                      class="form-control"
                      type="text"
                      v-model="query"
                      placeholder="Tìm kiếm theo mã hoặc tên"
                      @change="onSearch()"
                    ></b-form-input>
                  </b-form-group>
                  <div style="width: 440px" class="d-flex pl-0 ncc-date-picker">
                    <div class="col-sm-12 col-md-6 order-date pl-0 mb-2">
                      <date-picker
                        v-model="dateFrom"
                        value-type="format"
                        format="DD-MM-YYYY"
                        placeholder="Từ ngày"
                      ></date-picker>
                    </div>
                    <div class="col-sm-12 col-md-6 order-date pl-0 mb-2">
                      <date-picker
                        v-model="dateTo"
                        value-type="format"
                        format="DD-MM-YYYY"
                        placeholder="Đến ngày"
                      ></date-picker>
                    </div>
                  </div>
                  <div class="col-sm-2 pl-0 mb-2">
                    <b-button
                      class="btn-popup mb-0"
                      variant="primary"
                      @click="onSearch()"
                      >Tìm</b-button
                    >
                  </div>
                </div>
              </div>
              <div class="table-responsive datatable-vue standard-list">
                <b-table
                  v-if="!isLoading && providerOrder.length > 0"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  :items="providerOrder"
                  :fields="tablefields"
                >
                  <template v-slot:cell(created_at)="{ item }">
                    <div class="number-and-date-table" v-if="item.created_at">
                      {{ item.created_at | dateFormat }}
                    </div>
                  </template>
                  <template v-slot:cell(status)="{ item }">
                    <div class="d-flex justify-content-center text-center">
                      <b-badge
                        style="margin-right: 6px; height: 18px"
                        :variant="colorStatus[item.status]"
                      >
                        {{ ORDER_STATUS_SUPPLIER[item.status] }}
                      </b-badge>
                    </div>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <div class="d-flex justify-content-center text-center">
                      <span style="font-size: 12px; height: 12px; color: blue;">
                        <feather
                          style="cursor: pointer"
                          type="edit-2"
                          stroke="#3758FD"
                          stroke-width="1"
                          size="18px"
                          fill="#3758FD"
                          stroke-linejoin="round"
                          @click="onEdit(item)"
                        ></feather>
                      </span>
                    </div>
                  </template>
                  <template v-slot:cell(quotation_code)="{ item }">
                    <span v-if="item.quotation">
                      {{ item.quotation.code }}
                    </span>
                  </template>
                  <template v-slot:cell(created_by_name)="{ item }">
                    <span v-if="item.created_by_name">
                      {{ item.created_by_name }}
                    </span>
                  </template>
                  <template v-slot:cell(recieve_time)="{ item }">
                    <div class="number-and-date-table" v-if="item.recieve_time">
                      {{ item.recieve_time | dateFormat }}
                    </div>
                  </template>
                </b-table>
                <div
                  v-if="!isLoading && providerOrder.length === 0"
                  class="text-center"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalProviderOrder > 0"
                  v-model="pageNumber"
                  :total-rows="totalProviderOrder"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
              <OrderSupplierModalSupplier
                :orderInfo="orderInfo"
                @onUpdate="onUpdate"
                @onApproved="onApproved"
                @onCancel="onCancel"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import OrderSupplierModalSupplier from "../../components/modals/OrderSupplierModalSupplier.vue";
import { ORDER_SUPPLIER_STATUS } from "../../config/warehouse/index";
import { reverseDate } from "../../config/common";
import { Roles } from "../../types/roles";

export default {
  components: {
    OrderSupplierModalSupplier,
    Loader,
  },
  data() {
    return {
      tablefields: [
        { key: "code", label: "Mã đơn hàng" },
        { key: "name", label: "Tên Đơn hàng" },
        { key: "created_at", label: "Ngày tạo" },
        { key: "quotation_code", label: "Số báo giá áp dụng" },
        { key: "created_by_name", label: "Người tạo đơn" },
        { key: "recieve_time", label: "Thời gian giao hàng" },
        { key: "status", label: "Trạng thái" },
        { key: "actions", label: "Hành động" },
      ],
      query: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      isId: "",
      isName: "",
      orderInfo: {},
      dateFrom: "",
      dateTo: "",
    };
  },
  async created() {
    if (!this.userProfile) {
      await this.getUserProfile();
    }
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = {
      page: pageNumber,
      page_size: this.pageSize,
      supplier: this.userProfile.id,
    };

    // check if this user is a supplier

    this.getProviderOrder(params);
  },
  computed: {
    ...mapState("supplier", [
      "providerOrder",
      "isLoading",
      "totalProviderOrder",
      "isLoadingQuotation",
    ]),
    ...mapState("profile", ["userProfile"]),
    colorStatus() {
      return ORDER_SUPPLIER_STATUS.APPEND_COLOR;
    },
    ORDER_STATUS_SUPPLIER() {
      return ORDER_SUPPLIER_STATUS.ADMIN;
    },
    roleList() {
      return Roles;
    },
  },
  watch: {
    dateFrom: _.debounce(function() {
      if (!this.dateTo && !this.dateFrom) {
        this.onSearch();
      }
    }, 600),
    dateTo: _.debounce(function() {
      if (!this.dateFrom && !this.dateTo) {
        this.onSearch();
      }
    }, 600),
  },
  methods: {
    ...mapActions("supplier", [
      "getProviderOrder",
      "orderProviderDetail",
      "onApprovedOrderInSupplier",
      "onCancelOrderInSupplier",
      "onUpdateOrderSupplier",
    ]),
    ...mapActions("profile", ["getUserProfile"]),
    openModalImport() {
      this.orderInfo = {};
      this.$bvModal.show("detail-order-supplier");
    },
    onUpdate(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onUpdateOrderSupplier({ data: payload, params });
      this.dateFrom = "";
      this.dateTo = "";
      this.query = "";
    },
    onCancel(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onCancelOrderInSupplier({ data: payload, params });
      this.dateFrom = "";
      this.dateTo = "";
      this.query = "";
    },
    onApproved(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onApprovedOrderInSupplier({ data: payload, params });
      this.dateFrom = "";
      this.dateTo = "";
      this.query = "";
    },
    setItem(item) {
      this.isId = item.id;
      this.isName = item.name;
    },
    async onEdit(item) {
      this.orderInfo = await this.orderProviderDetail(item.id);
      this.$bvModal.show("detail-order-supplier");
    },
    onSearch() {
      let params = {
        page: 1,
        page_size: this.pageSize,
        supplier: this.userProfile.id,
      };
      if (this.dateFrom && this.dateTo) {
        params = {
          date_start: reverseDate(this.dateFrom),
          date_end: reverseDate(this.dateTo),
          ...params,
        };
      }
      if (this.query) {
        params = { search: this.query, ...params };
      }
      this.getProviderOrder(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/provider/list-order",
          query: { page: this.pageNumber },
        });
      }
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = {
        page: this.pageNumber,
        page_size: this.pageSize,
        supplier: this.userProfile.id,
      };
      if (this.dateFrom && this.dateTo) {
        params = {
          date_start: reverseDate(this.dateFrom),
          date_end: reverseDate(this.dateTo),
          ...params,
        };
      }
      if (this.query) {
        params = { search: this.query, ...params };
      }
      this.getProviderOrder(params);
      this.$router.push({
        path: "/provider/list-order",
        query: { page: this.pageNumber },
      });
    },
  },
};
</script>

<style>
.ncc-date-picker .mx-input-wrapper,
.ncc-date-picker .mx-input-wrapper input {
  height: 38px !important;
}
.order-date .mx-datepicker {
  width: 100% !important;
}
</style>

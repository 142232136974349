<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Thiết lập chung" title="Bảng Giá" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>{{ totalRecordPrice }} sản phẩm</h5>
            </div>
            <div class="card-body">
              <div class="row mb-4">
                <div class="col-sm-6 d-flex align-items-center mb-2">
                  <b-form-select v-model="currentStore" :disabled="storeID">
                    <option
                      v-for="store in storeList"
                      :key="store.index"
                      :value="store.id"
                      >{{ store.name }}</option
                    >
                  </b-form-select>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-sm-6 d-flex align-items-center mb-2">
                  <b-form-input
                    class="form-control"
                    type="text"
                    v-model="query"
                    placeholder="Tìm kiếm sản phẩm"
                    @input="onSearch()"
                  ></b-form-input>
                </div>
              </div>

              <div class="table-responsive datatable-vue standard-list">
                <b-table
                  v-if="!isLoading && totalRecordPrice > 0"
                  striped
                  hover
                  head-variant="light"
                  :select-mode="selectMode"
                  bordered
                  :items="priceListActive"
                  :fields="tablefields"
                  ref="selectableTable"
                  :selectable="true"
                  @row-selected="rowSelected"
                >
                  <template v-slot:cell(sno)="{ item }">
                    <div class="text-center">
                      {{ item.sno }}
                    </div>
                  </template>
                  <template v-slot:cell(name)="{ item }">
                    <div class="text-left">
                      {{ item.name }}
                    </div>
                  </template>
                  <template v-slot:cell(import_price)="{ item }">
                    <ul
                      v-for="priceDetail in item.price_detail.supplier_price"
                      :key="priceDetail.index"
                    >
                      <li class="d-flex align-items-center">
                        {{ priceDetail.supplier_name }} :
                        {{ priceDetail.price | formatCurrency }}
                      </li>
                    </ul>
                  </template>
                  <template v-slot:cell(recommended_price)="{ item }">
                    <ul
                      v-for="priceDetail in item.price_detail.supplier_price"
                      :key="priceDetail.index"
                    >
                      <li class="d-flex align-items-center">
                        {{ priceDetail.supplier_name }} :
                        {{ priceDetail.recommended_price | formatCurrency }}
                      </li>
                    </ul>
                  </template>
                  <template v-slot:cell(price)="{ item }">
                    <ul
                      v-for="priceDetail in item.price_detail.supplier_price"
                      :key="priceDetail.index"
                    >
                      <li class="d-flex align-items-center">
                        {{ priceDetail.supplier_name }} :
                        {{ priceDetail.price + (priceDetail.price * (item.margin_percent / 100)) | formatCurrency }}
                      </li>
                    </ul>
                  </template>
                  <template
                    v-slot:cell(store_price)="{
                      item,
                      field: { key },
                    }"
                  >
                    <div
                      v-for="salePrice in item.price_detail.store_price"
                      :key="salePrice.index"
                    >
                      <div
                        style="width: 120px"
                        v-if="salePrice.store_id === currentStore"
                      >
                        <span v-if="edit != item.id">
                          {{ salePrice.price | formatCurrency }}
                        </span>
                      </div>
                    </div>
                    <template v-if="edit === item.id">
                      <b-form-input
                        style="width: 120px"
                        type="number"
                        autofocus
                        v-model="item[key]"
                        @blur="saveRow(item)"
                        @keyup.enter="handleEnter(item)"
                      />
                    </template>
                  </template>
                </b-table>
                <div
                  v-if="!isLoading && totalRecordPrice === 0"
                  class="text-center"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRecordPrice > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecordPrice"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  components: {},
  data() {
    return {
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "name", label: "Sản phẩm" },
        { key: "unit", label: "Đơn vị tính" },
        { key: "recommended_price", label: "Giá khuyến nghị NCC" },
        { key: "price", label: "Giá mặc định" },
        { key: "store_price", label: "Giá bán" },
      ],
      query: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      edit: null,
      selectMode: "single",
      currentStore: null,
    };
  },
  async mounted() {
    this.pageNumber = this.$route.query.page || 1;
    this.query = this.$route.query.search || "";
    const params = {
      page: this.pageNumber,
      page_size: this.pageSize,
      ...(this.query && { search: this.query.trim() }),
    };
    await this.onPriceList(params);
    await this.getStoreList();
    // auto select fist store
    if (this.storeList.length > 0) {
      this.currentStore = this.storeID ? this.storeID : this.storeList[0].id;
    }

		if (!this.storeID) {
			this.tablefields = [
				...this.tablefields.slice(0, 3),
				{ key: "import_price", label: "Giá nhập" },
				{ key: "recommended_price", label: "Giá khuyến nghị NCC" },
				{ key: "price", label: "Giá mặc định" },
				{ key: "store_price", label: "Giá bán" },
			]
		}
  },
  computed: {
    ...mapState("product", ["listPrice", "isLoading", "totalRecordPrice"]),
    ...mapState("store", ["storeList"]),
    ...mapState("profile", ["userProfile"]),
    priceListActive() {
      return this.listPrice?.map((item, index) => {
        return {
          sno: this.pageNumber * this.pageSize - this.pageSize + index + 1,
          ...item,
        };
      });
    },
    storeID() {
      return this.userProfile?.store?.id;
    },
		allowedToEditPrice() {
			const productPricePermission = this.userProfile?.groups[0]?.group_data?.find(
				(permission) => permission.name == 'productprice'
			)
			return productPricePermission['permissions'].includes(3);
		}
  },
  watch: {},
  methods: {
    ...mapActions("product", ["onPriceList", "onCreatePrice"]),
    ...mapActions("store", ["getStoreList"]),
    onSearch: _.debounce(function() {
      this.pageNumber = 1;
      const params = {
        page: this.pageNumber,
        page_size: this.pageSize,
        search: this.query.trim(),
      };
      this.onPriceList(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          name: "list-price",
          query: { ...params },
        });
      }
    }, 250),
    handlePageChange(value) {
      this.pageNumber = value;
      let params = {
        page: this.pageNumber,
        page_size: this.pageSize,
        ...(this.query && { search: this.query.trim() }),
      };
      this.onPriceList(params);
      delete params.page_size;
      this.$router.push({
        name: "list-price",
        query: { ...params },
      });
    },
    rowSelected(item) {
      if (item[0] && this.allowedToEditPrice) {
        this.edit = this.edit !== item[0].id ? item[0].id : null;
      }
    },
    handlePrice(item) {
      const { id, store_price } = item;
      if (store_price) {
        this.onCreatePrice({
          data: {
            product: id,
            price: store_price,
            store: this.currentStore,
          },
          params: {
            page: this.pageNumber,
            page_size: this.pageSize,
            ...(this.query && { search: this.query.trim() }),
          },
        });
      }
    },
    saveRow(item) {
      this.handlePrice(item);
    },
    handleEnter(item) {
      const { id } = item;
      this.handlePrice(item);
      const currentIndex = this.listPrice.findIndex((i) => i.id === id);
      if (currentIndex < this.listPrice.length - 1) {
        const nextIndex = currentIndex + 1;
        this.$refs.selectableTable.selectRow(nextIndex);
      } else {
        this.edit = null;
      }
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loader',{attrs:{"is-visible":_vm.isLoading}}),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"page-header"},[_c('Breadcrumbs',{attrs:{"main":"Thiết lập chung","title":"Thiết lập cửa hàng"}})],1)]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"}),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"datatable-select col-md-6"},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Tìm kiếm"},on:{"change":function($event){return _vm.onSearch()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"col-md-6"},[_c('b-button',{staticClass:"btn-popup",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.onSearch()}}},[_vm._v("Tìm")]),_c('router-link',{attrs:{"to":"/thiet-lap/thiet-lap-cua-hang/them-cua-hang"}},[_c('b-button',{staticClass:"btn-popup pull-right"},[_vm._v("Thêm mới")])],1)],1)],1),_c('div',{staticClass:"table-responsive datatable-vue product-list"},[(!_vm.isLoading && _vm.storeList.length > 0)?_c('b-table',{staticClass:"text-center",attrs:{"striped":"","hover":"","head-variant":"light","bordered":"","stacked":"md","items":_vm.storeList,"fields":_vm.tablefields},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()]}},{key:"cell(address)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.address)+" "),(item.district)?_c('span',[_vm._v(" - "+_vm._s(item.district.name))]):_vm._e(),(item.city)?_c('span',[_vm._v(" - "+_vm._s(item.city.name))]):_vm._e()])]}},{key:"cell(phone_number)",fn:function(ref){
var item = ref.item;
return [(item.phone_number)?_c('div',[_c('span',[_vm._v(" "+_vm._s(item.phone_number)+" ")])]):_vm._e()]}},{key:"cell(manager)",fn:function(ref){
var item = ref.item;
return [(item.manager)?_c('div',[_c('span',[_vm._v(" "+_vm._s(item.manager.name)+" ")])]):_vm._e()]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('b-badge',{attrs:{"pill":"","color":"primary"}},[_vm._v("Đang hoạt động")]):_c('b-badge',{attrs:{"pill":"","variant":"danger"}},[_vm._v("Không hoạt động")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"20px","color":"blue"}},[_c('feather',{staticStyle:{"cursor":"pointer"},attrs:{"type":"edit-2","stroke":"#3758FD","stroke-width":"1","size":"18px","fill":"#3758FD","stroke-linejoin":"round"},on:{"click":function($event){return _vm.onEdit(item)}}})],1)]}}],null,false,102902164)}):_vm._e(),(!_vm.isLoading && _vm.storeList.length === 0)?_c('div',{staticClass:"text-center"},[_vm._v(" Không có dữ liệu ")]):_vm._e()],1)])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="col-xl-6 xl-100">
    <!-- <px-card title="Bán hàng">
      <div slot="with-padding">
        <router-link class="btn btn-primary" to="/sales/order"
          >Bán hàng</router-link
        >
      </div>
    </px-card> -->
  </div>
</template>

<script>
export default {
  props: ["order"],
  data() {
    return {
      tablefields: [
        { key: "orderId", label: "Mã", sortable: false },
        { key: "total", label: "Giá trị ĐH", sortable: false },
        {
          key: "paymentMethod",
          label: "Hình thức thanh toán",
          sortable: false,
        },
        { key: "status", label: "Trạng thái", sortable: false },
      ],
      filter: null,
      // totalRows: this.order.length,
      currentPage: 1,
      perPage: 7,
      pageOptions: [5],
    };
  },
  created() {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of
    //  this.totalRows = this.items.length
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style></style>

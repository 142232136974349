<template>
  <div
    class="num-block skin-7 d-flex justify-content-center align-items-center"
  >
    <div class="d-flex align-items-center justify-content-center">
      <!-- <span
        style="border-radius: 5px 0 0 5px; border-right: 1px solid #a4a4a3"
        class="minus dis d-flex justify-content-center align-items-center"
        :class="{ 'rbt-disable-decrease': data <= 1 && !isDiscount }"
        @click="onDecrease()"
        >-</span
      > -->
      <input
        class="rbt-custom-input form-control"
        ref="inputQuantity"
        type="number"
        :value="isNumber"
        @change="onChange"
      />
      <!-- <span
        style="border-radius: 0 5px 5px 0; border-left: 1px solid #a4a4a3"
        class="plus d-flex justify-content-center align-items-center"
        @click="onIncrease()"
        >+</span
      > -->
    </div>
    <!-- <div v-if="!isDiscount">
      <div
        v-if="!isOpenQuantity"
        class="d-flex ml-1"
        style="border-radius: 50%; background-color: #33830e"
        @click="openAddQuantity()"
      >
        <feather
          class="rbt-icon-plus"
          style="cursor: pointer"
          type="plus"
          stroke="#f5fbf6"
          size="18px"
          fill="#f5fbf6"
        ></feather>
      </div>
      <b-form-input
        v-if="isOpenQuantity"
        class="ml-1"
        type="number"
        @change.native="onAddQuantity"
        placeholder="Mua thêm"
        style="height: 34px; font-size: 14px"
      ></b-form-input>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    isDiscount: {
      type: Boolean,
      default: false,
    },
    isNumber: {
      type: Number,
    },
    isGreaterZero: {
      type: Boolean,
    },
    isWarehouseExport: {
      type: Object,
    },
    product: {
      type: Object,
    },
    isFocusInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpenQuantity: false,
      data: 0,
    };
  },
  created() {
    this.data = this.isNumber && !this.isDiscount ? this.isNumber : 0;
    this.getUnitList();
  },
  computed: {
    ...mapState("unit", ["unitList"]),
  },
  methods: {
    ...mapActions("unit", ["getUnitList"]),
    onChangeDataInstance() {
      if (this.data < 0) {
        this.data = 0;
      }
      const { data, product, isWarehouseExport, isGreaterZero } = this;
      if (isGreaterZero && this.data <= 0) {
        this.data = 1;
      }
      if (data > product?.product_inventory_quantity && isWarehouseExport) {
        this.data = product?.product_inventory_quantity || 1;
      }
      const result = this.unitList.filter((item) => item.name == product?.unit);
      if (result && result.length > 0) {
        const { is_real } = result[0];
        if (is_real) {
          this.$emit("onChangeData", {
            product,
            quantity: parseFloat(data),
          });
        } else {
          this.$emit("onChangeData", {
            product,
            quantity: parseInt(data),
          });
        }
      } else {
        this.$emit("onChangeData", {
          product,
          quantity: parseInt(data),
        });
      }
    },
    onChange(value) {
      this.data = value.target.value;
      this.onChangeDataInstance();
    },
    openAddQuantity() {
      this.isOpenQuantity = true;
    },
    onAddQuantity(value) {
      this.data += Number(value.target.value);
      this.isOpenQuantity = false;
      this.onChangeDataInstance();
    },
    onDecrease() {
      this.data = Number(this.isNumber) - 1;
      this.onChangeDataInstance();
    },
    onIncrease() {
      this.data = Number(this.isNumber) + 1;
      this.onChangeDataInstance();
    },
  },
  watch: {
    isFocusInput() {
      this.$refs.inputQuantity.focus();
    },
    isNumber() {
      this.data = this.isNumber;
      this.onChangeDataInstance();
    },
  },
};
</script>

<style>
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
</style>
<style scoped>
/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.rbt-custom-input {
  width: 90%;
  padding: 10px;
  white-space: nowrap;
  height: 40px !important;
}
.rbt-box-up-down:hover {
  border: 1px solid rgb(41, 38, 38) !important;
}
.rbt-disable-decrease {
  cursor: not-allowed !important;
}
.skin-7 .num-in {
  float: left;
  width: 100%;
  border: 1px solid #a4a4a3;
  border-radius: 5px;
}

.skin-7 input.in-num {
  font-family: "HelveticaNeueCyr-Roman";
  font-size: 18px;
  color: #33830e;
  font-weight: bold;
  float: left;
  height: 32px;
  width: 83px;
  border-left: 1px solid #a4a4a3;
  border-right: 1px solid #a4a4a3;
  background-color: #fff;
  text-align: center;
}

.skin-7 .num-in span {
  font-size: 24px;
  text-align: center;
  display: block;
  width: 23%;
  float: left;
  height: 32px;
  background-color: #f5f7fa;
  color: #606266;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.skin-7 .num-in span:hover {
  background-color: #d7d7d8;
}

.skin-7 .num-in input {
  border: none;
  float: left;
  width: 54%;
  line-height: 34px;
  text-align: center;
  font-family: "helveticaneuecyrbold";
}
.rbt-icon-plus .datatable-vue .table-striped .feather--plus svg,
.datatable-vue .table-striped .feather--edit-2 svg {
  color: white !important;
}
</style>

import dashboardService from "../../services/dashboard";

const state = {
  numIncompleteOrder: 0,
  numInvoice: 0,
  revenueTotal: 0,
  numShipOrder: 0,
  revenueReport: [],
};
const getters = {};
const actions = {
  async getDashboardList({ commit }) {
    try {
      const response = await dashboardService.getDashboard();
      const { incomplete_order, invoice, revenue, ship_order } = response.data;
      commit("SET_NUM_INCOMPLETE_ORDER", incomplete_order);
      commit("SET_NUM_INVOICE", invoice);
      commit("SET_REVENUE_TOTAL", revenue);
      commit("SET_NUM_SHIP_ORDER", ship_order);
    } catch (error) {
      console.log("error", error);
    }
  },

  async getRevenueReport({ commit }, params) {
    try {
      const response = await dashboardService.getRevenueReport(params);
      commit("SET_REVENUE_REPORT", response.data);
    } catch (error) {
      console.log("error", error);
    }
  },
};
const mutations = {
  SET_NUM_INCOMPLETE_ORDER(state, payload) {
    state.numIncompleteOrder = payload;
  },
  SET_NUM_INVOICE(state, payload) {
    state.numInvoice = payload;
  },
  SET_REVENUE_TOTAL(state, payload) {
    state.revenueTotal = payload;
  },
  SET_NUM_SHIP_ORDER(state, payload) {
    state.numShipOrder = payload;
  },
  SET_REVENUE_REPORT(state, payload) {
    state.revenueReport = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

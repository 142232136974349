<template>
  <div style="margin-bottom: 50px; margin-top: 90px">
    <template v-for="order in orderShipperList">
      <template>
        <b-card :key="order.id">
          <b-link @click="onDetailOrder(order.id)">
            <h5>
              <b>{{ order.code }} - {{ order.name }}</b>
            </h5>
          </b-link>
          <b-card-text v-if="order.address">
            Khách hàng: <b>{{ order.address.recipient_name }} </b>
            <b v-if="order.address.phone_number"
              >- {{ order.address.phone_number }}</b
            >
          </b-card-text>
          <b-card-text v-else>
            Khách hàng:
            <b>{{ order.customer.name || order.customer.username }} </b>
            <b v-if="order.customer.phone_number"
              >- {{ order.customer.phone_number }}</b
            >
          </b-card-text>

          <b-card-text
            >Điểm đến:
            <b v-if="order.address">
              {{ order.address.address }} / {{ order.address.district }} /
              {{ order.address.city }}
            </b>
            <b v-if="order.customer.address || order">
              {{ order.customer.address }}
            </b>
          </b-card-text>
          <b-card-text v-if="order.desired_delivery_time"
            >Thời gian muốn nhận hàng:
            <b>
              {{ order.desired_delivery_time | showDate }}
            </b>
          </b-card-text>
          <b-card-text
            >Điểm lấy hàng: <b>{{ getAddress(order) }}</b></b-card-text
          >
          <b-card-text
            >Ghi chú khách hàng: <b>{{ order.note }}</b></b-card-text
          >
          <b-card-text
            >Tổng thu:
            <b>{{ order.total_payment | formatCurrency }}</b></b-card-text
          >
          <b-card-text v-if="order.order_status === 'DELEVERING'"
            >Tiền khách trả:
            <b>{{ order.pay_directly | formatCurrency }}</b></b-card-text
          >
          <b-card-text
            >Ghi chú:
            <span class="pull-right">{{
              order.customer.note
            }}</span></b-card-text
          >
          <b-card-text>
            <img
              v-if="order.customer.avatar_url"
              :src="order.customer.avatar_url"
              width="100%"
              height="100%"
              fluid
              rounded
            />
          </b-card-text>
          <b-card-text
            class="pull-right"
            v-if="order.order_status === 'DELEVERING'"
          >
            {{ order.updated_at | showDate }}</b-card-text
          >
          <button
            class="pull-left btn btn-danger"
            @click="onDetailOrder(order.id)"
          >
            Chi tiết
          </button>
          <button
            v-if="order.order_status === 'PICKING'"
            class="pull-right btn btn-primary"
            @click="onTookTheGood(order.id)"
          >
            Đã lấy hàng
          </button>
          <button
            v-if="order.order_status === 'DELEVERING'"
            class="pull-right btn btn-primary"
            @click="onDelivering(order.id)"
          >
            Giao hàng
          </button>
          <button
            class="btn btn-primary pull-right"
            v-if="order.order_status === 'DELIVERING'"
            @click="handleGoLocation(order)"
          >
            <feather type="corner-right-up" size="14"></feather>
          </button>
          <button
            v-if="order.order_status === 'CONFIRMED'"
            class="pull-right btn btn-primary"
            @click="onReceiptOrder(order.id)"
          >
            Nhận
          </button>
        </b-card>
      </template>
    </template>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: {
    orderShipperList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions(["setMessage"]),
    getAddress(order) {
      return `${order.warehouse?.name}`; // (${order.store?.district?.name} - ${order.store?.city?.name})
    },
    onDetailOrder(id) {
      this.$router.push({
        name: "order-detail",
        query: { id: id },
      });
    },
    onReceiptOrder(id) {
      this.$emit("onReceiptOrder", id);
    },
    onTookTheGood(id) {
      this.$emit("onTookTheGood", id);
    },
    onCancelOrder(id) {
      this.$emit("onCancelOrder", id);
    },
    onDelivering(id) {
      this.$emit("onDelivering", id);
    },
    handleGoLocation(order) {
      const addressCustomer = order?.address;
      if (addressCustomer) {
        window.open(
          `https://www.google.com/maps/dir//${addressCustomer.lat},${addressCustomer.lon}/@${addressCustomer.lat},${addressCustomer.lon},17.25z`,
          "_blank"
        );
      } else {
        this.setMessage({
          type: "error",
          content: "Chưa có thông tin toạ độ",
          isShow: true,
        });
      }
    },
  },
};
</script>

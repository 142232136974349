import { apiRoutesEnum } from "../enums/routes";
import axiosInstance from "./axios";

class ProductServices {
  async getProducts(params) {
    return axiosInstance.get("products", { params: params });
  }
  async getPriceList(params) {
    return axiosInstance.get(apiRoutesEnum.productPriceList, {
      params: params,
    });
  }
  async getDetailPriceList(id) {
    return axiosInstance.get(`${apiRoutesEnum.productPriceList}/${id}`);
  }
  async getPriceListActive() {
    return axiosInstance.get("/price-list-active");
  }
  async getProductPrice(params) {
    return axiosInstance.get(apiRoutesEnum.productPrice, { params: params });
  }
  async createPrice(payload) {
    return axiosInstance.post(apiRoutesEnum.productPriceList, payload);
  }
  async deletePrice(id) {
    return axiosInstance.delete(`${apiRoutesEnum.productPriceList}/${id}`);
  }
  async updatePrice(payload) {
    const { id, ...data } = payload;
    return axiosInstance.put(`${apiRoutesEnum.productPriceList}/${id}`, {
      ...data,
    });
  }
  async updateStatusProduct(payload) {
    return axiosInstance.post(`${apiRoutesEnum.productPriceList}`, {
      ...payload,
    });
  }
  async sendNotifyPromotion(id) {
    return axiosInstance.post(`${apiRoutesEnum.sendNotifyPromotion}/${id}`);
  }
  async productAdd(payload) {
    return axiosInstance.post("products", payload);
  }
  async updateProduct(payload) {
    const { id, ...data } = payload;
    return axiosInstance.put(`products/${id}`, { ...data });
  }
  async detailProduct(id) {
    return axiosInstance.get(`products/${id}`);
  }
  async delete(id) {
    return axiosInstance.delete(`products/${id}`);
  }
  async importProduct(payload) {
    return axiosInstance.post(apiRoutesEnum.productImport, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new ProductServices();

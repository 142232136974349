var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loader',{attrs:{"is-visible":_vm.isLoading}}),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"page-header"},[_c('Breadcrumbs',{attrs:{"title":"Đơn hàng"}})],1)]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h5',[_vm._v(_vm._s(_vm.totalRecord)+" kết quả")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"datatable-select col-md-3"},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Tìm kiếm"},on:{"change":function($event){return _vm.onSearch()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"col-md-3"},[_c('b-button',{staticClass:"btn-popup",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.onSearch()}}},[_vm._v("Tìm")]),_c('router-link',{attrs:{"to":"/sales/order"}},[_c('b-button',{staticClass:"btn-popup pull-right"},[_vm._v("Đặt hàng")])],1)],1)],1),_c('div',{staticClass:"table-responsive datatable-vue product-list"},[(!_vm.isLoading && _vm.orderList.length > 0)?_c('b-table',{staticClass:"text-center",attrs:{"striped":"","hover":"","head-variant":"light","bordered":"","stacked":"md","items":_vm.orderList,"fields":_vm.tablefields},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [(item.type && item.type == 'online')?_c('span',[_c('div',{staticClass:"text-info"},[_vm._v(" "+_vm._s(item.type)+" ")])]):_vm._e(),(item.type && item.type == 'offline')?_c('span',[_vm._v(" "+_vm._s(item.type)+" ")]):_vm._e()]}},{key:"cell(customer)",fn:function(ref){
var item = ref.item;
return [(item.customer)?_c('span',[_vm._v(" "+_vm._s(item.customer.name || item.customer.phone_number || item.customer.email)+" ")]):_c('span',[_vm._v(" Khách lẻ ")])]}},{key:"cell(total_invoice)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.total_invoice))+" ")])]}},{key:"cell(total_payment)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.total_payment))+" ")])]}},{key:"cell(additional_fee)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.additional_fee))+" ")])]}},{key:"cell(order_status)",fn:function(ref){
var item = ref.item;
return [(item.order_status)?_c('b-badge',{attrs:{"variant":_vm.colorStatus[item.order_status]}},[_vm._v(" "+_vm._s(_vm.statusOrder[item.order_status])+" ")]):_c('span',[_vm._v("_")])]}},{key:"cell(order_date)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("showDate")(item.order_date))+" ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"20px","color":"blue"}},[_c('feather',{staticStyle:{"cursor":"pointer"},attrs:{"type":"edit-2","stroke":"#3758FD","stroke-width":"1","size":"18px","fill":"#3758FD","stroke-linejoin":"round"},on:{"click":function($event){return _vm.onEdit(item)}}})],1)]}}],null,false,1779267011)}):_vm._e(),(!_vm.isLoading && _vm.orderList.length === 0)?_c('div',{staticClass:"text-center"},[_vm._v(" Không có dữ liệu ")]):_vm._e()],1),_c('b-col',{staticClass:"my-1 p-0 pagination-justify",attrs:{"md":"12"}},[(_vm.totalRecord > 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRecord,"per-page":_vm.pageSize,"aria-controls":"my-table"},on:{"change":_vm.handlePageChange},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}}):_vm._e()],1)],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
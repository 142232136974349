export const WAREHOUSE_STATUS = {
  NUMERIC: {
    0: "Mới tạo",
    1: "Chờ duyệt",
    2: "Đã duyệt",
    3: "Hủy bỏ",
  },
  TEXT: {
    CREATE: 0,
    PENDING: 1,
    APPROVED: 1,
    CANCEL: 3,
  },
  APPEND_COLOR: {
    0: "warning",
    1: "success",
    2: "primary",
    3: "danger",
  },
};

export const ORDER_SUPPLIER_STATUS = {
  TEXT: {
    CREATE: "CREATED",
    APPROVED: "APPROVED",
    CANCEL: "CANCELLED",
    APPROVED_2: "APPROVED_2",
    REJECTED: "REJECTED",
  },
  ADMIN: {
    CREATED: "Mới tạo",
    APPROVED: "Đã duyệt",
    APPROVED_2: "NCC đã duyệt",
    CANCEL: "Đã hủy",
    REJECTED: "NCC hủy",
  },
  APPEND_COLOR: {
    CREATED: "warning",
    APPROVED: "success",
    APPROVED_2: "primary",
    CANCEL: "danger",
    REJECTED: "black",
  },
};

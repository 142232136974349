<template>
  <div>
    <loader :is-visible="isLoadingQuotation"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Danh sách báo giá" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>{{ totalRecordQuotation }} kết quả</h5>
            </div>
            <div class="card-body">
              <div class="row mb-4">
                <div class="col-sm-8 d-flex align-items-center mb-2">
                  <b-form-group
                    class="datatable-select col-md-9 pl-0"
                    style="margin-bottom: 0 !important"
                  >
                    <b-form-input
                      class="form-control"
                      type="text"
                      v-model="query"
                      placeholder="Tìm kiếm theo mã hoặc tên"
                      @change="onSearch()"
                    ></b-form-input>
                  </b-form-group>
                  <div class="ml-3">
                    <b-button
                      class="btn-popup mb-0"
                      variant="primary"
                      @click="onSearch()"
                      >Tìm</b-button
                    >
                  </div>
                </div>
                <div class="col-sm-4 d-flex justify-content-end mb-2">
                  <b-button @click="openCreateModal" class="btn-popup m-0"
                    ><span style="font-size: 20px; font-weight: 100"> + </span
                    >Tạo báo giá mới</b-button
                  >
                </div>
              </div>
              <!-- {{ quotationsList }} -->
              <div class="table-responsive datatable-vue standard-list">
                <b-table
                  v-if="!isLoadingQuotation && quotationsList.length > 0"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  :items="warehouseImportActive"
                  :fields="tablefields"
                >
                  <template #thead-top>
                    <b-tr>
                      <b-th>Tên báo giá</b-th>
                      <b-th style="width: 150px">Ngày tạo</b-th>
                      <b-th>Trạng thái</b-th>
                      <b-th>Hành động</b-th>
                    </b-tr>
                  </template>
                  <template v-slot:cell(user)="{ item }">
                    <span v-if="item.user">
                      {{ item.user.name }}
                    </span>
                  </template>
                  <template v-slot:cell(applied_time_start)="{ item }">
                    <div class="number-and-date-table" v-if="item.applied_date">
                      {{ item.applied_date | dateFormat }}
                    </div>
                  </template>
                  <template v-slot:cell(applied_time_end)="{ item }">
                    <div class="number-and-date-table" v-if="item.end_date">
                      {{ item.end_date | dateFormat }}
                    </div>
                  </template>
                  <template v-slot:cell(created_at)="{ item }">
                    <div class="number-and-date-table" v-if="item.created_at">
                      {{ item.created_at | dateFormat }}
                    </div>
                  </template>
                  <template v-slot:cell(status)="{ item }">
                    <div class="d-flex justify-content-center text-center">
                      <b-badge
                        style="margin-right: 6px; height: 18px"
                        :variant="colorStatus[item.status]"
                      >
                        {{ QUOTATION_STATUS_SUPPLIER[item.status] }}
                      </b-badge>
                    </div>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <div class="d-flex justify-content-center text-center">
                      <span style="font-size: 12px; height: 12px; color: blue;">
                        <feather
                          style="cursor: pointer"
                          type="eye"
                          stroke="#3758FD"
                          stroke-width="1"
                          size="18px"
                          stroke-linejoin="round"
                          @click="onEdit(item)"
                        ></feather>
                      </span>
                    </div>
                  </template>
                </b-table>
                <div
                  v-if="!isLoadingQuotation && quotationsList.length === 0"
                  class="text-center"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRecordQuotation > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecordQuotation"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
              <QuotationsModalSupplier :quotationsInfo="quotationsInfo" />
              <AddQuotationModal />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import QuotationsModalSupplier from "../../components/modals/QuotationsModalSupplier.vue";
import AddQuotationModal from "../../components/modals/AddQuotationModal.vue";
import { reverseDate } from "../../config/common";
import { QUOTATION_STATUS } from "../../config/quotations/index";

export default {
  components: {
    QuotationsModalSupplier,
    AddQuotationModal,
    Loader,
  },
  data() {
    return {
      tablefields: [
        { key: "name", label: "" },
        { key: "created_at", label: "" },
        { key: "status", label: "" },
        { key: "actions", label: "" },
      ],
      query: "",
      date: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      quotationsInfo: {},
    };
  },
  async mounted() {
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = {
      page: pageNumber,
      page_size: this.pageSize,
    };
    await this.onQuotationsSupplierList(params);
  },
  computed: {
    ...mapState("supplier", [
      "quotationsList",
      "isLoadingQuotation",
      "totalRecordQuotation",
    ]),
    colorStatus() {
      return QUOTATION_STATUS.APPEND_COLOR;
    },
    QUOTATION_STATUS_SUPPLIER() {
      return QUOTATION_STATUS.SUPPLIER;
    },
    warehouseImportActive() {
      return this.quotationsList?.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
  },
  watch: {},
  methods: {
    ...mapActions("supplier", [
      "onQuotationsSupplierList",
      "getQuotationDetailSupplier",
      "onDeleteQuotationSupplier",
    ]),
    ...mapActions("product", ["onListProduct"]),
    async onEdit(item) {
      const { id } = item;
      this.quotationsInfo = await this.getQuotationDetailSupplier(id);
      this.$bvModal.show("quotation-modal-supplier");
    },
    openCreateModal() {
      this.$bvModal.show("modal-create-quotation");
    },
    onSearch() {
      let params = {
        page: this.pageNumber,
        page_size: this.pageSize,
      };
      if (this.date[0] && this.date[1]) {
        params = {
          created_at_from: reverseDate(this.date[0]),
          created_at_to: reverseDate(this.date[1]),
          ...params,
        };
      }
      if (this.query) {
        params = { search: this.query, ...params };
      }
      this.onQuotationsSupplierList(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/provider/list-quote",
          query: { page: this.pageNumber },
        });
      }
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = {
        page: this.pageNumber,
        page_size: this.pageSize,
      };
      if (this.date[0] && this.date[1]) {
        params = {
          created_at_from: reverseDate(this.date[0]),
          created_at_to: reverseDate(this.date[1]),
          ...params,
        };
      }
      if (this.query) {
        params = { search: this.query, ...params };
      }
      this.onQuotationsSupplierList(params);
      this.$router.push({
        path: "/provider/list-quote",
        query: { page: this.pageNumber },
      });
    },
  },
};
</script>

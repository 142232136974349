<template>
  <div class="px-3 py-4">
    <AddCustomerModal
      :dataCustomer="dataCustomer"
      @checkOpenModal="checkOpenModal"
    />
    <b-modal id="modal-3" title="Huỷ đơn hàng" @ok="cancelOrder()">
      <p class="my-4">
        Xác nhận huỷ đơn <b>{{ this.paymentInfo.name }}</b
        >?
      </p>
    </b-modal>
    <div class="mr-3">
      <!-- name order -->
      <div
        class="form-group row d-flex justify-content-between align-items-center"
      >
        <label
          style="color: rgba(0,0,0,.85)"
          for="validationCustom1"
          class="col-xl-4 col-md-5 pr-0"
          ><span class="text-danger mr-1">*</span>Tên đơn hàng</label
        >
        <input
          v-model="paymentInfo.name"
          disabled
          class="form-control col-xl-7 col-md-6"
          id="validationCustom1"
          type="text"
        />
      </div>
      <!-- select customer -->
      <div
        class="form-group row d-flex justify-content-between align-items-center"
      >
        <label
          style="color: rgba(0,0,0,.85)"
          for="validationCustom2"
          class="col-xl-4 col-md-5 pr-0"
        >
          Khách hàng
        </label>
        <multiselect
          ref="selectCustomer"
          @search-change="onChangeCustomer"
          placeholder="Chọn khách hàng"
          style="min-width: 150px !important"
          :show-labels="false"
          :custom-label="searchingSelect"
          class="rbt-custom-input col-xl-7 col-md-6 p-0"
          :disabled="!!$route.query.id"
          v-model="paymentInfo.customer"
          :options="customers"
          :internal-search="false"
        >
          <span slot="noResult">Không tìm thấy kết quả!!!</span>
          <template slot="singleLabel" slot-scope="props"
            ><span class="option__desc">
              <span class="option__title">{{
                props.option.name ||
                  props.option.phone_number ||
                  props.option.email
              }}</span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <!-- <div class="option__desc">
              <p class="option__title">{{ props.option.phone_number }}</p>
            </div> -->
            <div class="option__desc d-block">
              <span class="option__title font-weight-bold">{{
                props.option.name ||
                  props.option.phone_number ||
                  props.option.email
              }}</span>
              <p class="option__small mb-0">{{ props.option.phone_number }}</p>
            </div>
          </template>
        </multiselect>
      </div>
      <div
        class="form-group row d-flex justify-content-between align-items-center"
        v-if="paymentInfo && paymentInfo.customer"
      >
        <label
          style="color: rgba(0,0,0,.85)"
          for="validationCustom3"
          class="col-xl-4 col-md-5 pr-0"
        >
          Số dư
        </label>
        <div class="col-xl-7 col-md-6 p-0">
          <span>
            {{ surplusCustomer | formatCurrency }} (Nợ tối đa
            {{ paymentInfo.customer.max_debt_amount | formatCurrency }})
          </span>
        </div>
      </div>
      <div
        class="form-group row d-flex justify-content-between align-items-center"
      >
        <label
          style="color: rgba(0,0,0,.85)"
          for="validationCustom3"
          class="col-xl-4 col-md-5 pr-0"
        >
          Tỉ lệ chiết khấu
        </label>
        <div class="col-xl-7 col-md-6 p-0">
          <h3 style="color: green; font-size: 16px; font-weight: bold;">
            {{ customerDiscount }} %
          </h3>
        </div>
      </div>
      <!-- total money -->
      <div
        class="form-group row d-flex justify-content-between align-items-center"
      >
        <label
          style="color: rgba(0,0,0,.85)"
          for="validationCustom3"
          class="col-xl-4 col-md-5 pr-0"
        >
          Hàng hóa thường
        </label>
        <div class="col-xl-7 col-md-6 p-0">
          <h3 style="color: green; font-size: 16px; font-weight: bold;">
            {{ paymentInfo.normal_goods_value | formatCurrency }}
          </h3>
        </div>
      </div>
      <!-- <div
        class="form-group row d-flex justify-content-between align-items-center"
      >
        <label
          style="color: rgba(0,0,0,.85)"
          for="validationCustom3"
          class="col-xl-4 col-md-5 pr-0"
        >
          Tỉ lệ chiết khấu (%)
        </label>
        <div class="col-xl-7 col-md-6 p-0">
          <IncreaseAndDecrease
            :isNumber="Number(customerDiscount)"
            @onChangeData="onIncreAndDecre"
            :isDiscount="true"
          />
        </div>
      </div> -->
      <div
        class="form-group row d-flex justify-content-between align-items-center"
      >
        <label
          style="color: rgba(0,0,0,.85)"
          for="validationCustom3"
          class="col-xl-4 col-md-5 pr-0"
        >
          Chiết khấu
        </label>
        <div class="col-xl-7 col-md-6 p-0">
          <h3 style="color: green; font-size: 16px; font-weight: bold;">
            {{ paymentInfo.discount | formatCurrency }}
          </h3>
        </div>
      </div>
      <div
        class="form-group row d-flex justify-content-between align-items-center"
      >
        <label
          style="color: rgba(0,0,0,.85)"
          for="validationCustom3"
          class="col-xl-4 col-md-5 pr-0"
        >
          Hàng hóa KM
        </label>
        <div class="col-xl-7 col-md-6 p-0">
          <h3 style="color: green; font-size: 16px; font-weight: bold;">
            {{ paymentInfo.promotional_goods_value | formatCurrency }}
          </h3>
        </div>
      </div>
      <div
        class="form-group row d-flex justify-content-between align-items-center"
      >
        <label
          style="color: rgba(0,0,0,.85)"
          for="validationCustom3"
          class="col-xl-4 col-md-5 pr-0"
        >
          Khuyến mãi
        </label>
        <div class="col-xl-7 col-md-6 p-0">
          <h3 style="color: green; font-size: 16px; font-weight: bold;">
            {{ paymentInfo.promotion | formatCurrency }}
          </h3>
        </div>
      </div>
      <!-- discount when selected customer -->
      <div
        class="form-group row d-flex justify-content-between align-items-center"
      >
        <label
          style="color: rgba(0,0,0,.85)"
          for="validationCustom3"
          class="col-xl-4 col-md-5 pr-0"
        >
          Tổng giá trị HĐ
        </label>
        <div class="col-xl-7 col-md-6 p-0">
          <h3 style="color: green; font-size: 16px; font-weight: bold;">
            {{ paymentInfo.total_invoice | formatCurrency }}
          </h3>
        </div>
      </div>
      <div
        class="form-group row d-flex justify-content-between align-items-center"
      >
        <label
          style="color: rgba(0,0,0,.85)"
          for="validationCustom3"
          class="col-xl-4 col-md-5 pr-0"
        >
          Phụ phí
        </label>
        <div class="col-xl-7 col-md-6 p-0">
          <input
            v-model="paymentInfo.additional_fee"
            disabled
            class="form-control"
            id="validationCustom1"
            placeholder="0đ"
          />
        </div>
      </div>
      <!-- note order -->

      <!-- total reality -->
      <div
        class="form-group row d-flex justify-content-between align-items-center"
      >
        <label
          style="color: rgba(0,0,0,.85)"
          for="validationCustom3"
          class="col-xl-4 col-md-5 pr-0"
        >
          Phải thanh toán
        </label>
        <div class="col-xl-7 col-md-6 p-0">
          <h3 style="color: green; font-size: 16px; font-weight: bold;">
            {{ paymentInfo.total_payment | formatCurrency }}
          </h3>
        </div>
      </div>

      <div
        class="form-group row d-flex justify-content-between align-items-center"
      >
        <label
          style="color: rgba(0,0,0,.85)"
          for="validationCustom1"
          class="col-xl-4 col-md-5 pr-0"
          >Tiền khách đưa</label
        >
        <div class="col-xl-7 col-md-6 p-0">
          <input
            ref="customerMoney"
            v-model="paymentInfo.pay_directly"
            class="form-control"
            id="validationCustom1"
            placeholder="0đ"
          />
        </div>
      </div>
      <div
        class="form-group row d-flex justify-content-between align-items-center"
      >
        <label
          style="color: rgba(0,0,0,.85)"
          for="validationCustom3"
          class="col-xl-4 col-md-5 pr-0"
        >
          <span v-if="!isUpdateOrder && !checkRoles('cskh')">
            Hoàn tiền
          </span>
        </label>
        <div
          v-if="
            paymentInfo.payment_method === 1 &&
              !isUpdateOrder &&
              !checkRoles('cskh') &&
              paymentInfo.customer
          "
          class="col-xl-7 col-md-6 p-0"
        >
          <input
            v-model="remainingPaymentTemp"
            class="form-control"
            id="validationCustom1"
            placeholder="0đ"
          />
        </div>

        <div
          v-if="
            (!isUpdateOrder &&
              !checkRoles('cskh') &&
              paymentInfo.payment_method !== 1) ||
              (!paymentInfo.customer && !isUpdateOrder && !checkRoles('cskh'))
          "
          class="col-xl-7 col-md-6 p-0"
        >
          <h3 style="color: green; font-size: 16px; font-weight: bold;">
            {{ paymentInfo.remaining_payment | formatCurrency }}
          </h3>
        </div>
      </div>

      <div
        v-if="isUpdateOrder || checkRoles('cskh')"
        class="form-group row d-flex justify-content-between align-items-center"
      >
        <label
          style="color: rgba(0,0,0,.85)"
          for="validationCustom3"
          class="col-xl-4 col-md-5 pr-0"
        >
          Thời gian
        </label>
        <input
          v-model="paymentInfo.desired_delivery_time"
          disabled
          type="datetime-local"
          class="form-control col-xl-7 col-md-6"
          format="dd/mm/yyyy"
        />
      </div>
      <div
        class="form-group row d-flex justify-content-between align-items-center"
      >
        <label
          style="color: rgba(0,0,0,.85)"
          for="validationCustom3"
          class="col-xl-4 col-md-5 pr-0"
        >
          Ghi chú
        </label>
        <textarea
          v-model="paymentInfo.note"
          disabled
          rows="2"
          class="form-control col-xl-7 col-md-6"
        ></textarea>
      </div>
      <div
        class="form-group row d-flex justify-content-between align-items-center"
      >
        <label
          style="color: rgba(0,0,0,.85)"
          for="validationCustom3"
          class="col-xl-4 col-md-5 pr-0 mb-0"
        >
          Khách tới lấy hàng
        </label>
        <div class="col-xl-7 col-md-6 px-0">
          <b-form-checkbox
            v-model="paymentInfo.customer_come_to_pick_up"
            disabled
            :value="true"
            :unchecked-value="false"
          >
          </b-form-checkbox>
        </div>
      </div>
      <!-- <div
        class="form-group row d-flex justify-content-between align-items-center"
      >
        <label
          style="color: rgba(0,0,0,.85)"
          for="validationCustom3"
          class="col-xl-4 col-md-5 pr-0"
        >
          Phương thức
        </label>
        <b-form-select
          class="form-control col-xl-7 col-md-6"
          v-model="paymentInfo.payment_method"
          :options="options"
        ></b-form-select>
      </div> -->
      <div
        v-if="!isUpdateOrder"
        class="d-flex justify-content-between align-items-center"
      >
        <b-form-group
          label="Phương thức thanh toán"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            v-model="paymentInfo.payment_method"
            :options="options"
            :aria-describedby="ariaDescribedby"
            name="radio-options"
          ></b-form-radio-group>
        </b-form-group>
      </div>
    </div>
    <!-- action -->
    <div class="d-flex justify-content-between align-items-center">
      <b-button
        @click="submitOrderNotShip()"
        :disabled="paymentType === 'card'"
        style="float: right; width: 100%; height: 50px; color: #fff; background-color: #145a32 !important;"
        block
        class="p-1"
      >
        <span style="font-size: 12px">
          Tiền mặt
        </span>
      </b-button>
      <b-button
        @click="submitOrderNotShip()"
        :disabled="paymentType === 'money'"
        style="float: right; width: 100%; height: 50px; color: #fff; background-color: #09cb5c !important;"
        block
        class="mt-0 ml-1 p-1"
      >
        <span style="font-size: 12px">
          Tài khoản
        </span>
      </b-button>
      <b-button
        v-b-modal.modal-3
        style="float: right; width: 100%; height: 50px; color: #fff; background-color: #00000080 !important;"
        block
        class="mt-0 ml-1 p-1"
      >
        <span style="font-size: 12px">
          Huỷ
        </span>
      </b-button>
    </div>
  </div>
</template>

<script>
import IncreaseAndDecrease from "../../components/UI/increaseAndDecrease.vue";
import AddCustomerModal from "../../components/modals/AddCustomerModal.vue";
import { mapGetters, mapState, mapActions } from "vuex";
import { debounce } from "lodash";
import {
  statusOrderEnum,
  statusPayment,
  typeOrder,
} from "../../constants/orderConfig";
import { addCommaPrice } from "../../config/common";
import customer from "../../services/customer";
import { Roles } from "../../types/roles";

const initalOfflineOrder = {
  products: [],
  customer: "",
  type: null,
  normal_goods_value: 0,
  promotional_goods_value: 0,
  discount: 0,
  promotion: 0,
  prepay: 0,
  note: "",
  pay_directly: null,
  name: "Đơn hàng tại quầy",
  total_invoice: 0,
  additional_fee: "0",
  total_payment: 0,
  status_order: null,
  store: null,
  address: "",
  desired_delivery_time: null,
  replacement_order: null,
  payment_method: 1,
  staff: 0,
  remaining_payment: 0,
  customer_come_to_pick_up: false,
};

export default {
  components: {
    IncreaseAndDecrease,
    AddCustomerModal,
  },
  props: {
    isSelectOrderToUpdate: {
      type: Boolean,
    },
    selectOrder: {
      default() {
        return null;
      },
    },
    customers: {
      type: Array,
      default() {
        return [];
      },
    },
    isOrderPayment: {
      type: Boolean,
      default: false,
    },
    isCloseTab: {
      type: Boolean,
      default: false,
    },
    isNewTab: {
      type: Boolean,
      default: false,
    },
    isSelectCustomer: {
      type: Boolean,
      default: false,
    },
    isCustomerMoney: {
      type: Boolean,
      default: false,
    },
    isPay: {
      type: Boolean,
      default: false,
    },
    isChangePrice: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customerDiscount: 0,
      dialog: true,
      paymentInfo: { ...initalOfflineOrder },
      options: [],
      canSubmit: true,
      dataCustomer: {},
      remainingPaymentTemp: 0,
      isModalData: false,
      listAddress: [],
      selectAddress: null,
      buttonText: {
        1: "Thanh toán tiền mặt",
        2: "Thanh toán tài khoản",
        3: "Đặt hàng",
      },
      paymentType: "",
    };
  },
  created() {
    this.getOrderStatusList();
    if (this.$route.query.id || this.checkRoles("cskh")) {
      this.$store.dispatch("order/onUpdateOrderLocal", true);
    } else if (!this.$route.query.id && !this.checkRoles("cskh")) {
      this.$store.dispatch("order/onUpdateOrderLocal", false);
    }
    this.optionsPayment();
  },
  methods: {
    ...mapActions("customer", ["getCustomerList"]),
    ...mapActions("order", ["handleBillInTab", "updatePaymentNotShip"]),
    ...mapActions(["setMessage"]),
    ...mapActions("reports", ["getOrderStatusList"]),
    submitOrderNotShip() {
      const { id, pay_directly } = this.paymentInfo;
      this.$emit("onUpdatePaymentNotShip", {
        id,
        pay_directly: Number(pay_directly?.toString()?.replace(/,/g, "")),
        payment_method: this.paymentType === "money" ? 1 : 2,
      });
    },
    checkRoles(role) {
      return this.userProfile?.groups[0].name === this.roleList[role];
    },
    getStatusId(code) {
      if (this.statusOrderList) {
        return this.statusOrderList.find((item) => item.code === `${code}`)?.id;
      }
    },
    getOrderTypeId(code) {
      if (this.statusOrderList && this.orderTypeList.length > 0) {
        return this.orderTypeList.find((item) => item.code === `${code}`)?.id;
      }
    },
    optionsPayment() {
      this.options = this.paymentInfo.customer
        ? this.userProfile.groups[0].name === this.roleList.employee
          ? this.isUpdateOrder
            ? [{ value: 3, text: "Ship" }]
            : [
                { value: 1, text: "Tiền mặt" },
                { value: 2, text: "Tài khoản" },
              ]
          : this.userProfile.groups[0].name === this.roleList.cskh ||
            this.isUpdateOrder
          ? [{ value: 3, text: "Ship" }]
          : [
              { value: 1, text: "Tiền mặt" },
              { value: 2, text: "Tài khoản" },
              { value: 3, text: "Ship" },
            ]
        : [{ value: 1, text: "Tiền mặt" }];
    },
    addProductToBill() {
      this.canSubmit = true;
      this.paymentInfo.products = this.orderActive?.map((item) => {
        if (item.quantity > item.product.product_inventory_quantity) {
          this.canSubmit = false;
        }
        return {
          note: item?.note || "",
          price: item.product.price,
          product: item.product.id,
          quantity: item.quantity,
          price_after_promotion:
            item.price ||
            item.product.price -
              item.product.price * (this.customerDiscount / 100) ||
            item.product.price,
        };
      });
    },
    onChangeCustomer: _.debounce(function(value) {
      const params = { query: value.trim() };
      this.getCustomerList(params);
    }, 250),
    submitOrder() {
      this.addProductToBill();
      const {
        customer,
        payment_method,
        total_payment,
        pay_directly,
        products,
        time_receive,
      } = this.paymentInfo;
      if (!this.canSubmit) {
        this.setMessage({
          type: "error",
          content: "Sản phẩm không có trong kho hoặc không đủ số lượng",
          isShow: true,
        });
        return;
      }
      if (products.length <= 0) {
        this.setMessage({
          type: "error",
          content: "Không có sản phẩm nào",
          isShow: true,
        });
        return;
      }
      if (!payment_method) {
        this.setMessage({
          type: "error",
          content: "Chọn phương thức thanh toán trước khi đặt hàng",
          isShow: true,
        });
        return;
      }
      if (
        !customer &&
        payment_method === 1 &&
        Number(pay_directly?.toString()?.replace(/,/g, "")) < total_payment
      ) {
        this.setMessage({
          type: "error",
          content: "Tiền đưa phải lớn hơn hoặc bằng tổng tiền",
          isShow: true,
        });
        return;
      } else if (!pay_directly && !customer) {
        this.setMessage({
          type: "error",
          content: "Tiền đưa phải lớn hơn hoặc bằng tổng tiền",
          isShow: true,
        });
      } else if (!pay_directly && customer && payment_method === 1) {
        this.setMessage({
          type: "error",
          content:
            "Hãy nhập tiền khách đưa trước khi tạo đơn với phương thức này",
          isShow: true,
        });
      } else if (
        !time_receive &&
        (this.isUpdateOrder || this.checkRoles("cskh"))
      ) {
        this.setMessage({
          type: "error",
          content: "Hãy nhập thời gian khách muốn nhận hàng",
          isShow: true,
        });
      } else {
        const darr = time_receive?.split("-"); // ["29", "1", "2016"]
        let timeConvert = null;
        if (darr) {
          timeConvert = new Date(
            parseInt(darr[0]),
            parseInt(darr[1]) - 1,
            parseInt(darr[2])
          );
        }
        this.addProductToBill();
        const {
          customer,
          staff,
          pay_directly,
          additional_fee,
          payment_method,
          remaining_payment,
          total_payment,
          address,
          ...data
        } = this.paymentInfo;
        this.$emit("onSubmitOrder", {
          ...data,
          address: address ? address.id : address,
          payment_method,
          total_payment,
          additional_fee:
            Number(additional_fee?.toString().replace(/,/g, "")) || 0,
          pay_directly:
            payment_method === 1 && customer
              ? Number(pay_directly?.toString()?.replace(/,/g, "")) -
                (Number(
                  this.remainingPaymentTemp?.toString().replace(/,/g, "")
                ) < 0
                  ? 0
                  : this.remainingPaymentTemp?.toString().replace(/,/g, ""))
              : Number(pay_directly?.toString()?.replace(/,/g, "")),
          remaining_payment,
          staff: this.userProfile?.id,
          customer: customer?.id || null,
          time_receive:
            this.isUpdateOrder || this.checkRoles("cskh")
              ? timeConvert.toISOString()
              : new Date(),
        });
      }
    },
    confirmOrder() {
      this.$emit("onConfirmOrder", {
        id: this.paymentInfo.id,
        status_order: this.getStatusId("CONFIRM"),
      });
    },
    cancelOrder() {
      this.$emit("onCancelOrder", {
        id: this.paymentInfo.id,
        status_order: this.getStatusId("CANCEL"),
      });
    },
    onIncreAndDecre({ quantity }) {
      this.customerDiscount = quantity;
      this.billCalculation();
    },
    calculationRemaningPayment() {
      this.remainingPaymentTemp = addCommaPrice(
        Math.trunc(
          Number(this.paymentInfo?.pay_directly?.toString().replace(/,/g, "")) -
            this.paymentInfo?.total_payment
        ) || 0
      );
    },
    billCalculation() {
      // total price
      let normal_goods_value = 0;
      // let paymentInfo.discount = 0;
      this.orderActive?.forEach((item) => {
        if (!item.price) {
          normal_goods_value += item.quantity * item.product.price;
        }
      });
      this.paymentInfo.discount = Math.trunc(
        normal_goods_value * (this.customerDiscount / 100)
      );
      this.paymentInfo.normal_goods_value =
        Math.trunc(normal_goods_value) || "0";

      let promotional_goods_value = 0;
      let promotion = 0;
      this.orderActive?.forEach((item) => {
        if (item.price) {
          promotional_goods_value += item.quantity * item.product.price;
          promotion +=
            item.product.price * item.quantity - item.quantity * item.price;
        }
      });
      this.paymentInfo.promotional_goods_value =
        Math.trunc(promotional_goods_value) || "0";
      this.paymentInfo.promotion = Math.trunc(promotion) || "0";

      // total payment
      let total = parseInt(normal_goods_value);
      total = total - total * (this.paymentInfo?.discount / 100);
      // this.paymentInfo.total = Math.trunc(total) || "0";

      this.paymentInfo.total_invoice =
        Number(this.paymentInfo.normal_goods_value) +
        Number(this.paymentInfo.promotional_goods_value) -
        Number(this.paymentInfo.promotion);
      this.paymentInfo.total_payment = Math.trunc(
        Number(this.paymentInfo.total_invoice) -
          Number(this.paymentInfo.discount) +
          Number(this.paymentInfo?.additional_fee?.toString().replace(/,/g, ""))
      );
      // refund money
      this.paymentInfo.remaining_payment =
        Math.trunc(
          Number(this.paymentInfo?.pay_directly?.toString().replace(/,/g, "")) -
            this.paymentInfo?.total_payment
        ) || 0;

      // this.paymentInfo.remaining_payment =
      //   remaining_payment > 0 ? remaining_payment : 0;
    },
    searchingSelect({ phone_number, name }) {
      return phone_number;
    },
    openModalAddCustomer() {
      this.dataCustomer = {};
      this.isModalData = false;
      this.$bvModal.show("modal-inventory-warehouse");
    },
    checkOpenModal(value) {
      this.isModalData = value;
    },
  },
  computed: {
    ...mapState("order", [
      "orderActive",
      "currentTab",
      "billActive",
      "orderDetail",
      "isUpdateOrder",
      "isDisableSubmit",
    ]),
    ...mapState("profile", ["userProfile"]),
    ...mapState("reports", ["statusOrderList"]),
    ...mapState("orderType", ["orderTypeList"]),
    roleList() {
      return Roles;
    },
    surplusCustomer() {
      if (this.paymentInfo && this.paymentInfo.customer) {
        return this.paymentInfo.customer.balance;
      }
    },
  },
  watch: {
    selectOrder() {
      if (this.selectOrder) {
        this.paymentInfo.payment_method = 3;
      }
    },
    isUpdateOrder() {
      this.optionsPayment();
      if (!this.isUpdateOrder && this.checkRoles("employee")) {
        this.paymentInfo.payment_method = 1;
      }
    },
    statusOrderList() {
      if (this.statusOrderList.length > 0 && !this.paymentInfo.id) {
        this.paymentInfo.status_order = this.getStatusId("SUCCESS");
      }
    },
    orderTypeList() {
      if (this.orderTypeList.length > 0 && !this.paymentInfo.id) {
        this.paymentInfo.type = this.getOrderTypeId("OFFLINE");
      }
    },
    orderDetail() {
      if (this.orderDetail) {
        const {
          name,
          customer,
          id,
          additional_fee,
          note,
          desired_delivery_time,
          status_payment,
          pay_directly,
          payment_method,
          type,
          store,
          address,
          customer_come_to_pick_up,
        } = this.orderDetail;
        const convertTime = desired_delivery_time
          ? desired_delivery_time
              .split(":")
              .splice(0, 2)
              .join(":")
          : desired_delivery_time;
        this.paymentInfo.name = name;
        this.paymentInfo.customer = customer;
        this.paymentInfo[`id`] = id;
        this.paymentInfo.additional_fee = additional_fee;
        this.paymentInfo.note = note;
        this.paymentInfo.type = type.id;
        this.paymentInfo.desired_delivery_time = convertTime;
        this.paymentInfo.status_payment = status_payment?.id;
        this.paymentInfo.pay_directly = pay_directly;
        this.paymentInfo.payment_method = payment_method?.id;
        this.paymentInfo.store = store;
        this.paymentInfo.address = address;
        this.paymentInfo.customer_come_to_pick_up = customer_come_to_pick_up;
      }
    },
    isPay() {
      this.submitOrder();
    },
    isCustomerMoney() {
      this.$refs.customerMoney.focus();
    },
    isSelectCustomer() {
      this.$refs.selectCustomer.toggle();
    },
    "paymentInfo.type": {
      handler() {},
      deep: true,
    },
    "paymentInfo.payment_method"() {
      const {
        customer,
        payment_method,
        total_payment,
        pay_directly,
        products,
      } = this.paymentInfo;
      if (payment_method > 1) {
        this.paymentInfo.pay_directly = "0";
        this.billCalculation();
      }
      if (payment_method === 3) {
        this.paymentInfo.status_order = this.getStatusId("NEW_ORDER");
        this.paymentInfo.type = this.getOrderTypeId("ONLINE");
      }
      if (payment_method !== 3) {
        this.paymentInfo.status_order = this.getStatusId("SUCCESS");
        this.paymentInfo.type = this.getOrderTypeId("OFFLINE");
      }
    },
    currentTab() {
      if (this.billActive) {
        const { products, ...data } = this.billActive;
        this.paymentInfo = {
          products: [...products],
          ...data,
        };
      } else {
        this.paymentInfo = { ...initalOfflineOrder };
      }
      if (this.orderTypeList.length > 0 && !this.paymentInfo.id) {
        this.paymentInfo.type = this.getOrderTypeId("OFFLINE");
      }
      if (this.statusOrderList.length > 0 && !this.paymentInfo.id) {
        this.paymentInfo.status_order = this.getStatusId("SUCCESS");
      }
    },
    "paymentInfo.pay_directly"() {
      const payDirecttly = this.paymentInfo.pay_directly;
      if (typeof payDirecttly == "string") {
        const result = addCommaPrice(payDirecttly);
        this.$nextTick(() => (this.paymentInfo.pay_directly = result));
      }
      const convertNumber = Number(
        this.paymentInfo.pay_directly?.toString()?.replace(/,/g, "")
      );
      if (convertNumber > 0) {
        this.paymentType = "money";
      } else {
        this.paymentType = "card";
      }
    },
    remainingPaymentTemp() {
      const remainingPaymentTemp = this.remainingPaymentTemp;
      if (typeof remainingPaymentTemp == "string") {
        const result = addCommaPrice(remainingPaymentTemp);
        this.$nextTick(() => (this.remainingPaymentTemp = result));
      }
    },
    "paymentInfo.additional_fee"() {
      const additionalFee = this.paymentInfo.additional_fee;
      if (typeof additionalFee == "string") {
        const result = addCommaPrice(additionalFee);
        this.$nextTick(() => (this.paymentInfo.additional_fee = result));
      }
    },
    "paymentInfo.remaining_payment"() {
      const additionalFee = this.paymentInfo.remaining_payment;
      if (typeof additionalFee == "string") {
        const result = addCommaPrice(additionalFee);
        this.$nextTick(() => (this.paymentInfo.remaining_payment = result));
      }
    },
    paymentInfo: {
      handler() {
        this.billCalculation();
        this.handleBillInTab(this.paymentInfo);
      },
      deep: true,
    },
    "paymentInfo.customer": {
      handler() {
        this.optionsPayment();
        if (this.paymentInfo.customer) {
          if (this.userProfile.groups[0].name === this.roleList.cskh) {
            this.paymentInfo.payment_method = 3;
          }
          const delivery_address = this.paymentInfo.customer.delivery_address;
          this.listAddress = delivery_address ? delivery_address : [];
          if (this.paymentInfo?.customer?.discount?.auto) {
            const discount = this.paymentInfo.customer?.discount?.discount_auto;
            this.onIncreAndDecre({ quantity: discount || 0 });
          } else {
            const discount = this.paymentInfo.customer?.discount?.discount;
            this.onIncreAndDecre({ quantity: discount || 0 });
          }
        } else {
          this.customerDiscount = 0;
          this.paymentInfo.payment_method = 1;
          this.paymentInfo.address = null;
          this.listAddress = [];
          this.onIncreAndDecre({ quantity: this.customerDiscount });
        }
        // const params = {
        //   query:
        // }
        // this.getCustomerList({params: query});
      },
      deep: true,
    },
    orderActive: {
      handler() {
        this.calculationRemaningPayment();
        this.billCalculation();
      },
      deep: true,
    },
    isOrderPayment() {
      this.dialog = this.isOrderPayment;
    },
    dialog() {
      if (!this.dialog) {
        this.$emit("onClosePayment");
        this.paymentInfo.pay_directly = 0;
      }
    },
    isModalData() {
      if (this.isModalData && this.customers.length > 0) {
        this.paymentInfo.customer = this.customers[0];
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
/* skin 1 */
.form-group {
  margin-bottom: 8px !important;
}

label {
  font-size: 12px;
}
.skin-1 .num-in {
  float: left;
  /* width: 94px; */
}

.skin-1 .num-in span {
  display: block;
  float: left;
  width: 30px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.skin-1 .num-in span.dis:before {
  background-color: #ccc !important;
}

.skin-1 .num-in input {
  float: left;
  width: 160px;
  height: 32px;
  /* color: #000; */
  text-align: center;
  padding: 0;
  border: 1px solid #d8d8d8;
}

.skin-1 .num-in span.minus:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 2px;
  background-color: #00a94f;
  top: 50%;
  left: 8px;
}

.skin-1 .num-in span.plus:before,
.skin-1 .num-in span.plus:after {
  content: "";
  position: absolute;
  right: 0px;
  width: 15px;
  height: 2px;
  background-color: #00a94f;
  top: 50%;
  right: 8px;
}

.skin-1 .num-in span.plus:after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
</style>

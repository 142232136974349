<template>
  <div>
    <loader :is-visible="isLoadingImport"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Phiếu nhập hàng nhà cung cấp" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>{{ totalImportlist }} kết quả</h5>
            </div>
            <div class="card-body">
              <div class="row mb-4">
                <div class="col-sm-12 d-flex align-items-center flex-wrap">
                  <date-picker
                    v-model="date"
                    value-type="format"
                    range
                    format="DD-MM-YYYY"
                    placeholder="Lọc theo ngày"
                    class="pl-0 flex-wrap ncc-date-picker mr-2 mb-2"
                    @change="onSearch"
                  ></date-picker>
                  <b-form-group class="datatable-select col-sm-5 pl-0 mb-2">
                    <b-form-input
                      type="text"
                      v-model="search"
                      placeholder="Lọc theo tên đơn hàng"
                      @change="onSearch()"
                    ></b-form-input>
                  </b-form-group>
                  <div class="mb-2">
                    <b-button
                      class="btn-popup mb-0"
                      variant="primary"
                      @click="onSearch()"
                      >Tìm</b-button
                    >
                  </div>
                </div>
              </div>
              <div class="table-responsive datatable-vue standard-list">
                <b-table
                  v-if="!isLoadingImport && importList.length > 0"
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  :items="importList"
                  :fields="tablefields"
                >
                  <template #empty>
                    <span>
                      Không có dữ liệu
                    </span>
                  </template>
                  <template v-slot:cell(status)="{ item }">
                    <div class="text-center">
                      <b-badge :variant="colorStatus[item.status]">
                        {{ TRANSACTION_STATUS_SUPPLIER[item.status] }}
                      </b-badge>
                    </div>
                  </template>
                  <template v-slot:cell(staff)="{ item }">
                    <span v-if="item.staff">
                      {{ item.staff.name }}
                    </span>
                  </template>
                  <template v-slot:cell(created_at)="{ item }">
                    <div class="number-and-date-table" v-if="item.created_at">
                      {{ item.created_at | dateFormat }}
                    </div>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="onEdit(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div
                  v-if="!isLoadingImport && importList.length === 0"
                  class="text-center"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalImportlist > 0"
                  v-model="pageNumber"
                  :total-rows="totalImportlist"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
              <ImportTransactionDetail
                :warehouseImportInfo="warehouseImportInfo"
                @onApproved="onApproved"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import ImportTransactionDetail from "../../components/modals/ImportTransactionDetail.vue";
import { TRANSACTION_STATUS } from "../../config/transactions/index";
import { reverseDate } from "../../config/common";
import { Roles } from "../../types/roles";

export default {
  components: {
    ImportTransactionDetail,
    Loader,
  },
  data() {
    return {
      tablefields: [
        { key: "code", label: "Mã đơn hàng" },
        { key: "name", label: "Tên đơn hàng" },
        { key: "created_at", label: "Ngày tạo" },
        { key: "staff", label: "Người tạo" },
        { key: "status", label: "Trạng thái" },
        { key: "actions", label: "Hành động" },
      ],
      search: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      isName: "",
      warehouseImportInfo: {},
      date: "",
      supplier: "",
    };
  },
  async created() {
    if (!this.userProfile) {
      await this.getUserProfile();
    }
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = {
      page: pageNumber,
      page_size: this.pageSize,
      supplier: this.userProfile.id,
    };
    if (this.userProfile.groups[0].name !== this.roleList.supplier) {
      delete params.supplier;
      this.getImportList(params);
    } else {
      this.getImportList(params);
    }
  },
  computed: {
    ...mapState("warehouse", [
      "importList",
      "totalImportlist",
      "isLoadingImport",
    ]),
    ...mapState("profile", ["userProfile"]),
    colorStatus() {
      return TRANSACTION_STATUS.APPEND_COLOR;
    },
    TRANSACTION_STATUS_SUPPLIER() {
      return TRANSACTION_STATUS.SUPPLIER;
    },
    roleList() {
      return Roles;
    },
  },
  watch: {},
  methods: {
    ...mapActions("warehouse", [
      "getImportList",
      "onImport",
      "onApprovedImport",
      "importDetail",
    ]),
    ...mapActions("inventory", ["getInventoryList"]),
    ...mapActions("profile", ["getUserProfile"]),
    onApproved(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onApprovedImport({ data: payload, params });
      this.handleClearData();
    },
    handleClearData() {
      this.search = "";
      this.date = "";
    },
    async onEdit(item) {
      const { id } = item;
      this.warehouseImportInfo = await this.importDetail(id);
      this.$bvModal.show("detail-import-transaction");
    },
    onSearch() {
      let params = {
        page: 1,
        page_size: this.pageSize,
        supplier: this.userProfile.id,
      };
      if (this.date[0] && this.date[1]) {
        params = {
          date_start: reverseDate(this.date[0]),
          date_end: reverseDate(this.date[1]),
          ...params,
        };
      }
      if (this.search) {
        params = { search: this.search, ...params };
      }
      this.getImportList(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/provider/import-transaction",
          query: { page: this.pageNumber },
        });
      }
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = {
        page: this.pageNumber,
        page_size: this.pageSize,
        supplier: this.userProfile.id,
      };
      if (this.search) {
        params = {
          query: this.search.trim(),
          ...params,
        };
      }
      if (this.date[0] && this.date[1]) {
        params = {
          date_start: reverseDate(this.date[0]),
          date_end: reverseDate(this.date[1]),
          ...params,
        };
      }
      this.getImportList(params);
      this.$router.push({
        path: "/provider/import-transaction",
        query: { page: this.pageNumber },
      });
    },
  },
};
</script>

export const OBJECT_STATUS = {
  STATUS_DEFAULT: Object.freeze([
    {
      id: 0,
      name: "Mới tạo",
    },
    {
      id: 1,
      name: "Chờ duyệt",
    },
    {
      id: 2,
      name: "Đã duyệt",
    },
    {
      id: 3,
      name: "Huỷ bỏ",
    },
  ]),
  STATUS_SUPPLIER: Object.freeze([
    {
      id: "moi_tao",
      name: "Mới tạo",
    },
    {
      id: "hoat_dong",
      name: "Hoạt động",
    },
    {
      id: "xac_nhan",
      name: "Xác nhận",
    },
  ]),
  UNIT_DEFAULT: Object.freeze([
    {
      name: "kg",
      isFloat: true,
    },
    {
      name: "hộp",
      isFloat: false,
    },
    {
      name: "cái",
      isFloat: false,
    },
    {
      name: "đĩa",
      isFloat: false,
    },
    {
      name: "suất",
      isFloat: false,
    },
    {
      name: "bó",
      isFloat: false,
    },
    {
      name: "cây",
      isFloat: false,
    },
    {
      name: "bát",
      isFloat: false,
    },
    {
      name: "con",
      isFloat: false,
    },
    {
      name: "túi",
      isFloat: false,
    },
    {
      name: "chai",
      isFloat: false,
    },
    {
      name: "set",
      isFloat: false,
    },
    {
      name: "khay",
      isFloat: false,
    },
    {
      name: "chiếc",
      isFloat: false,
    },
  ]),
};

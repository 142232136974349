<template>
  <div>
    <b-modal size="xl" id="modal-create-quotation" centered>
      <template #modal-header>
        <h3 class="mx-auto" style="color: #ff8084">
          Tạo báo giá mới
        </h3>
      </template>
      <div>
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div class="d-flex flex-wrap">
            <input
              v-model="dataSubmit.name"
              placeholder="Tên báo giá"
              class="form-control mb-2"
              id="validationCustom1"
              type="text"
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-3 mb-4 mt-3">
          <label class="input-header">Lấy từ báo giá cũ</label>
          <b-form-select v-model="oldQuotation" @change="changeQuotation">
            <option value="">Chọn báo giá</option>
            <option
              v-for="quotation in quotationsList"
              :key="quotation.index"
              :value="quotation.id"
              >{{ quotation.name }}</option
            >
          </b-form-select>
        </div>

        <div class="d-flex align-items-end mt-3 flex-wrap">
          <div class="col-sm-12 col-md-6 col-lg-3 mb-2">
            <label class="input-header">Sản phẩm</label>
            <b-form-select v-model="dataTable.product">
              <option :value="{}">Sản phẩm</option>
              <option
                v-for="product in products"
                :key="product.index"
                :value="product"
                >{{ product.name }}</option
              >
            </b-form-select>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-3 mb-2">
            <label class="input-header">Đơn vị nhập</label>
            <b-form-select v-model="dataTable.unit" disabled>
              <option value="">Đơn vị nhập</option>
              <option
                v-for="unit in unitList"
                :key="unit.index"
                :value="unit.name"
                >{{ unit.name }}</option
              >
            </b-form-select>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-3 mb-2">
            <label class="input-header">Đơn giá</label>
            <input
              v-model="dataTable.unitPrice"
              placeholder="Đơn giá"
              class="form-control"
              type="text"
              @keyup="onFormatPrice($event)"
            />
          </div>

          <div class="col-sm-12 col-md-6 col-lg-3 mb-2">
            <label class="input-header">Giá khuyến nghị</label>
            <input
              v-model="dataTable.recommendedPrice"
              placeholder="Giá khuyến nghị"
              class="form-control"
              type="text"
              @keyup="onFormatPriceRecommended($event)"
            />
          </div>
        </div>
        <div class="d-flex align-items-end mt-3 flex-wrap">
          <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
            <label class="input-header">Khối lượng thấp nhất</label>
            <input
              v-model="dataTable.lowestVol"
              placeholder="Khối lượng thấp nhất"
              class="form-control"
              type="text"
              disabled
              @keyup="validateLowestVolumeInput($event)"
            />
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
            <label class="input-header">Khối lượng cao nhất</label>
            <input
              v-model="dataTable.highestVol"
              placeholder="Khối lượng cao nhất"
              class="form-control"
              type="text"
              @keyup="validateHighestVolumeInput($event)"
            />
          </div>
          <div class="col-md-3">
            <b-button class="btn-popup mb-3" @click="addProduct">
              <span style="font-size: 18px; font-weight: 100">
                +
              </span>
            </b-button>
          </div>
        </div>
        <div
          class="table-responsive datatable-vue standard-list text-center mt-4"
        >
          <b-modal
            id="model-remove"
            title="Xác nhận"
            @ok="removeProductInTab()"
          >
            <p class="my-4">Are you sure!</p>
          </b-modal>
          <b-table
            show-empty
            striped
            hover
            head-variant="light"
            bordered
            :items="listProductActive"
            :fields="tablefields"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template #empty>
              <span class="text-danger">
                Chưa có sản phẩm nào
              </span>
            </template>

            <template #thead-top>
              <b-tr>
                <b-th>Mã hàng</b-th>
                <b-th>Tên hàng</b-th>
                <b-th>ĐVN</b-th>
                <b-th>Đơn giá</b-th>
                <b-th>Giá khuyến nghị</b-th>
                <b-th colspan="2">Khối lượng tính</b-th>
                <b-th>Xóa</b-th>
              </b-tr>
            </template>

            <template v-slot:cell(name)="{ item }">
              <span v-if="item.name">
                {{ item.name }}
              </span>
            </template>
            <template v-slot:cell(unit_price)="{ item }">
              <span>
                {{ item.unit_price | formatCurrency }}
              </span>
            </template>
            <template v-slot:cell(recommended_price)="{ item }">
              <span>
                {{ item.recommended_price | formatCurrency }}
              </span>
            </template>
            <template v-slot:cell(volume_from)="{ item }">
              <span> Lớn hơn {{ item.volume_from }} </span>
            </template>
            <template v-slot:cell(volume_to)="{ item }">
              <span>
                {{ item.volume_to }}
              </span>
            </template>
            <template v-slot:cell(actions)="{ item }">
              <span style="font-size: 20px; color: #FA399F;">
                <feather
                  style="cursor: pointer"
                  type="trash"
                  stroke="#F72E9F"
                  size="18px"
                  fill="#F72E9F"
                  @click="removeProductInTable(item.sno)"
                ></feather>
              </span>
            </template>
          </b-table>
          <div>
            <b-pagination
              v-if="listProduct.length > 0"
              class="mt-2 d-flex justify-content-end"
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="listProduct.length"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div
          style="width: 100%;"
          class="d-flex justify-content-center flex-wrap"
        >
          <div>
            <b-button
              class="mr-3 mb-2"
              size="sm"
              variant="danger"
              @click="close()"
            >
              Đóng
            </b-button>
          </div>
          <div>
            <b-button
              :disabled="!listProduct.length || !dataSubmit.name"
              size="sm"
              class="m-0"
              @click="submitQuotation()"
            >
              Gửi báo giá</b-button
            >
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../loading/loader.vue";
import IncreaseAndDecrease from "../UI/increaseAndDecrease.vue";
import { addDotPrice } from "../../config/common";

const initialDataSubmit = {
  name: "",
};

const initialDataTable = {
  product: {},
  unit: "",
  unitPrice: "",
  recommendedPrice: "",
  lowestVol: "0",
  highestVol: "100000",
};

export default {
  props: {},
  components: {},
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      tablefields: [
        { key: "code", label: "" },
        { key: "name", label: "" },
        { key: "unit", label: "" },
        { key: "unit_price", label: "" },
        { key: "recommended_price", label: "" },
        { key: "volume_from", label: "Từ" },
        { key: "volume_to", label: "Đến" },
        { key: "actions", label: "" },
      ],
      productQuotes: [],
      listProduct: [],
      downloadLoading: false,
      dataSubmit: { ...initialDataSubmit },
      dataTable: { ...initialDataTable },
      checkDisable: false,
      oldQuotation: "",
    };
  },
  components: {
    Loader,
    IncreaseAndDecrease,
  },
  async mounted() {
    if (!this.userProfile) {
      await this.getUserProfile();
    }
    this.getUnitList();
    this.onQuotationsSupplierList();
    this.getProductWithSupplier({
      suppliers: this.userProfile.id,
    });
  },
  computed: {
    ...mapState("supplier", [
      "supplierList",
      "isLoadingQuotation",
      "quotationsList",
      "products",
    ]),
    ...mapState("unit", ["unitList"]),
    ...mapState("profile", ["userProfile"]),
    productsActive() {
      let data = [];
      const idPurchasedProduct = this.productQuotes.map((item) => {
        return item.product;
      });
      data = this.products.filter((item) => {
        return !idPurchasedProduct.includes(item.id);
      });
      return data;
    },
    listProductActive() {
      return this.listProduct.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
  },
  watch: {
    "dataTable.product"() {
      const { id, import_unit } = this.dataTable.product;
      this.dataTable.unit = import_unit;
      if (this.listProduct.length > 0) {
        const getProductByID = this.listProduct.filter((item) => {
          return item.product === id;
        });
        if (!getProductByID.length) {
          this.dataTable.lowestVol = "0";
          this.dataTable.highestVol = "100000";
        } else {
          const maxVol = getProductByID.at(-1).volume_to;
          this.dataTable.lowestVol = maxVol;
          this.dataTable.highestVol = "";
        }
      }
    },
  },
  methods: {
    ...mapActions("supplier", [
      "onQuotationsSupplier",
      "onQuotationsSupplierList",
      "getQuotationDetailSupplier",
      "getProductWithSupplier",
    ]),
    ...mapActions(["setMessage"]),
    ...mapActions("unit", ["getUnitList"]),
    ...mapActions("profile", ["getUserProfile"]),
    close() {
      this.dataSubmit = { ...initialDataSubmit };
      this.listProduct = [];
      this.dataTable = { ...initialDataTable };
      this.oldQuotation = "";
      this.$bvModal.hide("modal-create-quotation");
    },
    validateLowestVolumeInput(event) {
      if (event.target.value) {
        this.dataTable.lowestVol = this.dataTable.lowestVol
          .replace(/[^0-9.]/g, "")
          .replace(/(\..*?)\..*/g, "$1");
      }
    },
    validateHighestVolumeInput(event) {
      if (event.target.value) {
        this.dataTable.highestVol = this.dataTable.highestVol
          .replace(/[^0-9.]/g, "")
          .replace(/(\..*?)\..*/g, "$1");
      }
    },
    addProduct() {
      const lowestVol = this.dataTable.lowestVol;
      const highestVol = this.dataTable.highestVol;
      if (
        !Object.keys(this.dataTable.product).length ||
        !this.dataTable.unit ||
        !this.dataTable.unitPrice ||
        !this.dataTable.recommendedPrice ||
        lowestVol === "" ||
        highestVol === ""
      ) {
        this.setMessage({
          type: "error",
          content:
            "Sản phẩm, ĐVT, đơn giá, giá khuyến nghị, khối lượng không được để trống",
          isShow: true,
        });
        return;
      }
      if (Number(lowestVol) === Number(highestVol)) {
        this.setMessage({
          type: "error",
          content: "Khối lượng thấp nhất và cao nhất không thể bằng nhau",
          isShow: true,
        });
        return;
      }
      if (Number(lowestVol) > Number(highestVol)) {
        this.setMessage({
          type: "error",
          content: "Khối lượng thấp nhất nhất không thể lớn hơn",
          isShow: true,
        });
        return;
      }
      this.listProduct.push({
        product: this.dataTable.product.id,
        code: this.dataTable.product.code,
        name: this.dataTable.product.name,
        unit: this.dataTable.unit,
        unit_price: this.dataTable.unitPrice,
        recommended_price: this.dataTable.recommendedPrice,
        volume_from: lowestVol,
        volume_to: highestVol,
      });
      this.dataTable.lowestVol = Number(highestVol);
      this.dataTable.highestVol = "";
    },
    async changeQuotation() {
      if (this.oldQuotation) {
        const quotationSelect = await this.getQuotationDetailSupplier(
          this.oldQuotation
        );
        this.listProduct = quotationSelect.products.map((item) => {
          return {
            product: item.product,
            name: item.name,
            unit: item.unit,
            unit_price: item.unit_price,
            recommended_price: item.recommended_price,
            volume_from: item.lowest_weight,
            volume_to: item.highest_weight || 100000,
          };
        });
      } else {
        this.listProduct = [];
      }
    },
    onFormatPrice(event) {
      if (event.target.value) {
        this.dataTable.unitPrice = addDotPrice(this.dataTable.unitPrice);
      }
    },
    onFormatPriceRecommended(event) {
      if (event.target.value) {
        this.dataTable.recommendedPrice = addDotPrice(
          this.dataTable.recommendedPrice
        );
      }
    },
    async submitQuotation() {
      if (this.listProduct.length > 0 && this.dataSubmit.name) {
        const productQuotations = this.listProduct.map((item) => {
          return {
            unit_price:
              typeof item.unit_price === "string"
                ? Number(item.unit_price.replaceAll(".", ""))
                : item.unit_price,
            recommended_price:
              typeof item.recommended_price === "string"
                ? Number(item.recommended_price.replaceAll(".", ""))
                : item.recommended_price,
            product: item.product,
            lowest_weight: Number(item.volume_from),
            highest_weight: Number(item.volume_to),
          };
        });
        const sendData = {
          data: {
            name: this.dataSubmit.name,
            products: [...productQuotations],
            supplier: this.userProfile.id,
          },
        };
        try {
          const status = await this.onQuotationsSupplier(sendData);
          if (status) {
            this.close();
          }
        } catch (error) {}
      } else {
        this.setMessage({
          type: "error",
          content: "Tên báo giá, ngày áp dụng, sản phẩm không được để trống",
          isShow: true,
        });
      }
    },
    removeProductInTable(index) {
      this.listProduct.splice(index - 1, 1);
      const { id } = this.dataTable.product;
      const getProductByID = this.listProduct.filter(
        (item) => item.product === id
      );
      if (!getProductByID.length) {
        this.dataTable.lowestVol = "0";
        this.dataTable.highestVol = "100000";
      } else {
        const maxVol = getProductByID.at(-1).volume_to;
        this.dataTable.lowestVol = maxVol;
        this.dataTable.highestVol = "";
      }
    },
  },
};
</script>

<style>
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
.create-quotation .mx-datepicker {
  width: 100% !important;
}

.create-quotation .mx-datepicker input::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
</style>

<style scoped>
.rbt-custom-input {
  max-width: 192px;
  white-space: nowrap;
  height: 43px !important;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
input {
  height: unset;
}
</style>

<template>
  <div>
    <b-modal
      size="xl"
      :no-close-on-backdrop="true"
      id="supplier-receipt-modal"
      centered
    >
      <template #modal-header>
        <h3 class="mx-auto" style="color: #ff8084">
          Phiếu nhập hàng NCC
        </h3>
      </template>
      <b-form-group
        label-cols-md="2"
        label-cols-lg="2"
        content-cols-sm
        content-cols-md="10"
      >
        <template v-slot:label>
          Tên phiếu nhập<span class="validate-star"> *</span></template
        >
        <input
          :disabled="!!dataSubmit.id"
          class="form-control rbt-custom-input"
          style="max-width: 100% !important"
          v-model="dataSubmit.name"
          placeholder="Nhập tên phiếu nhập"
        />
      </b-form-group>
      <b-form-group
        label-cols-md="2"
        label-cols-lg="2"
        content-cols-sm
        content-cols-md="10"
      >
        <template v-slot:label
          >Nhà cung cấp<span class="validate-star"> *</span></template
        >
        <multiselect
          :disabled="!!dataSubmit.id"
          v-model="dataSubmit.supplier"
          class="rbt-custom-input"
          style="max-width: 100% !important"
          placeholder="Danh sách nhà cung cấp"
          :show-labels="false"
          @input="onChangeSupplier"
          :internal-search="false"
          :options="supplierList"
        >
          <span slot="noOptions">
            Không có nhà cung cấp!
          </span>
          <span slot="noResult">Không tìm thấy kết quả!!!</span>
          <template slot="singleLabel" slot-scope="props"
            ><span class="option__desc"
              ><span class="option__title">{{ props.option.name }}</span></span
            ></template
          >
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <p class="option__title">
                {{ props.option.name }}
              </p>
            </div>
          </template>
        </multiselect>
      </b-form-group>
      <validation-provider
        name="Đơn hàng nhà cung cấp"
        :rules="{ required: false }"
        v-slot="validationContext"
      >
        <b-form-group
          label-cols-md="2"
          label-cols-lg="2"
          content-cols-sm
          content-cols-md="10"
        >
          <template v-slot:label>
            Đơn hàng nhà cung cấp<span class="validate-star"> *</span></template
          >
          <multiselect
            :disabled="!!dataSubmit.id"
            v-model="dataSubmit.order_supplier"
            class="rbt-custom-input"
            style="max-width: 100% !important"
            placeholder="Đơn hàng nhà cung cấp"
            :show-labels="false"
            @input="onChangeOrderSupplier"
            :internal-search="false"
            :options="ordersSupplier"
          >
            <span slot="noOptions">
              Không có đơn hàng nào!
            </span>
            <span slot="noResult">Không tìm thấy kết quả!!!</span>
            <template slot="singleLabel" slot-scope="props"
              ><span class="option__desc"
                ><span class="option__title">{{
                  props.option.name
                }}</span></span
              ></template
            >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <p class="option__title">
                  {{ props.option.name }}
                </p>
              </div>
            </template>
          </multiselect>
          <b-form-invalid-feedback>{{
            validationContext.errors[0]
          }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <div>
        <div class="table-responsive datatable-vue text-center mt-4">
          <b-table
            show-empty
            striped
            hover
            head-variant="light"
            bordered
            :items="itemsImport"
            :fields="tableFields"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template #empty>
              <span class="text-danger">
                Chưa có sản phẩm nào
              </span>
            </template>
            <template v-slot:cell(name)="{ item }">
              <span>{{ item.name }} </span>
            </template>
            <template v-slot:cell(unit)="{ item }">
              <span v-if="item.import_unit"
                >{{ item.product.import_unit }}
              </span>
            </template>
            <template v-slot:cell(quantity)="{ item }">
              <div class="d-flex justify-content-center align-items-center">
                <IncreaseAndDecrease
                  v-if="dataSubmit.status === 0 || !dataSubmit.id"
                  style="max-width: 150px"
                  :isNumber="Number(item.quantity) || 0"
                  @onChangeData="onChangeQuantity"
                  :isGreaterZero="true"
                  :product="item"
                  :isDiscount="true"
                />
                <span v-else>{{ item.quantity }}</span>
              </div>
            </template>
          </b-table>
          <div>
            <b-pagination
              v-if="itemsImport && itemsImport.length > 0"
              class="mt-2 d-flex justify-content-end"
              v-model="currentPage"
              :total-rows="itemsImport.length"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
      <template #modal-footer="{cancel}">
        <div style="width: 100%" class="d-flex justify-content-between">
          <div>
            <b-button
              class="mr-3"
              size="sm"
              variant="danger"
              @click="
                cancel();
                checkDisable = false;
                dataSubmit.products_in_goods_receipt.splice(
                  0,
                  dataSubmit.products_in_goods_receipt.length
                );
              "
            >
              Đóng
            </b-button>
          </div>
          <div>
            <b-button
              v-if="!dataSubmit.id"
              :disabled="!isComplete"
              class="btn-popup m-0"
              @click="createSupplierReceipt()"
            >
              <span style="font-size: 24px; font-weight: 100">
                +
              </span>
              Tạo phiếu</b-button
            >
            <b-button
              v-if="dataSubmit.status === 0"
              class="btn-popup mr-3 my-0"
              :disabled="!isComplete"
              @click="updateSupplierReceipt()"
            >
              Cập nhật</b-button
            >
            <b-button
              v-if="dataSubmit.status === 0"
              :disabled="checkDisable"
              class="btn-popup m-0"
              @click="approveSupplierReceipt()"
            >
              Duyệt</b-button
            >
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { WAREHOUSE_STATUS } from "../../config/warehouse/index";
import IncreaseAndDecrease from "../../components/UI/increaseAndDecrease.vue";

const initalDataImport = {
  product: null,
  quantity: null,
  price: null,
};

const initalDataSubmit = {
  products_in_goods_receipt: [],
  name: "",
  order_supplier: null,
  supplier: null,
};

export default {
  props: {
    warehouseImportInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    IncreaseAndDecrease,
  },
  name: "WarehouseImport",
  data() {
    return {
      currentPage: 1,
      perPage: process.env.VUE_APP_PAGE_SIZE,
      tableFields: [
        { key: "sno", label: "STT" },
        { key: "name", label: "Tên hàng" },
        { key: "unit", label: "Đơn vị tính" },
        { key: "quantity", label: "Số lượng" },
      ],
      dataImport: { ...initalDataImport },
      dataSubmit: { ...initalDataSubmit },
      checkDisable: false,
      WAREHOUSE_STATUS_TEXT: WAREHOUSE_STATUS.TEXT,
    };
  },
  async created() {
    this.getSupplierList();
  },
  methods: {
    ...mapActions("supplier", ["getSupplierList"]),
    ...mapActions("supplier", ["getOrderSupplier"]),
    createSupplierReceipt() {
      if (this.dataSubmit.products_in_goods_receipt.length > 0) {
        this.handleType({
          type: "add",
        });
        this.$bvModal.hide("supplier-receipt-modal");
        this.dataSubmit.products_in_goods_receipt.splice(
          0,
          this.dataSubmit.products_in_goods_receipt.length
        );
      } else {
        alert("Vui lòng chọn Nhà cung cấp và Đơn hàng");
      }
    },
    approveSupplierReceipt() {
      this.handleType({
        type: "approve",
      });
      this.$bvModal.hide("supplier-receipt-modal");
    },
    updateSupplierReceipt() {
      this.handleType({
        type: "update",
      });
      this.$bvModal.hide("supplier-receipt-modal");
    },
    handleType({ type }) {
      const {
        products_in_goods_receipt,
        order_supplier,
        ...data
      } = this.dataSubmit;
      const productList = products_in_goods_receipt.map((item) => {
        const { quantity } = item;
        return {
          product: item.product,
          quantity: parseFloat(quantity),
        };
      });

      const supplierReceiptData = {
        ...data,
        products_in_goods_receipt: productList,
        created_at: new Date(),
        order_supplier: order_supplier.id,
      };

      switch (type) {
        case "add":
          this.$emit("onCreate", {
            ...supplierReceiptData,
            status: this.WAREHOUSE_STATUS_TEXT.CREATE,
          });
          break;

        case "update":
          this.$emit("onUpdate", {
            ...supplierReceiptData,
            status: this.WAREHOUSE_STATUS_TEXT.CREATE,
          });
          break;

        case "approve":
          this.$emit("onUpdate", {
            ...supplierReceiptData,
            status: this.WAREHOUSE_STATUS_TEXT.PENDING,
          });
          break;
      }

      this.checkDisable = false;
    },
    addProduct() {
      this.dataSubmit.products_in_goods_receipt.push(this.dataImport);
      this.dataImport = { ...initalDataImport };
      this.checkDisable = true;
    },
    onChangeQuantity({ quantity, product }) {
      this.dataSubmit.products_in_goods_receipt.forEach((item) => {
        if (item.product.id === product.id) {
          item.quantity = quantity;
        }
      });
      this.checkDisable = true;
    },
    changeNote() {
      this.checkDisable = true;
    },
    onChangeOrderSupplier() {
      this.dataSubmit.products_in_goods_receipt = [];
      this.dataSubmit.order_supplier.products.forEach((item) => {
        const { quantity, product } = item;
        this.dataImport = {
          quantity,
          product,
        };
        this.addProduct();
        this.dataImport = { ...initalDataImport };
      });
    },
    onChangeSupplier() {
      this.dataSubmit.products_in_goods_receipt.splice(
        0,
        this.dataSubmit.products_in_goods_receipt.length
      );
      this.dataSubmit.order_supplier = null;
      const { id } = this.dataSubmit.supplier;
      this.getOrderSupplier({ supplier: id, status: "APPROVED_2" });
    },
  },
  computed: {
    ...mapState("supplier", ["ordersSupplier"]),
    ...mapState("supplier", ["supplierList"]),
    itemsImport() {
      return this.dataSubmit?.order_supplier?.products.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
    isComplete() {
      return this.dataSubmit.name;
    },
  },
  watch: {
    warehouseImportInfo() {
      if (this.warehouseImportInfo.id) {
        this.dataSubmit = this.warehouseImportInfo;
      } else {
        this.dataSubmit = { ...initalDataSubmit };
      }
    },
  },
};
</script>

<style>
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
.validate-star {
  color: red;
}
</style>

<style scoped>
.rbt-custom-input {
  max-width: 192px;
  white-space: nowrap;
  height: 43px !important;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
input {
  height: 100% !important;
}
</style>

<template>
  <div>
    <!-- Container-fluid starts-->
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Quản lý Slide" />
      </div>
      <div class="row">
        <div class="col">
          <div class="card tab2-card">
            <div class="card-header">
              <h5 v-if="this.$route.query.id">
                Cập nhật ảnh slide
              </h5>
              <h5 v-else>
                Thêm ảnh slide
              </h5>
            </div>
            <div class="card-body">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <b-form
                  class="needs-validation user-add"
                  @submit.stop.prevent="onSubmit()"
                  v-if="homepageImageInfo"
                >
                  <div class="row">
                    <div class="col-sm-6 col-md-12">
                      <div class="form-group row">
                        <label
                          for="validationCustom0"
                          class="col-xl-3 col-md-4"
                        >
                          Ảnh
                        </label>
                        <div class="col-xl-9 xl-50 col-sm-6 col-9">
                          <div class="text-center">
                            <img
                              v-if="homepageImageInfo.image"
                              :src="homepageImageInfo.image"
                              class="img-fluid image_zoom_1 blur-up lazyloaded"
                            />
                            <img
                              v-else
                              src="../../assets/images/image_default.png"
                              class="img-fluid image_zoom_1 blur-up lazyloaded test"
                            />
                            <a
                              style="position: absolute; bottom: -6px; left: 0;right: 0; cursor: pointer;"
                              class="text-dark"
                              @click="onChangeAvatar()"
                              ><i data-feather="camera"
                                ><feather type="camera"></feather></i
                            ></a>
                          </div>
                          <input
                            @change="onFileChanged"
                            ref="uploader"
                            class="form-control d-none"
                            id="validationCustom0"
                            type="file"
                            accept="image/*"
                          />
                        </div>
                      </div>

                      <validation-provider name="Tên slide">
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Tên slide
                          </template>
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="homepageImageInfo.name"
                            placeholder="Tên hiển thị với slide"
                          ></b-form-input>
                        </b-form-group>
                      </validation-provider>
                      <validation-provider>
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            Loại hình ảnh
                          </template>
                          <b-form-radio-group
                            id="radio-group-3"
                            v-model="homepageImageInfo.type"
                            name="radio"
                          >
                            <b-form-radio value="SLIDE">Slider</b-form-radio>
                            <b-form-radio value="LOGO">Logo</b-form-radio>
                          </b-form-radio-group>
                        </b-form-group>
                      </validation-provider>
                      <validation-provider>
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            Ẩn trên trang chủ
                          </template>
                          <b-form-checkbox
                            id="checkbox-2"
                            v-model="homepageImageInfo.disable"
                            name="checkbox-2"
                          >
                          </b-form-checkbox>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="pull-right">
                    <button
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="handleBackList()"
                    >
                      Trở lại
                    </button>
                    <button
                      v-if="!$route.query.id"
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="cancelUpdate()"
                    >
                      Xoá
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      :disabled="invalid || !isComplete"
                    >
                      Lưu
                    </button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loader from "../../components/loading/loader.vue";

const initialData = {
  name: "",
  image: "",
  type: "SLIDE",
  disable: false,
};

export default {
  data() {
    const homepageImageInfo = this.$route.query.id
      ? this.homepageImageInfo
      : initialData;
    return {
      homepageImageInfo,
      isChangeImage: false,
    };
  },
  components: {
    Loader,
  },
  async created() {
    if (this.$route.query.id) {
      this.homepageImageInfo = await this.getHomepageImageDetail(
        this.$route.query.id
      );
    } else {
      this.homepageImageInfo = {
        ...initialData,
      };
    }
    this.isChangeImage = false;
  },
  watch: {},
  computed: {
    ...mapState("homepageImage", ["isLoading"]),
    isComplete() {
      return this.homepageImageInfo.image && this.homepageImageInfo.name;
    },
  },
  methods: {
    ...mapActions("homepageImage", [
      "createHomepageImage",
      "updateHomepageImage",
      "getHomepageImageDetail",
    ]),
    onSubmit() {
      const { id, image, ...data } = this.homepageImageInfo;
      const homepageImageData = {
        ...(image && this.isChangeImage && { image }),
        ...data,
      };
      if (id) {
        const dataUpdate = {
          id: id,
          data: homepageImageData,
        };
        this.updateHomepageImage(dataUpdate);
      } else {
        this.createHomepageImage(homepageImageData);
      }
    },
    cancelUpdate() {
      this.homepageImageInfo = {
        ...initialData,
      };
    },
    onFileChanged(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = async () => {
        this.homepageImageInfo.image = await reader.result;
      };
      reader.readAsDataURL(file);
      this.isChangeImage = true;
    },
    onChangeAvatar() {
      this.$refs.uploader.click();
    },
    handleBackList() {
      this.$router.push({ name: "image-list" });
    },
  },
};
</script>
<style scoped></style>

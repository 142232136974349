import axiosInstance from "../../services/axios";

const url = "coupon.json";

const state = {
  couponList: [],
};
const getters = {
  getCoupons(state) {
    return state.couponList;
  },
};
const actions = {
  getCoupons(context) {
    axiosInstance
      .get(url)
      .then((response) => {
        context.commit("getCoupons", response.data);
      })
      .catch((error) => {});
  },
};
const mutations = {
  getCoupons(state, payload) {
    state.couponList = payload.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

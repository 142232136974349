export const ORDER_STATUS = {
  ADMIN: {
    0: "Mới tạo",
    1: "Chờ NCC duyệt",
    2: "Đã duyệt",
    3: "Hủy bỏ",
  },
  SUPPLIER: {
    0: "Chờ duyệt",
    1: "RBT đã duyệt",
    2: "Đã kiểm tra",
    3: "Đã hủy",
  },
  TEXT: {
    NEW_ORDER: "Đơn hàng mới",
    CONFIRMED: "Đã xác nhận",
    PICKING: "Đang lấy hàng",
    DELIVERING: "Đang giao",
    SUCCESS: "Hoàn thành",
    CUSTOMER_CANCEL: "Khách hủy",
    CANCEL: "Hủy",
  },
  APPEND_COLOR: {
    SUPPLIER: {
      0: "warning",
      1: "primary",
      2: "success",
      3: "danger",
    },
    ADMIN: {
      0: "warning",
      1: "success",
      2: "primary",
      3: "danger",
    },
  },
  APPEND_COLOR_TEXT: {
    NEW_ORDER: "primary",
    CONFIRMED: "secondary",
    PICKING: "warning",
    DELIVERING: "info",
    SUCCESS: "success",
    CUSTOMER_CANCEL: "danger",
    CANCEL: "danger",
  },
  SHIPPER_STATUS: {
    PICKING: "PICKING",
    DELIVERING: "DELIVERING",
    DELIVERED: "DELIVERED",
    CUSTOMER_CANCEL: "CUSTOMER_CANCEL",
    NEW_ORDER: "NEW_ORDER",
    CONFIRMED: "CONFIRMED",
    SUCCESS: "SUCCESS",
    CANCEL: "CANCEL",
  },
  NUMERIC: {
    NEW_ORDER: 31,
    ONLINE: 3,
  },
  ORDER_STATUS: {
    ACCOUNT: "Account",
    CASH: "Cash",
  },
  ORDER_STATUS_TEXT: {
    Account: "Tài khoản",
    Cash: "Tiền mặt",
  },
};

<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Thiết lập chung" title="Thiết lập chiết khấu theo GTĐH" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header"></div>
            <div class="card-body">
              <router-link to="/settings/create-discount">
                <b-button class="btn-popup pull-right"
                  >Thêm mới</b-button
                ></router-link
              >
              <div class="table-responsive datatable-vue product-list">
                <b-modal id="modal-2" title="Xác nhận" @ok="onDelete(isId)">
                  <p class="my-4">Xoá mức chiết khấu <b>{{ discount }}%</b></p>
                </b-modal>
                <b-table
                  v-if="discountList.length > 0"
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="discountList"
                  :fields="tablefields"
                >
                  <template v-slot:cell(value)="{ item }">
                    <span>
                      {{ item.value | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(discount)="{ item }">
                    <span> {{ item.discount }}% </span>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="onEdit(item)"
                      ></feather>
                    </span>
                    <span style="font-size: 20px; color: #FA399F;">
                      <feather
                        v-b-modal.modal-2
                        style="cursor: pointer"
                        type="trash"
                        stroke="#F72E9F"
                        size="18px"
                        fill="#F72E9F"
                        @click="setItem(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div class="text-center" v-if="discountList.length === 0">
                  Không có dữ liệu
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";

export default {
  data() {
    return {
      tablefields: [
        { key: "value", label: "Mức" },
        { key: "discount", label: "Được giảm" },
        { key: "actions", label: "Hành động" },
      ],
      search: "",
      pageNumber: 1,
      isId: "",
      discount: null,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
    };
  },
  components: {
    Loader,
  },
  created() {
    this.getDiscountList();
  },
  computed: {
    ...mapState("discount", ["discountList", "isLoading"]),
  },
  watch: {},
  methods: {
    ...mapActions("discount", ["getDiscountList", "deleteDiscount"]),
    onDelete(id) {
      this.deleteDiscount({ id: id });
      this.search = "";
    },
    onEdit(item) {
      this.$router.push({
        name: "create-discount",
        query: { id: item.id },
      });
    },
    setItem(item) {
      this.isId = item.id;
      this.discount = item.discount;
    },
  },
};
</script>

<template>
  <!-- Page Header Start-->
  <div
    :class="!sidebar ? 'page-main-header open' : 'page-main-header '"
    v-if="userProfile"
  >
    <div class="main-header-right row">
      <div class="main-header-left d-lg-none">
        <div class="logo-wrapper">
          <router-link to="/">
            <img
              class="blur-up lazyloaded"
              src="../../assets/images/dashboard/RBT-logo.png"
              alt=""
          /></router-link>
        </div>
      </div>
      <div class="mobile-sidebar">
        <div class="media-body text-right switch-sm">
          <label class="switch"
            ><a @click="toggle_sidebar"
              ><i id="sidebar-toggle"
                ><feather type="align-left"></feather></i></a
          ></label>
        </div>
      </div>
      <div class="nav-right col">
        <ul class="nav-menus" :class="headerMenu ? 'open' : ''">
          <b-button
            v-if="
              userProfile && userProfile.role != roleList.supplier && storeID
            "
            class="mr-3"
            @click="$router.push('/sales/order')"
            variant="outline-primary"
            >Bán hàng</b-button
          >

          <!-- <li>
            <form class="form-inline search-form">
              <div class="form-group">
                <i data-feather="search"> </i>
                <i
                  class="search-icon"
                  data-feather="search"
                  @click="toggle_search()"
                  ><feather type="search"></feather
                ></i>
                <input
                  class="form-control-plaintext"
                  :class="opensearch ? 'open' : ''"
                  type="search"
                  placeholder="Tìm kiếm.."
                /><span
                  class="d-sm-none mobile-search"
                  @click="toggle_search()"
                >
                </span>
              </div>
            </form>
          </li> -->
          <li>
            <a class="text-dark" href="#!" @click="toggleFullscreen()"
              ><i data-feather="maximize-2"
                ><feather type="maximize-2"></feather></i
            ></a>
          </li>
          <!-- <li class="onhover-dropdown">
            <a class="txt-dark">
              <h6>{{ $i18n.locale | capitalize }}</h6></a
            >
            <ul class="language-dropdown onhover-show-div p-20">
              <li
                v-for="(l, index) in localeOptions"
                :key="index"
                @click="changeLocale(l)"
              >
                <a
                  ><i class="flag-icon flag-icon-is"
                    ><country-flag :country="l.icon" size="normal"
                  /></i>
                  {{ l.name }}</a
                >
              </li>
            </ul>
          </li> -->
          <li class="onhover-dropdown">
            <i data-feather="help-circle"
              ><feather type="help-circle"></feather
            ></i>
            <!-- <span class="badge badge-primary pull-right notification-badge">3</span> -->
            <span class="dot"></span>
            <ul class="notification-dropdown onhover-show-div p-0">
              <li>
                Hướng dẫn
                <!-- <span class="badge badge-primary pull-right">3</span> -->
              </li>
              <li v-for="menu in menuHelp" :key="menu.index">
                <div class="media">
                  <div class="media-body d-flex align-items-center">
                    <h6 class="mt-0">
                      {{ menu.key }}
                    </h6>
                    <p class="mb-0">
                      {{ menu.title }}
                    </p>
                  </div>
                </div>
              </li>
              <!-- <li class="txt-dark"><a href="#">Tất cả thông báo</a></li> -->
            </ul>
          </li>
          <!-- <li>
            <a @click="openChatSidebar"
              ><i class="right_side_toggle" data-feather="message-square">
                <feather type="message-square"></feather
              ></i>
              <span class="dot"></span
            ></a>
            <chatBox></chatBox>
          </li> -->
          <li class="onhover-dropdown">
            <div class="media align-items-center" v-if="userProfile">
              <img
                class="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
                style="height: 50px"
                :src="getImgUrl(userProfile.avatar_url)"
                alt="header-user"
              />
              <div class="dotted-animation">
                <span class="animate-circle"></span
                ><span class="main-circle"></span>
              </div>
            </div>
            <ul
              class="profile-dropdown onhover-show-div p-10 profile-dropdown-hover"
            >
              <li>
                <router-link to="/settings/profile">
                  <a><feather type="user"></feather>Thông tin</a></router-link
                >
              </li>
              <li>
                <router-link to="/settings/change-password-profile">
                  <a
                    ><feather type="user"></feather>Đổi mật khẩu</a
                  ></router-link
                >
              </li>
              <li>
                <a><feather type="mail"></feather>Hòm thư</a>
              </li>
              <li>
                <a><feather type="settings"></feather>Cài đặt</a>
              </li>
              <li>
                <a @click="logout"
                  ><feather type="log-out"></feather><span>Thoát</span></a
                >
              </li>
            </ul>
          </li>
        </ul>
        <div class="d-lg-none mobile-toggle pull-right" @click="openHeader()">
          <feather type="more-horizontal"></feather>
        </div>
      </div>
    </div>
  </div>
  <!-- Page Header Ends -->
</template>

<script>
import firebase from "firebase";
import UserAuth from "../../auth/index.js";
import chatBox from "../../components/chatBox.vue";
import CountryFlag from "vue-country-flag";
import { localeOptions } from "../../constants/config";
import { mapActions, mapState, mapMutations } from "vuex";
import { menu } from "../../config/menu-help";
import { Roles } from "../../types/roles";

export default {
  name: "Header",
  components: {
    chatBox,
    CountryFlag,
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.resized = this.sidebar_toggle_var;
  },
  data() {
    return {
      chatSidebar: false,
      headerMenu: false,
      opensearch: false,
      localeOptions: localeOptions,
      langIcon: localStorage.getItem("currentLanguageIcon") || "flag-icon-us",
    };
  },
  filters: {
    capitalize: function(value) {
      if (!value) {
        return "";
      }
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  watch: {
    isMessage() {
      if (this.isMessage.isShow) {
        this.$toasted.show(`${this.isMessage.content}`, {
          type: this.isMessage.type,
        });
        setTimeout(() => {
          this.setMessage({
            type: "",
            isShow: false,
            content: "",
          });
        }, 4000);
      }
    },
    errorList() {
      if (this?.errorList?.status === 400) {
        const dataError = this.errorList?.data?.message
          ? this?.errorList?.data?.message
          : this?.errorList?.data;
        switch (Object.keys(dataError)[0]) {
          case "product_quotation":
            this.$toasted.show(
              "Có sản phẩm không tồn tại, vui lòng kiểm tra lại",
              {
                duration: 4000,
                type: "error",
              }
            );
            break;
          case "old_password":
            this.$toasted.show(dataError[Object.keys(dataError)], {
              duration: 4000,
              type: "error",
            });
            break;
          case "non_field_errors":
            this.$toasted.show(dataError[Object.keys(dataError)], {
              duration: 4000,
              type: "error",
            });
            break;
          case typeof dataError === "string":
            this.$toasted.show(dataError, {
              duration: 4000,
              type: "error",
            });
            break;
          default:
            for (const [key, value] of Object.entries(dataError)) {
              this.$toasted.show(`${value}`, {
                duration: 4000,
                type: "error",
              });
            }
        }
      }
      if (this?.errorList?.status === 403) {
        this.$toasted.show(this?.errorList?.data?.detail, {
          duration: 4000,
          type: "info",
        });
      }
      if (this?.errorList?.status === 500) {
        this.$toasted.show(this?.errorList?.data?.message, {
          duration: 4000,
          type: "error",
        });
      }
    },
    "$i18n.locale"(to, from) {
      if (from !== to) {
        this.$router.go(this.$route.path);
      }
    },
    isMessage() {
      if (this.isMessage.isShow) {
        this.$toasted.show(`${this.isMessage.content}`, {
          type: this.isMessage.type,
        });
        setTimeout(() => {
          this.setMessage({
            type: "",
            isShow: false,
            content: "",
          });
        }, 4000);
      }
    },
  },
  computed: {
    menuHelp() {
      return menu;
    },
    sidebar() {
      return this.$store.state.menu.toggleSidebar;
    },
    ...mapState("profile", ["userProfile"]),
    ...mapState(["isMessage", "errorList"]),
    roleList() {
      return Roles;
    },
    storeID() {
      return this.userProfile?.store?.id;
    },
  },
  methods: {
    ...mapActions(["setLang", "setMessage"]),
    ...mapMutations("menu", ["openSidebar"]),
    toggle_sidebar() {
      this.$store.dispatch("menu/openSidebar");
    },
    openHeader() {
      this.headerMenu = !this.headerMenu;
    },
    openChatSidebar() {
      this.$store.dispatch("menu/openChatSidebar", true);
    },
    toggle_search() {
      this.opensearch = !this.opensearch;
    },
    toggleFullscreen() {
      this.toggle_sidebar();
      if (
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)
      ) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    logout: function() {
      UserAuth.Logout();
      this.$router.replace("/auth/login").catch((err) => err);
    },
    changeLocale(locale) {
      this.setLang(locale);
    },
    handleResize() {
      this.width = window.innerWidth;
    },
    getImgUrl(path) {
      if (path) {
        return `${path}`;
      } else return require("../../assets/images/dashboard/avatar.png");
    },
  },
};
</script>

<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Đơn đặt hàng nhà cung cấp" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>{{ totalRecordOrder }} kết quả</h5>
            </div>
            <div class="card-body">
              <div class="row mb-4">
                <div
                  class="col-sm-8 d-flex justify-content-center align-items-center"
                >
                  <b-form-select v-model="supplier" class="col-sm-7">
                    <option value="">Lọc theo tên NCC</option>
                    <option
                      v-for="supplier in supplierList"
                      :key="supplier.index"
                      :value="supplier.id"
                      >{{ supplier.name }}</option
                    >
                  </b-form-select>
                  <date-picker
                    v-model="date"
                    value-type="format"
                    range
                    format="DD-MM-YYYY"
                    placeholder="Thời gian tạo từ ngày - đến ngày"
                    class="pl-0 flex-wrap ncc-date-picker mr-2 ml-3"
                  ></date-picker>
                  <div class="ml-3">
                    <b-button
                      class="btn-popup mb-0"
                      variant="primary"
                      @click="onSearch()"
                      >Tìm</b-button
                    >
                  </div>
                </div>
                <div class="col-sm-4 d-flex justify-content-end">
                  <b-button class="btn-popup m-0" @click="openModal()">
                    <span style="font-size: 24px; font-weight: 100">
                      +
                    </span>
                    Tạo đơn đặt hàng NCC</b-button
                  >
                </div>
              </div>
              <div class="table-responsive datatable-vue">
                <b-modal
                  id="modal-2"
                  title="Xác nhận"
                  @ok="deleteOrderSupplier(isId)"
                >
                  <p class="my-4">
                    Xóa <b>{{ isName }}</b>
                  </p>
                </b-modal>
                <b-table
                  v-if="!isLoading && ordersSupplier.length > 0"
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  :items="ordersSupplier"
                  :fields="tablefields"
                >
                  <template v-slot:cell(status)="{ item }">
                    <b-badge :variant="colorStatus[item.status]">
                      {{ ORDER_STATUS_ADMIN[item.status] }}
                    </b-badge>
                  </template>
                  <template v-slot:cell(supplier_name)="{ item }">
                    <span v-if="item.supplier_name">
                      {{ item.supplier_name }}
                    </span>
                  </template>
                  <template v-slot:cell(created_at)="{ item }">
                    <div class="number-and-date-table" v-if="item.created_at">
                      {{ item.created_at | dateFormat }}
                    </div>
                  </template>
                  <template v-slot:cell(created_by_name)="{ item }">
                    <span v-if="item.created_by_name">
                      {{ item.created_by_name }}
                    </span>
                  </template>
                  <template v-slot:cell(recieve_time)="{ item }">
                    <div class="number-and-date-table" v-if="item.recieve_time">
                      {{ item.recieve_time | dateFormat }}
                    </div>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="handleOrderSupplier(item)"
                      ></feather>
                    </span>
                    <span style="font-size: 20px; color: #FA399F;">
                      <feather
                        v-if="item.status === 0"
                        v-b-modal.modal-2
                        style="cursor: pointer"
                        type="trash"
                        stroke="#F72E9F"
                        size="18px"
                        fill="#F72E9F"
                        @click="setItem(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div
                  v-if="!isLoading && ordersSupplier.length === 0"
                  class="text-center"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRecordOrder > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecordOrder"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
              <OrderSupplierModal
                :orderInfo="orderInfo"
                @onCreate="onCreate"
                @onUpdate="onUpdate"
                @onApproved="onApproved"
                @onCancel="onCancel"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import OrderSupplierModal from "../../components/modals/OrderSupplierModal.vue";
import { ORDER_SUPPLIER_STATUS } from "../../config/warehouse/index";
import { reverseDate } from "../../config/common";

export default {
  components: {
    OrderSupplierModal,
    Loader,
  },
  data() {
    return {
      tablefields: [
        { key: "code", label: "Mã đơn hàng" },
        { key: "name", label: "Tên Đơn hàng" },
        { key: "created_at", label: "Ngày tạo" },
        { key: "created_by_name", label: "Người tạo" },
        { key: "supplier_name", label: "Nhà cung cấp" },
        { key: "recieve_time", label: "Thời gian nhận hàng" },
        // { key: "delivery_method", label: "Phương thức giao hàng" },
        { key: "status", label: "Trạng thái" },
        { key: "actions", label: "Hành động" },
      ],
      query: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      isId: "",
      isName: "",
      orderInfo: {},
      date: "",
      supplier: "",
    };
  },
  created() {
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = { page: pageNumber, page_size: this.pageSize };
    this.getOrderSupplier(params);
    this.getSupplierList();
  },
  computed: {
    ...mapState("supplier", [
      "supplierList",
      "ordersSupplier",
      "isLoading",
      "totalRecordOrder",
    ]),
    colorStatus() {
      return ORDER_SUPPLIER_STATUS.APPEND_COLOR;
    },
    ORDER_STATUS_ADMIN() {
      return ORDER_SUPPLIER_STATUS.ADMIN;
    },
  },
  watch: {},
  methods: {
    ...mapActions("supplier", [
      "getOrderSupplier",
      "onCreateOrderSupplier",
      "orderSupplierDetail",
      "onApprovedOrderSupplier",
      "onCancelOrderSupplier",
      "onDeleteOrder",
      "onUpdateOrderSupplier",
      "getSupplierList",
    ]),
    openModal() {
      this.orderInfo = {};
      this.$bvModal.show("order-supplier-modal");
    },
    deleteOrderSupplier(id) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onDeleteOrder({ data: id, params });
      this.handleClearData();
    },
    onCreate(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onCreateOrderSupplier({ data: payload, params });
      this.handleClearData();
    },
    onUpdate(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onUpdateOrderSupplier({ data: payload, params });
      this.handleClearData();
    },
    onCancel(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onCancelOrderSupplier({ data: payload, params });
      this.handleClearData();
    },
    onApproved(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onApprovedOrderSupplier({ data: payload, params });
      this.handleClearData();
    },
    setItem(item) {
      this.isId = item.id;
      this.isName = item.name;
    },
    handleClearData() {
      this.date = "";
      this.query = "";
      this.supplier = "";
    },
    async handleOrderSupplier(item) {
      this.orderInfo = await this.orderSupplierDetail(item.id);
      this.$bvModal.show("order-supplier-modal");
    },
    onSearch() {
      let params = {
        page: 1,
        page_size: this.pageSize,
      };
      if (this.date[0] && this.date[1]) {
        params = {
          date_start: reverseDate(this.date[0]),
          date_end: reverseDate(this.date[1]),
          ...params,
        };
      }
      if (this.query) {
        params = { search: this.query, ...params };
      }
      if (this.supplier) {
        params = {
          supplier: this.supplier,
          ...params,
        };
      }
      this.getOrderSupplier(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/supplier-transaction/order-list",
          query: { page: this.pageNumber },
        });
      }
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.date[0] && this.date[1]) {
        params = {
          date_start: reverseDate(this.date[0]),
          date_end: reverseDate(this.date[1]),
          ...params,
        };
      }
      if (this.query) {
        params = { search: this.query, ...params };
      }
      if (this.supplier) {
        params = {
          supplier: this.supplier,
          ...params,
        };
      }
      this.getOrderSupplier(params);
      this.$router.push({
        path: "/supplier-transaction/order-list",
        query: { page: this.pageNumber },
      });
    },
  },
};
</script>

<template>
  <div class="col-xl-12 xl-100">
    <div class="order-graph xl-space">
      <div class="row d-flex mb-2">
        <date-picker
          v-model="date"
          value-type="format"
          range
          class="ml-3"
          format="DD-MM-YYYY"
          placeholder="Chọn ngày để xem"
          @change="onChangeValue"
        ></date-picker>
      </div>
      <div class="ct-4 flot-chart-container">
        <apexchart
          ref="realtimeChart"
          type="bar"
          height="400"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { reverseDate } from "../../../../config/common";

export default {
  data() {
    return {
      series: [
        {
          name: "Doanh thu",
          data: [],
        },
      ],
      options: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [],
        },
        noData: {
          text: "Không có dữ liệu",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
        },
      },
      date: "",
    };
  },
  created() {
    this.getRevenueReport();
  },
  watch: {
    revenueReport() {
      if (this.revenueReport) {
        this.setDataChart();
      }
    },
  },
  computed: {
    ...mapState("dashboard", ["revenueReport"]),
  },
  methods: {
    ...mapActions("dashboard", ["getRevenueReport"]),
    setDataChart() {
      const dataDate = this.revenueReport.map((item) => reverseDate(item.date));
      const dataRevenue = this.revenueReport.map((item) => item.revenue);
      this.$refs.realtimeChart.updateSeries(
        [
          {
            data: dataRevenue,
          },
        ],
        false,
        true
      );
      this.options = {
        ...this.options,
        ...{
          xaxis: {
            categories: dataDate,
          },
        },
      };
    },
    onChangeValue() {
      if (this.date[0] && this.date[1]) {
        const params = {
          start: reverseDate(this.date[0]),
          end: reverseDate(this.date[1]),
          ...params,
        };
        this.getRevenueReport(params);
      } else {
        this.getRevenueReport();
      }
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <b-modal size="lg" id="quotation-modal" centered>
      <template #modal-header>
        <h3 class="mx-auto" style="color: #ff8084">
          Báo giá nhà cung cấp
        </h3>
      </template>
      <div>
        <b-form-group
          label-cols-md="2"
          label-cols-lg="2"
          content-cols-sm
          content-cols-md="10"
        >
          <template v-slot:label>
            Tên báo giá<span class="validate-star"> *</span></template
          >
          <input
            placeholder="Tên báo giá"
            id="validationCustom1"
            :disabled="!!dataSubmit.id"
            type="text"
            class="form-control rbt-custom-input"
            style="max-width: 100% !important"
            v-model="dataSubmit.name"
          />
        </b-form-group>
        <validation-provider>
          <b-form-group
            label-cols-md="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-md="10"
          >
            <template v-slot:label>
              Nhà cung cấp<span class="validate-star"> *</span></template
            >
            <input
              placeholder="Nhà cung cấp"
              :disabled="!!dataSubmit.id"
              class="form-control rbt-custom-input"
              style="max-width: 100% !important"
              v-model="dataSubmit.supplier_name"
            />
          </b-form-group>
        </validation-provider>
        <div class="table-responsive datatable-vue text-center mt-4">
          <b-table
            show-empty
            striped
            hover
            head-variant="light"
            bordered
            :items="listProductTable"
            :fields="tablefields"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template #empty>
              <span class="text-danger">
                Chưa có sản phẩm nào
              </span>
            </template>

            <template #thead-top>
              <b-tr>
                <b-th style="width: 50px">STT</b-th>
                <b-th>Sản phẩm</b-th>
                <b-th style="width: 100px">Đơn vị tính</b-th>
                <b-th style="width: 160px">Đơn giá</b-th>
                <b-th style="width: 160px">Giá khuyến nghị</b-th>
                <b-th colspan="2">Khối lượng tính</b-th>
              </b-tr>
            </template>

            <template v-slot:cell(unit_price)="{ item }">
              <span>
                {{ item.unit_price | formatCurrency }}
              </span>
            </template>
            <template v-slot:cell(recommended_price)="{ item }">
              <span>
                {{ item.recommended_price | formatCurrency }}
              </span>
            </template>
            <template v-slot:cell(quantity)="{ item }">
              <span>
                {{ item.quantity }}
              </span>
            </template>
            <template v-slot:cell(unit)="{ item }">
              <span>
                {{ item.unit }}
              </span>
            </template>
            <template v-slot:cell(lowest_weight)="{ item }">
              <div>Lớn hơn {{ item.lowest_weight || 0 }}</div>
            </template>
          </b-table>
          <div>
            <b-pagination
              v-if="listProductTable.length > 0"
              class="mt-2 d-flex justify-content-end"
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="listProductTable.length"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
      <template #modal-footer="{cancel}">
        <div style="width: 100%" class="d-flex justify-content-between">
          <div>
            <b-button
              v-if="dataSubmit.status === 0"
              type="button"
              class="m-0 btn btn-danger"
              @click="cancelQuotation()"
            >
              <span>
                Hủy báo giá
              </span>
            </b-button>
            <h2>
              <span v-if="dataSubmit.status === 3" class="badge badge-danger"
                >Đã huỷ</span
              >
            </h2>
            <h2>
              <span v-if="dataSubmit.status === 2" class="badge badge-primary"
                >Đã duyệt</span
              >
            </h2>
          </div>
          <div>
            <b-button class="mr-3" size="sm" variant="danger" @click="cancel()">
              Đóng
            </b-button>
            <b-button
              v-if="dataSubmit.status === 0"
              class="btn-popup m-0"
              @click="approveQuotation()"
            >
              Duyệt báo giá</b-button
            >
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { WAREHOUSE_STATUS } from "../../config/warehouse/index";

const initialDataSubmit = {
  products: [],
  supplier: {
    name: "",
  },
  name: "",
  note: "",
};

export default {
  props: {
    quotationsInfo: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      tablefields: [
        { key: "sno", label: "" },
        { key: "name", label: "" },
        { key: "unit", label: "" },
        { key: "unit_price", label: "" },
        { key: "recommended_price", label: "" },
        { key: "lowest_weight", label: "Từ" },
        { key: "highest_weight", label: "Đến" },
      ],
      productQuotes: [],
      dataSubmit: { ...initialDataSubmit },
      WAREHOUSE_STATUS_TEXT: WAREHOUSE_STATUS.TEXT,
    };
  },
  computed: {
    listProductTable() {
      return this.productQuotes.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
  },
  watch: {
    quotationsInfo() {
      if (this.quotationsInfo.id) {
        this.dataSubmit = { ...this.quotationsInfo };
        this.productQuotes = this.quotationsInfo.products;
      } else {
        this.dataSubmit = { ...this.initialDataSubmit };
        this.productQuotes = [];
      }
    },
  },
  methods: {
    ...mapActions("supplier", [
      "getSupplierList",
      "onQuotations",
      "onQuotationsUpdate",
    ]),
    approveQuotation() {
      this.handleType({
        type: "approve",
      });
    },
    cancelQuotation() {
      this.handleType({
        type: "cancel",
      });
    },
    handleType({ type }) {
      const { id } = this.dataSubmit;
      if (type === "approve") {
        this.$emit("onUpdateQuotations", {
          id,
          status: this.WAREHOUSE_STATUS_TEXT.APPROVED,
        });
      } else if (type === "cancel") {
        this.$emit("onUpdateQuotations", {
          id,
          status: this.WAREHOUSE_STATUS_TEXT.CANCEL,
        });
      }
      this.dataSubmit = { ...initialDataSubmit };
      this.$bvModal.hide("quotation-modal");
    },
  },
};
</script>

<style>
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
</style>

<style scoped>
.rbt-custom-input {
  max-width: 250px;
  white-space: nowrap;
  height: 43px !important;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
input {
  height: 100% !important;
}
</style>

<template>
  <div>
    <!-- Container-fluid starts-->
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Danh mục sản phẩm con" />
      </div>
      <div class="row">
        <div class="col">
          <div class="card tab2-card">
            <div class="card-header">
              <h5 v-if="this.$route.query.id">
                Cập nhật danh mục sản phẩm con
              </h5>
              <h5 v-else>
                Thêm danh mục sản phẩm con
              </h5>
            </div>
            <div class="card-body">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <b-form
                  class="needs-validation user-add"
                  @submit.stop.prevent="onSubmit()"
                  v-if="categoryInfo"
                >
                  <div class="row">
                    <div class="col-sm-6 col-md-12">
                      <div class="form-group row">
                        <label
                          for="validationCustom0"
                          class="col-xl-3 col-md-4"
                        >
                          Ảnh
                        </label>

                        <div class="col-xl-8 col-md-7 px-0">
                          <div class="text-center">
                            <img
                              v-if="categoryInfo.image"
                              :src="categoryInfo.image"
                              width="75"
                              height="75"
                              fluid
                              rounded
                            />
                            <img
                              v-else
                              src="../../assets/images/image_default.png"
                              width="75"
                              height="75"
                              fluid
                              rounded
                            />
                            <a
                              style="position: absolute; bottom: -6px; left: 0;right: 0; cursor: pointer;"
                              class="text-dark"
                              @click="onChangeAvatar()"
                              ><i data-feather="camera"
                                ><feather type="camera"></feather></i
                            ></a>
                            <input
                              ref="uploader"
                              @change="onFileChanged"
                              class="form-control d-none"
                              id="validationCustom0"
                              type="file"
                              accept="image/*"
                            />
                          </div>
                        </div>
                      </div>

                      <validation-provider
                        name="Tên danh mục sản phẩm"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Tên danh mục sản phẩm con
                          </template>
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="categoryInfo.name"
                            placeholder="Nhập tên danh mục sản phẩm con"
                            :state="getValidationState(validationContext)"
                          ></b-form-input>

                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Danh mục chính"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Danh mục sản phẩm chính
                          </template>
                          <multiselect
                            placeholder="Chọn danh mục sản phẩm chính"
                            v-model="categoryInfo.parent"
                            :show-labels="false"
                            class="rbt-custom-input"
                            :custom-label="searchingSelect"
                            :options="categoryList"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>

                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Thứ tự hiển thị"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-2"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-2"
                        >
                          <template v-slot:label>
                            <span>*</span>Thứ tự hiển thị
                          </template>
                          <b-form-input
                            id="example-input-2"
                            name="example-input-2"
                            type="number"
                            v-model="categoryInfo.order_by"
                            placeholder="Nhập thứ tự hiển thị"
                            :state="getValidationState(validationContext)"
                          ></b-form-input>

                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Mô tả"
                        :rules="{}"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label="Mô tả thêm"
                        >
                          <b-form-textarea
                            id="textarea-rows"
                            rows="8"
                            v-model="categoryInfo.description"
                            placeholder="Nhập mô tả về danh mục"
                            :state="getValidationState(validationContext)"
                          ></b-form-textarea>

                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <b-form-group
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="8"
                      >
                        <b-form-checkbox
                          id="checkbox-1"
                          v-model="categoryInfo.active"
                          name="checkbox-1"
                        >
                          Có kinh doanh
                        </b-form-checkbox>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="pull-right">
                    <button
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="handleBackList()"
                    >
                      Trở lại
                    </button>
                    <button
                      v-if="!$route.query.id"
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="cancelUpdate()"
                    >
                      Xoá
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      :disabled="invalid"
                    >
                      Lưu
                    </button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loader from "../../components/loading/loader.vue";

const initialData = {
  name: "",
  image: "",
  description: "",
  active: true,
  parent: "",
  order_by: "",
};

export default {
  data() {
    const categoryInfo = this.$route.query.id ? this.categoryInfo : initialData;
    return {
      categoryInfo,
      level: 0,
      isChangeImage: false,
    };
  },
  components: {
    Loader,
  },
  async created() {
    if (this.$route.query.id) {
      this.categoryInfo = await this.getCategoryDetail(this.$route.query.id);
    } else {
      this.categoryInfo = {
        ...initialData,
      };
    }
    const params = {
      level: this.level,
    };
    this.getCategoryList(params);
    this.isChangeImage = false;
  },
  watch: {},
  computed: {
    ...mapState("category", ["categoryList", "isLoading"]),
  },
  methods: {
    ...mapActions("category", [
      "getCategoryList",
      "createCategory",
      "updateCategory",
      "getCategoryDetail",
    ]),
    onSubmit() {
      const { id, image, parent, ...data } = this.categoryInfo;
      const dataSubmit = {
        parent: parent.id,
        ...(image && this.isChangeImage && { image }),
        ...data,
      };
      if (id) {
        const categoryData = { id: id, data: dataSubmit };
        this.updateCategory({ ...categoryData });
      } else {
        this.createCategory(dataSubmit);
      }
    },
    cancelUpdate() {
      this.categoryInfo = {
        ...initialData,
      };
    },
    onFileChanged(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = async () => {
        this.categoryInfo.image = await reader.result;
      };
      reader.readAsDataURL(file);
      this.isChangeImage = true;
    },
    searchingSelect({ name }) {
      return name;
    },
    onChangeAvatar() {
      this.$refs.uploader.click();
    },
    handleBackList() {
      this.$router.push({ name: "category-list" });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated
        ? (valid = valid === true ? null : valid)
        : null;
    },
  },
};
</script>
<style scoped></style>

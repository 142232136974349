import promotionService from "../../services/promotion";

const state = {
  promotions: [],
  totalRecord: 0,
  isLoading: false,
};
const getters = {};
const actions = {
  async getPromotions({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await promotionService.getPromotions(params);
      const { results, count } = response.data.data;
      commit("SET_PROMOTION", results);
      commit("SET_TOTAL_RECORD", count);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getDetailPromotion({ commit }, id) {
    try {
      commit("SET_LOADING", true);
      const response = await promotionService.getDetailPromotion(id);
      commit("SET_LOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING", false);
      return {};
    }
  },

  async createPromotion({ dispatch, commit }, payload) {
    try {
      const { params, data } = payload;
      await commit("SET_LOADING", true);
      const response = await promotionService.createPromotion(data);
      dispatch("getPromotions", params);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, {
        root: true,
      });
    }
  },

  async updatePromotion({ commit, dispatch }, payload) {
    try {
      const { data, params } = payload;
      await commit("SET_LOADING", true);
      const response = await promotionService.updatePromotion(data);
      dispatch("getPromotions", params);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
};
const mutations = {
  SET_PROMOTION(state, payload) {
    state.promotions = payload;
  },
  SET_TOTAL_RECORD(state, payload) {
    state.totalRecord = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import ProductServices from "../../services/product";
import { apiRoutesEnum } from "../../enums/routes";

const state = {
  totalRecord: 0,
  isLoading: false,
  isListProduct: [],
  isListAllProducts: [],
  totalRecordSellPrice: 0,
  totalRecordPrice: 0,
  listPrice: [],
  processImportProduct: {
		display: false,
    error: null,
    total: 0,
    success: 0,
    fail: 0,
    line_errors: null,
  },
  exampleProductExcel: `${process.env.VUE_APP_API_URL}${apiRoutesEnum.exampleProductExcel}`,
};
const getters = {
  getExampleProductExcel: (state) => {
    return state.exampleProductExcel;
  },
};
const actions = {
  async importListProduct({ commit, dispatch, state }, payload) {
    let error = null;
    try {
      commit("SET_LOADING", true);
      const response = await ProductServices.importProduct(payload);
      const { message, result } = response.data;
      const { fail, line_errors, success, total } = result;
      commit("SET_PROCESS_IMPORT_PRODUCT", {
        display: true,
        error,
        total,
        success,
        fail,
        line_errors: line_errors.join(","),
      });
      commit("SET_LOADING", false);
    } catch (err) {
      error = err.response.data.message;
      commit("SET_PROCESS_IMPORT_PRODUCT", {
        display: true,
        error,
        total: 0,
        success: 0,
        fail: 0,
        line_errors: null,
      });
      commit("SET_LOADING", false);
      // await commit("SET_ERROR_LIST", err.response, { root: true });
    }
  },
  async onListProduct({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      commit("setListProduct", []);
      const response = await ProductServices.getProducts(params);
      const { count, results } = response.data.data;
      commit("SET_TOTAL_RECORD", count);
      commit("setListProduct", results);
      commit("SET_PROCESS_IMPORT_PRODUCT", {
        display: false,
        error: null,
        total: 0,
        success: 0,
        fail: 0,
        line_errors: null,
      });
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },
  async onDetailProduct({ commit }, id) {
    try {
      commit("SET_LOADING", true);
      const response = await ProductServices.detailProduct(id);
      commit("SET_LOADING", false);
      return response;
    } catch (error) {
      commit("SET_LOADING", false);
      return null;
    }
  },
  async productAdd({ commit, dispatch }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await ProductServices.productAdd(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Thêm sản phẩm thành công",
          isShow: true,
        },
        { root: true }
      );
      dispatch("onListProduct", { page: 1, page_size: 10 });
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
  async onUpdateProduct({ commit, dispatch }, payload) {
    try {
      const { data, params } = payload;
      await commit("SET_LOADING", true);
      const response = await ProductServices.updateProduct(data);
      await dispatch("onListProduct", params);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
  async removeProduct({ commit, dispatch }, payload) {
    try {
      const { id, params } = payload;
      await commit("SET_LOADING", true);
      const response = await ProductServices.delete(id);
      await dispatch("onListProduct", params);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Xoá thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  // action price
  async removePrice({ dispatch, commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const { id, params } = payload;
      const response = await ProductServices.deletePrice(id);
      await commit("SET_LOADING", false);
      dispatch("onPriceList", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Xoá thành công",
          isShow: true,
        },
        { root: true }
      );
      return response;
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async sendNotifyPromotion({ dispatch, commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const { id, params } = payload;
      const response = await ProductServices.sendNotifyPromotion(id);
      await commit("SET_LOADING", false);
      dispatch("onPriceList", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Gửi thông báo thành công",
          isShow: true,
        },
        { root: true }
      );
      return response;
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async onCreatePrice({ commit, dispatch }, payload) {
    const { data, params } = payload;
    try {
      const response = await ProductServices.createPrice(data);
      dispatch("onPriceList", params);
    } catch (error) {
      commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async onProductSellPrice({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      commit("SET_LIST_ALL_PRODUCTS", []);
      const response = await ProductServices.getProductPrice(params);
      commit("SET_LIST_ALL_PRODUCTS", response.data);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getDetailPriceList({ commit }, id) {
    try {
      commit("SET_LOADING", true);
      const response = await ProductServices.getDetailPriceList(id);
      commit("SET_LOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING", false);
      return {};
    }
  },

  async onUpdatePrice({ commit, dispatch }, payload) {
    const { data, params } = payload;
    try {
      commit("SET_LOADING", true);
      const response = await ProductServices.updatePrice(data);
      commit("SET_LOADING", false);
      dispatch("onPriceList", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật bảng giá thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
  async updateStatusProduct({ commit, dispatch }, payload) {
    const { data, params } = payload;
    try {
      commit("SET_LOADING", true);
      const response = await ProductServices.updateStatusProduct(data);
      commit("SET_LOADING", false);
      dispatch("onListProduct", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật trạng thái thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
  async onPriceList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await ProductServices.getPriceList(params);
      const { count, results } = response.data.data;
      commit("SET_TOTAL_RECORD_PRICE", count);
      commit("SET_LIST_PRICE", results);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
  async onPriceListActive({ commit }) {
    try {
      commit("SET_LOADING", true);
      const response = await ProductServices.getPriceListActive();
      const { count, results } = response.data.data;
      commit("SET_TOTAL_RECORD_PRICE", count);
      commit("SET_LIST_PRICE", results);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
  onNotExcelExtension({ commit }) {
    commit("SET_PROCESS_IMPORT_PRODUCT", {
      display: false,
      total: 0,
      success: 0,
      fail: 0,
      line_errors: null,
      error: "Định dạng tệp không hợp lệ. Vui lòng tải lên tệp .xlsx",
    });
  },
  clearImportStatus({ commit }) {
    commit("SET_PROCESS_IMPORT_PRODUCT", {
      display: false,
      error: null,
      total: 0,
      success: 0,
      fail: 0,
      line_errors: null,
    });
  },
};
const mutations = {
  SET_PROCESS_IMPORT_PRODUCT(state, payload) {
    state.processImportProduct = payload;
  },
  SET_LIST_PRICE(state, payload) {
    state.listPrice = payload;
  },
  SET_TOTAL_RECORD_PRICE(state, payload) {
    state.totalRecordPrice = payload;
  },
  SET_TOTAL_RECORD(state, payload) {
    state.totalRecord = payload;
  },
  SET_TOTAL_RECORD_SELL_PRICE(state, payload) {
    state.totalRecordSellPrice = payload;
  },
  setListProduct(state, payload) {
    state.isListProduct = payload;
  },
  SET_LIST_ALL_PRODUCTS(state, payload) {
    state.isListAllProducts = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

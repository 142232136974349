var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loader',{attrs:{"is-visible":_vm.isLoadingImport}}),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"page-header"},[_c('Breadcrumbs',{attrs:{"title":"Phiếu nhập hàng nhà cung cấp"}})],1)]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h5',[_vm._v(_vm._s(_vm.totalImportlist)+" kết quả")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-sm-12 d-flex align-items-center flex-wrap"},[_c('date-picker',{staticClass:"pl-0 flex-wrap ncc-date-picker mr-2 mb-2",attrs:{"value-type":"format","range":"","format":"DD-MM-YYYY","placeholder":"Lọc theo ngày"},on:{"change":_vm.onSearch},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('b-form-group',{staticClass:"datatable-select col-sm-5 pl-0 mb-2"},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Lọc theo tên đơn hàng"},on:{"change":function($event){return _vm.onSearch()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"mb-2"},[_c('b-button',{staticClass:"btn-popup mb-0",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.onSearch()}}},[_vm._v("Tìm")])],1)],1)]),_c('div',{staticClass:"table-responsive datatable-vue standard-list"},[(!_vm.isLoadingImport && _vm.importList.length > 0)?_c('b-table',{staticClass:"text-center",attrs:{"striped":"","hover":"","head-variant":"light","bordered":"","items":_vm.importList,"fields":_vm.tablefields},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('span',[_vm._v(" Không có dữ liệu ")])]},proxy:true},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('b-badge',{attrs:{"variant":_vm.colorStatus[item.status]}},[_vm._v(" "+_vm._s(_vm.TRANSACTION_STATUS_SUPPLIER[item.status])+" ")])],1)]}},{key:"cell(staff)",fn:function(ref){
var item = ref.item;
return [(item.staff)?_c('span',[_vm._v(" "+_vm._s(item.staff.name)+" ")]):_vm._e()]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [(item.created_at)?_c('div',{staticClass:"number-and-date-table"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.created_at))+" ")]):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"20px","color":"blue"}},[_c('feather',{staticStyle:{"cursor":"pointer"},attrs:{"type":"edit-2","stroke":"#3758FD","stroke-width":"1","size":"18px","fill":"#3758FD","stroke-linejoin":"round"},on:{"click":function($event){return _vm.onEdit(item)}}})],1)]}}],null,false,2008170850)}):_vm._e(),(!_vm.isLoadingImport && _vm.importList.length === 0)?_c('div',{staticClass:"text-center"},[_vm._v(" Không có dữ liệu ")]):_vm._e()],1),_c('b-col',{staticClass:"my-1 p-0 pagination-justify",attrs:{"md":"12"}},[(_vm.totalImportlist > 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalImportlist,"per-page":_vm.pageSize,"aria-controls":"my-table"},on:{"change":_vm.handlePageChange},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}}):_vm._e()],1),_c('ImportTransactionDetail',{attrs:{"warehouseImportInfo":_vm.warehouseImportInfo},on:{"onApproved":_vm.onApproved}})],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
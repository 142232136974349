<template>
  <div>
    <!-- Container-fluid starts-->
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Thiết lập cửa hàng" title="Thêm cửa hàng" />
      </div>
      <div class="row">
        <div class="col">
          <div class="card tab2-card">
            <div class="card-header">
              <h5 v-if="this.$route.query.id">
                Cập nhật thông tin cửa hàng
              </h5>
              <h5 v-else>
                Thêm cửa hàng
              </h5>
            </div>
            <div class="card-body">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <b-form
                  class="needs-validation user-add"
                  @submit.stop.prevent="onSubmit()"
                  v-if="storeInfo"
                >
                  <div class="row">
                    <div class="col-sm-6">
                      <validation-provider
                        name="Tên cửa hàng"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Tên cửa hàng
                          </template>
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="storeInfo.name"
                            placeholder="Nhập tên cửa hàng"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Hotline"
                        :rules="{
                          regexPhone: /^[0-9]+$/,
                          min: 10,
                          max: 11,
                          required: true,
                        }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-6"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-6"
                        >
                          <template v-slot:label>
                            <span>*</span>Hotline
                          </template>
                          <b-form-input
                            id="example-input-6"
                            name="example-input-6"
                            v-model="storeInfo.phone_number"
                            placeholder="Nhập hotline"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-6-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-6-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Trạng thái"
                        :rules="{}"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            Trạng thái
                          </template>
                          <b-form-checkbox
                            id="checkbox-1"
                            v-model="storeInfo.status"
                            name="checkbox-1"
                          >
                            Đang hoạt động
                          </b-form-checkbox>

                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>

                    <div class="col-sm-6">
                      <validation-provider
                        name="Địa chỉ"
                        :rules="{}"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            Địa chỉ
                          </template>
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="storeInfo.address"
                            placeholder="Nhập địa chỉ"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Thành phố"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="exampleInputGroup3"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="exampleInput3"
                        >
                          <template v-slot:label>
                            <span>*</span>Thành phố
                          </template>
                          <multiselect
                            placeholder="Chọn thành phố"
                            v-model="storeInfo.city"
                            :custom-label="searchingSelect"
                            class="rbt-custom-input"
                            @input="onChangeCity"
                            :show-labels="false"
                            :options="cityList"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>

                          <b-form-invalid-feedback id="inputLiveFeedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Quận huyện"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="exampleInputGroup3"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="exampleInput3"
                        >
                          <template v-slot:label>
                            <span>*</span>Quận huyện
                          </template>
                          <multiselect
                            placeholder="Chọn quận huyện"
                            v-model="storeInfo.district"
                            :show-labels="false"
                            :custom-label="searchingSelect"
                            class="rbt-custom-input"
                            :options="districtList"
                            :disabled="!storeInfo.city"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>

                          <b-form-invalid-feedback id="inputLiveFeedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="pull-right">
                    <button
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="handleBackList()"
                    >
                      Trở lại
                    </button>
                    <button
                      v-if="!$route.query.id"
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="cancelUpdate()"
                    >
                      Xóa
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      :disabled="invalid"
                    >
                      Lưu
                    </button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loader from "../../components/loading/loader.vue";

const initialData = {
  name: "",
  address: "",
  phone_number: "",
  status: true,
  district: "",
  city: "",
};

export default {
  data() {
    const storeInfo = this.$route.query.id ? this.storeInfo : initialData;
    return {
      storeInfo,
    };
  },
  components: {
    Loader,
  },
  async created() {
    if (this.$route.query.id) {
      this.storeInfo = await this.getStoreDetail(this.$route.query.id);
    } else {
      this.storeInfo = {
        ...initialData,
      };
    }
    this.getCityList();
    const { city } = this.storeInfo;
    const idCity = city?.id;
    if (idCity) {
      this.getDistrictList({ id: idCity });
    }
  },
  computed: {
    ...mapState("store", ["isLoading"]),
    ...mapState("city", ["cityList", "districtList"]),
  },
  watch: {},
  methods: {
    ...mapActions("store", ["updateStore", "createStore", "getStoreDetail"]),
    ...mapActions("city", ["getCityList", "getDistrictList"]),
    onSubmit() {
      const { city, district, ...data } = this.storeInfo;
      const warehouseData = {
        city: city?.id,
        district: district?.id,
        ...data,
      };
      if (this.storeInfo.id) {
        const dataUpdate = { id: this.storeInfo.id, data: warehouseData };
        this.updateStore(dataUpdate);
      } else {
        this.createStore(warehouseData);
      }
    },
    cancelUpdate() {
      this.storeInfo = {
        ...initialData,
      };
    },
    searchingSelect({ name }) {
      return name;
    },
    handleBackList() {
      this.$router.push({ name: "store-list" });
    },
    onChangeCity() {
      const { city } = this.storeInfo;
      this.getDistrictList({ id: city?.id });
      this.storeInfo.district = "";
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated
        ? (valid = valid === true ? null : valid)
        : null;
    },
  },
};
</script>
<style scoped></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loader',{attrs:{"is-visible":_vm.isLoading}}),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"page-header"},[_c('Breadcrumbs',{attrs:{"title":"Danh sách sản phẩm trong kho"}})],1)]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h5',[_vm._v(_vm._s(_vm.totalRecord)+" kết quả")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row col-md-12"},[_c('b-form-group',{staticClass:"datatable-select col-md-5"},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Tìm kiếm"},on:{"change":_vm.onSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"col-md-5"},[_c('b-button',{staticClass:"btn-popup",attrs:{"variant":"primary"},on:{"click":_vm.onSearch}},[_vm._v("Tìm")])],1)],1),_c('div',{staticClass:"table-responsive datatable-vue product-list"},[(!_vm.isLoading && _vm.isListProduct.length > 0)?_c('b-table',{staticClass:"text-center",attrs:{"show-empty":"","striped":"","hover":"","head-variant":"light","bordered":"","stacked":"md","items":_vm.isListProduct,"fields":_vm.tablefields},scopedSlots:_vm._u([{key:"cell(image)",fn:function(field){return [(field.image)?_c('img',{attrs:{"height":"60px","src":_vm.item.image,"width":"60px"}}):_vm._e(),(!field.image)?_c('img',{attrs:{"height":"60px","src":require("../../assets/images/image_default.png"),"width":"60px"}}):_vm._e()]}},{key:"cell(product)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"cell(price)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.price))+" ")]}},{key:"cell(quantity)",fn:function(ref){
var item = ref.item;
return _vm._l((item.inventories),function(inventory){return _c('div',{key:inventory.index},[(inventory.warehouse === _vm.idWarehouse)?_c('div',[_c('span',[_vm._v(" "+_vm._s(inventory.quantity)+" ")])]):_vm._e()])})}},{key:"cell(unit)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.unit)+" ")])]}},{key:"cell(category)",fn:function(ref){
var item = ref.item;
return [(item.category)?_c('span',[_vm._v(" "+_vm._s(item.category.name)+" ")]):_vm._e()]}}],null,false,721418753)}):_vm._e(),(!_vm.isLoading && _vm.isListProduct.length === 0)?_c('div',{staticClass:"text-center"},[_vm._v(" Không có dữ liệu ")]):_vm._e()],1),_c('b-col',{staticClass:"my-1 p-0 pagination-justify",attrs:{"md":"12"}},[(_vm.totalRecord > 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRecord,"per-page":_vm.pageSize,"aria-controls":"my-table"},on:{"change":_vm.handlePageChange},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}}):_vm._e()],1)],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Chi tiết quyền hệ thống" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5 class="pull-left">
                Danh sách quyền của {{ roleDetail.name }}
              </h5>
            </div>
            <div class="card-body">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <b-form
                  class="needs-validation user-add"
                  @submit.prevent="onEdit(roleDetail)"
                >
                  <validation-provider>
                    <b-form-group
                      id="example-input-group-1"
                      label-cols-sm="3"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="9"
                      label-for="example-input-1"
                    >
                      <template v-slot:label>
                        Vai trò
                      </template>
                      <b-form-input
                        id="example-input-1"
                        name="example-input-1"
                        v-model="roleDetail.name"
                        disabled
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>
                  </validation-provider>
                  <div class="table-responsive datatable-vue product-list">
                    <b-table
                      v-if="!isLoading && roleDetail.group_data"
                      class="text-center"
                      striped
                      hover
                      head-variant="light"
                      bordered
                      stacked="md"
                      :items="roleDetailList"
                      :fields="tablefields"
                    >
                      <template v-slot:cell(name)="{ item }">
                        <span v-if="item.name">
                          {{ item.name }}
                        </span>
                      </template>
                      <template v-slot:cell(description)="{ item }">
                        <span v-if="dataConvert[item.name]">
                          {{ dataConvert[item.name] }}
                        </span>
                      </template>
                      <template v-slot:cell(1)="{ item }">
                        <input
                          type="checkbox"
                          id="flexCheckChecked"
                          @change="onChangeRole($event, 1, item.name)"
                          :checked="item.permissions.indexOf(1) !== -1"
                        />
                      </template>
                      <template v-slot:cell(2)="{ item }">
                        <input
                          type="checkbox"
                          value="2"
                          id="flexCheckChecked2"
                          @change="onChangeRole($event, 2, item.name)"
                          :checked="item.permissions.indexOf(2) !== -1"
                        />
                      </template>
                      <template v-slot:cell(3)="{ item }">
                        <span>
                          <input
                            type="checkbox"
                            value="3"
                            id="flexCheckChecked3"
                            @change="onChangeRole($event, 3, item.name)"
                            :checked="item.permissions.indexOf(3) !== -1"
                          />
                        </span>
                      </template>
                      <template v-slot:cell(4)="{ item }">
                        <span>
                          <input
                            type="checkbox"
                            value="4"
                            id="flexCheckChecked4"
                            @change="onChangeRole($event, 4, item.name)"
                            :checked="item.permissions.indexOf(4) !== -1"
                          />
                        </span>
                      </template>
                    </b-table>
                    <div class="text-center" v-else>
                      Không có dữ liệu
                    </div>
                  </div>
                  <div class="pull-right mt-4">
                    <button
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="handleBackList()"
                    >
                      Trở lại
                    </button>
                    <b-button
                      type="submit"
                      class="btn btn-primary"
                      :disabled="invalid"
                      >Cập nhật</b-button
                    >
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import DescriptionRole from "../../mocks/descriptionRole.json";

export default {
  data() {
    const data = DescriptionRole;
    return {
      group_data: [
        {
          app: "",
          name: "",
          permissions: [],
        },
      ],
      dataConvert: data,
      tablefields: [
        // { key: "name", label: "Tên bảng" },
        { key: "description", label: "Mô tả" },
        { key: "1", label: "Xem" },
        { key: "2", label: " Thêm mới" },
        { key: "3", label: "Cập nhật" },
        { key: "4", label: "Xoá" },
      ],
      roleDetail: {},
    };
  },
  components: {
    Loader,
  },
  async created() {
    const roleId = this.$route.query.id || 1;
    const data = await this.getRoleDetail(roleId);
    this.roleDetail = data;
  },
  watch: {},
  computed: {
    ...mapState("role", ["isLoading"]),
    roleDetailList() {
      const dataRoleFilter = [];
      this.roleDetail.group_data.filter((item) => {
        if (this.dataConvert[item.name] != "-1") {
          dataRoleFilter.push(item);
        }
      });
      return dataRoleFilter;
    },
  },
  methods: {
    ...mapActions("role", ["getRoleDetail", "updateRoleDetail"]),
    onEdit(data) {
      const { id } = data;
      const groupDataConvert = this.roleDetail.group_data.map(
        (obj) => this.group_data.find((o) => o.name === obj.name) || obj
      );
      const dataSubmit = {
        group_data: groupDataConvert,
        ...data,
      };
      this.updateRoleDetail({ id: id, data: dataSubmit });
    },
    // gửi luôn lên api khi check
    onChangeRole(event, idRole, tableName) {
      const value = event.target.checked;
      const allRole = this.roleDetail.group_data;
      for (const role in allRole) {
        const { app, name, permissions } = allRole[role];
        if (name === tableName) {
          if (value && permissions.indexOf(idRole) === -1) {
            permissions.push(idRole);
            this.group_data[0].permissions = permissions;
            this.group_data[0].app = app;
            this.group_data[0].name = name;
          } else {
            let index = permissions.indexOf(idRole);
            if (index !== -1) {
              permissions.splice(index, 1);
              this.group_data[0].permissions = permissions;
              this.group_data[0].app = app;
              this.group_data[0].name = name;
            }
          }
        }
      }
    },
    handleBackList() {
      this.$router.push({ name: "role-list" });
    },
    getValidationState({ validated, valid = null }) {
      return validated ? (valid = valid === true ? null : valid) : null;
    },
  },
};
</script>

export const QUOTATION_STATUS = {
  SUPPLIER: {
    0: "Mới tạo",
    1: "Đang áp dụng",
    2: "Đã duyệt",
    3: "Từ chối",
  },
  APPLY_PRICE: {
    PAUSE: "Tạm dừng",
    APPLIED: "Đang áp dụng",
    NEW: "Mới tạo",
    APPROVED: "Đã duyệt",
  },
  APPEND_COLOR: {
    0: "warning",
    1: "success",
    2: "primary",
    3: "danger",
  },
};

export const StatusMock = [
  {
    status: 0,
  },
  {
    status: 1,
  },
  {
    status: 2,
  },
  {
    status: 3,
  },
];

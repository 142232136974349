<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs
          main="Thiết lập chung"
          title="Thiết lập chiết khấu khách hàng"
        />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header"></div>
            <div class="card-body">
              <div class="row">
                <b-form-group class="datatable-select col-md-4">
                  <b-form-input
                    type="text"
                    v-model="search"
                    placeholder="Tìm kiếm"
                    @change="onSearch()"
                  ></b-form-input>
                </b-form-group>
                <b-form-select v-model="store" class="col-sm-2">
                  <option value="">Lọc theo cửa hàng</option>
                  <option
                    v-for="store in storeList"
                    :key="store.index"
                    :value="store.id"
                  >
                    {{ store.name }}
                  </option>
                </b-form-select>
                <div class="col-md-6">
                  <b-button
                    class="btn-popup"
                    variant="primary"
                    @click="onSearch()"
                    >Tìm</b-button
                  >
                </div>
              </div>
              <div class="table-responsive datatable-vue product-list">
                <b-table
                  v-if="personDiscountList.length > 0"
                  :select-mode="selectMode"
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="personDiscountList"
                  :fields="tablefields"
                  ref="selectableTable"
                  :selectable="true"
                  @row-selected="rowSelected"
                >
                  <template v-slot:cell(user)="{ item }">
                    <span>
                      {{ item.name }}
                    </span>
                  </template>
                  <template v-slot:cell(phone_number)="{ item }">
                    <span>
                      {{ item.phone_number }}
                    </span>
                  </template>
                  <template v-slot:cell(discount_for_new_customer)="{ item }">
                    <span> {{ item.discount_for_new_customer }}% </span>
                  </template>
                  <template v-slot:cell(discount_auto)="{ item }">
                    <span> {{ item.discount_auto }}% </span>
                  </template>
                  <template
                    v-slot:cell(personal_discount)="{
                      value,
                      item,
                      field: { key },
                    }"
                  >
                    <span v-if="edit != item.id"
                      >{{ value }}<span v-if="value">%</span></span
                    >
                    <template v-else>
                      <b-form-input
                        style="width: 120px"
                        type="number"
                        autofocus
                        v-model="item[key]"
                        @blur="saveRow(item)"
                        @keyup.enter="handleEnter(item)"
                      />
                    </template>
                  </template>
                </b-table>
                <div class="text-center" v-if="personDiscountList.length === 0">
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRecord > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecord"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";

export default {
  data() {
    return {
      tablefields: [
        { key: "user", label: "Khách hàng" },
        { key: "phone_number", label: "Số điện thoại" },
        {
          key: "discount_for_new_customer",
          label: "Chiết khấu khách hàng mới",
        },
        { key: "discount_auto", label: "Chiết khấu theo giá trị đơn hàng" },
        { key: "personal_discount", label: "Chiết khấu riêng" },
      ],
      search: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      edit: null,
      selectMode: "single",
      store: "",
    };
  },
  components: {
    Loader,
  },
  created() {
    const pageNumber = this.$route.query.page || 1;
    const params = { page: pageNumber, page_size: this.pageSize };
    this.getPersonDiscountList(params);
    this.getStoreList();
  },
  computed: {
    ...mapState("personDiscount", [
      "personDiscountList",
      "isLoading",
      "totalRecord",
    ]),
    ...mapState("store", ["storeList"]),
  },
  watch: {},
  methods: {
    ...mapActions("personDiscount", [
      "getPersonDiscountList",
      "updatePersonDiscount",
    ]),
    ...mapActions("store", ["getStoreList"]),
    onSearch() {
      let params = {
        page: 1,
        page_size: this.pageSize,
      };
      if (this.search) {
        params = { search: this.search.trim(), ...params };
      }
      if (this.store) {
        params = { store: this.store, ...params };
      }
      this.getPersonDiscountList(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          name: "person-discount-list",
          query: { page: this.pageNumber },
        });
      }
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.search) {
        params = {
          search: this.search.trim(),
          ...params,
        };
      }
      if (this.store) {
        params = { store: this.store, ...params };
      }
      this.getPersonDiscountList(params);
      this.$router.push({
        name: "person-discount-list",
        query: { page: this.pageNumber },
      });
    },
    rowSelected(item) {
      if (item[0]) {
        this.edit = this.edit !== item[0].id ? item[0].id : null;
      }
    },
    handleDiscount(item) {
      const { id, personal_discount } = item;
      const dataSubmit = {
        id,
        data: { personal_discount },
      };
      this.updatePersonDiscount(dataSubmit);
    },
    saveRow(item) {
      this.handleDiscount(item);
    },
    handleEnter(item) {
      const { id } = item;
      this.handleDiscount(item);
      const currentIndex = this.personDiscountList.findIndex(
        (i) => i.id === id
      );
      if (currentIndex < this.personDiscountList.length - 1) {
        const nextIndex = currentIndex + 1;
        this.$refs.selectableTable.selectRow(nextIndex);
      } else {
        this.edit = null;
      }
    },
  },
};
</script>

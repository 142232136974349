<template>
  <div>
    <!-- Container-fluid starts-->
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Quản lý phiếu thu/chi" />
      </div>
      <div class="row">
        <div class="col">
          <div class="card tab2-card">
            <div class="card-header">
              <h5 v-if="this.$route.query.id">
                Cập nhật phiếu thu/chi
              </h5>
              <h5 v-else>
                Thêm phiếu thu/chi
              </h5>
            </div>
            <div class="card-body">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <b-form
                  class="needs-validation user-add"
                  @submit.stop.prevent="onSubmit()"
                  v-if="transactionReceiptInfo"
                >
                  <div class="row">
                    <div class="col-sm-6 col-md-12">
                      <validation-provider
                        name="Người giao dịch"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Người giao dịch</template
                          >
                          <multiselect
                            placeholder="Chọn khách hàng hoặc nhân viên"
                            :show-labels="false"
                            class="rbt-custom-input"
                            :disabled="!!$route.query.id"
                            v-model="transactionReceiptInfo.transaction_person"
                            @search-change="search"
                            :internal-search="false"
                            :options="userList"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        :rules="{ required: true }"
                        v-slot="validationContext"
                        name="Loại giao dịch"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Loại giao dịch
                          </template>
                          <b-form-radio-group
                            id="radio-group-3"
                            v-model="transactionReceiptInfo.type"
                            :disabled="!!$route.query.id"
                            name="radio"
                          >
                            <b-form-radio value="income_ballot"
                              >Phiếu thu</b-form-radio
                            >
                            <b-form-radio value="spending_ballot"
                              >Phiếu chi</b-form-radio
                            >
                          </b-form-radio-group>
                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        id="example-input-group-1"
                        name="Số tiền giao dịch"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span class="">*</span>Số tiền giao dịch
                          </template>
                          <b-form-input
                            v-model="transactionReceiptInfo.money_transcation"
                            v-if="!$route.query.id"
                            placeholder="Số tiền giao dịch"
                            :state="getValidationState(validationContext)"
                          ></b-form-input>
                          <span v-else>{{
                            transactionReceiptInfo.money_transcation
                              | formatCurrency
                          }}</span>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        :rules="{ required: true }"
                        v-slot="validationContext"
                        name="Hình thức"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Hình thức giao dịch
                          </template>
                          <b-form-radio-group
                            id="radio-group-2"
                            v-model="transactionReceiptInfo.method"
                            name="radio-sub-component"
                            v-if="!$route.query.id"
                          >
                            <b-form-radio
                              value="cash"
                              v-if="
                                roleUserProfile === roleList.employee ||
                                  roleUserProfile === roleList.admin
                              "
                              >Tiền mặt</b-form-radio
                            >
                            <b-form-radio
                              value="transfer"
                              v-if="
                                roleUserProfile === roleList.accountant ||
                                  roleUserProfile === roleList.admin
                              "
                              >Chuyển khoản</b-form-radio
                            >
                            <span v-else class="text-danger"
                              >Không có quyền thực hiện!!!</span
                            >
                          </b-form-radio-group>
                          <b-form-radio-group
                            id="radio-group-2"
                            v-model="transactionReceiptInfo.method"
                            name="radio-sub-component"
                            v-if="$route.query.id"
                            :disabled="!!$route.query.id"
                          >
                            <b-form-radio value="cash">Tiền mặt</b-form-radio>
                            <b-form-radio value="transfer"
                              >Chuyển khoản</b-form-radio
                            >
                          </b-form-radio-group>
                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Ngày giao dịch"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Ngày giao dịch
                          </template>
                          <date-picker
                            v-model="transactionReceiptInfo.transaction_time"
                            v-if="!$route.query.id"
                            type="datetime"
                            placeholder="Chọn thời gian giao dịch"
                            :show-time-panel="showTimePanel"
                            format="YYYY-MM-DDTHH:mm:ssZ"
                            value-type="format"
                            @close="handleOpenChange"
                          >
                            <template v-slot:footer>
                              <button
                                class="mx-btn mx-btn-text"
                                @click="toggleTimePanel"
                              >
                                {{
                                  showTimePanel ? "Chọn ngày" : "Chọn thời gian"
                                }}
                              </button>
                            </template>
                          </date-picker>
                          <span v-if="!$route.query.id">
                            {{
                              transactionReceiptInfo.transaction_time | showDate
                            }}</span
                          >
                          <span v-else>
                            {{
                              transactionReceiptInfo.transaction_time | showDate
                            }}
                          </span>
                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Ghi chú"
                        :rules="{}"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label="Ghi chú"
                        >
                          <b-form-textarea
                            id="textarea-rows"
                            rows="7"
                            v-model="transactionReceiptInfo.note"
                            :disabled="!!$route.query.id"
                            placeholder="Ghi chú"
                            :state="getValidationState(validationContext)"
                          ></b-form-textarea>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        :rules="{ required: true }"
                        v-slot="validationContext"
                        name="Trạng thái"
                        v-if="!$route.query.id"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            Trạng thái
                          </template>
                          <b-form-radio-group
                            id="radio-group-4"
                            v-model="transactionReceiptInfo.status"
                            name="radio-status"
                          >
                            <b-form-radio value="new_created"
                              >Mới tạo</b-form-radio
                            >
                            <b-form-radio value="confirm">Đã nhận</b-form-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                      <h1 v-if="$route.query.id">
                        <span
                          v-if="transactionReceiptInfo.status == 'new_created'"
                          class="badge badge-warning"
                          >Mới tạo</span
                        >
                        <span
                          v-if="transactionReceiptInfo.status == 'confirm'"
                          class="badge badge-success"
                          >Đã nhận</span
                        >
                      </h1>
                    </div>
                  </div>
                  <div class="pull-right">
                    <button
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="handleBackList()"
                    >
                      Trở lại
                    </button>
                    <button
                      v-if="!$route.query.id"
                      class="btn btn-danger mr-3"
                      type="button"
                      @click="cancelUpdate()"
                    >
                      Xóa
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      v-if="!$route.query.id"
                      :disabled="invalid || !isComplete"
                    >
                      Lưu
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      v-if="
                        transactionReceiptInfo.status == 'new_created' &&
                          $route.query.id
                      "
                      :disabled="invalid"
                    >
                      Xác nhận
                    </button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loader from "../../components/loading/loader.vue";
import { TRANSACTION_STATUS } from "../../config/transactions/index";
import { debounce } from "lodash";
import { addCommaPrice } from "../../config/common";
import { Roles } from "../../types/roles";

const initialData = {
  money_transcation: "",
  type: "income_ballot",
  status: "confirm",
  transaction_person: "",
  method: "",
  note: "",
  transaction_time: new Date(),
};

export default {
  data() {
    const transactionReceiptInfo = this.$route.query.id
      ? this.transactionReceiptInfo
      : initialData;
    return {
      transactionReceiptInfo,
      showTimePanel: false,
    };
  },
  components: {
    Loader,
  },
  async created() {
    if (this.$route.query.id) {
      this.transactionReceiptInfo = await this.getTransactionReceiptDetail(
        this.$route.query.id
      );
    } else {
      this.transactionReceiptInfo = {
        ...initialData,
      };
      if (this.roleUserProfile === "Kế toán") {
        this.transactionReceiptInfo.method = "transfer";
      } else if (this.roleUserProfile === "Nhân viên bán hàng") {
        this.transactionReceiptInfo.method = "cash";
      } else if (this.roleUserProfile === "Quản trị viên") {
        this.transactionReceiptInfo.method = "cash";
      }
      this.transactionReceiptInfo.transaction_time = new Date();
    }
    this.getUserList();
  },
  computed: {
    ...mapState("transactionReceipt", ["isLoading"]),
    ...mapState("user", ["userList"]),
    ...mapState("profile", ["userProfile"]),
    roleUserProfile() {
      return this.userProfile?.groups[0].name;
    },
    defaultDate() {
      return (dataDate = new Date().toISOString());
    },
    isComplete() {
      return !this.transactionReceiptInfo.money_transcation.includes("-");
    },
    roleList() {
      return Roles;
    },
  },
  watch: {
    "transactionReceiptInfo.money_transcation": {
      handler: function(value) {
        if (typeof value == "string") {
          const result = addCommaPrice(value);
          this.$nextTick(
            () => (this.transactionReceiptInfo.money_transcation = result)
          );
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("transactionReceipt", [
      "createTransactionReceipt",
      "updateTransactionReceipt",
      "getTransactionReceiptDetail",
    ]),
    ...mapActions("user", ["getUserList"]),
    onSubmit() {
      const {
        transaction_person,
        status,
        transaction_time,
        money_transcation,
        ...data
      } = this.transactionReceiptInfo;
      if (this.transactionReceiptInfo.id) {
        const transactionReceiptData = {
          id: this.transactionReceiptInfo.id,
          data: { status: TRANSACTION_STATUS.RECEIPT.CONFIRM },
        };
        this.updateTransactionReceipt(transactionReceiptData);
        this.transactionReceiptInfo.status = TRANSACTION_STATUS.RECEIPT.CONFIRM;
      } else {
        const dataSubmit = {
          status: this.transactionReceiptInfo.status,
          transaction_person: transaction_person?.id,
          transaction_time: new Date(transaction_time).toISOString(),
          money_transcation: Number(money_transcation.replace(/,/g, "")),
          ...data,
        };
        this.createTransactionReceipt(dataSubmit);
      }
    },
    cancelUpdate() {
      this.transactionReceiptInfo = {
        ...initialData,
      };
    },
    handleBackList() {
      this.$router.push({ name: "transaction-receipt" });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated
        ? (valid = valid === true ? null : valid)
        : null;
    },
    search: _.debounce(function(query) {
      this.getUserList({ query: query });
    }, 250),
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    handleOpenChange() {
      this.showTimePanel = false;
    },
  },
};
</script>
<style scoped></style>
